import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Image } from "antd";
import Facebook from "../../../../assets/img/social/facebook.png";
import Instagram from "../../../../assets/img/social/instagram.png";
import Line from "../../../../assets/img/social/line.png";

const Forms = ({ form }: any) => {
  const [data, setData] = useState<any>({
    facebook: "",
    line: "",
    instagram: "",
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    try {
      const datas = {
        facebook: "wwww.facebook.com",
        line: "line_play",
        instagram: "ig_addme",
      };
      const res = { code: 200 };
      if (res.code === 200) {
        setData({
          ...data,
          facebook: datas.facebook,
          line: datas.line,
          instagram: datas.instagram,
        });
        form.setFieldsValue({
          ...datas,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Row gutter={20}>
        <Col lg={2} offset={1} className="mt-2 border-social">
          <div className="icon-social">
            <Image src={Facebook} width={25}></Image>
            <span>Facebook</span>
          </div>
        </Col>
        <Col lg={12}>
          <Form.Item label="ID" name="facebook">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col lg={2} offset={1} className="mt-2 border-social">
          <div className="icon-social">
            <Image src={Line} width={25}></Image>
            <span>Line</span>
          </div>
        </Col>
        <Col lg={12}>
          <Form.Item label="ID" name="line_id">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col lg={2} offset={1} className="mt-2 border-social">
          <div className="icon-social">
            <Image src={Instagram} width={25}></Image>
            <span>Instagram</span>
          </div>
        </Col>
        <Col lg={12}>
          <Form.Item label="ID" name="instagram">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Forms;
