import React from "react";
import {
  Row,
  Col,
  Button,
  //Tabs
} from "antd";
import Fields from "./Fields";
import { useHistory } from "react-router-dom";
import BlackHome from "../../../../assets/img/icons/black-home.svg";

const Form = () => {
  const history = useHistory();
  // const { TabPane } = Tabs;
  // const changeTab = (e: any) => {
  //   setLang(e === "1" ? "th" : "en");
  // };

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="blackHome">
            <Button
              onClick={() => history.goBack()}
              type="primary"
              shape="circle"
            >
              <img className="buttonBlackHome" src={BlackHome} alt="" />
            </Button>
          </span>
          <span className="text-head">บทความ</span>
        </Col>
      </Row>

      <div className="set-layout">
        <div className="pt-1">
          {/* <Tabs
            className="tab-mainmenu"
            defaultActiveKey="1"
            onChange={(e) => changeTab(e)}
          >
            {/* <TabPane tab="ไทย" key="1">
              <Fields lang={lang} />
            </TabPane>
            <TabPane tab="อังกฤษ" key="2">
              <Fields lang={lang} />
            </TabPane> *
          </Tabs> */}
          <Fields />
        </div>
      </div>
    </div>
  );
};

export default Form;
