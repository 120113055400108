import React, { useState, useEffect } from "react";
import MoTable from "../../../component/table/Table";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Switch, Avatar } from "antd";
import { useHistory } from "react-router-dom";
import {
  User,
  SearchValue,
  IGetAdminList,
} from "../../../interface/IUsers.interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import {
  deleteAdminList,
  getUserList,
  putAdminActive,
} from "../../../services/api/user";
import { openNotification } from "../../../component/notification/Notification";
import Modaler from "../../../component/modaler/Modaler";
import { IResStatus } from "../../../tools/fecth/interface";
import { getRoleList } from "../../../services/api/role";
import { _isEmpty } from "../../../tools/util";

type Props = {
  search: SearchValue;
  setRoleList: any;
};

const UserTab = ({ search, setRoleList }: Props) => {
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>();
  const [visible, setVisible] = useState<boolean>(false);
  const setLoading = useSetRecoilState<boolean>(loadingState);
  const history = useHistory();
  const [adminList, setAdminList] = useState<IGetAdminList[]>([]);
  const [id, setId] = useState<number>(0);
  const [firstWorks, setFirstWorks] = useState<boolean>(true);

  useEffect(() => {
    if (firstWorks) handleRoleList();

    if (!_isEmpty(search)) {
      setCurrentPage(1);
    }
    setFirstWorks(false);
    getDataUserList();
    // eslint-disable-next-line
  }, [currentPage, limitPage, search]);

  const getDataUserList = async () => {
    setLoading(true);
    try {
      const res = await getUserList({
        page: currentPage,
        limit: limitPage,
        ...search,
      });
      if (res.code === 200) {
        setTotal(res.result[0].total);
        setAdminList(res.result[0].item);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDeleteAdminList = async (id: number) => {
    try {
      const res = await deleteAdminList(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        getDataUserList();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }
    onModal();
  };

  const handleUpdateActive = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await putAdminActive(id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        getDataUserList();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onDelete = () => {
    handleDeleteAdminList(id);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const onClickRow = (row: any) => {
    history.push(`/user/user/edit/${row.id}`);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const handleRoleList = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await getRoleList();
      if (res.code === 200) {
        setRoleList(res.result[0].item);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "10%",
      render: (status: boolean, data: any) => {
        return (
          <>
            <Switch
              checked={status}
              onClick={() => handleUpdateActive(data.id)}
            />
          </>
        );
      },
    },
    {
      title: "รูปภาพ",
      dataIndex: "image",
      key: "image",
      align: "center",
      width: "10%",
      render: (_: string, media: User) => {
        return (
          <div key={media.image.id}>
            {media.image === null ? (
              <Avatar shape="square" size={60} icon={<UserOutlined />} />
            ) : (
              <img
                className="img-squad"
                src={media.image?.file_path}
                alt="user"
              />
            )}
          </div>
        );
      },
    },
    {
      title: "ชื่อ - นามสกุล",
      dataIndex: "name",
      key: "name",

      width: "15%",
      render: (_: string, data: User) => {
        return (
          <div key={data.id}>
            <b>{data.first_name + " " + data.last_name}</b>
          </div>
        );
      },
    },
    {
      title: "อีเมลล์",
      dataIndex: "email",
      key: "email",
      // align: "center",
      width: "20%",
    },
    {
      title: "เบอร์โทร",
      dataIndex: "phone",
      key: "phone",
      // align: "center",
      width: "15%",
    },
    {
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
      // align: "center",
      width: "20%",

      render: (_: string, data: any) => {
        return <div key={data.id}>{data.role.name}</div>;
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      width: "20%",

      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                e.stopPropagation();
                setId(data.id);
                onModal();
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="25%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            คุณแน่ใจไหม?
          </div>
        }
      >
        คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
      </Modaler>

      <MoTable
        dataSource={adminList}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
      />
    </div>
  );
};

export default UserTab;
