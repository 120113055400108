import { Button, Card, Col, Form, Input, InputNumber, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import MediaModal from "../../../../component/mediamodal/MediaModal";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
// import MediaModal from "../../../../component/mediamodal/MediaModal";
// import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import { openNotification } from "../../../../component/notification/Notification";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import {
  getLogDeviceByID,
  postLogDeviceList,
  putLogDeviceList,
} from "../../../../services/api/log_device";
import { IResStatus } from "../../../../tools/fecth/interface";
import {
  loadingButtonState,
  loadingState,
} from "../../../../tools/recoil/recoil-atom";
import { _isEmpty } from "../../../../tools/util";

const Fields = () => {
  // const [visible, setVisible] = useState<boolean>(false);

  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });

  const [form] = Form.useForm();
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [visible, setVisible] = useState<boolean>(false);
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);
  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    if (!_isEmpty(pathID)) {
      handleGetLogDeviceByID();
    }
  };

  const onFinish = (values: any) => {
    if (!_isEmpty(pathID)) {
      handleUpdateLogDevice(values);
    } else {
      handleCreatLogDevice(values);
    }
  };

  const handleGetLogDeviceByID = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getLogDeviceByID(pathID);
      if (res.code === 200) {
        const data = res.result[0];
        setMediaSelect({
          file: data.media?.file_path,
          id: data.media?.id,
          media_name: data.media?.media_name,
        });
        form.setFieldsValue({
          ...data,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleCreatLogDevice = async (values: any) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await postLogDeviceList({
        ...values,
        media_id: mediaSelect.id,
      });
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const handleUpdateLogDevice = async (values: any) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await putLogDeviceList(
        {
          ...values,
          media_id: mediaSelect.id,
        },
        pathID
      );
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  // const onModal = () => {
  //   setVisible(!visible);
  // };

  const onModal = () => {
    setVisible(!visible);
  };
  return (
    <div>
      <MediaModal
        visible={visible}
        onModal={onModal}
        setMediaSelect={setMediaSelect}
      />
      {/* <MediaModal visible={visible} onModal={onModal} /> */}
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Card
          extra={!_isEmpty(pathID) ? "แก้ไขอุปกรณ์ล็อค" : "สร้างอุปกรณ์ล็อค"}
          actions={[
            <Row justify="end">
              <Col>
                <Button type="default" onClick={() => history.goBack()}>
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button
                  className="btn-save"
                  htmlType="submit"
                  loading={loadingButton}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row justify="center">
            <Col lg={12}>
              <Row gutter={[14, 14]}>
                <Col lg={12}>
                  <Form.Item
                    label="ชื่ออุปกรณ์ล็อค (TH)"
                    name="name_th"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="ชื่ออุปกรณ์ล็อค (EN)"
                    name="name_en"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    label="ชื่อ SKU"
                    name="sku"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="สี"
                    name="color"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="ราคา"
                    name="price"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <InputNumber addonAfter="บาท" />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    key={mediaSelect.media_name ? "mediaHave" : "mediaNull"}
                    label="รูปอุปกรณ์ล็อค"
                    name="media_id"
                    rules={[
                      {
                        required: _isEmpty(mediaSelect.media_name)
                          ? true
                          : false,
                        message: "โปรดเลือกรูปภาพ!",
                      },
                    ]}
                  >
                    <MediaSpace onModal={onModal} mediaSelect={mediaSelect} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};
export default Fields;
