/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import {
  editProjectContentCategory,
  addProjectContentCategory,
  getProjectContentCategoryById,
} from "../../../../services/api/project";
import { openNotification } from "../../../../component/notification/Notification";
import { useHistory, useRouteMatch } from "react-router-dom";

const Fields = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;

  useEffect(() => {
    if (pathID) {
      GetDataByID();
    }
  }, []);

  const GetDataByID = async () => {
    try {
      const res = await getProjectContentCategoryById(pathID);
      if (res.code === 200) {
        const result = res.result[0];
        form.setFieldsValue({
          topicTh: result.name_th,
          topicEn: result.name_en,
        });
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
        history.push({
          pathname: "/project",
          state: { tabSelected: 2 },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (values: any) => {
    const id = pathID;
    const dataUpdate = {
      name_en: values.topicEn,
      name_th: values.topicTh,
    };
    try {
      const res = id
        ? await editProjectContentCategory(id, dataUpdate)
        : await addProjectContentCategory(dataUpdate);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        form.resetFields();
        history.push({
          pathname: "/project",
          state: { tabSelected: 2 },
        });
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        name="projectType"
      >
        <Card
          extra={"สร้างหมวดหมู่โครงการ"}
          actions={[
            <Row justify="end">
              <Col>
                <Button
                  type="default"
                  onClick={() => {
                    history.push({
                      pathname: "/project",
                      state: { tabSelected: 2 },
                    });
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button className="btn-save" htmlType="submit">
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row gutter={14} justify="center">
            <Col lg={12}>
              <Form.Item
                label={"ชื่อหมวดหมู่โครงการ (TH)"}
                name="topicTh"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={14} justify="center" style={{ marginTop: "20px" }}>
            <Col lg={12}>
              <Form.Item
                label={"ชื่อหมวดหมู่โครงการ (EN)"}
                name="topicEn"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
