import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from '../../tools/util'


export const GET_MENU_API = async (data?:any) => {
    const value = createQueryString(data);
    const res = await Fecth({
        method: "GET",
        path: `${version}/menu${value}`,
    })
    return res
}
