import { Col, Row } from "antd";

const InfoOnRow = ({ topic, data }: { topic: string; data: string }) => {
  return (
    <Row style={{ paddingTop: "4px" }}>
      <Col lg={7}>{topic}</Col>
      <Col className="pl-1" lg={2}>
        :
      </Col>
      <Col className="pl-1" lg={15} style={{ wordBreak: "break-all" }}>
        {data}
      </Col>
    </Row>
  );
};
export default InfoOnRow;
