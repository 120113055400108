
import { version } from "../../config/api"
import { IInstallationPost } from "../../interface/IInstallation.interface"
import { Fecth } from "../../tools/fecth/fecth"
import { createQueryString } from "../../tools/util"

export const getInstallation = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/installation${queryStr}`,
    })
    return res
}


export const getInstallationByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/installation/${id}`,
    })
    return res
}



export const postInstallation = async (data: IInstallationPost) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/installation`,
    })
    return res
}
export const putInstallation = async (data: IInstallationPost, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/installation/${id}`,
    })
    return res
}


export const putInstallationActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/installation/active/${id}`,
    })
    return res
}


export const delInstallation = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/installation/${id}`,
    })
    return res
}




