import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import Header from "./Header";
import ApproveTable from "./table/Table";
import { useHistory } from "react-router-dom";
import { getApproves } from "../../services/api/approve";
import { IResStatus } from "../../tools/fecth/interface";
import { IApproves } from "../../interface/IApprove.interface";

const ApproveManagement = () => {
  // const [isFirstWork, setFirstWork] = useState(true);
  const [approveList, setApproveList] = useState<IApproves[]>([]);
  const [currentPage, setCurrenPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [filterValue, setFilterValue] = useState<{}>({});

  const history = useHistory();

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, [currentPage, limitPage, filterValue]);

  const handleGetData = () => {
    handleGetApproves();
  };

  const handleGetApproves = async () => {
    try {
      const res: IResStatus = await getApproves({
        ...filterValue,
        page: currentPage,
        limit: limitPage,
      });
      if (res.code === 200) {
        const data = res.result[0];

        setApproveList(data.items);
        setTotal(data.total);
      }
    } catch (error) {}
  };
  const onFinish = (value: any) => {
    const filterFormat = {
      ...value,
      start_date: value.date ? Number(value.date[0]) : "",
      end_date: value.date ? Number(value.date[1]) : "",
    };
    delete filterFormat.date;
    setFilterValue(filterFormat);
    // setModalVisible(true);
  };

  const onClickRow = (record: IApproves) => {
    history.push({
      pathname: `/approve-management/${record.id}`,
    });
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrenPage(page);
  };
  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">จัดการอนุมัติ</span>
        </Col>
      </Row>
      <div className="set-layout">
        <Header onFinish={onFinish} />
        <div className="pt-1">
          <ApproveTable
            dataSource={approveList}
            onClickRow={onClickRow}
            currentPage={currentPage}
            limitPage={limitPage}
            total={total}
            onChangePage={onChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default ApproveManagement;
