import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import MoTable from "../../../component/table/Table";
import {
  delColor,
  getColors,
  putColorActive,
} from "../../../services/api/colors";
import { IResStatus } from "../../../tools/fecth/interface";
import { useHistory } from "react-router-dom";
import { openNotification } from "../../../component/notification/Notification";
import { IColors } from "../../../interface/IColors.inteface";
import Modaler from "../../../component/modaler/Modaler";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import { DateTimeStandard } from "../../../tools/date";
import { IEditor } from "../../../interface/IEditor.interface";
import { IMedia } from "../../../interface/IMedia.interface";
import { _isEmpty } from "../../../tools/util";

type Props = {
  filterValue: any;
};
const Colors = ({ filterValue }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [colorsList, setColorsList] = useState<IColors[]>([]);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [visible, setVisible] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const history = useHistory();
  const setLoading = useSetRecoilState(loadingState);

  const [total, setTotal] = useState<number>(1);
  useEffect(() => {
    handleGetData();

    // eslint-disable-next-line
  }, [currentPage, limitPage, filterValue]);
  const handleGetData = () => {
    handleGetColors();
  };

  const handleGetColors = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getColors({
        page: currentPage,
        limit: limitPage,
        ...filterValue,
      });
      if (res.code === 200) {
        setColorsList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleteColors = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await delColor(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetColors();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    onModal();
    setLoading(false);
  };

  const handleUpdateActive = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await putColorActive(id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetColors();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };
  const onClickRow = (event: any) => {
    history.push("/product/color/" + event.id);
  };
  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleteColors(id);
  };

  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "8%",
      render: (status: boolean, data: any) => {
        return (
          <>
            <Switch
              checked={status}
              onClick={() => handleUpdateActive(data.id)}
            />
          </>
        );
      },
    },
    // {
    //   title: "โค้ดสี",
    //   dataIndex: "color_code",
    //   key: "color_code",
    //   width: "20%",
    // },
    {
      title: "รูปภาพ",
      dataIndex: "media",
      key: "media",
      width: "10%",
      align: "center",

      render: (media: IMedia) => {
        return (
          <>
            <img
              src={media.file_path ? media.file_path : ""}
              className="img-squad"
              alt={media.media_name}
            />
          </>
        );
      },
    },

    {
      title: "ชื่อสี",
      dataIndex: "name_th",
      key: "name_th",
      width: "15%",
      render: (name_th: string) => {
        return (
          <>
            <b>{name_th}</b>
          </>
        );
      },
    },

    {
      title: "ประเภทสี",
      dataIndex: "color_type",
      key: "color_type",
      width: "40%",
      render: (colorType: any) => {
        return <>{colorType.name_th}</>;
      },
    },
    {
      title: "อัปเดต",
      dataIndex: "edit_by",
      key: "edit_by",
      width: "15%",
      render: (editBy: IEditor, data: IColors) => {
        return (
          <>
            {editBy.first_name} {editBy.last_name}
            <br />
            {DateTimeStandard(
              !_isEmpty(data.updated_at) ? data.updated_at : data.created_at
            )}
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                setId(data.id);
                onModal();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div>
        <Modaler
          visible={visible}
          onCancel={onModal}
          onOk={onDelete}
          width="25%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              คุณแน่ใจไหม?
            </div>
          }
        >
          คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
        </Modaler>
        <div className="card-table-tab">
          <MoTable
            dataSource={colorsList}
            columns={columns}
            onChangePage={onChangePage}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            onClickRow={onClickRow}
          />
        </div>
      </div>
    </div>
  );
};

export default Colors;
