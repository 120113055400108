import { IMedia, IMediaCategory } from "../../../interface/IMedia.interface";
import MediaContainer from "./Container";
type Props = {
  onModal?: () => void;
  mediaList?: IMedia[];
  mediaCategoryList: IMediaCategory[];
  onChange?: any;
  handleSelectMedia?: any;
  handleGetMedia: () => void;
  total: number;
  value: any;
  maxCount: number;
};
const Overall = ({
  onModal,
  mediaList,
  mediaCategoryList,
  onChange,
  handleGetMedia,
  handleSelectMedia,
  total,
  value,
  maxCount,
}: Props) => {
  return (
    <div>
      <MediaContainer
        lg={6}
        onModal={onModal}
        mediaList={mediaList}
        mediaCategoryList={mediaCategoryList}
        onChange={onChange}
        handleSelectMedia={handleSelectMedia}
        handleGetMedia={handleGetMedia}
        total={total}
        value={value}
        maxCount={maxCount}
      />
    </div>
  );
};

export default Overall;
