import { Button, Col, Form, Input, Row, Upload, message } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { useRecoilState } from "recoil";

import UploadIcon from "../../assets/img/icons/upload.png";
import { IMediaCategory } from "../../interface/IMedia.interface";
import { postMediaImage } from "../../services/api/media";
import { IResStatus } from "../../tools/fecth/interface";
import { loadingButtonState } from "../../tools/recoil/recoil-atom";

import { _appendFields, _isEmpty } from "../../tools/util";
import { openNotification } from "../notification/Notification";
import Selector from "../selector/Selector";
type Props = {
  mediaCategoryList: IMediaCategory[];
  setTabSelected: Dispatch<SetStateAction<string>>;
  handleGetMedia: () => void;
  onModal?: () => void;
};
const Drager = ({ mediaCategoryList, handleGetMedia }: Props) => {
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);

  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState({});

  const { Dragger } = Upload;

  const [form] = Form.useForm();

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const hadleUploadImage = async (values: any) => {
    setloadingButton(true);
    try {
      const data = _appendFields({
        ...values,
        media: imageFile,
      });
      const res: IResStatus = await postMediaImage(data);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        form.resetFields();
        setImageUrl("");
        handleGetMedia();
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const beforeUpload = (file: any) => {
    const fileName = file.name.split(".");
    form.setFieldsValue({ media_name: fileName[0] });
    return;
  };

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    beforeUpload: beforeUpload,
    onChange(info: any) {
      let fileObj = info.file;
      const isSize = fileObj.size / 1024 / 1024 < 10;
      if (!isSize) {
        message.error("รูปภาพควรมีขนาดไฟล์ไม่เกิน 10MB!");
      } else {
        if (fileObj.type === "image/jpeg" || fileObj.type === "image/png") {
          getBase64(info.file.originFileObj, (imageUrl: any) => {
            setImageUrl(imageUrl);
            setImageFile(info.file.originFileObj);
          });
        } else {
          message.error(
            "อัปโหลดไฟล์ไม่สำเร็จ กรุณาอัปโหลดไฟล์รูปภาพ (png, jpg) เท่านั้น "
          );
          return false;
        }
      }
    },
    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <div>
      <Form onFinish={hadleUploadImage} layout="vertical" form={form}>
        <Row gutter={[14, 14]}>
          <Col lg={24}>
            <Form.Item
              label="มีเดีย"
              name="media"
              valuePropName="file"
              rules={[
                {
                  required: !_isEmpty(imageUrl) ? false : true,
                  message: "โปรดกรอกข้อมูล!",
                },
              ]}
            >
              <Dragger {...props} customRequest={() => {}}>
                {!_isEmpty(imageUrl) ? (
                  <img
                    src={imageUrl}
                    alt="media"
                    className="image-render"
                    style={{ height: "50vh" }}
                  />
                ) : (
                  <>
                    <p className="ant-upload-drag-icon">
                      <img src={UploadIcon} alt="uploader" />
                    </p>
                    <p className="ant-upload-text">
                      กด หรือ ลาก ไฟล์ไปยังพื้นที่อัพโหลดนี้
                    </p>
                    <p className="ant-upload-hint">สำหรับอัพโหลดไฟล์เดียว</p>
                  </>
                )}
              </Dragger>
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="ชื่อมีเดีย"
              name="media_name"
              rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="ประเภทมีเดีย"
              name="media_category_id"
              rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
            >
              <Selector
                Source={mediaCategoryList}
                keyValue="id"
                keyName="name"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="pt-1">
          <Button
            type="primary"
            className="mr-1"
            htmlType="submit"
            loading={loadingButton}
          >
            บันทึก
          </Button>
          <Button
            onClick={() => {
              setImageUrl("");
            }}
            htmlType="reset"
          >
            ล้าง
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Drager;
