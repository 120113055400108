import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'



export const getProductCategoryContentByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/product-content/byCategory/${id}`,
    })
    return res
}

export const putProductCategoryContent = async (data: any, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/product-content/${id}`,
    })
    return res
}




