import MoTable from "../../../component/table/Table";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { IBannerList, IMenuWeb } from "../../../interface/IBanner.interface";
// import { IEditor } from "../../../interface/IEditor.interface";
// import { DateTimeStandard } from "../../../tools/date";
// import { IGallery_banner } from "../../../interface/IInspiration.interface";
// import { IMedia } from "../../../interface/IMedia.interface";
import Modaler from "../../../component/modaler/Modaler";
import { Switch } from "antd";
// import { _isEmpty } from "@yim/util-tool-ts";

type Props = {
  onDelete: () => void;
  onModal: () => void;
  setId: any;
  visible: boolean;
  bannerList: IBannerList[];
  onChangePage: (page: number, type?: string) => void;
  total: number;
  limitPage: number;
  currentPage: number;
  onClickRow: (event: any) => void;
  handleChangeSwitch: (event: any) => void;
};

const Table = ({
  setId,
  onModal,
  visible,
  onDelete,
  bannerList,
  onChangePage,
  total,
  limitPage,
  currentPage,
  onClickRow,
  handleChangeSwitch,
}: Props) => {
  const columns = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "8%",
      render: (status: boolean, soure: any) => {
        return (
          <>
            <Switch
              checked={status}
              onChange={() => {
                handleChangeSwitch(soure);
              }}
            />
          </>
        );
      },
    },
    {
      title: "รูปภาพ",
      dataIndex: "gallery_banner",
      align: "center",
      key: "gallery_banner",
      width: "10%",

      render: (gallery_banner: any) => {
        return (
          <>
            <img
              src={gallery_banner[0].media.file_path}
              className="img-squad"
              alt={gallery_banner[0].media.media_name}
            />
          </>
        );
      },
    },
    {
      title: "เมนู",
      dataIndex: "menu_web",
      key: "menu_web",
      width: "55%",
      render: (menu_web: IMenuWeb) => {
        return (
          <>
            <b>{menu_web.name}</b>
          </>
        );
      },
    },
    {
      title: "ชื่อมีเดีย",
      dataIndex: "gallery_banner",
      key: "gallery_banner",
      width: "15%",

      render: (gallery_banner: any) => {
        return (
          <>
            <b>{gallery_banner[0].media.media_name}</b>
          </>
        );
      },
    },
    // {
    //   title: "อัปเดต",
    //   dataIndex: "edit_by",
    //   align: "center",
    //   key: "edit_by",

    //   render: (editBy: IEditor, data: IBannerList) => {
    //     return (
    //       <>
    //         {/* {editBy.first_name} {editBy.last_name}
    //         <br />
    //         {!_isEmpty(data.updatedAt)
    //           ? DateTimeStandard(data.updatedAt)
    //           : DateTimeStandard(data.createdAt)} */}
    //       </>
    //     );
    //   },
    // },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",

      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                setId(data.id);
                onModal();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <Modaler
          visible={visible}
          onCancel={onModal}
          onOk={onDelete}
          width="25%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              คุณแน่ใจไหม?
            </div>
          }
        >
          คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
        </Modaler>
        <div className="card-table-notab">
          <MoTable
            dataSource={bannerList}
            columns={columns}
            onChangePage={onChangePage}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            onClickRow={onClickRow}
          />
        </div>
      </div>
    </div>
  );
};

export default Table;
