import { _isEmpty } from "../../tools/util";
type Props = {
  src: string;
  alt: string;
  className?: string;
  style?: any;
};
const CImage = ({ src, alt, className, style }: Props) => {
  const defaultImage = process.env.PUBLIC_URL + "/_isEmpty";
  const validateUrl = (url: string) => {
    const regex =
      /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\\/]))?/;
    if (regex.test(url)) {
      return src;
    } else if (url.startsWith("data:")) {
      return url;
    } else {
      return process.env.PUBLIC_URL + url;
    }
  };
  return (
    <>
      <img
        src={!_isEmpty(src) ? validateUrl(src) : defaultImage}
        alt={alt}
        className={className}
        onError={(e: any) => {
          e.target.src! = validateUrl(defaultImage);
        }}
        style={style}
      />
    </>
  );
};

export default CImage;
