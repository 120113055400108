import { Button, Input } from "antd";
import { useState } from "react";
import MediaModal from "../../component/mediamodal/MediaModal";
import left from "../../assets/img/icons/left.svg";
import rigth from "../../assets/img/icons/rigth.svg";
import center from "../../assets/img/icons/center.svg";
import H1 from "../../assets/img/icons/H1.svg";
import H2 from "../../assets/img/icons/H2.svg";
import H3 from "../../assets/img/icons/H3.svg";
import H4 from "../../assets/img/icons/H4.svg";
import H5 from "../../assets/img/icons/H5.svg";
import H6 from "../../assets/img/icons/H6.svg";
import photoImage from "../../assets/img/icons/photo.svg";
import bold from "../../assets/img/icons/bold.svg";
import italic from "../../assets/img/icons/italic.svg";
import underline from "../../assets/img/icons/underline.svg";
import bulletList from "../../assets/img/icons/bulletList.svg";
import orderedList from "../../assets/img/icons/orderedList.svg";
import undo from "../../assets/img/icons/undo.svg";
import redo from "../../assets/img/icons/redo.svg";
import link from "../../assets/img/icons/link.svg";
import borderHorizontal from "../../assets/img/icons/borderHorizontal.svg";
import borderLeft from "../../assets/img/icons/borderLeft.svg";
import Selector from "../selector/Selector";
const MenuBar = ({ editor, handleSetLink }) => {
  const [visible, setVisible] = useState(false);
  const [customColor, setCustomColor] = useState("2");

  if (!editor) {
    return null;
  }

  const onModal = () => {
    setVisible(!visible);
  };

  const addImage = (media) => {
    if (media.file) {
      editor.chain().focus().setImage({ src: media.file }).run();
    }
  };
  return (
    <div>
      <MediaModal
        handleSelectMedia={addImage}
        visible={visible}
        onModal={onModal}
      />
      <div className="editor-block">
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={
            editor.isActive("bold")
              ? "t-btn  bold is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* bold */}
          <img className="circle editor-button" src={bold} alt="bold" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={
            editor.isActive("italic")
              ? "t-btn  italic is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* italic */}
          <img className="circle editor-button" src={italic} alt="italic" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={
            editor.isActive("underline")
              ? "t-btn  underline is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* underline */}
          <img
            className="circle editor-button"
            src={underline}
            alt="underline"
          />
        </Button>

        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={
            editor.isActive("heading", { level: 1 })
              ? "t-btn   h1 is-active editor-icon"
              : "t-btn  editor-icon"
          }
        >
          {/* h1
           */}
          <img className="circle editor-button" src={H1} alt="H1" />
        </Button>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive("heading", { level: 2 })
              ? "t-btn  h2 is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* h2 */}
          <img className="circle editor-button" src={H2} alt="H2" />
        </Button>
        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 })
              ? "t-btn  h3 is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* h3 */}
          <img className="circle editor-button" src={H3} alt="H3" />
        </Button>

        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
          className={
            editor.isActive("heading", { level: 4 })
              ? "t-btn  h4 is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* h4 */}
          <img className="circle editor-button" src={H4} alt="H4" />
        </Button>

        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
          className={
            editor.isActive("heading", { level: 5 })
              ? "t-btn  h5 is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* h5*/}
          <img className="circle editor-button" src={H5} alt="H5" />
        </Button>

        <Button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
          className={
            editor.isActive("heading", { level: 6 })
              ? "t-btn  h6 is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* h6 */}
          <img className="circle editor-button" src={H6} alt="H6" />
        </Button>

        <Button
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          className={
            editor.isActive({ textAlign: "left" })
              ? "t-btn  left is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* left */}
          <img className="circle editor-button" src={left} alt="left" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          className={
            editor.isActive({ textAlign: "center" })
              ? "t-btn  right is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* center
           */}
          <img className="circle editor-button" src={center} alt="center" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={
            editor.isActive({ textAlign: "right" })
              ? "t-btn  center is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* right */}
          <img className="circle editor-button" src={rigth} alt="rigth" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={
            editor.isActive("bulletList")
              ? "t-btn  bullet is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* bullet list */}
          <img
            className="circle editor-button"
            src={bulletList}
            alt="bulletList"
          />
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={
            editor.isActive("orderedList")
              ? "t-btn  orderlist is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* ordered list */}

          <img
            className="circle editor-button"
            src={orderedList}
            alt="orderedList"
          />
        </Button>

        <Button onClick={() => onModal()} className="t-btn editor-icon">
          {/* image
           */}
          <img
            className="circle editor-button"
            src={photoImage}
            alt="photoImage"
          />
        </Button>
        <Button
          onClick={() => handleSetLink()}
          className={
            editor.isActive("link")
              ? "t-btn  link is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* Link */}
          <img className="circle editor-button" src={link} alt="link" />
        </Button>

        <Button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={
            editor.isActive("blockquote")
              ? "t-btn  blockquote is-active editor-icon"
              : "t-btn editor-icon"
          }
        >
          {/* blockquote */}
          <img
            className="circle editor-button"
            src={borderLeft}
            alt="borderLeft"
          />
        </Button>
        <Button
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          className="t-btn editor-icon"
        >
          {/* horizontal rule */}
          <img
            className="circle editor-button"
            src={borderHorizontal}
            alt="borderHorizontal"
          />
        </Button>
        <Button
          onClick={() => editor.chain().focus().undo().run()}
          className="t-btn editor-icon"
        >
          {/* undo */}
          <img className="circle editor-button" src={undo} alt="undo" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().redo().run()}
          className="t-btn editor-icon "
        >
          {/* redo */}
          <img className="circle editor-button" src={redo} alt="redo" />
        </Button>

        <Selector
          Source={[
            { name: "สี pantone", value: "2" },
            { name: "สีเลือกเอง", value: "1" },
          ]}
          keyName="name"
          keyValue="value"
          value={customColor}
          onChange={(e) => {
            setCustomColor(e);
          }}
          options={{ className: "w-10 t-select " }}
          placeholder="สี"
        >
          Color
        </Selector>

        {customColor === "1" && (
          <span className="ml-1px">
            <Input
              type="color"
              onInput={(e) =>
                editor.chain().focus().setColor(e.target.value).run()
              }
              value={editor.getAttributes("textStyle").color}
              className="t-btn"
            ></Input>
          </span>
        )}
        {customColor === "2" && (
          <>
            <Button
              className="t-color-btn tt-color-orange-red ml-1px"
              onClick={() => editor.chain().focus().setColor("#E24301").run()}
            >
              {" "}
            </Button>
            <Button
              className="t-color-btn tt-color-orange ml-1px"
              onClick={() => editor.chain().focus().setColor("#FF6B0B").run()}
            >
              {" "}
            </Button>
            <Button
              className="t-color-btn tt-color-yellow ml-1px"
              onClick={() => editor.chain().focus().setColor("#ffc627").run()}
            >
              {" "}
            </Button>
            <Button
              className="t-color-btn tt-color-black ml-1px"
              onClick={() => editor.chain().focus().setColor("#231F20").run()}
            >
              {" "}
            </Button>
            <Button
              className="t-color-btn tt-color-cool-gray ml-1px"
              onClick={() => editor.chain().focus().setColor("#808284").run()}
            >
              {" "}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default MenuBar;
