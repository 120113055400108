import { Form, Row, Col, Input } from 'antd'
import UploadPhoto from '../../../users/usertab/component/_UploadPhoto'
import { IMediaSelect } from "../../../../interface/IMedia.interface";

interface Props {
  mediaSelectSetting: IMediaSelect;
  setMediaSelectSetting: any;
}

const Forms = ({ mediaSelectSetting, setMediaSelectSetting }: Props) => {
  return (
    <>
      <Row>
        <Col lg={7}>
          <Col lg={16} offset={5}>
            <UploadPhoto
              mediaSelect={mediaSelectSetting}
              setMediaSelect={setMediaSelectSetting}
            />
          </Col>
        </Col>
        <Col lg={16}>
          <Row gutter={20} className="pt-1">
            <Col lg={24}>
              <Form.Item
                label="ชื่อบริษัท"
                name="name"
                // rules={[
                //   {
                //     required: true,
                //     message: "โปรดใส่ข้อมูลชื่อบริษัทของท่านด้วย",
                //   },
                // ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} className="pt-1">
            <Col lg={24}>
              <Form.Item
                label="ที่อยู่บริษัท"
                name="address"
                // rules={[
                //   {
                //     required: true,
                //     message: "โปรดใส่ข้อมูลที่อยู่บริษัทของท่านด้วย",
                //   },
                // ]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} className="pt-1">
            <Col lg={24}>
              <Form.Item
                label="เบอร์โทรบริษัท"
                name="phone"
                // rules={[
                //   {
                //     required: true,
                //     message: "โปรดใส่ข้อมูลเบอร์โทรบริษัทของท่านด้วย",
                //   },
                // ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} className="pt-1">
            <Col lg={24}>
              <Form.Item
                label="อีเมล์บริษัท"
                name="email"
                // rules={[
                //   {
                //     required: true,
                //     message: "โปรดใส่ข้อมูลอีเมล์บริษัทของท่านด้วย",
                //   },
                // ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20} className="pt-1">
            <Col lg={24}>
              <Form.Item
                label="ลิงค์แผนที่"
                name="map"
                // rules={[
                //   {
                //     required: true,
                //     message: "โปรดใส่ข้อมูลลิงค์แผนที่ของท่านด้วย",
                //   },
                // ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Forms;
