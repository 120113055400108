import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row } from "antd";
import { useState } from "react";
import PDFIcon from "../../assets/img/icons/pdf.png";
import ExelIcon from "../../assets/img/icons/exel.png";
import DWGIcon from "../../assets/img/icons/dwg.png";

import MedaiModal from "./Modal";
import CImage from "../image/Image";
type Props = {
  onChange?: any;
  onSelect?: any;
  fileExtension: "pdf" | "exel" | "dwg";
  value?: any;
  maxCount?: number | boolean;
};
const NewMediaModalFile = ({
  onChange,
  onSelect,
  fileExtension,
  value,
  maxCount = false,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const checkFileKey = (key: string) => {
    if (key === "pdf") {
      return { acceptFile: "application/pdf", filterTypeFile: ".pdf" };
    } else if (key === "exel") {
      return { acceptFile: ".xlsx,.xls", filterTypeFile: ".xls" };
    } else if (key === "dwg") {
      return { acceptFile: ".dwg,.pdf", filterTypeFile: ".dwg|.pdf" };
    } else {
      return {
        acceptFile: ".xlsx,.xls,application/pdf,.dwg",
        filterTypeFile: "",
      };
    }
  };

  const onModal = () => {
    setVisible(!visible);
  };
  // const displayValue = (data: any) => {
  //   setMediaSelect({
  //     id: data.id,
  //     media_name: data.media_name,
  //     file: data.file_path,
  //     type: data.type,
  //   });
  // };

  const onDeleteFile = (index: number) => {
    const filterListImage = value.filter(
      (_item: any, ind: number) => ind !== index
    );
    onChange(filterListImage);
  };
  return (
    <div>
      <Button
        icon={<UploadOutlined />}
        disabled={false}
        block
        type="dashed"
        onClick={onModal}
      >
        อัพโหลด
      </Button>
      {value &&
        value.length > 0 &&
        value.map((mediaFile: any, ind: number) => {
          return (
            <div className="list-image-upload mt-05" key={ind}>
              <Row className="h-100">
                <Col
                  lg={8}
                  className="d-flex justify-start items-center img-upload-padding"
                >
                  <div
                    onClick={() => window.open(mediaFile.file)}
                    className="cursor-pointer"
                  >
                    <CImage
                      src={
                        mediaFile.type === ".pdf"
                          ? PDFIcon
                          : mediaFile.type === ".dwg"
                          ? DWGIcon
                          : ExelIcon
                      }
                      className="img-upload-squad"
                      alt="upload"
                    />
                  </div>
                </Col>
                <Col lg={8} className="d-flex justify-center items-center">
                  <span
                    className="cut-text-1-row cursor-pointer"
                    //
                  >
                    <Popover content={mediaFile.media_name}>
                      {mediaFile.media_name}
                    </Popover>
                  </span>
                </Col>
                <Col
                  lg={8}
                  className="d-flex justify-flex-end items-center h-100"
                >
                  <Button
                    className="btn-delete-upload"
                    onClick={() => onDeleteFile(ind)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          );
        })}

      <MedaiModal
        maxCount={maxCount}
        value={value}
        visible={visible}
        onModal={onModal}
        onChange={onChange}
        onSelect={onSelect}
        // displayValue={displayValue}
        fileExtension={checkFileKey(fileExtension)}
      />
    </div>
  );
};

export default NewMediaModalFile;
