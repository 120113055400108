import { Card, Col, Form, FormInstance, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from "react";
import Gallery from "../../../../../component/gallery/Gallery";
import MediaModal from "../../../../../component/mediamodal/MediaModal";
import MediaSpace from "../../../../../component/mediaspace/MediaSpace";
import Selector from "../../../../../component/selector/Selector";
import { _isEmpty } from "../../../../../tools/util";

type Props = {
  formMain: FormInstance<any>;
  onFinish: (values: any) => void;
  productCategoryTypeList: any[];
  productGallery: any;
  setProductGallery: any;
  mediaSelectCoverImage: any;
  setMediaSelectCoverImage: any;
};
const Product = ({
  onFinish,
  formMain,
  productCategoryTypeList,
  productGallery,
  setProductGallery,
  mediaSelectCoverImage,
  setMediaSelectCoverImage,
}: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleCoverImage, setVisibleCoverImage] = useState<boolean>(false);

  const onModal = () => {
    setVisible(!visible);
  };
  const onModalCoverImage = () => {
    setVisibleCoverImage(!visibleCoverImage);
  };
  return (
    <div>
      <MediaModal
        visible={visible}
        onModal={onModal}
        gallery={productGallery}
        setGellery={setProductGallery}
      />
      <MediaModal
        visible={visibleCoverImage}
        onModal={onModalCoverImage}
        setMediaSelect={setMediaSelectCoverImage}
      />
      <Card extra={<>สินค้า</>} className="card-form ">
        <Form onFinish={onFinish} layout="vertical" form={formMain}>
          <Row gutter={[14, 14]}>
            <Col span={12}>
              <Form.Item
                label="หัวข้อสินค้า (TH)"
                name="title_th"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="หัวข้อสินค้า (EN)"
                name="title_en"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="กลุ่มสินค้า"
                name="product_category"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Selector
                  Source={productCategoryTypeList}
                  keyName="name_th"
                  keyValue="id"
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>

            <Col span={24} className="mt-1">
              <Form.Item
                label="รูปปก"
                name="cover_image"
                rules={[
                  {
                    required: _isEmpty(mediaSelectCoverImage) ? true : false,
                    message: "โปรดกรอกข้อมูล!",
                  },
                ]}
              >
                <MediaSpace
                  onModal={onModalCoverImage}
                  mediaSelect={mediaSelectCoverImage}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label=" "
                name="gallerys"
                rules={[
                  {
                    required: !_isEmpty(productGallery) ? false : true,
                    message: "โปรดใส่ข้อมูลของท่านด้วย",
                  },
                ]}
              >
                <Gallery
                  onModal={onModal}
                  gallery={productGallery}
                  setGellery={setProductGallery}
                  label="แกลเลอรีสินค้า"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="product_detail_th"
                label="รายละเอียด (TH)"
                rules={[
                  {
                    required: true,
                    message: "โปรดใส่ข้อมูลของท่านด้วย",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="product_detail_en"
                label="รายละเอียด (EN)"
                rules={[
                  {
                    required: true,
                    message: "โปรดใส่ข้อมูลของท่านด้วย",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default Product;
