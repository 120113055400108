import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Switch } from "antd";
import MoTable from "../../../component/table/Table";
import { useHistory } from "react-router-dom";
import { isDelProjectCategoryList } from "../../../services/api/project";
import { openNotification } from "../../../component/notification/Notification";
import Modaler from "../../../component/modaler/Modaler";
import { useState } from "react";
import { DateTimeStandard } from "../../../tools/date";

type Props = {
  dataSource: any[];
  HandleOnCheck: (tab: string) => void;
  onChangePage: (page: number, type?: string, tab?: string) => void;
  handleChangeSwitch: (source: any) => void;
  config: {};
};

const GroupTab = ({
  dataSource,
  handleChangeSwitch,
  HandleOnCheck,
  onChangePage,
  config,
}: Props) => {
  const [delID, setDelID] = useState(0);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const history = useHistory();

  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "10%",
      render: (status: boolean, record: any) => {
        return (
          <>
            <Switch
              checked={status}
              onChange={() => handleChangeSwitch(record)}
            />
          </>
        );
      },
    },

    {
      title: "หมวดหมู่โครงการ",
      dataIndex: "name_th",
      key: "name_th",
      width: "65%",
      render: (name_th: string) => {
        return (
          <>
            <b>{name_th}</b>
          </>
        );
      },
    },

    {
      title: "อัปเดต",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render(text: any, record: any) {
        return {
          props: {
            className: "",
          },
          children: (
            <div>
              <p style={{ marginBottom: 5 }}>
                {record.edit_by?.first_name} {record.edit_by?.last_name}
              </p>
              <p style={{ marginBottom: 5 }}>
                {DateTimeStandard(record.updated_at)}
              </p>
            </div>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      render: (text: any, record: any) => {
        return (
          <ActionButton
            icon={<DeleteOutlined />}
            onClick={(e: any) => {
              e.stopPropagation();
              setDelID(record?.id);
              setVisibleDelete(true);
              //  HandleDelete(record?.id)
            }}
          />
        );
      },
    },
  ];

  const HandleDelete = async (id: number) => {
    try {
      const res = await isDelProjectCategoryList(id);
      if (res.code === 200) {
        openNotification({
          custom: true,
          type: "success",
          message: "ลบข้อมูลรายการหมวดหมู่สำเร็จ!",
        });
        HandleOnCheck("2");
      } else {
        openNotification({ custom: true, type: "error", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    onCancel();
  };

  const ActionButton = ({ icon, onClick }: { icon: any; onClick: any }) => {
    return (
      <div className="action-btn" onClick={onClick}>
        {icon}
      </div>
    );
  };

  const onClickRow = (record: any) => {
    history.push({
      pathname: `/project/group/${record.id}`,
    });
  };

  const onCancel = () => {
    setVisibleDelete(false);
  };

  const onDelete = () => {
    HandleDelete(delID);
  };

  const handleOnChangePage = (page: number, type?: string) => {
    onChangePage(page, type, "2");
  };

  return (
    <div>
      <Modaler
        okText="ตกลง"
        visible={visibleDelete}
        onCancel={onCancel}
        onOk={onDelete}
        width="25%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            คุณแน่ใจไหม?
          </div>
        }
      >
        คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
      </Modaler>
      <MoTable
        config={config}
        dataSource={dataSource}
        columns={columns}
        onClickRow={onClickRow}
        onChangePage={handleOnChangePage}
      />
    </div>
  );
};

export default GroupTab;
