import React from "react";

import DnDList from "../../../../../../component/@yim/react-dnd-list/List";

import ItemComponent from "./ItemGroupComponent";

import "./styles.css";
interface Props {
  id: number;
  group: any;
  cFunction: any;
  setList: any;
  horizontal?: boolean;
  itemComponent: React.ReactNode;
  setSwapThreshold?: number;
  setOverflowThreshold?: number;
  onChange?: (e: string) => void;
  setGroup: any;
  onModal: any;
  onDelete: any;
  idCate: number;
}

function MainGroupComponent(props: Props) {
  // console.log("main group", props);
  const Comp = (p: any) => (
    <ItemComponent
      {...p}
      setList={props.setList}
      onModal={props.onModal}
      idCate={props.idCate}
      onDelete={props.onDelete}
    />
  );
  return (
    <div className="main-card-group">
      <DnDList
        id={props.idCate}
        items={props.group}
        setList={props.setGroup}
        type="group"
        horizontal={props.horizontal || false}
        itemComponent={Comp}
        setSwapThreshold={props.setSwapThreshold || null}
        setOverflowThreshold={props.setOverflowThreshold || null}
      />
    </div>
  );
}

export default MainGroupComponent;
