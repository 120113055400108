import { Col, Divider, Row } from "antd";

type Props = {
  src: string;
  title: string;
  textHeight?: string;
  textWidth?: string;
  width?: string;
  price?: number | string;
  lang?: string;
};

const CardBoxInline = ({
  src = "",
  title = "",
  textHeight = "",
  textWidth = "",
  price = "",
  lang = "th",
}: Props) => {
  return (
    <Row>
      <Col xl={9} lg={9} md={9} xs={24}>
        <img
          src={src}
          width={"100%"}
          height={100}
          alt="inline-img"
          className="rounded"
          style={{ objectFit: "cover" }}
        />
      </Col>
      <Col xl={15} lg={15} md={15} xs={24}>
        <div className="py-1" style={{ padding: "0 12px" }}>
          <div className="title-card-box" style={{ margin: "6px 0 0 0" }}>
            {title}
          </div>
          <Divider style={{ margin: 0 }} className="bg-gray-100" />
          <div
            className="text-box-inline"
            style={{ paddingLeft: 10, paddingTop: 8 }}
          >
            <div>
              - {lang === "th" ? `ความกว้างเริ่มต้น` : `Default Width`}{" "}
              {textWidth} {lang === "th" ? `มม.` : `mm.`}
            </div>
            <div>
              - {lang === "th" ? `ความสูงเริ่มต้น` : `Default Height`}{" "}
              {textHeight} {lang === "th" ? `มม.` : `mm.`}
            </div>
          </div>
        </div>
        <div
          className="relative"
          style={{ marginTop: 5, padding: "0px 12px 5px 12px" }}
        >
          <Row align="top">
            <span className="left-0 relative" style={{ fontSize: 12 }}>
              {lang === "th" ? `เริ่มต้น` : `Start`}{" "}
              <span style={{ fontWeight: "bold" }}>
                {Number(price || 0).toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })}
              </span>{" "}
              {lang === "th" ? `บาท` : `Bath`}
            </span>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default CardBoxInline;
