import { Button, Card, Col, Form, Input, Row } from "antd";

type Props = {
  onFinish: (values: any) => void;
  initialValue: string;
};
const Header = ({ onFinish }: Props) => {
  const [form] = Form.useForm();

  return (
    <div>
      <Form onFinish={onFinish} form={form}>
        <Card>
          <Row gutter={14}>
            <Col lg={9}>
              <Form.Item name="search">
                <Input placeholder="ค้นหาหมวดหมู่" />
              </Form.Item>
            </Col>
            <Col lg={3} className="d-flex justify-between">
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Header;
