import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from "../../tools/util"

export const getArticleList = async (data?: any) => {
    const value = createQueryString(data);
    const res = await Fecth({
        method: "GET",
        path: `${version}/article${value}`
    });
    return res;
}

export const getArticleByID = async (data?: any) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/article/${data}`
    });
    return res;
}

export const postArticleList = async (data?: any) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/article`
    });
    return res;
}

export const putArticleList = async (data?: any) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/article/${data.id}`
    });
    return res;
}

export const putArticleActive = async (data?: any) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/article/active/${data}`
    });
    return res;
}

export const deleteArticleList = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/article/${id}`
    });
    return res;
}
