import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { openNotification } from "../../../component/notification/Notification";
import MoTable from "../../../component/table/Table";
import { IEditor } from "../../../interface/IEditor.interface";
import {
  delColorType,
  getColorType,
  putColorTypeActive,
} from "../../../services/api/color_type";
import { DateTimeStandard } from "../../../tools/date";
import { IResStatus } from "../../../tools/fecth/interface";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import { IColorType } from "../../../interface/IColorType.interface";
import Modaler from "../../../component/modaler/Modaler";
import { _isEmpty } from "../../../tools/util";

type Props = {
  filterValue: any;
};

const ColorType = ({ filterValue }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [colorTypeList, setColorTypeList] = useState<IColorType[]>([]);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [id, setId] = useState<number>(0);
  const [total, setTotal] = useState<number>(1);
  const [visible, setVisible] = useState<boolean>(false);
  const setLoading = useSetRecoilState(loadingState);
  const history = useHistory();

  useEffect(() => {
    handleGetData();
    if (!_isEmpty(filterValue)) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line
  }, [currentPage, limitPage, filterValue]);

  const handleGetData = () => {
    handleGetColorType();
  };

  const handleGetColorType = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getColorType({
        page: currentPage,
        limit: limitPage,
        ...filterValue,
      });
      if (res.code === 200) {
        setColorTypeList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUpdateActive = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await putColorTypeActive(id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetColorType();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleteColorType = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await delColorType(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetColorType();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    onModal();
    setLoading(false);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push("/product/color-type/" + event.id);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleteColorType(id);
  };

  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "10%",
      render: (status: boolean, data: any) => {
        return (
          <>
            <Switch
              checked={status}
              onClick={() => handleUpdateActive(data.id)}
            />
          </>
        );
      },
    },
    {
      title: "ชื่อประเภทสี",
      dataIndex: "name_th",
      key: "name_th",
      width: "65%",
      render: (name_th: string) => {
        return (
          <>
            <b>{name_th}</b>
          </>
        );
      },
    },

    {
      title: "อัปเดต",
      dataIndex: "edit_by",
      key: "edit_by",
      width: "15%",
      render: (editBy: IEditor, data: IColorType) => {
        return (
          <>
            {editBy.first_name} {editBy.last_name}
            <br />
            {!_isEmpty(data.updated_at)
              ? DateTimeStandard(data.updated_at)
              : DateTimeStandard(data.created_at)}
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                setId(data.id);
                onModal();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div>
        <Modaler
          visible={visible}
          onCancel={onModal}
          onOk={onDelete}
          width="25%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              คุณแน่ใจไหม?
            </div>
          }
        >
          คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
        </Modaler>
        <div className="card-table-tab">
          <MoTable
            dataSource={colorTypeList}
            columns={columns}
            onChangePage={onChangePage}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            onClickRow={onClickRow}
          />
        </div>
      </div>
    </div>
  );
};

export default ColorType;
