import UploadIcon from "../../assets/img/icons/upload.png";
import { IMediaSelect } from "../../interface/IMedia.interface";
import { _isEmpty } from "../../tools/util";
import { Image } from "antd";

// import MediaModal from "../mediamodal/MediaModal";

type Props = {
  visible: boolean;
  onModal?: () => void;
  mediaSelect?: IMediaSelect;
};

const Space = ({
  onModal,
  mediaSelect = { file: "", id: "", media_name: "" },
}: Props) => {
  return (
    <div>
      <div
        className="media-space-container d-flex items-center justify-center"
        onClick={onModal && onModal}
      >
        {_isEmpty(mediaSelect.file) ? (
          <>
            <p className="ant-upload-drag-icon">
              <img src={UploadIcon} alt="media" />
            </p>
            <p className="header-xs">กด หรือ ลาก ไฟล์ไปยังพื้นที่อัพโหลดนี้</p>
            <p>สำหรับอัพโหลดไฟล์เดียว</p>
          </>
        ) : (
          <Image
            src={mediaSelect.file}
            alt="uploader"
            className="image-render"
            preview={false}
            // height="450vh"
            // width="100%"
          />
        )}
      </div>
    </div>
  );
};

export default Space;
