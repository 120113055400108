import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { IResStatus } from "../../tools/fecth/interface";
import { getMediaCategory } from "../../services/api/media";
import { ICategorymedia } from "./image/form/fieds";
type Props = {
  form: any;
  initialValue: any;
  onFinish: (values: any) => void;
  name: string;
};
const FormModal = ({ form, onFinish, name }: Props) => {
  const [mediaCategory, setMediaCategory] = useState<ICategorymedia[]>([]);
  const { Option } = Select;
    useEffect(() => {
    handleGetMediaCategory();
    // eslint-disable-next-line
  }, []);

    const handleGetMediaCategory = async () => {
    try {
      const res: IResStatus = await getMediaCategory();
      if (res.code === 200) {
        setMediaCategory(res.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={[14, 14]}>
          <Col lg={24}>
            <Form.Item
              label={`ชื่อ${name}`}
              name="media_name"
              rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {name === "ภาพ" ? (
            <>
              <Col lg={24}>
                <Form.Item
                  label={`ประเภทมีเดีย`}
                  name="media_category_id"
                  rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                >
                  <Select placeholder="โปรดเลือก">
                    {mediaCategory.map((item) => {
                    return <Option key={item.id} value={item.id}>{item.name}</Option>;
                  })}
                  </Select>
                </Form.Item>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default FormModal;
