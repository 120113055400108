import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { IDoorOpenPattern } from "../../../interface/IDoorOpenPattern.interface";
import {
  delDoorOpenPattern,
  getDoorOpenPattern,
  putDoorOpenPatternActive,
} from "../../../services/api/door_open_pattern";
import { IResStatus } from "../../../tools/fecth/interface";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import { Switch } from "antd";
import { openNotification } from "../../../component/notification/Notification";
import { IEditor } from "../../../interface/IEditor.interface";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { DateTimeStandard } from "../../../tools/date";
import MoTable from "../../../component/table/Table";
import Modaler from "../../../component/modaler/Modaler";
import { _isEmpty } from "../../../tools/util";

type Props = {
  filterValue: any;
};

const DoorOpenPattern = ({ filterValue }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [doorOpenPatternList, setDoorOpenPatternList] = useState<
    IDoorOpenPattern[]
  >([]);
  const setLoading = useSetRecoilState(loadingState);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [visible, setVisible] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [total, setTotal] = useState<number>(1);

  const history = useHistory();

  useEffect(() => {
    if (!_isEmpty(filterValue)) {
      setCurrentPage(1);
    }
    handleGetData();

    // eslint-disable-next-line
  }, [currentPage, limitPage, filterValue]);

  const handleGetData = () => {
    handleGetDoorOpenPattern();
  };

  const handleDeleteDoorOpenPattern = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await delDoorOpenPattern(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetDoorOpenPattern();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    onModal();
    setLoading(false);
  };

  const handleUpdateActive = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await putDoorOpenPatternActive(id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetDoorOpenPattern();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetDoorOpenPattern = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getDoorOpenPattern({
        page: currentPage,
        limit: limitPage,
        ...filterValue,
      });
      if (res.code === 200) {
        setDoorOpenPatternList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push("/product/door-open-pattern/" + event.id);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleteDoorOpenPattern(id);
  };

  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "10%",
      render: (status: boolean, data: any) => {
        return (
          <>
            <Switch
              checked={status}
              onClick={() => handleUpdateActive(data.id)}
            />
          </>
        );
      },
    },
    {
      title: "ชื่อรูปแบบการเปิดปิด",
      dataIndex: "name_th",
      key: "name_th",
      width: "65%",
      render: (name_th: string) => {
        return (
          <>
            <b>{name_th}</b>
          </>
        );
      },
    },

    {
      title: "อัปเดต",
      dataIndex: "edit_by",
      key: "edit_by",
      width: "15%",
      render: (editBy: IEditor, data: IDoorOpenPattern) => {
        return (
          <>
            {editBy.first_name} {editBy.last_name}
            <br />
            {!_isEmpty(data.updated_at)
              ? DateTimeStandard(data.updated_at)
              : DateTimeStandard(data.created_at)}
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                setId(data.id);
                onModal();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="25%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            คุณแน่ใจไหม?
          </div>
        }
      >
        คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
      </Modaler>
      <div className="card-table-tab">
        <MoTable
          dataSource={doorOpenPatternList}
          columns={columns}
          onChangePage={onChangePage}
          config={{
            total: total,
            pageSize: limitPage,
            currentPage: currentPage,
          }}
          onClickRow={onClickRow}
        />
      </div>
    </div>
  );
};

export default DoorOpenPattern;
