import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'

export const GET_CONTACTID_API = async() =>{
    const res = await Fecth({
        method:"GET",
        path:`${version}/web-contact`
    });
    return res;
}

export const PUT_CONTACT_API = async(data:any) =>{
    const res = await Fecth({
        data,
        method:"PUT",
        path:`${version}/web-contact/${data.id}`
    });
    return res;
}