import { Button, Checkbox, Col, Row } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

type Props = {
  options?: any;
  value?: CheckboxValueType[] | undefined;
  onChange?: (checkedValue: CheckboxValueType[]) => void;
  keyName: string;
  keyValue: string;
  disabled?: boolean;
};
const CCheckBoxGroup = ({
  options = [],
  keyName,
  keyValue,
  value,
  onChange,
  disabled = false,
}: Props) => {
  return (
    <Checkbox.Group value={value} onChange={onChange}>
      <Row gutter={[14, 14]}>
        {options.map((item: any, idx: number) => {
          return (
            <Col key={idx}>
              <Button disabled={disabled}>
                <Checkbox value={item[keyValue]}>{item[keyName]}</Checkbox>
              </Button>
            </Col>
          );
        })}
      </Row>
    </Checkbox.Group>
  );
};

export default CCheckBoxGroup;
