/* eslint-disable react-hooks/exhaustive-deps */
import { IMediaSelect } from "../../interface/IMedia.interface";
import Space from "./Space";
import { useEffect, useState } from "react";
import MediaModal from "../mediamodal/MediaModal";
import { _isEmpty } from "../../tools/util";

type Props = {
  // uploadPage?: boolean;
  onChange?: any;
  value?: any;
  typelist: string
  textMessage?: string
  onMarker?:boolean;
};

const MediaSpaceMarker = ({ onChange, value, typelist, textMessage,onMarker=true }: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [marker, setMarker] = useState([] as any)
  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });

  useEffect(() => {
    if (!_isEmpty(value)) {
      setMediaSelect(value.media)
      setMarker(value.marker)
    }
  }, [value])

  const onModal = () => {
    setVisible(!visible);
  };

  const Finish = (values: any) => {
    const result = {
      marker: values,
      media: mediaSelect
    }
    setMarker(values);
    onChange(result)
  };

  const onChangeImage = (image: any) => {
    const mediaRefacter = {
      file: image?.file_path,
      id: image?.id,
      media_name: image?.media_name,
    }
    setMediaSelect(mediaRefacter);
    const result = {
      marker: marker,
      media: mediaRefacter
    }
    onChange(result)
  }

  return (
    <div>
      <MediaModal
        visible={visible}
        onModal={onModal}
        setMediaSelect={setMediaSelect}
        onChange={onChangeImage}
      />
      <Space
        onModal={onModal}
        visible={visible}
        mediaSelect={mediaSelect}
        initialMarkers={marker}
        getMarkers={Finish}
        type={typelist}
        textMessage={textMessage}
        onMarker={onMarker}
      />
    </div>
  );
};

export default MediaSpaceMarker;
