import { Row } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import { _isEmpty } from "@yim/util-tool-ts";
const dateFormat = "DD/MM/YYYY";

type Props = {
  date?: string;
  tag?: string;
  lang?: string;
};

const TagText = ({ date, tag, lang = "th" }: Props) => {
  return (
    <div className="relative">
      <Row align="middle" style={{ color: "gray" }}>
        <CalendarOutlined className="left-0 bottom-0-5 relative" />
        <div style={{ fontSize: 12 }} className="mx-2">
          {lang === "th" ? "โพสต์เมื่อ" : "Update at"}{" "}
          {!_isEmpty(date)
            ? moment(Number(date)).format(dateFormat)
            : moment().format(dateFormat)}
        </div>
        {tag && <div className="title-tag">{tag}</div>}
      </Row>
    </div>
  );
};

export default TagText;
