
import { version } from "../../config/api"
import { IAluminiumPost } from "../../interface/IAluminium.interface"
import { Fecth, FecthFormdata } from "../../tools/fecth/fecth"
import { createQueryString } from "../../tools/util"

export const getAluminium = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/aluminium${queryStr}`,
    })
    return res
}


export const getAluminiumByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/aluminium/${id}`,
    })
    return res
}



export const postAluminium = async (data: IAluminiumPost) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/aluminium`,
    })
    return res
}


export const putAluminium = async (data: IAluminiumPost, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/aluminium/${id}`,
    })
    return res
}


export const putAluminiumActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/aluminium/active/${id}`,
    })
    return res
}


export const delAluminium = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/aluminium/${id}`,
    })
    return res
}

export const postExcelAluminium = async (data: any) => {
    const res = await FecthFormdata({
        data,
        method: "POST",
        path: `${version}/aluminium/import-excel`,
    })
    return res
}




