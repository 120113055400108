import { CopyOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
type Props = {
  onGetValue: any;
  value?: any;
};
const CkeepValue = ({ value, onGetValue }: Props) => {
  return (
    <Button onClick={() => onGetValue(value)}>
      <CopyOutlined />
    </Button>
  );
};

export default CkeepValue;
