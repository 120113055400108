import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import Header from "./header/Header";
import Table from "./table/Table";
import { useHistory } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../tools/recoil/recoil-atom";
import { IResStatus } from "../../tools/fecth/interface";
import {
  delBannerList,
  getBannerList,
  putBannerListActive,
} from "../../services/api/banner";
import { IBannerList } from "../../interface/IBanner.interface";
import { openNotification } from "../../component/notification/Notification";

const BannerManagement = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const setLoading = useSetRecoilState(loadingState);
  const [id, setId] = useState<number>(0);
  const [bannerList, setBannerList] = useState<IBannerList[]>([]);
  const [total, setTotal] = useState<number>(1);
  const [visible, setVisible] = useState(false);
  const bannerMenuList = [
    { name: "หน้าหลัก", id: 1 },
    { name: "ไอเดีย", id: 2 },
    { name: "สินค้า", id: 3 },
    { name: "โครงการ", id: 4 },
    { name: "บทความ", id: 5 },
    { name: "บริการ", id: 6 },
  ];

  const onFinish = (value: any) => {
    handleGetBannerList(value);
  };

  useEffect(() => {
    handleGetData();

    // eslint-disable-next-line
  }, [currentPage, limitPage]);

  const handleGetData = () => {
    handleGetBannerList();
  };

  const handleGetBannerList = async (value?: any) => {
    try {
      setLoading(true);
      const res: IResStatus = await getBannerList({
        page: currentPage,
        limit: limitPage,
        ...value,
      });
      if (res.code === 200) {
        setBannerList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleteBannerList = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await delBannerList(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetBannerList();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    onModal();
    setLoading(false);
  };

  const onDelete = () => {
    handleDeleteBannerList(id);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push("/banner-management/" + event.id);
  };

  const handleChangeSwitch = async (data: any) => {
    try {
      const res = await putBannerListActive(data?.id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetData();
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">จัดการแบนเนอร์</span>
        </Col>
        <Col lg={2}>
          <Button
            className="btn-create"
            type="primary"
            onClick={() => history.push("/banner-management/create")}
          >
            <PlusOutlined className="icons-size" /> สร้าง
          </Button>
        </Col>
      </Row>
      <div className="set-layout">
        <Header bannerMenuList={bannerMenuList} onFinish={onFinish} />
        <div className="pt-1">
          <Table
            onClickRow={onClickRow}
            currentPage={currentPage}
            limitPage={limitPage}
            total={total}
            onChangePage={onChangePage}
            bannerList={bannerList}
            onDelete={onDelete}
            visible={visible}
            onModal={onModal}
            setId={setId}
            handleChangeSwitch={handleChangeSwitch}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerManagement;
