import { useEditor, EditorContent } from "@tiptap/react";
import MenuBar from "./MenuBar";
import StarterKit from "@tiptap/starter-kit";
//import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import BubbleMenu from "./BubleMenu";
import Color from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import { MyImage } from "./extensions/image";

const Editor = (props) => {
  const { header, onChange, content } = props;

  const editor = useEditor({
    extensions: [
      StarterKit,
      MyImage,
      Underline,
      Link,
      onChange,
      Placeholder,
      TextStyle,
      Color,
      TextAlign.configure({
        types: ["heading", "paragraph", "image"],
      }),
    ],

    content: content,
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    },
  });

  const handleSetLink = () => {
    const url = window.prompt("URL");
    editor.chain().focus().setLink({ href: url }).run();
    console.log("editor", editor.chain().focus());
  };
  return (
    <div>
      <div className="text-h">{header}</div>
      <div className="editor-frame">
        <BubbleMenu editor={editor} handleSetLink={handleSetLink} />
        <MenuBar editor={editor} handleSetLink={handleSetLink} />
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

export default Editor;
