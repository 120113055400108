import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Modal, Tabs, Select } from "antd";
import Editor from "../../../../component/editor/Editor";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  putArticleList,
  getArticleByID,
  postArticleList,
} from "../../../../services/api/article";
import MediaModal from "../../../../component/mediamodal/MediaModal";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import {
  loadingButtonState,
  loadingState,
} from "../../../../tools/recoil/recoil-atom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { openNotification } from "../../../../component/notification/Notification";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import { _isEmpty } from "../../../../tools/util";
import { EyeOutlined } from "@ant-design/icons";
import { getArticleCategory } from "../../../../services/api/article_category";
import Preview from "./Preview";

const Fields = ({ formName = "" }: { formName?: string }) => {
  const setLoading = useSetRecoilState<boolean>(loadingState);
  const history = useHistory();
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);
  const [descriptionTH, setDescriptionTH] = useState<string>("");
  const [descriptionEN, setDescriptionEN] = useState<string>("");
  const [coverTH, setCoverTH] = useState<string>("");
  const [coverEN, setCoverEN] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleCoverImage, setVisibleCoverImage] = useState<boolean>(false);
  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });
  const [mediaSelectCoverImage, setMediaSelectCoverImage] =
    useState<IMediaSelect>({
      file: "",
      id: "",
      media_name: "",
    });

  const [groupList, setGroupList] = useState<any[]>([]);

  const [form] = Form.useForm();
  const { params } = useRouteMatch();
  const { id }: any = params;
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const { Option } = Select;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewData, setPreviewData] = useState<any>("");
  const [previewTab, setPreviewTab] = useState<string>("1");
  const { TabPane } = Tabs;

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = async () => {
    let categorySelect = undefined;
    if (!_isEmpty(pathID)) {
      categorySelect = await handleGetArticleById();
    }
    handleGetArticleCategory(categorySelect);
  };

  const handleGetArticleById = async () => {
    setLoading(true);
    try {
      if (!_isEmpty(id)) {
        const res = await getArticleByID(id);
        const data = res.result[0];
        if (res.code === 200) {
          form.setFieldsValue({
            ...data,
            article_category: data.article_category.id,
          });
          setDescriptionTH(data.description_th);
          setDescriptionEN(data.description_en);
          setCoverTH(data.cover_description_th);
          setCoverEN(data.cover_description_en);
          setMediaSelect({
            file: data.media.file_path,
            id: data.media.id,
            media_name: data.media.media_name,
          });
          setMediaSelectCoverImage({
            file: data.cover_image?.file_path,
            id: data.cover_image?.id,
            media_name: data.cover_image?.media_name,
          });
        }
        return data.article_category;
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    return undefined;
  };

  const handleGetArticleCategory = async (categorySelect: any) => {
    try {
      setLoading(true);
      const res = await getArticleCategory({ is_active: true });
      if (res.code === 200) {
        if (categorySelect) {
          const findCategorySelect = res.result[0].item.find(
            (item: any) => categorySelect.id === item.id
          );
          if (!findCategorySelect) {
            setGroupList([...res.result[0].item, categorySelect]);
          } else {
            setGroupList(res.result[0].item);
          }
        } else {
          setGroupList(res.result[0].item);
        }
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onFinish = (values: any) => {
    if (!_isEmpty(pathID)) {
      handleUpdateArticleList(values);
    } else {
      handleCreateArticleList(values);
    }
  };

  const handleCreateArticleList = async (values: any) => {
    try {
      setloadingButton(true);
      const data = {
        ...values,
        cover_image: mediaSelectCoverImage.id,
        media_id: mediaSelect.id,
      };
      const res = await postArticleList(data);
      if (res.code === 200) {
        if (res.code === 200) {
          history.push("/article");
          openNotification({ method: "POST_SUCCESS", message: res.message });
        } else {
          openNotification({ method: "POST_ERROR", message: res.message });
        }
      }
    } catch (error) {
      console.error(error);
    }
    setloadingButton(false);
  };

  const handleUpdateArticleList = async (value: any) => {
    try {
      setloadingButton(true);
      let datas = {
        ...value,
        id: id,
        cover_image: mediaSelectCoverImage.id,
        media_id: mediaSelect.id,
      };
      delete datas.image;
      const res = await putArticleList(datas);
      if (res.code === 200) {
        if (res.code === 200) {
          openNotification({ method: "PUT_SUCCESS", message: res.message });
          history.push("/article");
        } else {
          openNotification({ method: "PUT_ERROR", message: res.message });
        }
      }
    } catch (error) {
      console.error(error);
    }
    setloadingButton(false);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const onModalCoverImage = () => {
    setVisibleCoverImage(!visibleCoverImage);
  };
  return (
    <div>
      <MediaModal
        visible={visibleCoverImage}
        onModal={onModalCoverImage}
        setMediaSelect={setMediaSelectCoverImage}
      />
      <MediaModal
        visible={visible}
        onModal={onModal}
        setMediaSelect={setMediaSelect}
      />
      <Form form={form} onFinish={onFinish} layout="vertical" name={formName}>
        <Card
          extra={!_isEmpty(pathID) ? "แก้ไขบทความ" : "สร้างบทความ"}
          actions={[
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    const dataInfo = form.getFieldsValue();
                    const categoryName = groupList.find(
                      (item: any) => item?.id === dataInfo?.article_category
                    );
                    setPreviewVisible(true);
                    setPreviewData({
                      ...dataInfo,
                      article_category: categoryName,
                    });
                  }}
                >
                  <EyeOutlined />
                  ตัวอย่าง
                </Button>
              </Col>
              <Col className="pl-1">
                <Button type="default" onClick={() => history.push("/article")}>
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button
                  className="btn-save"
                  htmlType="submit"
                  loading={loadingButton}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Modal
            className="modal-preview"
            title={<div>ตัวอย่าง บทความ</div>}
            centered
            visible={previewVisible}
            onCancel={() => {
              setPreviewVisible(false);
              setPreviewTab("1");
            }}
            width={1350}
            footer={null}
          >
            <div>
              <div className="mt-0.5">
                <Tabs
                  className="tab-mainmenu"
                  key={previewTab ? "notLoadedYet" : "loaded"}
                  defaultActiveKey={previewTab}
                  onChange={(e) => setPreviewTab(e)}
                >
                  <TabPane tab="TH" key="1" />
                  <TabPane tab="EN" key="2" className="border-tab-mainmenu" />
                </Tabs>
                <Preview
                  previewData={previewData}
                  mediaSelect={mediaSelect}
                  lang={previewTab === "1" ? "th" : "en"}
                />
              </div>
            </div>
          </Modal>
          <Row gutter={[14, 14]}>
            <Col lg={12}>
              <Form.Item
                label="หัวข้อบทความ (TH)"
                name="title_th"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="หัวข้อบทความ (EN)"
                name="title_en"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                name="article_category"
                label={"หมวดหมู่บทความ"}
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Select showSearch optionFilterProp="children">
                  {groupList.map((item: any, index: number) => (
                    <Option key={index} value={item.id}>
                      {item?.name_th}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col lg={24} className="mt-1">
              <Form.Item
                label="รูปปก"
                name="cover_image"
                rules={[
                  {
                    required: _isEmpty(mediaSelectCoverImage) ? true : false,
                    message: "โปรดกรอกข้อมูล!",
                  },
                ]}
              >
                <MediaSpace
                  onModal={onModalCoverImage}
                  mediaSelect={mediaSelectCoverImage}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item name="cover_description_th">
                <Editor
                  content={coverTH}
                  key={coverTH ? "succeed" : "Failed"}
                  header="ข้อความปก (TH)"
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item name="cover_description_en">
                <Editor
                  content={coverEN}
                  key={coverEN ? "succeed" : "Failed"}
                  header="ข้อความปก (EN)"
                />
              </Form.Item>
            </Col>
            <Col lg={24} className="mt-1">
              <Form.Item
                label="รูปภาพ"
                name="image"
                rules={[
                  {
                    required: _isEmpty(mediaSelect) ? true : false,
                    message: "โปรดกรอกข้อมูล!",
                  },
                ]}
              >
                <MediaSpace onModal={onModal} mediaSelect={mediaSelect} />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item name="description_th">
                <Editor
                  header="รายละเอียด (TH)"
                  content={descriptionTH}
                  key={descriptionTH ? "notLoadedYet" : "loaded"}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item name="description_en">
                <Editor
                  header="รายละเอียด (EN)"
                  content={descriptionEN}
                  key={descriptionEN ? "notLoadedEN" : "loadedEN"}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
