import "./assets/scss/index.scss";
import "./assets/less/variables.less";
import "react-aspect-ratio/aspect-ratio.css";
import {
  HashRouter,
  Switch,
  Route,
  Redirect /* useRouteMatch,  useParams */,
} from "react-router-dom";
import { routers } from "./routes/router";
import LoginPage from "./views/login/LoginPage";
import DefaultLayout from "./layout/DefaultLayout";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <HashRouter>
        <Switch>
          <Route exact path="/404" component={() => <div>404</div>} />

          <Route
            exact
            path="/login"
            render={(props) => (
              <HomeRoute>
                <LoginPage />
              </HomeRoute>
            )}
          />

          {routers.map((route, ind) => (
            <Route
              key={ind}
              path="/"
              render={(props) => (
                <ProtectedRoute>
                  <DefaultLayout />
                </ProtectedRoute>
              )}
            />
          ))}
        </Switch>
      </HashRouter>
    </RecoilRoot>
  );
}

const ProtectedRoute = (rest: any): any => {
  const accessToken = getToken();
  if (!accessToken) return <Redirect to="/login" />;
  return <Route {...rest} exact={true} />;
};

const HomeRoute = (rest: any): any => {
  const accessToken = getToken();
  if (accessToken) return <Redirect to="/overall" />;
  return <Route {...rest} exact={true} />;
};

export const getToken = (): string => {
  const token = localStorage.getItem("cmsWebPlateformAccessToken");
  return token || "";
};

export default App;
