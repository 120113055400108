
import { version } from "../../config/api"
import { IBannerListPost } from "../../interface/IBanner.interface"
import { Fecth } from "../../tools/fecth/fecth"
import { createQueryString } from "../../tools/util"

export const getBannerList = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/banner${queryStr}`,
    })
    return res
}


export const getBannerListByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/banner/${id}`,
    })
    return res
}



export const postBannerList = async (data: IBannerListPost) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/banner`,
    })
    return res
}
export const putBannerList = async (data: IBannerListPost, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/banner/${id}`,
    })
    return res
}


export const putBannerListActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/banner/active/${id}`,
    })
    return res
}


export const delBannerList = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/banner/${id}`,
    })
    return res
}




