import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'

export const getServiceByMenu = async (menuName: string) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/service-content/${menuName}`,
    })
    return res
}

export const putService = async (menuName: string, data: any) => {
    console.log("test", data);

    const res = await Fecth({
        method: "PUT",
        path: `${version}/service-content/${menuName}`,
        data
    })
    return res
}

