import { Input, Modal } from 'antd';
import { useEffect, useState } from 'react';

import Selector from '../../../../component/selector/Selector';
import { getProductCategoryOnlyGroup } from '../../../../services/api/product_category';

import { useSetRecoilState } from 'recoil';
import { loadingState } from '../../../../tools/recoil/recoil-atom';
import MainCategoryComponent from './components/category/MainCategoryComponent';
const FormDragDrop = ({
  visible,
  categoryList,
  handleSetGroupList,
  handleSetList,
  handleSetCategoryList,
  topicName,
  onCancel,
  onOK,
  modalDetal,
  titleTH,
  setTitleTH,
  titleEN,
  setTitleEN,
  onModal,
  onDelete,
  groupID,
  setGroupID,
  handleGetProductsGroup,
}: any) => {
  const [groupList, setGroupList] = useState([]);
  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    if (visible) {
      handleGetGroup();
    }
    // eslint-disable-next-line
  }, [visible]);

  const handleGetGroup = async () => {
    setLoading(true);
    try {
      const res = await getProductCategoryOnlyGroup();
      if (res.code === 200) {
        const filterGroup = checkCategory(res.result);
        // setGroupList(res.result);
        setGroupList(filterGroup);
      }
    } catch (error) {}
    setLoading(false);
  };

  const checkCategory = (list: any) => {
    const category = categoryList.find((item: any) => item.id === modalDetal.idCate);

    const filterGroup = list.filter((item: any) => {
      const check = category?.group.some((group: any) => {
        if (groupID && groupID === group.id) {
          return false;
        }
        return group.id === item.id;
      });
      if (check) {
        return false;
      }
      return true;
    });
    return filterGroup;
  };

  return (
    <div>
      <Modal visible={visible} onCancel={onCancel} onOk={onOK} title={topicName(modalDetal.topic)} width={'50%'}>
        {modalDetal.type !== 'group' ? (
          <div>
            <p>{modalDetal.topic} (TH)</p>
            <Input value={titleTH} onChange={(e) => setTitleTH(e.target.value)} />
            <p className="pt-1">{modalDetal.topic} (EN)</p>
            <Input value={titleEN} onChange={(e) => setTitleEN(e.target.value)} />
          </div>
        ) : (
          <Selector
            Source={groupList}
            keyName="name_th"
            keyValue="id"
            value={groupID}
            onChange={(e: any) => setGroupID(e)}
          />
        )}
      </Modal>

      <MainCategoryComponent
        handleGetProductsGroup={handleGetProductsGroup}
        list={categoryList}
        setCategory={handleSetCategoryList}
        onModal={onModal}
        onDelete={onDelete}
        setGroup={handleSetGroupList}
        setList={handleSetList}
      />
    </div>
  );
};

export default FormDragDrop;
