import { Collapse, Button } from "antd";

import MainGroupComponent from "../group/MainGroupComponent";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Row, Col, Switch } from "antd";
import { useRecoilState } from "recoil";
import {
  editState,
  dragState,
} from "../../../../../../tools/recoil/recoil-atom";
import { availableID } from "../../../../../../tools/util";
import { updateActiveGroup } from "../../../../../../services/api/product_group";
import { openNotification } from "../../../../../../component/notification/Notification";
const { Panel } = Collapse;

function ItemCategoryComponent(props) {
  // console.log("item  cate", props);
  const [toggleEdit] = useRecoilState(editState);
  const [toggleDrag] = useRecoilState(dragState);

  const dnd = props.dnd;
  const cardClasses = props.itemInDrag ? "card in-drag" : "card";
  const onClick = (titleTH, titleEN) => {
    props.onModal({
      type: "category",
      functional: "edit",
      titleTH: titleTH,
      titleEN: titleEN,
      idCate: props.item.id,
      nane_th: props.item.nane_th,
      nane_en: props.item.nane_en,
    });
  };

  const handleSetDetailCreateGroup = () => {
    props.onModal({
      type: "group",
      functional: "create",
      idCate: props["item"][availableID(props.item)],
    });
  };

  const handleSetDetailDelCategory = () => {
    props.onDelete({
      type: "category",
      functional: "delete",
      idCate: props["item"][availableID(props.item)],
    });
  };

  const handleActiveGruoup = async (id) => {
    try {
      const res = await updateActiveGroup(id);
      if (res.code === 200) {
        props.handleGetProductsGroup();
        openNotification({ method: "PUT_SUCCESS", message: res.message });
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {}
  };

  return (
    <div
      style={dnd.item.styles}
      className={`${dnd.item.classes} card-outer main-card-category-item`}
      ref={dnd.item.ref}
    >
      <div className={cardClasses}>
        <div className="w-100  d-flex collapse-card-category ">
          {toggleDrag && (
            <div className="drag-card-category" {...dnd.handler.listeners}>
              ::
            </div>
          )}
          <div className="w-100">
            <Collapse className="collapse-custom">
              <Panel
                key="1"
                header={
                  <div className="d-flex items-center">
                    <div onClick={(e) => e.stopPropagation()} className="mr-1">
                      <Switch
                        disabled={!toggleEdit}
                        checked={props.item.public}
                        onClick={() => handleActiveGruoup(props.item.id)}
                      />
                    </div>
                    <Button
                      onClick={() => {
                        onClick(props.item.name_th, props.item.name_en);
                      }}
                      disabled={!toggleEdit}
                    >
                      {props.item.name_th}
                    </Button>

                    {toggleEdit ? (
                      <>
                        <Button
                          className="ml-1"
                          onClick={() => handleSetDetailDelCategory()}
                        >
                          <DeleteOutlined />
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                }
              >
                <Row justify="end">
                  <Col>
                    <Button
                      className="ml-1"
                      type="primary"
                      onClick={() => handleSetDetailCreateGroup()}
                      disabled={!toggleEdit}
                    >
                      <PlusOutlined className="icons-size" /> สร้างกลุ่ม
                    </Button>
                  </Col>
                </Row>

                <MainGroupComponent
                  {...props}
                  group={props.item.group}
                  setGroup={props.setGroup}
                  setList={props.setList}
                  idCate={props["item"][availableID(props.item)]}
                  onModal={props.onModal}
                  onDelete={props.onDelete}
                />
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemCategoryComponent;
