import { Fecth } from "../../tools/fecth/fecth"
import { version } from "../../config/api"
import { IDoorOpenPatternPost } from "../../interface/IDoorOpenPattern.interface"
import { createQueryString } from "../../tools/util"


export const getDoorOpenPattern = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/open-type${queryStr}`,
    })
    return res
}


export const getDoorOpenPatternByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/open-type/${id}`,
    })
    return res
}



export const postDoorOpenPattern = async (data: IDoorOpenPatternPost) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/open-type`,
    })
    return res
}

export const putDoorOpenPattern = async (data: IDoorOpenPatternPost, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/open-type/${id}`,
    })
    return res
}


export const putDoorOpenPatternActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/open-type/active/${id}`,
    })
    return res
}


export const delDoorOpenPattern = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/open-type/${id}`,
    })
    return res
}




