import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useState, useEffect } from "react";
import { _isEmpty } from "@yim/util-tool-ts";
import { getProjectContentCategory } from "../../../services/api/project";

type Props = {
  onFinish: (values: any) => void;
  initialValue: {
    projectItem: string;
    projectTypeID: any;
  };
};

const Headers = ({ onFinish, initialValue }: Props) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const [groupList, setGroupList] = useState([] as any);

  useEffect(() => {
    if (!_isEmpty(initialValue)) {
      form.setFieldsValue({
        search: initialValue?.projectItem,
        category_id: initialValue?.projectTypeID
          ? initialValue?.projectTypeID
          : null,
      });
    }
    handleGetProjectGroupList();
    // eslint-disable-next-line
  }, []);

  const handleGetProjectGroupList = async () => {
    const query = {
      page: 1,
      limit: 10,
      orderby: "DESC",
      search: "",
      is_active: true,
    };
    try {
      const res = await getProjectContentCategory(query);
      if (res.code === 200) {
        setGroupList(res.result[0].item);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Form onFinish={onFinish} form={form}>
        <Card className="card-round">
          <Row gutter={[14, 14]}>
            <Col lg={9}>
              <Form.Item name="search">
                <Input placeholder="ค้นหาโครงการ" />
              </Form.Item>
            </Col>
            <Col lg={5}>
              <Form.Item name="category_id">
                <Select
                  showSearch
                  placeholder="หมวดหมู่โครงการ"
                  optionFilterProp="children"
                >
                  {groupList?.map((item: any, index: number) => (
                    <Option key={index} value={item.id}>
                      {item.name_th}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
            </Col>
            <Col lg={3}>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Headers;
