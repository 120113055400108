import { Button, Card, Col, Row, Form } from "antd";
import { useEffect, useState } from "react";
import MediaModal from "../../../../component/mediamodal/MediaModal";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import { _isEmpty } from "../../../../tools/util";
import { getServiceByMenu, putService } from "../../../../services/api/service";
import { openNotification } from "../../../../component/notification/Notification";
import TextArea from "antd/lib/input/TextArea";

const SpecDesignForm = () => {
  const [form] = Form.useForm();
  const [visibleImageModal, setVisibleImageModal] = useState(false);
  const [mediaImageSelect, setMediaImageSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });

  useEffect(() => {
    handleGetSpecDesignByMenu();
    // eslint-disable-next-line
  }, []);

  const onModalImage = () => {
    setVisibleImageModal(!visibleImageModal);
  };

  const handleGetSpecDesignByMenu = async () => {
    const res = await getServiceByMenu("spec_design");
    if (res.code === 200) {
      const data = res.result[0];
      form.setFieldsValue({
        description_en: data.description_en,
        description_th: data.description_th,
      });
      if (!_isEmpty(data)) {
        setMediaImageSelect({
          file: data.media.file_path,
          id: data.media.id,
          media_name: data.media.media_name,
        });
      }
    }
  };
  const onFinish = (values: any) => {
    handleUpdateSpecDesign(values);
  };

  const handleUpdateSpecDesign = async (values: any) => {
    try {
      const res = await putService("spec_design", {
        ...values,
        media_id: mediaImageSelect.id,
      });
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {}
  };

  return (
    <div>
      <MediaModal
        visible={visibleImageModal}
        onModal={onModalImage}
        setMediaSelect={setMediaImageSelect}
      />
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Card
          extra={<>ออกแบบวางสเปค</>}
          actions={[
            <Row justify="end">
              <Col className="pl-1">
                <Button className="btn-save" htmlType="submit">
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form.Item
            label="รูปภาพ"
            name="media_id"
            rules={[
              {
                required: _isEmpty(mediaImageSelect) ? true : false,
                message: "โปรดกรอกข้อมูล!",
              },
            ]}
          >
            <MediaSpace onModal={onModalImage} mediaSelect={mediaImageSelect} />
          </Form.Item>
          <Form.Item name="description_th" label={"รายละเอียด (TH)"}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="description_en" label={"รายละเอียด (EN)"}>
            <TextArea rows={4} />
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
};

export default SpecDesignForm;
