
export const data: any = {
    POST_SUCCESS: {
        type: "success",
        message: "สร้างข้อมูลสำเร็จ",
    },
    POST_ERROR: {
        type: "error",
        message: "สร้างข้อมูลไม่สำเร็จ",
    },
    PUT_SUCCESS: {
        type: "success",
        message: "อัปเดตข้อมูลสำเร็จ",
    },
    PUT_ERROR: {
        type: "error",
        message: "อัปเดตข้อมูลไม่สำเร็จ",
    },
    DEL_SUCCESS: {
        type: "success",
        message: "ลบข้อมูลสำเร็จ",
    },
    DEL_ERROR: {
        type: "error",
        message: "ลบข้อมูลไม่สำเร็จ",
    },
    DOWNLOAD_SUCCESS: {
        type: "success",
        message: "ดาวน์โหลดสำเร็จ",
    },
    DOWNLOAD_ERROR: {
        type: "error",
        message: "ดาวน์โหลดไม่สำเร็จ",
    },

    UPLOAD_SUCCESS: {
        type: "success",
        message: "อัพโหลดสำเร็จ",
    },
    UPLOAD_ERROR: {
        type: "error",
        message: "อัพโหลดไม่สำเร็จ",
    },
    TOKEN_EXPIRE: {
        type: "error",
        message: "Token หมดอายุแล้ว",
    },
    ERROR: {
        type: "error",
        message: "เกิดข้อผิดพลาด",
    },
};
