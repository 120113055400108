import React, { useState } from "react";
import { Button, Card, Col, Form, Input, Modal, Row, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { downloadFile, dummyRequest, _appendFields } from "../../../tools/util";
import { openNotification } from "../../../component/notification/Notification";
import CImage from "../../../component/image/Image";
import ExelIcon from "../../../assets/img/icons/exel.png";
import { getExcelGlass, postExcelGlass } from "../../../services/api/glass";

type Props = {
  onFinish: (values: any) => void;
};
const Headers = ({ onFinish }: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onOK = () => {
    form.submit();
  };

  const onModal = () => {
    setVisible(!visible);
    form.resetFields();
  };

  const handleImportExcel = async (values: any) => {
    try {
      const data = _appendFields({
        file: values.file.file.originFileObj,
      });
      const res = await postExcelGlass(data);
      if (res.code === 200) {
        openNotification({ method: "UPLOAD_SUCCESS", message: res.message });
        onModal();
      } else {
        openNotification({ method: "UPLOAD_ERROR", message: res.message });
      }
    } catch (error) {}
  };

  const handleExportExcel = async () => {
    try {
      const res = await getExcelGlass();
      if (res.code === 200) {
        downloadFile(res.data, "xlsx", "glass");
        openNotification({ method: "DOWNLOAD_SUCCESS" });
      } else {
        openNotification({ method: "DOWNLOAD_ERROR" });
      }
    } catch (error) {}
  };

  const props = {
    customRequest: dummyRequest,
    maxCount: 1,
    accept: ".xlsx,.xls",
  };
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Card className="card-round">
          <Row gutter={[14, 14]}>
            <Col lg={9}>
              <Form.Item name="search">
                <Input placeholder="ค้นหาชื่อกระจก" />
              </Form.Item>
            </Col>
            <Col lg={5} className="d-flex justify-between">
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
            <Col lg={10} className="d-flex justify-flex-end">
              <Button
                className="d-flex items-center mr-1"
                onClick={() => setVisible(true)}
              >
                นำเข้า
                <CImage
                  src={ExelIcon}
                  alt="excel"
                  style={{ height: "25px", width: "25px", marginLeft: "5px" }}
                />
              </Button>
              <Button
                className="d-flex items-center"
                onClick={handleExportExcel}
              >
                นำออก
                <CImage
                  src={ExelIcon}
                  alt="excel"
                  style={{ height: "25px", width: "25px", marginLeft: "5px" }}
                />
              </Button>
            </Col>
          </Row>

          <Modal
            visible={visible}
            title="นำเข้า Excel"
            centered
            onCancel={onModal}
            onOk={onOK}
          >
            <Form onFinish={handleImportExcel} form={form}>
              <Form.Item
                name="file"
                rules={[{ required: true, message: "โปรดใส่ excel !" }]}
              >
                <Upload listType="picture" {...props}>
                  <Button icon={<UploadOutlined />} block>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Form>
          </Modal>
        </Card>
      </Form>
    </div>
  );
};

export default Headers;
