import { Fecth } from '../../tools/fecth/fecth'


interface IUserLogin {
  username: string;
  password: string;
}

export const userLogin = async (data: IUserLogin) => {
  const res = await Fecth({
    data,
    method: "POST",
    path: `api/auth/login`,
  })
  return res
}