import React from "react";
import { Row, Col, Card, Button, Form, Input, Select, DatePicker } from "antd";

type Props = {
  onFinish: (value: any) => void;
};

const Header = ({ onFinish }: Props) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  return (
    <div>
      <Form onFinish={onFinish}>
        <Card className="card-round">
          <Row gutter={14}>
            <Col lg={6} sm={8}>
              <Form.Item name="search">
                <Input placeholder="ค้นหาชื่อโครงการ" />
              </Form.Item>
            </Col>
            <Col lg={4} sm={8}>
              <Form.Item name="status">
                <Select placeholder="สถานะ : ทั้งหมด">
                  <Option value="">ทั้งหมด</Option>
                  <Option value="APPROVE">อนุมัติแล้ว</Option>
                  <Option value="WAIT_FOR_APPROVE">รออนุมัติ</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={4} sm={8}>
              <Form.Item name="date">
                {
                  //@ts-ignore
                  <RangePicker placeholder={["วันเริ่ม", "วันสิ้นสุด"]} />
                }
              </Form.Item>
            </Col>
            <Col lg={3} sm={8} className="d-flex justify-between">
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Header;
