import { Pagination } from "antd";
import CImage from "../../../../component/image/Image";
import { IProductImage } from "../../../../interface/IApprove.interface";

type Props = {
  productImageVisible: IProductImage;
  productImageLength: number;
  currentProductImage: number;
  handleProductImageVisible: (page: number) => void;
};
const ProductImage = ({
  productImageVisible,
  productImageLength,
  currentProductImage,
  handleProductImageVisible,
}: Props) => {
  return (
    <div className="border-box item-between mb-1 h-100">
      <div>
        ชื่อสินค้า : <span className="pl-1">{productImageVisible.name}</span>
      </div>
      <div className="d-flex justify-center">
        <CImage
          src={productImageVisible.image}
          alt="productImage"
          className="img-product-image"
        />
      </div>
      <div className="d-flex justify-center">
        {productImageLength > 0 && (
          <Pagination
            onChange={(page) => handleProductImageVisible(page)}
            current={currentProductImage}
            total={productImageLength}
            pageSize={1}
          />
        )}
      </div>
    </div>
  );
};

export default ProductImage;
