import { Row, Col, Select, Input, Form } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import Selector from "../../../../component/selector/Selector";
import { dataFromSelect } from "../../../../interface/IUsers.interface";
import { getRoleList } from "../../../../services/api/role";

//------------------API--------------------//
import { GET_DEPARTMENT_API } from "../../../../services/api/user";

type Props = {
  form?: any;
};

const SetDataUser = ({ form }: Props) => {
  const [dataRole, setDataRole] = useState<dataFromSelect>([]);
  const [dataDep, setdataDep] = useState<dataFromSelect>([]);

  const { Option } = Select;

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    getRole();
    getDepartment();
  };

  const getRole = async () => {
    try {
      const res = await getRoleList();
      const newData = res.result[0].item;
      const data = newData.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      if (res.code === 200) {
        setDataRole(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDepartment = async () => {
    try {
      const res = await GET_DEPARTMENT_API();
      const newData = res.result[0].item;
      const data = newData.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      if (res.code === 200) {
        setdataDep(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div>
        <b>ข้อมูลพื้นฐาน</b>
      </div>
      <br />
      <Row gutter={20}>
        <Col lg={12}>
          <Form.Item
            label="ชื่อ"
            name="first_name"
            rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            label="ชื่อสกุล"
            name="last_name"
            rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col lg={12}>
          <Form.Item
            className="mt-1"
            label="ชื่อเล่น"
            name="nickname"
            rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            className="mt-1"
            label="เบอร์โทรศัพท์"
            name="phone"
            rules={[{ required: true, message: "โปรดกรอกหมายเลขโทรศัพท์!" }]}
          >
            <Input
              pattern="[0-9].{9,}"
              title="กรุณากรอกหมายเลขโทรศัพท์ โดยประกอบด้วยเลข 10 ตัว"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col lg={24}>
          <Form.Item
            className="mt-1"
            label="อีเมล์"
            name="email"
            rules={[
              { required: true, message: "โปรดกรอกอีเมล์!" },
              { type: "email", message: "อีเมล์ไม่ถูกต้อง" },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col lg={12}>
          <Form.Item
            className="mt-1"
            label="ตำแหน่ง"
            name="department"
            rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
          >
            <Selector Source={dataDep} keyName="name" keyValue="id" />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            className="mt-1"
            label="บทบาท"
            name="role"
            rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
          >
            <Select placeholder="Manager AE">
              {dataRole.map((item: any) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default SetDataUser;
