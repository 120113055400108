import React from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";

type Props = {
  onFinish: (values: any) => void;
};
const Headers = ({ onFinish }: Props) => {
  return (
    <div>
      <Form onFinish={onFinish}>
        <Row gutter={14}>
          <Col lg={10}>
            <Form.Item name="media_name">
              <Input placeholder="ค้นหาชื่อไฟล์" />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Button className="mr-1 btn-search" htmlType="submit" block>
              <SearchOutlined className="search-icons" /> ค้นหา
            </Button>
          </Col>
          <Col lg={4}>
            <Button className="clear" htmlType="reset" block>
              ล้างข้อมูล
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Headers;
