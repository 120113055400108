import { useRouteMatch } from "react-router-dom";
import { IMediaSelect } from "../../interface/IMedia.interface";
import { _isEmpty } from "../../tools/util";
import { UserOutlined } from "@ant-design/icons";

type Props = {
  visible: boolean;
  onModal?: () => void;
  mediaSelect?: IMediaSelect;
  uploadPage?: boolean;
};

const SpacePhoto = ({
  onModal,
  mediaSelect = { file: "", id: "", media_name: "" },
  uploadPage,
}: Props) => {
  const { url } = useRouteMatch();

  return (
    <div>
      <div
        className="media-space-container d-flex items-center justify-center"
        onClick={onModal && onModal}
      >
        {_isEmpty(mediaSelect.file) ? (
          <>
            {url === "/setting" ? (
              <h1 className="header-xs font-size-40 mt-3">LOGO</h1>
            ) : (
              <>
                <UserOutlined className="font-size-250" />
              </>
            )}
          </>
        ) : (
          <img
            src={mediaSelect.file}
            alt="uploader"
            className="image-render-uploadphoto"
          />
        )}
      </div>
    </div>
  );
};

export default SpacePhoto;
