import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { getColorsType } from "../../../services/api/colors";
import { IResStatus } from "../../../tools/fecth/interface";
import { IColors } from "../../../interface/IColors.inteface";
import Selector from "../../../component/selector/Selector";

type Props = {
  onFinish: (values: any) => void;
};
const Headers = ({ onFinish }: Props) => {
  const [colorsTypeList, setColorsTypeList] = useState<IColors[]>([]);

  useEffect(() => {
    handleGetColorsType();
  }, []);
  const handleGetColorsType = async () => {
    try {
      const res: IResStatus = await getColorsType({});
      if (res.code === 200) {
        setColorsTypeList(res.result[0].item);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Card className="card-round">
          <Row gutter={[14, 14]}>
            <Col lg={9}>
              <Form.Item name="color">
                <Input placeholder="ค้นหาชื่อสี" />
              </Form.Item>
            </Col>
            <Col lg={5}>
              <Form.Item name="color_type">
                <Selector
                  Source={colorsTypeList}
                  keyName="name_th"
                  keyValue="id"
                />
              </Form.Item>
            </Col>
            <Col lg={5} className="d-flex justify-between">
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Headers;
