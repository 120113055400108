import React, { useState, useEffect } from "react";
import MoTable from "../../../component/table/Table";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { SearchValue } from "../../../interface/IUsers.interface";
import { DateTimeStandard } from "../../../tools/date";

import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import { IGetRoleList } from "../../../interface/IRole.interface";
import { openNotification } from "../../../component/notification/Notification";
import Modaler from "../../../component/modaler/Modaler";
import { deleteRoleList, getRoleList } from "../../../services/api/role";
import { _isEmpty } from "../../../tools/util";

type Props = {
  search: SearchValue;
};

const IRoleTab = ({ search }: Props) => {
  const [total, setTotal] = useState<number>();
  const [visible, setVisible] = useState<boolean>(false);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const setLoading = useSetRecoilState<boolean>(loadingState);
  const [roleList, setRoleList] = useState<IGetRoleList[]>([]);
  const [id, setId] = useState<number>(0);

  const history = useHistory();

  useEffect(() => {
    // if (firstWorks) handleGetInspirationCategoryList();
    // setFirstWorks(false);
    if (!_isEmpty(search)) {
      setCurrentPage(1);
    }
    handleGetDataRoleList();
    // eslint-disable-next-line
  }, [currentPage, limitPage, search]);

  const handleGetDataRoleList = async () => {
    setLoading(true);
    try {
      const res = await getRoleList({
        page: currentPage,
        limit: limitPage,
        ...search,
      });
      if (res.code === 200) {
        setTotal(res.result[0].total);
        setRoleList(res.result[0].item);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleDeleteRoleList = async (data: number) => {
    try {
      setLoading(true);
      const res = await deleteRoleList(data);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetDataRoleList();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }
    onModal();
    setLoading(false);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleteRoleList(id);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = async (row: any) => {
    history.push(`/user/role/edit/${row.id}`);
  };

  const columns: any = [
    {
      width: "0.1%",
    },
    {
      title: "ชื่อบทบาท",
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (name: string) => {
        return (
          <>
            <b>{name}</b>
          </>
        );
      },
    },
    {
      title: "อัปเดต",
      dataIndex: "update",
      key: "update",
      width: "15%",
      render: (_: any, data: any) => {
        return (
          <>
            <span>{data.edit_by?.first_name + data.edit_by?.last_name}</span>
            <br />
            <span>
              {!_isEmpty(data.updatedAt)
                ? DateTimeStandard(data.updated_at)
                : DateTimeStandard(data.created_at)}
            </span>
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",

      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                e.stopPropagation();
                setId(data.id);
                onModal();
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="25%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            คุณแน่ใจไหม?
          </div>
        }
      >
        คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
      </Modaler>
      <MoTable
        dataSource={roleList}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
      />
    </div>
  );
};

export default IRoleTab;
