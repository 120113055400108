import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tabs } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Category from "./category/Category";
import GroupTab from "./group/Group";
import ProductTab from "./product/Product";
import ProductHeader from "./product/Header";
import ColorTypeHeader from "./colortype/Header";
import InstallationHeader from "./installation/Header";
import ColorsHeader from "./colors/Header";
import MirrorHeader from "./mirror/Header";
import LogDeviceHeader from "./logdevice/Header";
import AluminiumHeader from "./aluminium/Header";
import DoorOpenPatternHeader from "./dooropenpattern/Header";
import CategoryHeader from "./category/Header";

import Mirror from "./mirror/Mirror";
import LogDevice from "./logdevice/LogDevice";
import Colors from "./colors/Colors";
import Aluminium from "./aluminium/Aluminium";
import ColorType from "./colortype/ColorType";
import DoorOpenPattern from "./dooropenpattern/DoorOpenPattern";
import { useRecoilState } from "recoil";
import { tabSelectedState } from "../../tools/recoil/recoil-atom";
import Installation from "./installation/Installation";

const Product = () => {
  // const [tabSelected, setTabSelected] = useState("1");
  const [tabSelected, setTabSelected] = useRecoilState(tabSelectedState);
  const [productFilterValue, setProductFilterValue] = useState<any[]>([]);

  const [aluminiumFilterValue, setAluminiumFilterValue] = useState<any>({});
  const [logDeviceFilterValue, setLogDeviceFilterValue] = useState<any>({});
  const [categoryFilterValue, setCategoryFilterValue] = useState<any>({});
  const [colorTypeFilterValue, setColorTypeFilterValue] = useState<any>({});
  const [doorOpenPatternFilterValue, setDoorOpenPatternFilterValue] =
    useState<any>({});
  const [installationFilterValue, setInstallationFilterValue] = useState<any>(
    {}
  );
  const [colorsFilterValue, setColorFilterValue] = useState<any>({});
  const [mirrorFilterValue, setMirrorFilterValue] = useState<any>({});
  const { TabPane } = Tabs;

  const history = useHistory();
  const productFilter = (values: any) => {
    setProductFilterValue(values);
  };

  const colorsTypeFilter = (values: any) => {
    setColorTypeFilterValue(values);
  };
  const categoryFilter = (values: any) => {
    setCategoryFilterValue(values);
  };

  const doorOpenPatternFilter = (values: any) => {
    setDoorOpenPatternFilterValue(values);
  };

  const installationFilter = (values: any) => {
    setInstallationFilterValue(values);
  };

  const colorsFilter = (values: any) => {
    setColorFilterValue(values);
  };

  const mirrorFilter = (values: any) => {
    setMirrorFilterValue(values);
  };

  const logDeviceFilter = (values: any) => {
    setLogDeviceFilterValue(values);
  };

  const aluminiumFilter = (values: any) => {
    setAluminiumFilterValue(values);
  };
  const handleChangePage = () => {
    tabSelected === "2"
      ? history.push("/product/product/create")
      : tabSelected === "3"
      ? history.push("/product/category/create")
      : tabSelected === "4"
      ? history.push("/product/aluminium/create")
      : tabSelected === "5"
      ? history.push("/product/door-open-pattern/create")
      : tabSelected === "6"
      ? history.push("/product/installation/create")
      : tabSelected === "7"
      ? history.push("/product/color-type/create")
      : tabSelected === "8"
      ? history.push("/product/color/create")
      : tabSelected === "9"
      ? history.push("/product/mirror/create")
      : tabSelected === "10"
      ? history.push("/product/log-device/create")
      : history.push("/inspiration/inspiration/create");
  };

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">จัดการสินค้า</span>
        </Col>
        <Col lg={2}>
          {tabSelected !== "1" && tabSelected !== "4" && (
            <Button
              className="btn-create"
              type="primary"
              onClick={handleChangePage}
            >
              <PlusOutlined className="icons-size" /> สร้าง
            </Button>
          )}
        </Col>
      </Row>

      <div className="set-layout">
        {tabSelected === "2" && <ProductHeader onFinish={productFilter} />}
        {tabSelected === "3" && <CategoryHeader onFinish={categoryFilter} />}
        {tabSelected === "4" && <AluminiumHeader onFinish={aluminiumFilter} />}
        {tabSelected === "5" && (
          <DoorOpenPatternHeader onFinish={doorOpenPatternFilter} />
        )}
        {tabSelected === "6" && (
          <InstallationHeader onFinish={installationFilter} />
        )}
        {tabSelected === "7" && <ColorTypeHeader onFinish={colorsTypeFilter} />}
        {tabSelected === "8" && <ColorsHeader onFinish={colorsFilter} />}
        {tabSelected === "9" && <MirrorHeader onFinish={mirrorFilter} />}
        {tabSelected === "10" && <LogDeviceHeader onFinish={logDeviceFilter} />}

        <div>
          <Tabs
            className="tab-mainmenu"
            defaultActiveKey={tabSelected}
            onChange={(e) => setTabSelected(e)}
          >
            <TabPane tab="หมวดหมู่สินค้า" key="1">
              <GroupTab />
            </TabPane>
            <TabPane tab="สินค้า" key="2" className="border-tab-mainmenu">
              <ProductTab filterValue={productFilterValue} />
            </TabPane>
            <TabPane tab="ประเภทสินค้า" key="3" className="border-tab-mainmenu">
              <Category filterValue={categoryFilterValue} />
            </TabPane>
            <TabPane tab=" อลูมิเนียม" key="4" className="border-tab-mainmenu">
              <Aluminium filterValue={aluminiumFilterValue} />
            </TabPane>
            <TabPane
              tab="รูปแบบการเปิดปิด"
              key="5"
              className="border-tab-mainmenu"
            >
              <DoorOpenPattern filterValue={doorOpenPatternFilterValue} />
            </TabPane>
            <TabPane
              tab="รูปแบบติดตั้ง"
              key="6"
              className="border-tab-mainmenu"
            >
              <Installation filterValue={installationFilterValue} />
            </TabPane>
            <TabPane tab="ประเภทสี" key="7" className="border-tab-mainmenu">
              <ColorType filterValue={colorTypeFilterValue} />
            </TabPane>
            <TabPane tab="สี" key="8" className="border-tab-mainmenu">
              <Colors filterValue={colorsFilterValue} />
            </TabPane>
            <TabPane tab="กระจก" key="9" className="border-tab-mainmenu">
              <Mirror filterValue={mirrorFilterValue} />
            </TabPane>
            <TabPane tab="อุปกรณ์ล็อค" key="10" className="border-tab-mainmenu">
              <LogDevice filterValue={logDeviceFilterValue} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Product;
