import React from "react";
import { BubbleMenu } from "@tiptap/react";
import { Button } from "antd";
import left from "../../assets/img/icons/left.svg";
import rigth from "../../assets/img/icons/rigth.svg";
import center from "../../assets/img/icons/center.svg";
import bold from "../../assets/img/icons/bold.svg";
import italic from "../../assets/img/icons/italic.svg";
import link from "../../assets/img/icons/link.svg";

const MoBubbleMenu = ({ editor, handleSetLink }) => {
  return (
    <>
      {editor && (
        <BubbleMenu editor={editor} 
        tippyOptions={{ offset:[0,-60] }}
        >
          <Button
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
            className={
              editor.isActive({ textAlign: "left" })
                ? "t-btn-buble left is-active "
                : "t-btn-buble "
            }
          >
              {/* left */}
              <img className="circle editor-process" src={left} alt="left" />
          </Button>
          <Button
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
            className={
              editor.isActive({ textAlign: "center" })
                ? "t-btn-buble right is-active"
                : "t-btn-buble"
            }
          >
           {/* center
           */}
          <img className="circle editor-process" src={center} alt="center" />
          </Button>
          <Button
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
            className={
              editor.isActive({ textAlign: "right" })
                ? "t-btn-buble center is-active"
                : "t-btn-buble"
            }
          >
             {/* right */}
             <img className="circle editor-process" src={rigth} alt="rigth" />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={
              editor.isActive("bold")
                ? "t-btn-buble bold is-active"
                : "t-btn-buble"
            }
          >
               {/* bold */}
          <img className="circle editor-process" src={bold} alt="bold" />
          </Button>
          <Button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={
              editor.isActive("italic")
                ? "t-btn-buble italic is-active"
                : "t-btn-buble"
            }
          >
            {/* italic */}
            <img className="circle editor-process" src={italic} alt="italic" />
          </Button>
          <Button
            onClick={() => handleSetLink()}
            className={
              editor.isActive("link") ? "t-btn-buble is-active" : "t-btn-buble"
            }
          >
            <img className="circle editor-process" src={link} alt="link" />
          </Button>
        </BubbleMenu>
      )}
    </>
  );
};

export default MoBubbleMenu;
