import MoTable from "../../../component/table/Table";
import DWGIcon from "../../../assets/img/icons/dwg.png";
import PDFIcon from "../../../assets/img/icons/pdf.png";
import ExelIcon from "../../../assets/img/icons/exel.png";
import { IMedia } from "../../../services/api/media";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import { useSetRecoilState } from "recoil";

type Props = {
  mediaFileList: IMedia[];
  onChangePage?: (page: number, pageSize?: string) => void;
  total: number;
  limitPage: number;
  currentPage: number;
  setIntialValue: any;
  onModal: any;
  setMediaId: any;
};
const FileList = ({
  onModal,
  setMediaId,
  onChangePage,
  total,
  limitPage,
  currentPage,
  mediaFileList,
  setIntialValue,
}: Props) => {
  const setLoading = useSetRecoilState<boolean>(loadingState);

  const columns: any = [
    {
      title: " ",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "10%",
      render: (type: string) => {
        return (
          <>
            {type === ".pdf" ? (
              <img
                src={PDFIcon}
                alt="pdf"
                style={{ height: "35px", width: "35px" }}
              />
            ) : type === ".xlsx" || type === ".xls" ? (
              <img
                src={ExelIcon}
                alt="exel"
                style={{ height: "35px", width: "35px" }}
              />
            ) : type === ".dwg" ? (
              <img
                src={DWGIcon}
                alt="exel"
                style={{ height: "35px", width: "35px" }}
              />
            ) : (
              "ไม่พบชนิดไฟล์นี้"
            )}
          </>
        );
      },
    },
    {
      title: "ชื่อไฟล์",
      dataIndex: "media_name",
      key: "media_name",
      width: "77%",
      render: (media_name: string) => {
        return (
          <>
            <b>{media_name}</b>
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",

      render: (_: string, data: any) => {
        return (
          <>
            <DownloadOutlined
              onClick={(e) => {
                setLoading(true);
                window.open(data.file_path);
                setLoading(false);
                e.stopPropagation();
              }}
            />
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                onModal &&
                  onModal({
                    type: "delete",
                    actionName: data.media_name,
                  });
                setMediaId(data.id);
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];

  const onRow = (value: any) => {
    onModal &&
      onModal({
        type: "edit",
        actionName: value.media_name,
      });
    setMediaId(value.id);
    setIntialValue({ media_name: value.media_name });
  };

  return (
    <div>
      <div className="card-table">
        <MoTable
          rowKey={"id"}
          dataSource={mediaFileList}
          columns={columns}
          onChangePage={onChangePage}
          config={{
            total: total,
            pageSize: limitPage,
            currentPage: currentPage,
          }}
          onClickRow={onRow}
        />
      </div>
    </div>
  );
};

export default FileList;
