import {useEffect} from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { _isEmpty } from '@yim/util-tool-ts';

type Props = {
  onFinish: (values: any) => void;
  initialValue: string;
};
const Header = ({ onFinish,initialValue }: Props) => {
  const [form] = Form.useForm();
  
  useEffect(()=>{
    GetInitialValue();
     // eslint-disable-next-line
  },[])

  const GetInitialValue = () => {
    if(!_isEmpty(initialValue)){
      form.setFieldsValue({
        search:initialValue
      })
    }
  }

  return (
    <div>
      <Form onFinish={onFinish} form={form}>
        <Card>
          <Row gutter={14}>
            <Col lg={9}>
              <Form.Item name="search">
                <Input placeholder="ค้นหาหมวดหมู่" />
              </Form.Item>
            </Col>
            <Col lg={3} className="d-flex justify-between">
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Header;
