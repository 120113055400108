import { Button, Card, Col, Form, Input, InputNumber, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";

import MediaModal from "../../../../component/mediamodal/MediaModal";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import { openNotification } from "../../../../component/notification/Notification";
import { IColorsPost } from "../../../../interface/IColors.inteface";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import { IMirror } from "../../../../interface/IMirror.interface";
import {
  getGlassById,
  postGlass,
  putGlass,
} from "../../../../services/api/glass";
import { IResStatus } from "../../../../tools/fecth/interface";
import {
  loadingButtonState,
  loadingState,
} from "../../../../tools/recoil/recoil-atom";
import { _isEmpty } from "../../../../tools/util";

const Fields = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });
  const [form] = Form.useForm();
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);
  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    if (!_isEmpty(pathID)) {
      handleGetGlassById();
    }
  };

  const handleGetGlassById = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getGlassById(pathID);
      if (res.code === 200) {
        const data: IMirror = res.result[0];
        setMediaSelect({
          file: data.media?.file_path,
          id: data.media?.id,
          media_name: data.media?.media_name,
        });
        form.setFieldsValue({
          media_id: data.media?.id,
          ...data,
        });
      }
    } catch (error) {}
    setLoading(false);
  };

  const onFinish = (values: any) => {
    if (!_isEmpty(pathID)) {
      handleUpdateMirror(values);
    } else {
      handleCreateMirror(values);
    }
  };

  const handleCreateMirror = async (values: IColorsPost) => {
    try {
      setloadingButton(true);
      const data = {
        ...values,
        media_id: mediaSelect.id,
      };
      const res: IResStatus = await postGlass(data);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const handleUpdateMirror = async (values: IColorsPost) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await putGlass(
        {
          ...values,
          media_id: mediaSelect.id,
        },
        pathID
      );
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <MediaModal
        visible={visible}
        onModal={onModal}
        setMediaSelect={setMediaSelect}
      />
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Card
          extra={!_isEmpty(pathID) ? "แก้ไขกระจก" : "สร้างกระจก"}
          actions={[
            <Row justify="end">
              <Col>
                <Button type="default" onClick={() => history.goBack()}>
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button
                  className="btn-save"
                  htmlType="submit"
                  loading={loadingButton}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row justify="center">
            <Col lg={12}>
              <Row gutter={[14, 14]}>
                <Col lg={12}>
                  <Form.Item
                    label="ชื่อกระจก (TH)"
                    name="name_th"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="ชื่อกระจก (EN)"
                    name="name_en"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    label="ชื่อ SKU"
                    name="sku"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="สี"
                    name="color"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="ราคา"
                    name="price"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <InputNumber addonAfter="บาท/ตร.ฟุต" />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    key={mediaSelect.media_name ? "mediaHave" : "mediaNull"}
                    label="รูปกระจก"
                    name="media_id"
                    rules={[
                      {
                        required: _isEmpty(mediaSelect.media_name)
                          ? true
                          : false,
                        message: "โปรดเลือกรูปภาพ!",
                      },
                    ]}
                  >
                    <MediaSpace onModal={onModal} mediaSelect={mediaSelect} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
