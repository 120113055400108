import { Button, Card, Col, Form, Input, Modal, Row, Table } from "antd";
import { useEffect, useState } from "react";
import UserInfo from "./component/UserInfo";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IResStatus } from "../../../tools/fecth/interface";
import { getApproveById, putApprove } from "../../../services/api/approve";
import {
  IApprove,
  IProductImage,
  IProductList,
} from "../../../interface/IApprove.interface";
import ProjectDetail from "./component/ProjectDetail";
import ProductImage from "./component/ProductImage";
import InstallPrice from "./component/InstallPrice";
import Products from "./component/Products";
import Selector from "../../../component/selector/Selector";
import BlackHome from "../../../assets/img/icons/black-home.svg";
import { openNotification } from "../../../component/notification/Notification";
import imgFile from "../../../assets/img/icons/img_file.svg";

const Fields = () => {
  const [approve, setApprove] = useState<IApprove>({
    id: 1,
    approve_status: "",
    cover_image: "",
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    project_name: "",
    project_category: "",
    start_date: "",
    end_date: null,
    company_name: "",
    address: "",
    post_code: "",
    province: "",
    district: "",
    sub_district: "",
    install_price: 1406003.6,
    is_file_plan: false,
    have_file: false,
    image_product: [
      {
        name: "",
        image: "",
      },
    ],
    list_product: [
      {
        name: "",
        product_type: "",
        amount: 0,
      },
    ],
  });
  const [productImages, setProductImage] = useState<IProductImage[]>([
    { name: "", image: "" },
  ]);
  const [productImageVisible, setProductImageVisible] = useState<IProductImage>(
    { name: "", image: "" }
  );
  const [currentProductImage, setCurrentProductImage] = useState<number>(1);
  const [productList, setProductList] = useState<IProductList[]>([]);
  const [modalVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const history = useHistory();

  const handleOpenFile = (file: string) => {
    window.open(file);
  };

  const columns: any = [
    {
      title: "ลำดับ",
      align: "center",
      width: "10%",
      render: (_value: any, _item: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: "ชื่อไฟล์",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "ไฟล์",
      dataIndex: "detail",
      key: "detail",
      render: (file: string) => {
        return (
          <>
            <img
              src={imgFile}
              alt="file"
              style={{ width: "18px" }}
              className="cursor"
              onClick={() => handleOpenFile(file)}
            />
          </>
        );
      },
    },
  ];
  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    handleGetApproveById();
  };

  const handleGetApproveById = async () => {
    try {
      const res: IResStatus = await getApproveById(pathID);
      if (res.code === 200) {
        const data = res.result[0];
        setApprove(data);
        setProductList(data.list_product);
        setProductImage(data.image_product);
        setCurrentProductImage(data.list_product.length > 0 ? 1 : 0);
        setProductImageVisible(
          data.image_product.length > 0 ? data.image_product[0] : {}
        );
        form.setFieldsValue({ approve_to: data.approve_status });
      }
    } catch (error) { }
  };

  const handleProductImageVisible = (page: number) => {
    const findedProductSelect =
      productImages.length > 0
        ? productImages[page - 1]
        : { name: "", image: "" };
    setProductImageVisible(findedProductSelect);
    setCurrentProductImage(page);
  };

  const onClickModal = () => {
    setModalVisible(!modalVisible);
  };

  const onSubmitApprove = async (value: any) => {
    const res: IResStatus = await putApprove(value, pathID);
    if (res.code === 200) {
      openNotification({
        method: "PUT_SUCCESS",
        message: res.message,
      });
      onClickModal();
    } else {
      openNotification({
        method: "PUT_ERROR",
        message: res.message,
      });
    }
  };
  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="blackHome">
            <Button
              onClick={() => history.goBack()}
              type="primary"
              shape="circle"
            >
              <img className="buttonBlackHome" src={BlackHome} alt="" />
            </Button>
          </span>
          <span className="text-head ">จัดการอนุมัติ</span>
        </Col>
        <Col lg={2}>
          <Button className="btn-create" onClick={onClickModal}>
            จัดการ
          </Button>
        </Col>
      </Row>
      <div className="set-layout">
        <div className="pt-1">
          <Modal
            title={<b>อนุมัติ</b>}
            visible={modalVisible}
            onOk={form.submit}
            onCancel={onClickModal}
            centered
          >
            <Form form={form} onFinish={onSubmitApprove} layout="vertical">
              <Form.Item name="approve_to" label="เลือกสถานะ">
                <Selector
                  Source={[
                    { name: "Job Center", status: "Job Center" },
                    { name: "Cem", status: "Cem" },
                  ]}
                  keyName={"name"}
                  keyValue={"status"}
                />
              </Form.Item>
              <Form.Item name="information" label="โน๊ต" style={{ marginTop: 10 }}>
                <Input placeholder="โน๊ต" />
              </Form.Item>
            </Form>
          </Modal>
          <Card extra={"ลูกค้าขอคำร้อง"} className="card-form">
            <Row gutter={[14, 14]}>
              <Col lg={8}>
                <div className="h-100">
                  <div style={{ height: "30%", paddingBottom: "1rem" }}>
                    <UserInfo approve={approve} />
                  </div>
                  <div style={{ height: "70%" }}>
                    <ProjectDetail approve={approve} />
                  </div>
                </div>
              </Col>
              {!approve.have_file ? (
                <>
                  <Col lg={8}>
                    <div className="h-100">
                      <ProductImage
                        handleProductImageVisible={handleProductImageVisible}
                        productImageVisible={productImageVisible}
                        productImageLength={productImages.length}
                        currentProductImage={currentProductImage}
                      />
                    </div>
                  </Col>

                  <Col lg={8}>
                    <div className="h-100">
                      <div style={{ height: "25%", paddingBottom: "1rem" }}>
                        <InstallPrice approve={approve} />
                      </div>
                      <div style={{ height: "75%" }}>
                        <Products productList={productList} />
                      </div>
                    </div>
                  </Col>
                </>
              ) : (
                <Col lg={16}>
                  <div className="border-box h-100">
                    <Table
                      dataSource={approve.file_list}
                      columns={columns}
                      pagination={false}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Fields;
