import React, { useState } from "react";
import { Button, Row, Col, Input } from "antd";
import Logo from "../../assets/img/New-Logo-Alumet-Hub-01.svg";
import { useHistory } from "react-router-dom";
import { openNotification } from "../../component/notification/Notification";
import { userLogin } from "../../services/api/auth";
import { IResStatus } from "../../tools/fecth/interface";
import { useRecoilState } from "recoil";
import { loadingButtonState } from "../../tools/recoil/recoil-atom";
import { KeyOutlined, UserOutlined } from "@ant-design/icons";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);
  const handleLogin = async () => {
    setloadingButton(true);
    try {
      const data = {
        username: username,
        password: password,
      };
      const res: IResStatus = await userLogin(data);

      if (res.code === 200) {
        localStorage.setItem(
          "cmsWebPlateformUserInfo",
          JSON.stringify(res.result[0].userInfo)
        );
        localStorage.setItem(
          "cmsWebPlateformAccessToken",
          res.result[0].accessToken
        );
        history.push("/");
        openNotification({
          type: "success",
          message: "ยินดีต้อนรับ",
          description: (
            <>
              คุณ
              <span style={{ paddingLeft: "5px" }}>
                <b className="text-massage-name">
                  {res.result[0].userInfo.firstName}
                </b>
              </span>
              <span style={{ paddingLeft: "5px" }}>
                <b className="text-massage-name">
                  {res.result[0].userInfo.lastName}
                </b>
              </span>
              <span style={{ paddingLeft: "5px" }}>เข้าสู่ระบบ</span>
            </>
          ),
          custom: true,
        });
      } else {
        openNotification({
          type: "error",
          message: "ผิดพลาด",
          description: res.message,
          custom: true,
        });
      }
      setloadingButton(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="login-background">
      <div className="login-layout">
        <Row justify="center">
          <Col xl={24} xs={20}>
            <div className="w-100 d-flex justify-center">
              <img src={Logo} alt="alumet-logo" width={270} />
            </div>
            <h1 className="text-center mt-1 mb-0">ยินดีต้อนรับ</h1>
            <h4 className="text-center">
              เข้าสู่ระบบบัญชีของคุณเพื่อดำเนินการต่อ
            </h4>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              className="mt-2 login-input"
              placeholder="ชื่อผู้ใช้"
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? handleLogin() : "")}
            />
            <Input.Password
              prefix={<KeyOutlined />}
              type="password"
              placeholder="รหัสผ่าน"
              className="mt-1 login-input"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? handleLogin() : "")}
            />
            <div className="w-100 d-flex justify-center mt-3">
              <Button
                className="login-button"
                onClick={() => handleLogin()}
                loading={loadingButton}
              >
                เข้าสู่ระบบ
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginPage;
