import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "antd";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import MediaModal from "../../../../component/mediamodal/MediaModal";
import { _isEmpty } from "../../../../tools/util";
import { useRouteMatch } from "react-router-dom";

import { getUserListByID } from "../../../../services/api/user";

interface Props {
  mediaSelect: IMediaSelect;
  setMediaSelect: any;
}

const UploadPhoto = ({ mediaSelect, setMediaSelect }: Props) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const [uploadPage] = useState<boolean>(true);
  const { params }: any = useRouteMatch();
  const ID = params;

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    if (!_isEmpty(ID)) {
      getData();
    }
  };

  const getData = () => {
    getUser();
  };

  const getUser = async () => {
    try {
      const res = await getUserListByID(params.id);
      if (res.code === 200) {
        const data: any = res.result[0];
        setMediaSelect({
          file: data.image?.file_path,
          id: data.image.id,
        });
        form.setFieldsValue({
          media_id: mediaSelect?.id,
          ...data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onModal = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <MediaModal
        visible={visible}
        onModal={onModal}
        setMediaSelect={setMediaSelect}
      />

      <Row className="mt-3">
        <Col lg={24}>
          <Form.Item
            name="media_id"
            rules={[
              {
                required: _isEmpty(mediaSelect.file) ? true : false,
                message: "โปรดเลือกรูปภาพ!",
              },
            ]}
          >
            <MediaSpace
              onModal={onModal}
              mediaSelect={mediaSelect}
              uploadPage={uploadPage}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={5} className="mt-05">
        <Col lg={16}>
          <Button className="bt-uploadimg" onClick={onModal}>
            Upload Photo <CameraOutlined />
          </Button>
        </Col>
        <Col lg={8}>
          <Button
            className="bt-delPhoto"
            onClick={() => setMediaSelect({ file: "", id: "" })}
          >
            <DeleteOutlined />
          </Button>
        </Col>
      </Row>
      <Row>
        <p className="p-border">Up to 1 MB(300*300 px)</p>
      </Row> */}
    </div>
  );
};

export default UploadPhoto;
