import { Card, Col, Row } from "antd";
import Logo from "../../assets/img/New-Logo-Alumet-Hub-01.svg";

const Home = () => {
  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">หน้าหลัก</span>
        </Col>
        <Col lg={2}></Col>
      </Row>
      <div className="set-layout ">
        <Card className="card-round h-80vh d-flex justify-center items-center">
          <h1 className="text-center mt-2 mb-0">ยินดีต้อนรับ</h1>
          <div className="w-100 d-flex justify-center">
            <img src={Logo} alt="alumat" width={240} />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Home;
