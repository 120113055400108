import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { DeleteOutlined, EyeOutlined, MediumOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import {
  editState,
  dragState,
} from "../../../../../../tools/recoil/recoil-atom";
import { _isEmpty } from "../../../../../../tools/util";
function ListComponent(props) {
  // console.log("item list", props);
  const history = useHistory();

  const dnd = props.dnd;
  const [toggleEdit] = useRecoilState(editState);
  const [toggleDrag] = useRecoilState(dragState);

  let classes = "el no-select";
  if (props.itemInDrag) classes += " active";
  const onClick = (titleTH, titleEN) => {
    // console.log("Proper>>>", props.item);

    props.onModal({
      type: "list",
      functional: "edit",
      titleTH: titleTH,
      titleEN: titleEN,
      idCate: props.idCate,
      idGroup: props.idGroup,
      idList: props.item.id,
      name_th: props.item.name_th,
      name_en: props.item.name_en,
    });
  };
  const handleSetDetailDelList = () => {
    props.onDelete({
      type: "list",
      functional: "delete",
      idCate: props.idCate,
      idGroup: props.idGroup,
      idList: props.item.id,
    });
  };

  const handleCreateForm = (id) => {
    history.push("/product/list/" + id);
  };
  return (
    <div className="w-100  d-flex collapse-card-group ">
      {toggleDrag && (
        <div className="drag-card-group" {...dnd.handler.listeners}>
          ::
        </div>
      )}
      <div className="w-100">
        <li
          style={{ ...dnd.item.styles, ...dnd.handler.styles }}
          className={dnd.item.classes}
          ref={dnd.item.ref}
        >
          <div className={classes} style={{ height: props.item.height }}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onClick(props.item.name_th, props.item.name_en);
              }}
              disabled={!toggleEdit}
            >
              {props.item.name_th}
            </Button>
            {!toggleEdit && !toggleDrag ? (
              <>
                <Button
                  className="ml-1"
                  onClick={() => handleCreateForm(props.item.id)}
                >
                  {!_isEmpty(props.item.edit_content_at) ? (
                    <>
                      <EyeOutlined style={{ marginRight: "0.5rem" }} />
                      ดูข้อมูล
                    </>
                  ) : (
                    <>
                      <MediumOutlined style={{ marginRight: "0.5rem" }} />
                      ป้อนข้อมูล
                    </>
                  )}
                </Button>
              </>
            ) : (
              ""
            )}
            {toggleEdit && (
              <Button className="ml-1" onClick={() => handleSetDetailDelList()}>
                <DeleteOutlined />
              </Button>
            )}
          </div>
        </li>
      </div>
    </div>
  );
}

export default ListComponent;
