import React, { useState } from "react";
import { Row, Col, Button, Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import HeaderUser from "./usertab/_HeaderUser";
import HeaderRole from "./roletab/_HeaderRole";
import RoleTab from "./roletab/RoleTab";
import UserTab from "./usertab/UserTab";
import { SearchValue } from "../../interface/IUsers.interface";
import { tabSelectedUserState } from "../../tools/recoil/recoil-atom";

import { useRecoilState } from "recoil";
import { displayHide } from "../../tools/util";
import { IGetRoleList } from "../../interface/IRole.interface";

const DefaultUsers: React.FC = () => {
  const [tabSelected, setTabSelected] = useRecoilState(tabSelectedUserState);
  const [searchRole, setSearchRole] = useState<SearchValue>({});
  const [searchUser, setSearchUser] = useState<SearchValue>({});
  const [roleList, setRoleList] = useState<IGetRoleList[]>([]);

  const { TabPane } = Tabs;

  const history = useHistory();

  const useFilter = (value: any) => {
    setSearchUser(value);
  };

  const roleFilter = (value: any) => {
    setSearchRole(value);
  };

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">ผู้ใช้งาน</span>
        </Col>
        <Col lg={2}>
          {tabSelected === "1" && (
            <Button
              className="btn-create"
              type="primary"
              onClick={() => history.push("/user/user/create")}
            >
              <PlusOutlined className="icons-size" /> สร้าง
            </Button>
          )}

          {tabSelected === "2" && (
            <Button
              className="btn-create"
              type="primary"
              onClick={() => history.push("/user/role/create")}
            >
              <PlusOutlined className="icons-size" /> สร้าง
            </Button>
          )}
        </Col>
      </Row>
      <div className="set-layout">
        <div className={displayHide(tabSelected, "1")}>
          <HeaderUser roleList={roleList} onFinish={useFilter} />
        </div>

        <div className={displayHide(tabSelected, "2")}>
          <HeaderRole onFinish={roleFilter} />
        </div>

        <div className="pt-1">
          <Tabs
            className="tab-mainmenu"
            defaultActiveKey={tabSelected}
            onChange={(key) => setTabSelected(key)}
          >
            <TabPane tab="ผู้ใช้" key="1" className="border-tab-mainmenu">
              <UserTab setRoleList={setRoleList} search={searchUser} />
            </TabPane>
            <TabPane tab="บทบาท" key="2" className="border-tab-mainmenu">
              <RoleTab search={searchRole} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default DefaultUsers;
