
// import { MenuFoldOutlined } from "@ant-design/icons";
import Product from "./assets/img/icons/product.svg"
import Project from "./assets/img/icons/project.svg"
import Approve from "./assets/img/icons/approve.svg"
import Article from "./assets/img/icons/article.svg"
import ManageBanner from "./assets/img/icons/mangebanner.svg"
import Inspiration from "./assets/img/icons/inspiration.svg"
import Media from "./assets/img/icons/media.svg"
import Overall from "./assets/img/icons/overall.svg"
import Setting from "./assets/img/icons/setting.svg"
import User from "./assets/img/icons/user.svg"
import Serve from "./assets/img/icons/service.svg"


const navMenu = [
    { name: "ภาพรวม", route: "/overall", icons: Overall, authentMenuName: "Dashboard" },
    { name: "จัดการอนุมัติ", route: "/approve-management", icons: Approve, authentMenuName: "Manage Approvals" },
    { name: "จัดการแบนเนอร์", route: "/banner-management", icons: ManageBanner, authentMenuName: "Manage Banners" },
    { name: "มีเดีย", route: "/media", icons: Media, authentMenuName: "Medias" },
    { name: "ไอเดีย", route: "/inspiration", icons: Inspiration, authentMenuName: "Idea" },
    { name: "จัดการสินค้า", route: "/product", icons: Product, authentMenuName: "Manage Products" },
    { name: "โครงการ", route: "/project", icons: Project, authentMenuName: "Project" },
    { name: "บทความ", route: "/article", icons: Article, authentMenuName: "Project" },
    { name: "ผู้ใช้งาน", route: "/user", icons: User, authentMenuName: "User" },
    { name: "บริการ", route: "/serve", icons: Serve, authentMenuName: "Setting" },
    { name: "ตั้งค่า", route: "/setting", icons: Setting, authentMenuName: "Setting" }
]
export default navMenu
