/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Card, Carousel, Col, Form, Modal, Row } from "antd";
import Selector from "../../../component/selector/Selector";
import MediaSpaceMarker from "../../../component/mediaspacemarker/MediaSpaceMarker";
import { _isEmpty } from "../../../tools/util";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IBannerListPost } from "../../../interface/IBanner.interface";
import { IResStatus } from "../../../tools/fecth/interface";
import { openNotification } from "../../../component/notification/Notification";
import {
  getBannerListByID,
  postBannerList,
  putBannerList,
} from "../../../services/api/banner";
import { getInspirationList } from "../../../services/api/inspiration";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  loadingButtonState,
  loadingState,
} from "../../../tools/recoil/recoil-atom";
import { EyeOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import PreviewMarkerImage from "../../../component/map/PreviewMarkerImage";

const Fields = () => {
  const [menuSelected, setMenuSelected] = useState<any>("");
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [form] = Form.useForm();
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);
  const setLoading = useSetRecoilState(loadingState);
  const [addBlockMaker, setAddBlockMarker] = useState([0] as any);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [mediaSelectList, setMediaSelectList] = useState([] as any);
  const settings = {
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    handleGetData();
    handleGetProductList();
  }, []);

  const handleGetData = () => {
    if (!_isEmpty(pathID)) {
      handleGetBannerListById();
    }
  };

  function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#ff9021" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#ff9021" }}
        onClick={onClick}
      />
    );
  }

  const handleGetProductList = async () => {
    try {
      const dataSearch = {
        page: 1,
        limit: 10,
        orderby: "DESC",
        search: "",
        product_category_id: "",
      };
      const res = await getInspirationList(dataSearch);
      if (res.code === 200) {
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetBannerListById = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getBannerListByID(pathID);
      let blockCount = [] as any;
      if (res.code === 200) {
        // const data: IBannerList = res.result[0];
        const data = res.result[0];
        const resultRefacter = data.gallery_banner.map(
          (item: any, idx: number) => {
            blockCount.push(idx);
            const arr = {
              [`media${idx}`]: {
                marker: item.mark.map((child: any, index: number) => {
                  const result = {
                    color: child.data_mark.color,
                    inspiration: child.data_mark.inspiration.id,
                    x: child.data_mark.x,
                    y: child.data_mark.y,
                  };
                  return result;
                }),
                media: {
                  file: item.media.file_path,
                  id: item.media.id,
                  media_name: item.media.media_name,
                },
              },
              menu_web: data.menu_web.id,
            };
            return arr;
          }
        );
        RefacterDataPreview(data.gallery_banner);
        const convertResult = Object.assign({}, ...resultRefacter);
        form.setFieldsValue(convertResult);
        setAddBlockMarker(blockCount);
        setMenuSelected(data.menu_web.id ? true : false);
      }
    } catch (error) {}
    setLoading(false);
  };

  const RefacterDataPreview = (data: any) => {
    const resultData = data.map((item: any, idx: number) => {
      const arr = {
        mark: item.mark.map((child: any, index: number) => {
          const result = {
            color: child.data_mark.color,
            inspiration: child.data_mark.inspiration.id,
            x: child.data_mark.x,
            y: child.data_mark.y,
          };
          return result;
        }),
        media: {
          file: item.media.file_path,
          id: item.media.id,
          media_name: item.media.media_name,
        },
      };
      return arr;
    });
    setMediaSelectList(resultData);
  };

  const onFinish = (values: any) => {
    let gallery_banner = [] as any;
    for (const [key, value] of Object.entries(values)) {
      if (key !== `menu_web` && !_isEmpty(value)) {
        gallery_banner.push({
          mark: values[key].marker,
          media: values[key].media.id,
        });
      }
    }

    let reCheckNull = {};
    gallery_banner.map((item: any) => {
      reCheckNull = item.mark.find((child: any) => child.inspiration === null);
      return reCheckNull;
    });

    if (!_isEmpty(reCheckNull)) {
      openNotification({
        custom: true,
        type: "warning",
        message: "ข้อมูลของคุณยังไม่ครบถ้วนสมบูรณ์!",
        description:
          "การเพิ่มจุดบนรูปภาพของคุณบางรายการ ยังไม่เลือกข้อมูลเเรงบันดาลใจ",
      });
    } else {
      const data = {
        menu_web: values.menu_web,
        gallery_banner: gallery_banner,
      };
      if (!_isEmpty(pathID)) {
        handleUpdateBanner(data);
      } else {
        handleCreateBanner(data);
      }
    }
  };

  const handleUpdateBanner = async (values: IBannerListPost) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await putBannerList(values, pathID);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/banner-management");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const handleCreateBanner = async (values: IBannerListPost) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await postBannerList(values);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        history.push("/banner-management");
      } else {
        if (res.message === "Duplicate Menu") {
          openNotification({
            type: "error",
            message: "เมนูที่ถูกเลือกมีรายการแล้ว",
            custom: true,
          });
        } else {
          openNotification({
            method: "POST_ERROR",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const updatePreviewSlide = (data: any) => {
    let gallery_banner = [] as any;
    for (const [key, value] of Object.entries(data)) {
      if (key !== `menu_web` && !_isEmpty(value)) {
        gallery_banner.push({
          mark: data[key].marker,
          media: data[key].media,
        });
      }
    }
    setMediaSelectList(gallery_banner);
    setPreviewVisible(true);
  };

  const bannerMenuList = [
    { name: "หน้าหลัก", id: 1 },
    { name: "ไอเดีย", id: 2 },
    { name: "สินค้า", id: 3 },
    { name: "โครงการ", id: 4 },
    { name: "บทความ", id: 5 },
    { name: "บริการ", id: 6 },
  ];

  return (
    <div>
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Card
          extra={!_isEmpty(pathID) ? "แก้ไขแบนเนอร์" : "สร้างแบนเนอร์"}
          actions={[
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    updatePreviewSlide(form.getFieldsValue());
                  }}
                >
                  <EyeOutlined />
                  ตัวอย่าง
                </Button>
              </Col>
              <Col>
                <Button
                  type="default"
                  onClick={() => history.goBack()}
                  className="ml-1"
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button
                  className="btn-save"
                  htmlType="submit"
                  loading={loadingButton}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row gutter={[14, 14]}>
            <Col lg={12}>
              <Form.Item
                label="เลือกเมนู"
                name="menu_web"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Selector
                  Source={bannerMenuList}
                  keyName="name"
                  keyValue="id"
                  onChange={(e) => setMenuSelected(e)}
                />
              </Form.Item>
            </Col>
            {menuSelected && (
              <Col lg={24}>
                {addBlockMaker.map((item: any, idx: number) => (
                  <Form.Item
                    key={`media${idx}`}
                    label={
                      addBlockMaker.length === 1
                        ? "รูปภาพ"
                        : `รูปภาพที่ ${idx + 1}`
                    }
                    name={`media${idx}`}
                    style={{ paddingBottom: 10 }}
                  >
                    <MediaSpaceMarker
                      typelist={"inspiration"}
                      textMessage={"เเรงบันดาลใจ"}
                      onMarker={false}
                    />
                  </Form.Item>
                ))}
                <Row justify="start" align="middle" style={{ marginTop: 12 }}>
                  <div
                    className="plus-btn"
                    onClick={() => {
                      setAddBlockMarker([
                        ...addBlockMaker,
                        addBlockMaker.length,
                      ]);
                    }}
                  >
                    <PlusOutlined />
                  </div>
                  {addBlockMaker.length > 1 && (
                    <div
                      className="plus-btn ml-1"
                      onClick={() => {
                        const minus = addBlockMaker.slice(0, -1);
                        setAddBlockMarker(minus);
                      }}
                    >
                      <MinusOutlined />
                    </div>
                  )}
                </Row>
              </Col>
            )}
          </Row>
        </Card>
      </Form>
      <Modal
        className="modal-preview"
        title={<div>ตัวอย่าง แบนเนอร์</div>}
        centered
        visible={previewVisible}
        onCancel={() => {
          setPreviewVisible(false);
        }}
        width={1350}
        footer={null}
      >
        <div>
          <div className="mt-0.5">
            <div className="preview-content ">
              <Carousel {...settings} className="slide-marker-image" autoplay>
                {mediaSelectList.map((item: any, idx: number) => {
                  return (
                    <div
                      key={idx}
                      className="text-aling-center mt-1 mb-1 img-preview"
                      style={{ padding: "30px 30px 0px 30px", width: 1920 }}
                    >
                      <PreviewMarkerImage mapImg={item.media.file} />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Fields;
