import React, { useState } from "react";
import { Badge, Col, Dropdown, Menu, Modal, Row } from "antd";
import DropDown from "../assets/img/icons/dropdown.svg";
import HomeIcon from "../assets/img/icons/home-change.svg";
import Alert from "../assets/img/icons/Alert.svg";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

interface IUserInfo {
  firstName: string;
  lastName: string;
  role: string;
  image: string;
}

const TheHeader = ({ collapsed }: { collapsed: boolean }) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const dataLocalStorage: any = localStorage.getItem("cmsWebPlateformUserInfo");
  const userInfo: IUserInfo = JSON.parse(
    dataLocalStorage
      ? dataLocalStorage
      : `$"{ firstName: "", lastName: "",role:"" }`
  );

  const onClickModal = (data: any) => {
    setVisible(!visible);
  };

  const clearLocalStorage = () => {
    setVisible(!visible);
    localStorage.clear();
    history.push("/webplatform/login");
  };
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push(`/settingprofile`);
        }}
      >
        <h5 className="list-menu mb-0">ตั้งค่าโปรไฟล์</h5>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <h5 className="list-menu mb-0">ออกจากระบบ</h5>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={collapsed ? "header flex-layout" : "header"}>
        <Row className="w-100 row-h">
          <Col span={8} className="d-flex items-center"></Col>
          <Col span={8} className="brand-name ">
            ALUMET PLATFORM
          </Col>
          <Col className="d-flex justify-flex-end items-center" span={8}>
            <div
              style={{ height: "40px", paddingRight: "0.3rem" }}
              className="d-flex items-center"
            >
              <Badge count={4}>
                <img className="circle avatar" src={Alert} alt="aleart" />
              </Badge>
            </div>
            <div className="hr-verticle"></div>
            <div
              className="pr-15 d-flex items-center"
              style={{ height: "40px", paddingLeft: "0.3rem" }}
            >
              <img className="circle avatar" src={HomeIcon} alt="home" />
            </div>
            <div className="pr-1">
              <Dropdown
                overlay={menu}
                placement="bottomCenter"
                className="cursor"
              >
                <div className="d-flex justify-center menu-hover ">
                  <div className="pr-1">
                    <img
                      className="circle avatar"
                      src={userInfo.image || ""}
                      alt="profile"
                    />
                  </div>
                  <div className="pr-1">
                    <h4 className="c-white mb-0 menu-list cut-text-1-row ">
                      {`${userInfo.firstName} ${userInfo.lastName}`}
                    </h4>
                    <h5 className="c-whitesmoke mb-0 menu-list">
                      {userInfo.role}
                    </h5>
                  </div>
                  <div className="d-flex items-center">
                    <img
                      src={DropDown}
                      alt="profile"
                      style={{ width: "32px" }}
                      className="c-white"
                    />
                  </div>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        title={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            คุณแน่ใจไหม?
          </div>
        }
        visible={visible}
        onOk={clearLocalStorage}
        centered
        onCancel={onClickModal}
        okText="ยืนยัน"
        cancelText="ยกเลิก"
      >
        คุณแน่ใจที่จะออกจากระบบ
      </Modal>
    </>
  );
};

export default TheHeader;
