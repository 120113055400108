import React from "react";
import { Layout, Spin } from "antd";
import { Switch, Route } from "react-router-dom";
import { routers } from "../routes/router";
import { loadingState } from "../tools/recoil/recoil-atom";
import { useRecoilState } from "recoil";

const TheContent = () => {
  const { Content } = Layout;
  const [loading] = useRecoilState(loadingState);

  const filterRoutePermission = (_route: any) => {
    const userInfo = localStorage.getItem("cmsWebPlateformUserInfo");
    const { permission } = JSON.parse(userInfo ? userInfo : ``);
    let result: any = [];
    let homeRoute = {};
    permission.forEach((item: any) => {
      _route.forEach((val: any) => {
        if (val.authentMenuName === item.name_en) {
          result.push(val);
        }
        if (val.authentMenuName === "Home") homeRoute = val;
      });
    });
    result = [...result, homeRoute];
    return result;
  };

  return (
    <>
      <Spin spinning={loading} size="large" tip="กำลังโหลด..." delay={500}>
        <Content style={{ margin: "0 16px" }}>
          <div>
            <Switch>
              {filterRoutePermission(routers).map((route: any, ind: number) => (
                <Route
                  exact={route.exact}
                  key={ind}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
          </div>
        </Content>
      </Spin>
    </>
  );
};

export default TheContent;
