import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'

import { EditData } from '../../interface/IUsers.interface'

export const GET_SETTINGPROFILE_API = async() => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/admin/profile`,
    })
    return res
}

export const PUT_SETTINGPROFILE_API = async(data?:EditData) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/admin/profile`,
    })
    return res
}