import React, { useEffect, useState } from "react";
import FileList from "../file/FileList";
import {
  IMediaFilterSearch,
  IMedia,
} from "../../../interface/IMedia.interface";
import { IResStatus } from "../../../tools/fecth/interface";
import {
  delMediaCategory,
  getMedia,
  putMedia,
} from "../../../services/api/media";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import Modaler from "../../../component/modaler/Modaler";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { openNotification } from "../../../component/notification/Notification";
import { Form } from "antd";
import FormModal from "../FormModal";

type Props = {
  fiterSearchFile: IMediaFilterSearch;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const Container = ({ fiterSearchFile, currentPage, setCurrentPage }: Props) => {
  const [mediaFileList, setFileList] = useState<IMedia[]>([]);
  const [initialValue, setIntialValue] = useState({ media_name: "" });
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const [mediaId, setMediaId] = useState(1);
  const [mediaAction, setMediaAction] = useState({ type: "", actionName: "" });

  const [form] = Form.useForm();

  const setLoading = useSetRecoilState(loadingState);

  React.useEffect(() => {
    form.setFieldsValue({
      media_name: initialValue.media_name,
    });
    // eslint-disable-next-line
  }, [initialValue]);

  useEffect(() => {
    handleGetMediaFile();
    // eslint-disable-next-line
  }, [currentPage, limitPage, fiterSearchFile]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onDelete = () => {
    handleDeleteMedia(mediaId);
  };
  const handleGetMediaFile = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await getMedia({
        ...fiterSearchFile,
        directory: "file",
        page: currentPage,
        limit: limitPage,
      });
      if (res.code === 200) {
        setFileList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleteMedia = async (mediaId: number) => {
    try {
      setLoading(false);

      const res: IResStatus = await delMediaCategory(mediaId);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetMediaFile();
        onCancel();
      } else {
        openNotification({
          method: "DEL_ERROR",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUpdateTitle = async (values: any) => {
    try {
      const res: IResStatus = await putMedia(values, mediaId);
      if (res.code === 200) {
        handleGetMediaFile();
        openNotification({ method: "PUT_SUCCESS", message: res.message });
      } else {
        openNotification({
          method: "PUT_ERROR",
        });
      }
    } catch (error) {
      console.log(error);
    }
    onCancel();
  };

  const onCancel = () => {
    setVisibleEdit(false);
    setVisibleDelete(false);
  };

  const onOK = () => {
    form.submit();
  };

  const onModal = (modal: { type: string; actionName: string }) => {
    setMediaAction({ ...modal });
    if (modal.type === "delete") {
      setVisibleDelete(!visibleDelete);
    } else {
      setVisibleEdit(!visibleEdit);
    }
  };
  return (
    <div>
      <Modaler
        visible={visibleEdit}
        onCancel={onCancel}
        onOk={onOK}
        header="แก้ไขชื่อไฟล์"
        width="30%"
      >
        <FormModal
          name="ไฟล์"
          form={form}
          onFinish={handleUpdateTitle}
          initialValue={initialValue}
        />
      </Modaler>

      <Modaler
        visible={visibleDelete}
        onCancel={onCancel}
        onOk={onDelete}
        width="25%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            คุณแน่ใจไหม?
          </div>
        }
      >
        คุณต้องการลบ {mediaAction.actionName} หรือไม่
        กระบวนการนี้ไม่สามารถยกเลิกได้
      </Modaler>

      <FileList
        onModal={onModal}
        setIntialValue={setIntialValue}
        mediaFileList={mediaFileList}
        onChangePage={onChangePage}
        total={total}
        limitPage={limitPage}
        currentPage={currentPage}
        setMediaId={setMediaId}
      />
    </div>
  );
};

export default Container;
