import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { delMediaCategory, putMedia } from "../../services/api/media";
import { IResStatus } from "../../tools/fecth/interface";
import MoTable from "../table/Table";
import PDFIcon from "../../assets/img/icons/pdf.png";
import ExelIcon from "../../assets/img/icons/exel.png";
import { Input, Popconfirm } from "antd";
import { openNotification } from "../notification/Notification";
import Header from "./Header";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../tools/recoil/recoil-atom";

const Table = ({
  displayValue,
  onChange,
  onModal,
  onSelect,
  handleGetMediaFile,
  onChangePage,
  setFilterSearch,
  mediaFileList,
  pagiConfig,
}: any) => {
  const [title, setTitle] = useState<string>("");
  const [mediaID, setMediaID] = useState<number>(0);

  const setLoading = useSetRecoilState<boolean>(loadingState);

  const handleUpdateMediaObject = async () => {
    try {
      const res: IResStatus = await putMedia({ media_name: title }, mediaID);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetMediaFile();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMediaObject = async () => {
    try {
      const res: IResStatus = await delMediaCategory(mediaID);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetMediaFile();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRow = (value: any) => {
    onSelect && onSelect(value.id);
    displayValue && displayValue(value);
    onChange(value);
    onModal();
  };

  const onFinish = (values: any) => {
    setFilterSearch({ ...values });
  };

  const columns: any = [
    {
      title: " ",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (type: string) => {
        return (
          <>
            {type === ".pdf" ? (
              <img
                src={PDFIcon}
                alt="pdf"
                style={{ height: "35px", width: "35px" }}
              />
            ) : type === ".xlsx" || type === ".xls" ? (
              <img
                src={ExelIcon}
                alt="exel"
                style={{ height: "35px", width: "35px" }}
              />
            ) : (
              "ไม่พบชนิดไฟล์นี้"
            )}
          </>
        );
      },
    },
    {
      title: "ชื่อไฟล์",
      dataIndex: "media_name",
      key: "media_name",
      align: "center",
      width: "65%",
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      width: "30%",

      render: (_: string, data: any) => {
        return (
          <>
            <DownloadOutlined
              onClick={() => {
                setLoading(true);
                window.open(data.file_path);
                setLoading(false);
              }}
            />
            <Popconfirm
              onConfirm={handleUpdateMediaObject}
              icon={<>ชื่อไฟล์</>}
              title={
                <>
                  <Input
                    value={title}
                    className="media-container"
                    onChange={(e: any) => {
                      setTitle(e.target.value);
                    }}
                  />
                </>
              }
              okText="บันทึก"
              cancelText="ยกเลิก"
            >
              <EditOutlined
                className="pl-1"
                onClick={() => {
                  setMediaID(data.id);
                  setTitle(data.media_name);
                }}
              />
            </Popconfirm>
            <Popconfirm
              onConfirm={handleDeleteMediaObject}
              title={`คุณต้องการลบ ${data.media_name} หรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้`}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              okText="ยืนยัน"
              cancelText="ยกเลิก"
            >
              <DeleteOutlined
                className="pl-1"
                onClick={() => {
                  setMediaID(data.id);
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Header onFinish={onFinish} />
      <div className="mt-1">
        <MoTable
          rowKey={"id"}
          dataSource={mediaFileList}
          columns={columns}
          onChangePage={onChangePage}
          config={{
            ...pagiConfig,
          }}
          onClickRow={onRow}
        />
      </div>
    </div>
  );
};

export default Table;
