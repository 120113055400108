import React, { useState } from "react";
import { Col, Row, Form, Input, Card, Button, Popover } from "antd";
import FileSpace from "../../../../../component/filespace/FileSpace";
import FileSpaceImage from "../../../../../component/filespaceImage/FileSpaceImage";
import MediaModalFile from "../../../../../component/mediamodalfile/MediaModalFile";
import Selector from "../../../../../component/selector/Selector";
import { _isEmpty } from "../../../../../tools/util";
import DuplicateSKU from "./DuplicateSKU";
import DeleteDubplicate from "./DeleteDuplicateSKU";
import NewMediaModalFile from "../../../../../component/newmediamodalfile/NewMediaModalFile";

const SKUFields = ({
  onFinish,
  formSkuDetail,
  logDeviceList,
  mirrorList,
  handleDuplicateSKU,
  handleDeleteDuplicateSKU,
}: any) => {
  const [skuList, setSkuList] = useState([]);
  const handleLoadSKU = () => {
    const skuData = formSkuDetail.getFieldsValue();
    setSkuList(skuData.sku.filter((val: any) => val.sub_sku));
  };

  return (
    <div>
      <Form onFinish={onFinish} layout="vertical" form={formSkuDetail}>
        <Form.List name="sku">
          {(fields) => (
            <>
              {fields.map(
                (
                  {
                    key,
                    name,

                    //@ts-ignore
                    fieldKey,
                    ...restField
                  },
                  index
                ) => (
                  <Card className="mt-2 mb-1" key={key}>
                    <Row align="middle" gutter={[14, 14]} className="h-100">
                      <Col span={2}>
                        <div className="circle-shape d-flex items-center h-100 mt-1">
                          {key + 1}
                        </div>
                      </Col>
                      <Col span={22}>
                        <Form.Item
                          name={[name, "name_option"]}
                          label=" "
                          {...restField}
                        >
                          <InputHover />
                        </Form.Item>
                      </Col>
                      {/* <Col lg={16} className="d-flex justify-flex-end"></Col> */}
                    </Row>
                    <Row gutter={[14, 14]}>
                      <Col lg={8} sm={24}>
                        <Row gutter={[14, 14]} className="mt-1">
                          <Col lg={16} sm={12}>
                            <Form.Item
                              name={[name, "sku"]}
                              label="SKU"
                              rules={[
                                {
                                  required: index === 0 ? true : false,
                                  message: "โปรดกรอกข้อมูล!",
                                },
                              ]}
                              {...restField}
                            >
                              <InputHover disabled />
                            </Form.Item>
                          </Col>
                          <Col lg={8} sm={12}>
                            <Form.Item
                              name={[name, "sub_sku"]}
                              label=" "
                              rules={[
                                { required: true, message: "โปรดกรอกข้อมูล!" },
                              ]}
                              {...restField}
                            >
                              <InputHover />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={16} sm={24}>
                        <Row gutter={[14, 14]} className="mt-1">
                          <Col lg={12} sm={24}>
                            <Form.Item
                              name={[name, "shop_drawing"]}
                              label="รูปภาพช็อบดรออิ้ง"
                              rules={[
                                {
                                  required: index === 0 ? true : false,
                                  message: "โปรดกรอกข้อมูล!",
                                },
                              ]}
                              {...restField}
                            >
                              <FileSpaceImage />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24}>
                            <Form.Item
                              name={[name, "shop_drawing_dwg"]}
                              label="ช็อบดรออิ้ง"
                              rules={[
                                {
                                  required: index === 0 ? true : false,
                                  message: "โปรดกรอกข้อมูล!",
                                },
                              ]}
                              {...restField}
                            >
                              <NewMediaModalFile fileExtension="dwg" />
                            </Form.Item>
                          </Col>

                          <Col lg={12} sm={24}>
                            <Form.Item
                              name={[name, "architect_drawing"]}
                              label="รูปแบบขยาย"
                              rules={[
                                {
                                  required: index === 0 ? true : false,
                                  message: "โปรดกรอกข้อมูล!",
                                },
                              ]}
                              {...restField}
                            >
                              <FileSpace
                                // onDelete={() => {
                                //   const sku =
                                //     formSkuDetail.getFieldValue("sku");
                                //   sku[index].architect_drawing = undefined;
                                //   formSkuDetail.setFieldsValue({ sku });
                                // }}
                                fileType="image"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24}>
                            <Form.Item
                              name={[name, "product_formula"]}
                              label="สูตรสินค้า"
                              rules={[
                                {
                                  required: index === 0 ? true : false,
                                  message: "โปรดกรอกข้อมูล!",
                                },
                              ]}
                              {...restField}
                            >
                              <MediaModalFile
                                onDelete={() => {
                                  const sku =
                                    formSkuDetail.getFieldValue("sku");
                                  sku[index].product_formula = undefined;
                                  formSkuDetail.setFieldsValue({ sku });
                                }}
                                fileExtension="exel"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={12} sm={24}>
                            <Form.Item
                              name={[name, "lock_kit_file"]}
                              label="อุปกรณ์ล็อค/มือจับ"
                              rules={[
                                {
                                  required: index === 0 ? true : false,
                                  message: "โปรดกรอกข้อมูล!",
                                },
                              ]}
                              {...restField}
                            >
                              <MediaModalFile
                                onDelete={() => {
                                  const sku =
                                    formSkuDetail.getFieldValue("sku");
                                  sku[index].lock_kit_file = undefined;
                                  formSkuDetail.setFieldsValue({ sku });
                                }}
                                fileExtension="exel"
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col lg={24}>
                            <Form.Item
                              name={[name, "lock_kits"]}
                              label="อุปกรณ์ล็อค / มือจับ"
                              // rules={[
                              //   { required: true, message: "โปรดกรอกข้อมูล!" },
                              // ]}
                            >
                              <Selector
                                Source={logDeviceList}
                                keyName="name_th"
                                keyValue="id"
                                options={{ mode: "multiple" }}
                              ></Selector>
                            </Form.Item>
                          </Col> */}
                          <Col lg={24}>
                            <Form.Item
                              name={[name, "glass"]}
                              label="กระจก"
                              
                              rules={[
                                {
                                  required: index === 0 ? true : false,
                                  message: "โปรดกรอกข้อมูล!",
                                },
                              ]}
                            >
                              <Selector
                                Source={mirrorList}
                                keyName="name_th"
                                keyNameSecondary="color"
                                keyValue="id"
                                options={{ mode: "multiple" }}
                              ></Selector>
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <h4>
                              <b>ข้อมูลตัดประกอบ</b>
                            </h4>
                          </Col>
                          <Col lg={16} sm={24}>
                            <Row gutter={[14, 14]}>
                              <Col lg={12} sm={24}>
                                <Form.Item
                                  name={[name, "cross_section_img"]}
                                  label="ไฟล์ภาพ"
                                  rules={[
                                    {
                                      required: index === 0 ? true : false,
                                      message: "โปรดกรอกข้อมูล!",
                                    },
                                  ]}
                                  {...restField}
                                >
                                  <FileSpace
                                  // onDelete={() => {
                                  //   const sku =
                                  //     formSkuDetail.getFieldValue("sku");
                                  //   sku[index].cross_section_img = undefined;
                                  //   formSkuDetail.setFieldsValue({ sku });
                                  // }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} sm={24}>
                                <Form.Item
                                  name={[name, "cross_section_pdf"]}
                                  label="ไฟล์ PDF"
                                  rules={[
                                    {
                                      required: index === 0 ? true : false,
                                      message: "โปรดกรอกข้อมูล!",
                                    },
                                  ]}
                                  {...restField}
                                >
                                  <MediaModalFile
                                    // onDelete={() => {
                                    //   const sku =
                                    //     formSkuDetail.getFieldValue("sku");
                                    //   sku[index].cross_section_pdf = undefined;
                                    //   formSkuDetail.setFieldsValue({ sku });
                                    // }}
                                    fileExtension="pdf"
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={24}>
                            <Form.Item
                              name={[name, "description"]}
                              label="รายละเอียด"
                              {...restField}
                            >
                              <Input.TextArea
                                autoSize={{ minRows: 5, maxRows: 5 }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={22} className="d-flex justify-flex-end">
                            <Form.Item
                              name={[name, "duplicate"]}
                              label=" "
                              {...restField}
                            >
                              <DeleteDubplicate
                                onGetValue={handleDeleteDuplicateSKU}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={2} className="d-flex justify-flex-end">
                            <Form.Item
                              name={[name, "options"]}
                              label=" "
                              {...restField}
                            >
                              <DuplicateSKU onGetValue={handleDuplicateSKU} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                )
              )}
            </>
          )}
        </Form.List>
        <Button className="mb-1" onClick={handleLoadSKU}>
          เรียกรายการ SKU
        </Button>
        <Row align="middle" gutter={[14, 14]}>
          <Col lg={12}>
            <Form.Item
              name="sku_default"
              label="SKU เริ่มต้น"
              rules={[
                {
                  required: true,
                  message: "โปรดกรอกข้อมูล!",
                },
              ]}
            >
              <Selector
                Source={skuList}
                keyName="sub_sku"
                keyValue="sub_sku"
                disabled={!_isEmpty(skuList) ? false : true}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const InputHover = (props: any) => {
  return (
    <>
      <Popover content={props.value}>
        <Input {...props} />
      </Popover>
    </>
  );
};
export default SKUFields;
