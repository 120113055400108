import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from '../../tools/util'

export const getProductCategory = async (qs?: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/product-type${queryStr}`,
    })
    return res
}

export const getProductCategoryByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/product-type/${id}`,
    })
    return res
}

export const postProductCategory = async (data: any) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/product-type`,
    })
    return res
}
export const putProductCategory = async (data: any, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/product-type/${id}`,
    })
    return res
}

export const putProductCategoryActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/product-type/active/${id}`,
    })
    return res
}

export const delProductCategory = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/product-type/${id}`,
    })
    return res
}

export const getProductCategoryType = async (qs?: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/color-type${queryStr}`,
    })
    return res
}

export const getProductCategoryMenu = async () => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/product-category`,
    })
    return res
}

export const getProductCategoryMenuActive = async (data?: any) => {
    const queryStr = createQueryString(data)
    const res = await Fecth({
        method: "GET",
        path: `${version}/products/active-products${queryStr}`,
    })
    return res
}

export const getProductCategoryList = async (data?: any) => {
    const queryStr = createQueryString(data)
    const res = await Fecth({
        method: "GET",
        path: `${version}/products${queryStr}`,
    })
    return res
}


export const getProductCategoryOnlyType = async (data?: any) => {
    const queryStr = createQueryString(data)
    const res = await Fecth({
        method: "GET",
        path: `${version}/product-category/get-type${queryStr}`,
    })
    return res
}
export const getProductCategoryOnlyGroup = async (data?: any) => {
    const queryStr = createQueryString(data)
    const res = await Fecth({
        method: "GET",
        path: `${version}/product-category/get-group${queryStr}`,
    })
    return res
}

