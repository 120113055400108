import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { Dispatch, SetStateAction } from "react";

type Props = {
  gallery: any[];
  onModal: () => void;
  setGellery?: Dispatch<SetStateAction<any>>;
  label?: string;
  onChange?: any;
};
const Gallery = ({ gallery = [], onModal, setGellery, label }: Props) => {
  const handleDelete = (id: number) => {
    const newGellery = gallery.filter((item) => item.id !== id);
    setGellery && setGellery([...newGellery]);
  };

  return (
    <div>
      <Card className="bg-grey " extra={label}>
        <Row gutter={[14, 14]}>
          <Col lg={4}>
            <div onClick={() => onModal()} className="icon-edit-fix">
              <PlusOutlined style={{ fontSize: "40px", color: "lightgray" }} />
            </div>
          </Col>
          {gallery.map((item, idx) => {
            return (
              <Col lg={4} key={idx}>
                <div className="media-focus cursor">
                  <div className="icon-media-format">
                    <DeleteOutlined
                      className="ml-05 media-icon-block"
                      onClick={() => handleDelete(item.id)}
                    />
                  </div>
                  <img
                    src={item.file ? item.file : item.file_path}
                    alt={item.id}
                    className="w-100 img-round img-cover blur h-12rem"
                  />
                </div>
                <div className="pt-05">{item.name}</div>
              </Col>
            );
          })}
        </Row>
      </Card>
    </div>
  );
};

export default Gallery;
