import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Row, Select, Upload } from "antd";
import { useHistory } from "react-router-dom";
import { openNotification } from "../../../../component/notification/Notification";
import {
  getMediaCategory,
  postMediaManyImage,
} from "../../../../services/api/media";
import { IResStatus } from "../../../../tools/fecth/interface";
import { findItemByKey } from "../../../../tools/util";
import { ICategorymedia } from "./fieds";

const ManyImage = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [mediaCategory, setMediaCategory] = useState<ICategorymedia[]>([]);
  const { Option } = Select;
  useEffect(() => {
    handleGetMediaCategory();
    // eslint-disable-next-line
  }, []);
  const beforeUpload = (file: any) => {
    const fileName = file.name.split(".");
    form.setFieldsValue({ media_name: fileName[0] });
    return;
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const onSubmit = () => {
    form.submit();
  };

  const onFinish = (values: any) => {
    handleCreateMedia(values);
    // handleCreateMedia(values.file.fileList);
  };

  const handleGetMediaCategory = async () => {
    try {
      const res: IResStatus = await getMediaCategory();
      if (res.code === 200) {
        setMediaCategory(res.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const mapKeyArray = (list: any[]) => {
  //   var formData = new FormData();
  //   list.forEach((item) => {
  //     formData.append("file", item);
  //   });
  //   return formData;
  // };
  const handleCreateMedia = async (values: any) => {
    const filterObjFile = findItemByKey("originFileObj", values.file.fileList);
    // const fileList = mapKeyArray(filterObjFile);
    const fromData = new FormData();
    filterObjFile.forEach((item) => fromData.append("file", item));
    fromData.append("media_category_id", values.media_category_id);
    const res: IResStatus = await postMediaManyImage(fromData);
    if (res.code === 200) {
      form.resetFields();
      openNotification({
        method: "POST_SUCCESS",
      });
    } else {
      openNotification({
        method: "POST_ERROR",
      });
    }
  };
  const props = {
    customRequest: dummyRequest,
    beforeUpload: beforeUpload,
    multiple: true,
  };

  return (
    <div>
      <Card
        extra={<>อัพโหลด</>}
        actions={[
          <Row justify="end">
            <Col>
              <Button type="default" onClick={history.goBack}>
                ยกเลิก
              </Button>
            </Col>
            <Col className="pl-1">
              <Button className="btn-save" onClick={() => onSubmit()}>
                บันทึก
              </Button>
            </Col>
          </Row>,
        ]}
        className="card-for-tabs"
      >
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Row gutter={[14, 14]}>
            <Col lg={8}>
              <Form.Item
                label="ประเภทมีเดีย"
                name="media_category_id"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Select placeholder="โปรดเลือก">
                  {mediaCategory.map((item) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                label="รูปภาพ"
                name="file"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Upload listType="picture" {...props} multiple>
                  <Button icon={<UploadOutlined />} block>
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ManyImage;
