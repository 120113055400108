import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Card, Col, Pagination, Row } from "antd";
import { Dispatch, SetStateAction } from "react";
import { IMedia } from "../../../interface/IMedia.interface";
import CImage from "../../../component/image/Image";

type Props = {
  onModal?: (modal: { type: string; actionName: string }) => void;
  lg?: number;
  mediaList: IMedia[];
  setMediaId: Dispatch<SetStateAction<number>>;
  setIntialValue: Dispatch<SetStateAction<{ media_name: string ,media_category_id : number }>>;
  total: number;
  onChangePage: (page: any, type: string) => void;
  limitPage: number;
  currentPage: number;
};
const Container = ({
  onModal,
  mediaList = [],
  lg = 4,
  setMediaId,
  setIntialValue,
  total,
  onChangePage,
  limitPage,
  currentPage,
}: Props) => {
  return (
    <div>
      <Card className="card-media">
        <Row gutter={[14, 14]}>
          {mediaList.map((item, ind) => (
            <Col lg={lg} sm={12} key={ind}>
              <div className="media-focus cursor  ">
                <div className="icon-media-format">
                  <EditOutlined
                    className="media-icon-block"
                    onClick={() => {
                      onModal &&
                        onModal({
                          type: "edit",
                          actionName: item.media_name,
                        });
                      setMediaId(item.id);
                      setIntialValue({ media_name: item.media_name , media_category_id: item.media_category_id});
                    }}
                  />
                  <DeleteOutlined
                    className="ml-05 media-icon-block"
                    onClick={() => {
                      onModal &&
                        onModal({
                          type: "delete",
                          actionName: item.media_name,
                        });
                      setMediaId(item.id);
                    }}
                  />
                </div>

                <CImage
                  src={item.file_path}
                  alt={item.media_name}
                  className="w-100 img-round img-cover blur media-img"
                />
              </div>

              <div className="pt-05 cut-text-2-row">{item.media_name}</div>
            </Col>
          ))}
        </Row>
        <div className="p-1 w-100 d-flex justify-flex-end">
          <div className="d-flex items-center pr-1">แสดงรายการ</div>

          <Pagination
            showSizeChanger
            onChange={(page) => onChangePage && onChangePage(page, "page")}
            total={total}
            pageSize={limitPage}
            current={currentPage}
            className="pagi"
            onShowSizeChange={(_current, pageSize) =>
              onChangePage && onChangePage(pageSize, "pageSize")
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default Container;
