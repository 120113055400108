import MoTable from "../../../component/table/Table";
import { CheckOutlined } from "@ant-design/icons";
import SandClock from "../../../assets/img/icons/sand-clock.png";
import { Image } from "antd";
import { IMedia } from "../../../interface/IInspiration.interface";
import { IApproves } from "../../../interface/IApprove.interface";
import { DateStandard } from "../../../tools/date";
import noImage from "../../../assets/img/No_image.svg";

type Props = {
  dataSource: IApproves[];
  onClickRow: (recode: IApproves) => void;
  currentPage: number;
  limitPage: number;
  total: number;
  onChangePage: (page: number, type?: string) => void;
};

const Table = ({
  dataSource,
  onClickRow,
  currentPage,
  limitPage,
  total,

  onChangePage,
}: Props) => {
  const columns: any = [
    {
      title: "รูปภาพ",
      dataIndex: "cover_image",
      key: "cover_image",
      width: "10%",
      align: "center",
      render: (cover_image: string) => {
        return (
          <>
            <img
              src={cover_image === "" ? noImage : cover_image}
              className="img-squad"
              alt={cover_image}
            />
          </>
        );
      },
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "user",
      key: "user",
      width: "15%",
      render: (fullname: IMedia, value: IApproves) => {
        return (
          <>
            {fullname}
            <br />
            โทร : {value.phone}
          </>
        );
      },
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "create_at",
      key: "create_at",
      width: "8%",

      render: (create_at: string) => {
        return <>{DateStandard(create_at)}</>;
      },
    },
    {
      title: "ชื่อโครงการ",
      dataIndex: "project_name",
      key: "project_name",
      width: "17%",
    },

    {
      title: "วันเริ่ม-สิ้นสุดโครงการ",
      dataIndex: "start_date",
      key: "start_date",
      render: (_date: string, value: IApproves) => {
        return (
          <>
            <div>
              <div>วันเริ่ม {DateStandard(value.start_date)}</div>
              <div>วันสิ้นสุด {DateStandard(value.end_date)}</div>
            </div>
          </>
        );
      },
    },
    {
      title: "ไปยัง",
      dataIndex: "show_to",
      key: "show_to",
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "5%",
      render: (status: any) => {
        return (
          <>
            <StatusTag status={status} />
          </>
        );
      },
    },
  ];

  return (
    <div className="card-table-notab">
      <MoTable
        dataSource={dataSource}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
      />
    </div>
  );
};

const StatusTag = ({ status }: { status: string }) => {
  return (
    <span>
      {status === "APPROVE" ? (
        <div className="approve-status">
          <CheckOutlined />
          <span style={{ paddingLeft: "3px" }}> อนุมัติแล้ว</span>
        </div>
      ) : (
        <div className="pending-status">
          <Image className="Image" preview={false} width={16} src={SandClock} />
          รออนุมัติ
        </div>
      )}
    </span>
  );
};

export default Table;
