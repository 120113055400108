/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Modal,
  Tabs,
  Divider,
  Input,
} from "antd";
import Editor from "../../../../component/editor/Editor";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IResStatus } from "../../../../tools/fecth/interface";
import { openNotification } from "../../../../component/notification/Notification";
import Selector from "../../../../component/selector/Selector";
import {
  getInspirationListById,
  postInspirationList,
  putInspirationList,
} from "../../../../services/api/inspiration";
import {
  // IInspiList,
  IInspiPost,
} from "../../../../interface/IInspiration.interface";
import { IProductCategory } from "../../../../interface/IProductCategory.interface";
import { getInspirationCategoryList } from "../../../../services/api/inspiration_category";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  listCurrentItemMarker,
  loadingButtonState,
  loadingState,
} from "../../../../tools/recoil/recoil-atom";
import { EyeOutlined } from "@ant-design/icons";
import MediaSpaceMarker from "../../../../component/mediaspacemarker/MediaSpaceMarker";
import PreviewMarkerImage from "../../../../component/map/PreviewMarkerImage";
import MediaModal from "../../../../component/mediamodal/MediaModal";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import { _isEmpty } from "../../../../tools/util";
import TagText from "../../../../component/map/TagText";
import CardBoxInlineMarkerList from "../../../../component/map/CardBoxInlineMarkerList";

const Fields = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);
  const setLoading = useSetRecoilState(loadingState);
  const [contentTH, setContentTH] = useState<string>("");
  const [contentEN, setContentEN] = useState<string>("");
  const [inspirationCategoryList, setinspirationCategoryList] = useState<
    IProductCategory[]
  >([]);
  const [listItemmarker] = useRecoilState(listCurrentItemMarker);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewData, setPreviewData] = useState<any>({
    content_en: "",
    content_th: "",
    inspiration_category: 0,
    product_category: 0,
    product_type: 0,
    title_en: "",
    title_th: "",
    media: {
      marker: [] as any,
      media: {
        file: "",
        id: "",
        media_name: "",
      },
    },
  });

  const [visibleCoverImage, setVisibleCoverImage] = useState<boolean>(false);
  const [mediaSelectCoverImage, setMediaSelectCoverImage] =
    useState<IMediaSelect>({
      file: "",
      id: "",
      media_name: "",
    });

  const [previewTab, setPreviewTab] = useState<string>("1");
  const { TabPane } = Tabs;
  const datatest = inspirationCategoryList.find((data) => {
    if (data.id === previewData.inspiration_category) {
      return data;
    }
    return 0;
  });

  useEffect(() => {
    handleGetData();
  }, []);

  const handleGetData = async () => {
    let categorySelect: any = undefined;
    if (!_isEmpty(pathID)) {
      categorySelect = await handleGetGlassById();
    }
    handleGetInspirationCategoryList(categorySelect);
  };

  const handleGetGlassById = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getInspirationListById(pathID);
      if (res.code === 200) {
        const data = res.result[0];
        setContentTH(data.content_th);
        setContentEN(data.content_en);
        setMediaSelectCoverImage({
          file: data.cover_image?.file_path,
          id: data.cover_image?.id,
          media_name: data.cover_image?.media_name,
        });
        const arr = {
          media: {
            marker: data.mark.map((child: any, index: number) => {
              const result = {
                color: child.data_mark.color,
                product: child.data_mark.product.id,
                x: child.data_mark.x,
                y: child.data_mark.y,
              };
              return result;
            }),
            media: {
              file: data.media.file_path,
              id: data.media.id,
              media_name: data.media.media_name,
            },
          },
          title_th: data.title_th,
          title_en: data.title_en,
          content_en: data.content_en,
          content_th: data.content_th,
          cover_description_en: data.cover_description_en,
          cover_description_th: data.cover_description_th,
          product_category: data.inspiration_category.id,
          inspiration_category: data.inspiration_category.id,
          product_type: data.inspiration_category.id,
        };
        form.setFieldsValue(arr);
        setLoading(false);
        return data.inspiration_category;
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    return undefined;
  };

  const onFinish = (values: any) => {
    const markerCheck = values.media.marker;
    const reCheckNull = markerCheck.find(
      (child: any) => child.product === null
    );

    if (!_isEmpty(reCheckNull)) {
      openNotification({
        custom: true,
        type: "warning",
        message: "ข้อมูลของคุณยังไม่ครบถ้วนสมบูรณ์!",
        description:
          "การเพิ่มจุดบนรูปภาพของคุณบางรายการ ยังไม่เลือกข้อมูลเเรงบันดาลใจ",
      });
    } else {
      const data = {
        ...values,
        mark: values.media.marker,
        media: values.media.media.id,
        cover_image: mediaSelectCoverImage.id,
      };
      if (!_isEmpty(pathID)) {
        handleUpdateInspirationList(data);
      } else {
        handleCreateInspirationList(data);
      }
    }
  };

  const handleGetInspirationCategoryList = async (
    categorySelect?: IProductCategory
  ) => {
    const res: IResStatus = await getInspirationCategoryList({
      is_active: true,
    });
    if (res.code === 200) {
      if (categorySelect) {
        const findCategorySelect = res.result[0].item.find(
          (item: IProductCategory) => categorySelect.id === item.id
        );
        if (!findCategorySelect) {
          setinspirationCategoryList([...res.result[0].item, categorySelect]);
        } else {
          setinspirationCategoryList(res.result[0].item);
        }
      } else setinspirationCategoryList(res.result[0].item);
    }
  };

  const handleCreateInspirationList = async (values: IInspiPost) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await postInspirationList(values);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        history.push("/inspiration");
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const handleUpdateInspirationList = async (values: IInspiPost) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await putInspirationList(values, pathID);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/inspiration");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const onModalCoverImage = () => {
    setVisibleCoverImage(!visibleCoverImage);
  };

  const getDataListProductByID = (id: number) => {
    const result = listItemmarker.find((item: any) => item.id === id);
    return result || {};
  };

  return (
    <div>
      <MediaModal
        visible={visibleCoverImage}
        onModal={onModalCoverImage}
        setMediaSelect={setMediaSelectCoverImage}
      />
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Card
          extra={!_isEmpty(pathID) ? "แก้ไขไอเดีย" : "สร้างไอเดีย"}
          actions={[
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    setPreviewVisible(true);
                    setPreviewData(form.getFieldsValue());
                  }}
                >
                  <EyeOutlined />
                  ตัวอย่าง
                </Button>
              </Col>
              <Col className="pl-1">
                <Button type="default" onClick={() => history.goBack()}>
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button
                  className="btn-save"
                  htmlType="submit"
                  loading={loadingButton}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row gutter={[14, 14]}>
            <Col lg={12}>
              <Form.Item
                label="หัวข้อไอเดีย (TH)"
                name="title_th"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label="หัวข้อไอเดีย (EN)"
                name="title_en"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                label="หมวดหมู่ไอเดีย"
                name="inspiration_category"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Selector
                  Source={inspirationCategoryList}
                  keyName="name_th"
                  keyValue="id"
                />
              </Form.Item>
            </Col>
            <Col lg={24} className="mt-1">
              <Form.Item
                label="รูปปก"
                name="cover_image"
                rules={[
                  {
                    required: _isEmpty(mediaSelectCoverImage) ? true : false,
                    message: "โปรดเลือกรูปภาพ!",
                  },
                ]}
              >
                <MediaSpace
                  onModal={onModalCoverImage}
                  mediaSelect={mediaSelectCoverImage}
                />
              </Form.Item>
            </Col>

            <Col lg={24}>
              <Form.Item
                key={`media`}
                label="รูปภาพ"
                name={`media`}
                rules={[
                  {
                    required: true,
                    message: "โปรดเลือกรูปภาพ!",
                  },
                ]}
              >
                <MediaSpaceMarker typelist={"product"} textMessage={"สินค้า"} />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item name="content_th">
                <Editor
                  key={contentTH ? "succeed" : "Failed"}
                  content={contentTH}
                  header="รายละเอียด (TH)"
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item name="content_en">
                <Editor
                  content={contentEN}
                  key={contentEN ? "succeed" : "Failed"}
                  header="รายละเอียด (EN)"
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
      <Modal
        className="modal-preview"
        title={<div>ตัวอย่าง ไอเดีย</div>}
        centered
        visible={previewVisible}
        onCancel={() => {
          setPreviewVisible(false);
          setPreviewTab("1");
        }}
        width={1350}
        footer={null}
      >
        <div>
          <div className="mt-0.5">
            <Tabs
              className="tab-mainmenu"
              key={previewTab ? "notLoadedYet" : "loaded"}
              defaultActiveKey={previewTab}
              onChange={(e) => setPreviewTab(e)}
            >
              <TabPane tab="TH" key="1" />
              <TabPane tab="EN" key="2" className="border-tab-mainmenu" />
            </Tabs>

            <div className="preview-content ">
              <Row justify="center">
                <div
                  className="text-aling-center mt-1 mb-1 img-preview"
                  style={{ padding: "30px 30px 0px 30px", width: 1920 }}
                >
                  <PreviewMarkerImage
                    mapImg={previewData?.media?.media?.file}
                    initialMarkers={previewData?.media?.marker}
                    lang={previewTab === "1" ? "th" : "en"}
                  />
                </div>
              </Row>
              <Row
                justify="space-between"
                gutter={50}
                style={{
                  paddingTop: "3rem",
                  paddingBottom: "3rem",
                  paddingRight: "2rem",
                  paddingLeft: "2rem",
                }}
              >
                <Col
                  xl={!_isEmpty(previewData?.media?.marker) ? 14 : 24}
                  lg={!_isEmpty(previewData?.media?.marker) ? 14 : 24}
                  md={!_isEmpty(previewData?.media?.marker) ? 14 : 24}
                  xs={24}
                >
                  <TagText
                    tag={
                      previewTab === "1" ? datatest?.name_th : datatest?.name_en
                    }
                    lang={previewTab === "1" ? "th" : "en"}
                    date={datatest?.updated_at}
                  />
                  <div
                    className="preview-title-ititle c-orange-light mt-1"
                    dangerouslySetInnerHTML={{
                      __html:
                        previewTab === "1"
                          ? previewData?.cover_description_th
                          : previewData?.cover_description_en,
                    }}
                  ></div>
                  <div className="d-flex mb-1">
                    <div
                      className="preview-content-text mt-1"
                      dangerouslySetInnerHTML={{
                        __html:
                          previewTab === "1"
                            ? previewData.content_th
                            : previewData.content_en,
                      }}
                    />
                  </div>
                </Col>
                {!_isEmpty(previewData?.media?.marker) && (
                  <Col xl={10} lg={10} md={10} xs={22}>
                    <Divider orientation="left" className="mb-1">
                      <span
                        className="text-defualt-color"
                        style={{ fontWeight: "bold", fontSize: 15 }}
                      >
                        {previewTab === "1"
                          ? "สินค้าแนะนำ"
                          : "Recommended products"}
                      </span>
                    </Divider>
                    <div className="overflow-productlist-idea">
                      {previewData?.media?.marker?.map(
                        (item: any, idn: number) => {
                          const result = getDataListProductByID(item?.product);
                          return (
                            <div key={idn} style={{ paddingBottom: "1rem" }}>
                              <CardBoxInlineMarkerList
                                src={result?.cover_image?.file_path}
                                title={
                                  previewTab === "1"
                                    ? result?.title_detail_th
                                    : result?.title_detail_en
                                }
                                price={result?.size?.price}
                                textHeight={result?.size?.height}
                                textWidth={result?.size?.width}
                                lang={previewTab === "1" ? "th" : "en"}
                              />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Fields;
