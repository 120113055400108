import { GetData, SubmitRole } from "../../interface/IUsers.interface";
import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from "../../tools/util"

export const postRoleList = async(data:SubmitRole)=>{
    const res = await Fecth({
        data,
        method:"POST",
        path:`${version}/role`
    })
    return res;
 }

export const getRoleList = async(data?:GetData)=>{
    const value = createQueryString(data);
    const res = await Fecth({
        method:"GET",
        path:`${version}/role${value}`
    })
    return res;
}

export const getRoleListByID = async(id:number)=>{
    const res = await Fecth({
        method:"GET",
        path:`${version}/role/${id}`
    })
    return res
}

export const putRoleList = async(data:SubmitRole)=>{
    const res = await Fecth({
        data,
        method:"PUT",
        path:`${version}/role/${data?.id}`
    })
    return res
}

export const deleteRoleList = async(id:number)=>{
    const res = await Fecth({
        method:"DELETE",
        path:`${version}/role/${id}`
    })
    return res
}
