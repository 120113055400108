import { useState, useEffect } from "react";
import { Menu, Modal, Row, Col, Card, Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { prodectSelect } from "../../tools/recoil/recoil-atom";
// import { ListProduct } from "../../tools/recoil/interface";
import { IProductCategoryMenu } from "../../interface/IProductCategory.interface";
import {
  getProductCategoryMenu,
  getProductCategoryMenuActive,
} from "../../services/api/product_category";
import { _isEmpty } from "@yim/util-tool-ts";
import { openNotification } from "../notification/Notification";

type Props = {
  list: IProductCategoryMenu[];
  onClose: Function;
};

const ModalProduct = ({ onClose }: Props) => {
  const { SubMenu } = Menu;
  const [productSelected, setProductSelected] = useRecoilState(prodectSelect);
  const [listMenuProduct, setListMenuProduct] = useState<
    IProductCategoryMenu[]
  >([]);
  const [listItem, setListItem] = useState([] as any);

  useEffect(() => {
    HandleGetMenuProductList();
    HandleGetListProduct();
  }, []);

  const HandleGetMenuProductList = async () => {
    try {
      const res = await getProductCategoryMenu();
      if (res.code === 200) {
        setListMenuProduct(res.result);
      } else {
        openNotification({ method: "PUT_ERROR", message: res?.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleGetListProduct = async (id?: number) => {
    const insertValue = {
      page: 1,
      limit: 10,
      orderby: "DESC",
      search: "",
      product_category_id: !_isEmpty(id) ? id : "",
    };
    try {
      const res = await getProductCategoryMenuActive(insertValue);
      const itemList = res.result[0]?.item;
      setListItem(itemList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectProduct = (key: string) => {
    const result = listItem.find((item: any) => {
      return item.id === key;
    });

    setProductSelected([
      ...productSelected,
      {
        key: result?.id,
        image: result?.cover_image?.file_path,
        price: result?.size?.price,
        width: result?.size?.width,
        height: result?.size?.height,
        title: result?.title_th,
        title_th: result?.title_th,
        title_en: result?.title_en,
        description: result?.product_detail_th,
        description_th: result?.product_detail_th,
        description_en: result?.product_detail_en,
      },
    ]);
  };

  const handleRemoveProduct = (id: string) => {
    const updateList = productSelected.filter((item) => {
      return item.key !== id;
    });
    setProductSelected(updateList);
  };

  const ListItemMenu = () => {
    return (
      listMenuProduct &&
      listMenuProduct?.map((item: any, index: number) => (
        <SubMenu key={`sub-${index}`} title={item?.name_th}>
          {item?.group.map((child: any, idx: number) =>
            CheckSubMenu(child, idx)
          )}
        </SubMenu>
      ))
    );
  };

  const CheckSubMenu = (child: any, index: number) => {
    return child && !_isEmpty(child.list) ? (
      <SubMenu key={`sub-child${index}${child.id}`} title={child?.name_th}>
        {child?.list.map((subChild: any, idxchild: number) =>
          CheckSubChildMenu(subChild, idxchild)
        )}
      </SubMenu>
    ) : (
      <Menu.Item
        onClick={() => HandleGetListProduct(child?.id)}
        key={`sub-item${index}${child.id}`}
        className="tw-b c-grey d-flex justify-between"
      >
        {`${child?.name_th}`}
      </Menu.Item>
    );
  };

  const CheckSubChildMenu = (child: any, index: number) => {
    return (
      child && (
        <Menu.Item
          onClick={() => HandleGetListProduct(child?.id)}
          key={`sub-children${index}${child.id}`}
          className="tw-b c-grey d-flex justify-between"
        >
          {`${child?.name_th}`}
        </Menu.Item>
      )
    );
  };

  const ListItemCard = () => {
    return (
      listItem &&
      productSelected &&
      listItem.map((item: any, index: number) => {
        const resultFind = productSelected.find(
          (itemk) => itemk.key === item?.id
        );
        return (
          <Col lg={6} key={index} className="mb-1">
            {resultFind ? (
              <div className="icon-true-check">
                <CheckOutlined />
              </div>
            ) : null}
            <Card
              onClick={() =>
                resultFind
                  ? handleRemoveProduct(item?.id)
                  : handleSelectProduct(item?.id)
              }
              hoverable={true}
              cover={
                <img
                  src={item?.gallerys[0]?.file_path}
                  alt={item?.title_th}
                  width={"100%"}
                  height={200}
                  style={{ objectFit: "cover" }}
                />
              }
            >
              <div style={{ height: 60 }}>
                <h5 className="c-black text-description-custom">
                  {item?.title_th}
                </h5>
                <h6 className="c-grey text-description-custom ">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.product_detail_th,
                    }}
                  />
                </h6>
                {/* <h5 className="c-black">{item.price} THB</h5> */}
              </div>
            </Card>
          </Col>
        );
      })
    );
  };

  return (
    <div>
      <div className="m-1">
        <div className="d-flex justify-end mr-2">
          <Modal
            className="boder-radius"
            title={<h3 className="tw-b">รายการ</h3>}
            visible={true}
            onCancel={() => onClose()}
            width={1300}
            footer={null}
          >
            <Row>
              <Col lg={5}>
                <Menu
                  onClick={(e) => console.log("menuMom", e)}
                  style={{ width: 256 }}
                  defaultSelectedKeys={["sub-all"]}
                  defaultOpenKeys={["sub-all"]}
                  mode="inline"
                >
                  <Menu.Item
                    key={"sub-all"}
                    className="tw-b c-grey d-flex justify-between"
                    onClick={() => HandleGetListProduct()}
                  >
                    All
                  </Menu.Item>
                  {ListItemMenu()}
                </Menu>
              </Col>
              <Col lg={19} className="p-3">
                <Row gutter={18}>{ListItemCard()}</Row>
              </Col>
            </Row>
            <Row justify="end">
              <Button className="btn-save" onClick={() => onClose()}>
                ปิด
              </Button>
            </Row>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ModalProduct;
