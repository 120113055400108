import React from "react";
import DnDList from "../../../../../../component/@yim/react-dnd-list/List";
import ItemComponent from "./ItemListComponent";
import "./styles.css";
interface Props {
  list: any;
  setList: any;
  id: number;
  idCate: number;
  idGroup: number;
  horizontal?: boolean;
  // itemComponent: React.ReactNode;
  setSwapThreshold?: number;
  setOverflowThreshold?: number;
  onModal: any;
  onDelete: any;
}

const ListComponent = (props: Props) => {
  // console.log("main list", props);
  const Comp = (p: any) => (
    <ItemComponent
      {...p}
      onModal={props.onModal}
      idCate={props.idCate}
      idGroup={props.idGroup}
      onDelete={props.onDelete}
    />
  );
  return (
    <ul className="main-card-list">
      <DnDList
        idCate={props.idCate}
        id={props.id}
        items={props.list}
        setList={props.setList}
        horizontal={props.horizontal || false}
        itemComponent={Comp}
        type="item"
        setSwapThreshold={props.setSwapThreshold || null}
        setOverflowThreshold={props.setOverflowThreshold || null}
      />
    </ul>
  );
};

export default ListComponent;
