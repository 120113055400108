import { Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { getMedia, IMedia } from "../../services/api/media";
import { IResStatus } from "../../tools/fecth/interface";
import { _isEmpty } from "../../tools/util";
import FormFields from "./Form";
import Table from "./Table";

const MedaiModal = ({
  value,
  onModal,
  visible,
  onChange,
  onSelect,
  fileExtension,
  displayValue,
}: any) => {
  const [isFirst, setIsFirst] = useState(true);

  const [tabSelected, setTabSelected] = useState("1");
  const [mediaFileList, setFileList] = useState<IMedia[]>([]);
  const [filterSearch, setFilterSearch] = useState<{ media_name: string }>({
    media_name: "",
  });
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);

  const { TabPane } = Tabs;

  useEffect(() => {
    handleGetMediaFile();

    if (isFirst && !_isEmpty(value)) {
      onChange(value);
    }
    setIsFirst(false);
    // eslint-disable-next-line
  }, [currentPage, limitPage, filterSearch]);

  const handleGetMediaFile = async () => {
    try {
      const res: IResStatus = await getMedia({
        ...filterSearch,
        directory: "file",
        page: currentPage,
        limit: limitPage,
        type: fileExtension.filterTypeFile,
      });
      if (res.code === 200) {
        setFileList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  return (
    <div>
      <Modal
        footer={null}
        onCancel={onModal}
        title={
          tabSelected === "1"
            ? "BROWSE"
            : tabSelected === "2"
            ? "FROM COMPUTER"
            : "FROM URL"
        }
        visible={visible}
        width="50%"
        centered
      >
        <Tabs
          className="tab-modal"
          onChange={(e) => setTabSelected(e)}
          activeKey={tabSelected}
        >
          <TabPane tab="BROWSE" key="1">
            <Table
              displayValue={displayValue}
              onChange={onChange}
              onModal={onModal}
              onSelect={onSelect}
              handleGetMediaFile={handleGetMediaFile}
              onChangePage={onChangePage}
              setFilterSearch={setFilterSearch}
              mediaFileList={mediaFileList}
              pagiConfig={{
                total: total,
                pageSize: limitPage,
                currentPage: currentPage,
              }}
            />
          </TabPane>
          <TabPane tab="FROM COMPUTER" key="2">
            <FormFields
              fileExtension={fileExtension}
              setTabSelected={setTabSelected}
              handleGetMediaFile={handleGetMediaFile}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default MedaiModal;
