import { Col, Row } from "antd";
import React from "react";
import { IApprove } from "../../../../interface/IApprove.interface";
import InfoOnRow from "./InfoOnRow";
import noImage from "../../../../assets/img/No_image.svg";

type Props = {
  approve: IApprove | any;
};
const UserInfo = ({ approve }: Props) => {
  return (
    <div className="h-100">
      <Row className="border-box h-100">
        <Col lg={6} className="">
          <img
            src={approve.cover_image === "" ? noImage : approve.cover_image}
            className="img-squad-detail "
            alt={approve.cover_image}
          />
        </Col>
        <Col lg={18}>
          <InfoOnRow
            topic="ชื่อ-นามสกุล"
            data={approve.first_name + " " + approve.last_name}
          />
          <InfoOnRow topic="เบอร์โทร" data={approve.phone} />
          <InfoOnRow topic="อีเมล์" data={approve.email} />
        </Col>
      </Row>
    </div>
  );
};

export default UserInfo;
