import { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, Row, Select, Tabs } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Editor from "../../../../component/editor/Editor";
import ModalProduct from "../../../../component/modaler/ModalProduct";
import { useRecoilState, RecoilRoot, useSetRecoilState } from "recoil";
import {
  loadingState,
  prodectSelect,
} from "../../../../tools/recoil/recoil-atom";
import ChildrenForm from "./ChildrenForm";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import { IProjectList } from "../../../../interface/IProject.interface";
import {
  IProductCategory,
  IProductCategoryMenu,
} from "../../../../interface/IProductCategory.interface";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import MediaModal from "../../../../component/mediamodal/MediaModal";
import {
  getProjectContentCategory,
  postAddProject,
  getProjectContentByID,
  UpdateProjectContentByID,
} from "../../../../services/api/project";
import { getProductCategoryMenuActive } from "../../../../services/api/product_category";
import { openNotification } from "../../../../component/notification/Notification";
import { useHistory, useRouteMatch } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";
import Preview from "./Preview";
import { _isEmpty } from "../../../../tools/util";

const { Option } = Select;
export interface IValue {
  key: string;
  image: string;
  name: string;
}
export interface IGallery {
  image: string;
  name: string;
  title_th: string;
  inspiration_id: number;
  image_id: number;
}

export interface IPreviewGallery {
  data: IGallery[];
}
const Fields = () => {
  const setLoading = useSetRecoilState(loadingState);
  const [form] = Form.useForm();
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [productList, setProductList] = useRecoilState(prodectSelect);
  const [galleryList, setGalleryList] = useState<IGallery[]>([]);
  const [activeAdd, setactiveAdd] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleCoverImage, setVisibleCoverImage] = useState<boolean>(false);

  const [description, setDescription] = useState({
    descriptionTh: "",
    descriptionEn: "",
  });
  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });
  const [mediaSelectCoverImage, setMediaSelectCoverImage] =
    useState<IMediaSelect>({
      file: "",
      id: "",
      media_name: "",
    });
  const [childInitial, setChildInitial] = useState({} as any);
  const [projectCategory, setProjectCategory] = useState<IProjectList[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [listMenuProduct, setListMenuProduct] = useState<
    IProductCategoryMenu[]
  >([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewData, setPreviewData] = useState<any>("");
  const [previewTab, setPreviewTab] = useState<string>("1");
  const [activeFirst, setActiveFirst] = useState(true);
  const { TabPane } = Tabs;

  useEffect(() => {
    handleGetData();

    // eslint-disable-next-line
  }, [activeAdd]);

  const handleGetData = async () => {
    let categorySelect: any = undefined;
    if (activeFirst) {
      if (pathID) {
        categorySelect = await GetdataInfo(pathID);
      }
      HandleGetMenuProductList();
      HandleGetProjectTypeList(categorySelect);
    }
    setActiveFirst(false);
  };

  const GetdataInfo = async (id: number) => {
    setLoading(true);
    try {
      const res = await getProjectContentByID(id);
      const dataInfo = !_isEmpty(res.result[0]) && res.result[0];
      if (!_isEmpty(dataInfo)) {
        form.setFieldsValue({
          title_th: dataInfo?.title_th,
          title_en: dataInfo?.title_en,
          area_th: dataInfo?.area_th,
          area_en: dataInfo?.area_en,
          project_category: `${dataInfo?.project_category?.id}`,
          description_en: dataInfo?.description_en,
          description_th: dataInfo?.description_th,
        });
        setPreviewData(dataInfo);
        const gallerItemList = dataInfo?.inspiration?.map((item: any) => {
          return {
            image: item?.media?.file_path,
            name: item?.media?.media_name,
            title_th: item?.title_th,
            inspiration_id: item?.inspiration_id?.id,
            image_id: item?.media?.id,
          };
        });
        setGalleryList(gallerItemList);
        const productListItem = dataInfo?.products?.map((item: any) => {
          return {
            key: item?.product?.id,
            image: item?.product?.cover_image?.file_path,
            price: item?.product?.size?.price,
            width: item?.product?.size?.width,
            height: item?.product?.size?.height,
            title: item?.product?.title_th,
            title_th: item?.product?.title_th,
            title_en: item?.product?.title_en,
            description: item?.product?.product_detail_th,
            description_th: item?.product?.product_detail_th,
            description_en: item?.product?.product_detail_en,
          };
        });
        if (productListItem) setProductList(productListItem);

        setMediaSelect({
          file: dataInfo?.media?.file_path,
          id: dataInfo?.media?.id,
          media_name: dataInfo?.media?.media_name,
        });
        setMediaSelectCoverImage({
          file: dataInfo?.cover_image?.file_path,
          id: dataInfo?.cover_image?.id,
          media_name: dataInfo?.cover_image?.media_name,
        });

        setDescription({
          ...description,
          descriptionTh: dataInfo?.description_th,
          descriptionEn: dataInfo?.description_en,
        });
        return dataInfo.project_category;
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    return undefined;
  };

  const HandleGetMenuProductList = async () => {
    setLoading(true);
    try {
      const res = await getProductCategoryMenuActive();
      if (res.code === 200) {
        setListMenuProduct(res.result[0]);
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const HandleGetProjectTypeList = async (categorySelect?: IProjectList) => {
    setLoading(true);
    const parameter = {
      page: 1,
      limit: 10,
      orderby: "DESC",
      search: "",
      is_active: true,
    };
    try {
      const res = await getProjectContentCategory(parameter);
      if (res.code === 200) {
        if (categorySelect) {
          const findCategorySelect = res.result[0].item.find(
            (item: IProductCategory) => categorySelect.id === item.id
          );
          if (!findCategorySelect) {
            setProjectCategory([...res.result[0].item, categorySelect]);
          } else {
            setProjectCategory(res.result[0].item);
          }
        } else {
          setProjectCategory(res.result[0].item);
        }
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onFinishChildForm = (value: any) => {
    setGalleryList([
      ...galleryList,
      {
        image: value?.image?.file,
        name: value?.image?.media_name,
        title_th: value?.topic,
        inspiration_id: value?.inspiration,
        image_id: value?.image?.id,
      },
    ]);
    setactiveAdd(false);
  };

  const onEditChildForm = (value: any) => {
    const result = galleryList?.map((item: any, index: number) => {
      return index === childInitial.key
        ? {
            image: value?.image?.file,
            name: value?.image?.media_name,
            title_th: value?.topic,
            inspiration_id: value?.inspiration,
            image_id: value?.image?.id,
          }
        : item;
    });
    setGalleryList(result);
    setChildInitial({});
    setactiveAdd(false);
  };

  const handleRemoveGallery = (index: number) => {
    const updateList = galleryList?.filter((item, key) => {
      return key !== index;
    });
    setGalleryList([...updateList]);
  };

  const handleEditGallery = (index: any) => {
    const updateList = galleryList?.find((item, key) => key === index);
    const initailData = {
      ...updateList,
      key: index,
    };
    setChildInitial(initailData);
    setactiveAdd(true);
  };

  const handleRemoveProduct = (index: string) => {
    const updateList = productList?.filter((item) => {
      return item.key !== index;
    });
    setProductList([...updateList]);
  };

  const onCloseChildForm = () => {
    setChildInitial({});
    setactiveAdd(false);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const onModalCoverImage = () => {
    setVisibleCoverImage(!visibleCoverImage);
  };

  const onFinish = async (values: any) => {
    const inspirationList = galleryList?.map((item: any) => {
      return {
        title_th: item?.title_th,
        inspiration_id: item?.inspiration_id,
        media: item?.image_id,
      };
    });

    const ProductFilterList = productList?.filter(
      (item: any) => item.key !== "init"
    );
    const itemProductList = ProductFilterList?.map((item: any) => item.key);

    const data = {
      title_en: values?.title_en,
      title_th: values?.title_th,
      area_en: values?.area_en,
      area_th: values?.area_th,
      description_en: values?.description_en,
      description_th: values?.description_th,
      media: mediaSelect.id,
      cover_image: mediaSelectCoverImage.id,
      project_category_id: Number(values?.project_category),
      inspiration: inspirationList,
      products: itemProductList,
    };

    try {
      const editID = pathID;
      const res = _isEmpty(editID)
        ? await postAddProject(data)
        : await UpdateProjectContentByID(editID, data);
      if (res.code === 200) {
        // setProductList([]); //clear State
        openNotification(
          _isEmpty(editID)
            ? { message: "เพิ่มข้อมูลสำเร็จ!", custom: true, type: "success" }
            : { method: "PUT_SUCCESS", message: "เพิ่มข้อมูลสำเร็จ!" }
        );
        history.push({
          pathname: "/project",
          state: { tabSelected: 1 },
        });
      } else {
        openNotification(
          _isEmpty(editID)
            ? {
                message: `เพิ่มข้อมูลไม่สำเร็จ!`,
                custom: true,
                type: "error",
                description: `เนื่องจาก ${res?.message}`,
              }
            : {
                custom: true,
                type: "error",
                message: res.message,
                description: `เนื่องจาก ${res?.message}`,
              }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckDisplayBtnIcon = (idx: number) => {
    return activeAdd ? null : (
      <div className="icon-media-format">
        <EditOutlined
          className={"media-icon-block"}
          onClick={() => handleEditGallery(idx)}
        />
        <DeleteOutlined
          className="ml-05 media-icon-block"
          onClick={() => handleRemoveGallery(idx)}
        />
      </div>
    );
  };

  return (
    <div>
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        name="project"
        scrollToFirstError
      >
        <Card
          extra={"สร้างโครงการ"}
          actions={[
            <Row justify="end">
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    setPreviewVisible(true);
                    setPreviewData(form.getFieldsValue());
                  }}
                >
                  <EyeOutlined />
                  ตัวอย่าง
                </Button>
              </Col>
              <Col className="pl-1">
                <Button
                  type="default"
                  onClick={() => {
                    history.push({
                      pathname: "/project",
                      state: { tabSelected: 1 },
                    });
                  }}
                >
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button className="btn-save" htmlType="submit">
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Modal
            className="modal-preview"
            title={<div>ตัวอย่าง โครงการ</div>}
            centered
            visible={previewVisible}
            onCancel={() => {
              setPreviewVisible(false);
              setPreviewTab("1");
            }}
            width={1350}
            footer={null}
          >
            <div>
              <div className="mt-0.5">
                <Tabs
                  className="tab-mainmenu"
                  key={previewTab ? "notLoadedYet" : "loaded"}
                  defaultActiveKey={previewTab}
                  onChange={(e) => setPreviewTab(e)}
                >
                  <TabPane tab="TH" key="1" />
                  <TabPane tab="EN" key="2" className="border-tab-mainmenu" />
                </Tabs>

                {previewTab === "1" && (
                  <Preview
                    mediaSelect={mediaSelect}
                    galleryList={galleryList}
                    productList={productList}
                    previewData={previewData}
                    previewTab={previewTab}
                  />
                )}
                {previewTab === "2" && (
                  <Preview
                    mediaSelect={mediaSelect}
                    galleryList={galleryList}
                    productList={productList}
                    previewData={previewData}
                    previewTab={previewTab}
                  />
                )}
              </div>
            </div>
          </Modal>
          <Row gutter={14}>
            <Col lg={12} className="layout-col-project">
              <Form.Item
                className="layout-formitem-project"
                label={"หัวข้อโครงการ (TH)"}
                name="title_th"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} className="layout-col-project">
              <Form.Item
                className="layout-formitem-project"
                label={"หัวข้อโครงการ (EN)"}
                name="title_en"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} className="layout-col-project">
              <Form.Item
                className="layout-formitem-project"
                label={"บริเวณ I เช่น สาธร กรุงเทพ I (TH)"}
                name="area_th"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} className="layout-col-project">
              <Form.Item
                className="layout-formitem-project"
                label={"บริเวณ I เช่น สาธร กรุงเทพ I (EN)"}
                name="area_en"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} className="layout-col-project">
              <Form.Item
                className="layout-formitem-project"
                name="project_category"
                label={"หมวดหมู่โครงการ"}
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Select showSearch optionFilterProp="children">
                  {projectCategory.map((item: IProjectList, index: number) => (
                    <Option key={index} value={item?.id.toString()}>
                      {item?.name_th}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={24} className="layout-col-project mt-1">
              <MediaModal
                visible={visibleCoverImage}
                onModal={onModalCoverImage}
                setMediaSelect={setMediaSelectCoverImage}
              />
              <Form.Item
                label="รูปปก"
                name="cover_image"
                rules={[
                  {
                    required: _isEmpty(mediaSelectCoverImage) ? true : false,
                    message: "โปรดกรอกข้อมูล!",
                  },
                ]}
              >
                <MediaSpace
                  onModal={onModalCoverImage}
                  mediaSelect={mediaSelectCoverImage}
                />
              </Form.Item>
            </Col>

            <Col lg={24} className="layout-col-project mt-1">
              <MediaModal
                visible={visible}
                onModal={onModal}
                setMediaSelect={setMediaSelect}
              />
              <Form.Item
                key={`media`}
                label="รูปภาพ"
                name={`media`}
                rules={[
                  {
                    required: _isEmpty(mediaSelect) ? true : false,
                    message: "โปรดกรอกข้อมูล!",
                  },
                ]}
              >
                <MediaSpace onModal={onModal} mediaSelect={mediaSelect} />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item name="description_th">
                <Editor
                  content={description.descriptionTh}
                  key={description.descriptionTh ? "succeed" : "Failed"}
                  header="รายละเอียด (TH)"
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item name="description_en">
                <Editor
                  content={description.descriptionEn}
                  key={description.descriptionEn ? "succeed" : "Failed"}
                  header="รายละเอียด (EN)"
                />
              </Form.Item>
            </Col>
            <Col className="no-shadow" lg={24}>
              <Card className="bg-grey mt-2" extra={<>{"แกลลอรี่ (ไอเดีย)"}</>}>
                <Row gutter={15}>
                  <Col lg={4}>
                    <div
                      onClick={() => setactiveAdd(true)}
                      className="icon-edit"
                    >
                      <PlusOutlined
                        style={{ fontSize: "40px", color: "lightgray" }}
                      />
                    </div>
                  </Col>
                  {galleryList.map((item, idx) => {
                    return (
                      <Col lg={4} key={idx}>
                        <div className="media-focus cursor">
                          {handleCheckDisplayBtnIcon(idx)}
                          <img
                            src={item.image}
                            alt={item.name}
                            className="w-100 img-round img-cover blur h-12rem"
                          />
                        </div>
                        <div className="pt-05">{item.title_th}</div>
                      </Col>
                    );
                  })}
                </Row>
                <ChildrenForm
                  form={form}
                  initialValue={childInitial}
                  active={activeAdd}
                  onChange={onCloseChildForm}
                  onFinish={onFinishChildForm}
                  onEdit={onEditChildForm}
                />
              </Card>
            </Col>
            <Col className="no-shadow" lg={24}>
              <Card className="bg-grey mt-2" extra={"สินค้า"}>
                <Row gutter={15}>
                  <Col lg={4}>
                    <div
                      onClick={() => {
                        if (!_isEmpty(listMenuProduct)) {
                          setIsModalVisible(true);
                        } else {
                          openNotification({
                            message: "ไม่มีรายการสินค้า",
                            custom: true,
                            type: "error",
                          });
                        }
                      }}
                      className="icon-edit"
                    >
                      <PlusOutlined
                        style={{ fontSize: "40px", color: "lightgray" }}
                      />
                    </div>
                  </Col>
                  {productList
                    ?.filter((product) => product.key !== "init")
                    ?.map((item, idx) => {
                      return (
                        <Col lg={4} key={idx}>
                          <div className="media-focus cursor">
                            <div className="icon-media-format">
                              <DeleteOutlined
                                className="ml-05 media-icon-block"
                                onClick={() => handleRemoveProduct(item.key)}
                              />
                            </div>
                            <img
                              src={item.image}
                              alt={item.title_en}
                              className="w-100 img-round img-cover blur h-12rem"
                            />
                          </div>
                          <div className="pt-05">{item?.title_th}</div>
                          <h6>
                            <div
                              className="c-grey text-description-custom "
                              dangerouslySetInnerHTML={{
                                __html: item?.description_th,
                              }}
                            />
                          </h6>
                        </Col>
                      );
                    })}
                </Row>
              </Card>
              {isModalVisible && !_isEmpty(listMenuProduct) && (
                <ModalProduct
                  // list={list}
                  list={listMenuProduct}
                  onClose={() => setIsModalVisible(false)}
                />
              )}
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

const FieldContainer = () => {
  return (
    <RecoilRoot>
      <Fields />
    </RecoilRoot>
  );
};

export default FieldContainer;
