import { useState } from "react";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MoTable from "../../../component/table/Table";
import { Switch } from "antd";
import { useHistory } from "react-router-dom";
import { delProjectContent } from "../../../services/api/project";
import { openNotification } from "../../../component/notification/Notification";
import Modaler from "../../../component/modaler/Modaler";
import { DateTimeStandard } from "../../../tools/date";
import CImage from "../../../component/image/Image";
import { IMedia } from "../../../interface/IInspiration.interface";

type Props = {
  handleChangeSwitch: (source: any) => void;
  HandleOnCheck: (tab: string) => void;
  onChangePage: (page: number, type?: string, tab?: string) => void;
  dataSource: any[];
  config: {};
};
const ProjectTab = ({
  handleChangeSwitch,
  dataSource,
  HandleOnCheck,
  onChangePage,
  config,
}: Props) => {
  const history = useHistory();
  const [delID, setDelID] = useState(0);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);

  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "8%",
      render: (status: boolean, soure: any) => {
        return (
          <>
            <Switch
              checked={status}
              onChange={() => handleChangeSwitch(soure)}
            />
          </>
        );
      },
    },
    {
      title: "รูปปก",
      dataIndex: "cover_image",
      key: "cover_image",
      width: "10%",
      align: "center",
      render: (cover_image: IMedia) => {
        return (
          <>
            <CImage
              src={cover_image?.file_path}
              className="img-squad"
              alt={cover_image?.media_name}
            />
          </>
        );
      },
    },
    {
      title: "ชื่อหัวข้อ",
      dataIndex: "title_th",
      key: "title_th",
      width: "45%",
      render: (title_th: string) => {
        return (
          <>
            <b>{title_th}</b>
          </>
        );
      },
    },
    {
      title: "หมวดหมู่โครงการ",
      dataIndex: "",
      key: "",
      render: (value: string, row: any) => {
        const name = row?.project_category?.name_th;
        return <div>{name}</div>;
      },
    },
    {
      title: "อัปเดต",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render(text: any, record: any) {
        return {
          props: {
            className: "",
          },
          children: (
            <div>
              <p style={{ marginBottom: 5 }}>
                {record.edit_by?.first_name} {record.edit_by?.last_name}
              </p>
              <p style={{ marginBottom: 5 }}>
                {DateTimeStandard(record.updated_at)}
              </p>
            </div>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      width: "90px",
      render: (text: any, record: any) => {
        return (
          <ActionButton
            icon={<DeleteOutlined />}
            onClick={(e: any) => {
              e.stopPropagation();
              setDelID(record?.id);
              setVisibleDelete(true);
            }}
          />
        );
      },
    },
  ];

  const HandleDelete = async () => {
    try {
      console.log("del", delID);
      const res = await delProjectContent(delID);
      if (res.code === 200) {
        openNotification({
          method: "DEL_SUCCESS",
        });
        HandleOnCheck("1");
      } else {
        openNotification({
          method: "DEL_ERROR",
        });
      }
    } catch (error) {
      console.log(error);
    }
    onCancel();
  };

  const ActionButton = ({ icon, onClick }: { icon: any; onClick: any }) => {
    return (
      <div className="action-btn" onClick={onClick}>
        {icon}
      </div>
    );
  };

  const onClickRow = (record: any) => {
    console.log("record", record);
    history.push({
      pathname: `/project/project/${record.id}`,
      state: { recordData: record },
    });
  };

  const onCancel = () => {
    setVisibleDelete(false);
  };

  const onDelete = () => {
    HandleDelete();
  };

  const handleOnChangePage = (page: number, type?: string) => {
    onChangePage(page, type, "1");
  };

  return (
    <div>
      <Modaler
        okText="ตกลง"
        visible={visibleDelete}
        onCancel={onCancel}
        onOk={onDelete}
        width="25%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            คุณแน่ใจไหม?
          </div>
        }
      >
        คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
      </Modaler>
      <MoTable
        config={config}
        dataSource={dataSource}
        columns={columns}
        // config={{ total: total, pageSize: 10, currentPage: currentPage }}
        onChangePage={handleOnChangePage}
        onClickRow={onClickRow}
      />
    </div>
  );
};
export default ProjectTab;
