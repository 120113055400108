import { useState, useEffect } from "react";
import { Card, Row, Col, Form, Input, Select, Button } from "antd";
import MediaModal from "../../../../component/mediamodal/MediaModal";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import { getInspirationList } from "../../../../services/api/inspiration";
import { IInspiList } from "../../../../interface/IInspiration.interface";
import { _isEmpty } from "@yim/util-tool-ts";
import { openNotification } from "../../../../component/notification/Notification";

const { Option } = Select;
const ChildrenForm = ({
  active,
  initialValue,
  onChange,
  onFinish,
  onEdit,
  form,
}: {
  form: any;
  active: boolean;
  initialValue: any;
  onChange: () => void;
  onFinish: (data: any) => void;
  onEdit: (data: any) => void;
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });

  const [inspireList, setInsprieList] = useState<IInspiList[]>([]);

  useEffect(() => {
    HandleGetInspirationList();
    HandleGetInitialValue();
    // eslint-disable-next-line
  }, [initialValue]);

  const HandleGetInitialValue = async () => {
    if (!_isEmpty(initialValue)) {
      form.setFieldsValue({
        topic: initialValue?.title_th,
        inspiration: !_isEmpty(initialValue?.inspiration_id)
          ? Number(initialValue?.inspiration_id)
          : "",
      });
      setMediaSelect({
        file: initialValue?.image,
        id: initialValue?.image_id,
        media_name: initialValue?.name,
      });
    }
  };

  const HandleGetInspirationList = async () => {
    const parameter = { page: 1, limit: 10, orderby: "DESC" };
    try {
      const res = await getInspirationList(parameter);
      if (res.code === 200) {
        setInsprieList(res.result[0].item);
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleCheckValidate = () => {
    const inputValue = form.getFieldsValue(["topic"]);
    if (inputValue.topic === "") {
      form.validateFields(["topic"]);
    } else {
      if (_isEmpty(mediaSelect.file)) {
        openNotification({
          message: "กรุณาอัพโหลดรูปภาพ",
          custom: true,
          type: "error",
        });
      } else {
        HandleOnFinish();
      }
    }
  };

  const HandleOnFinish = () => {
    const inputValue = form.getFieldsValue(["topic", "inspiration"]);
    const dataChild = {
      ...inputValue,
      image: { ...mediaSelect },
    };
    if (!_isEmpty(initialValue)) {
      onEdit(dataChild);
    } else {
      onFinish(dataChild);
    }
    HandleResetValue();
  };

  const HandleResetValue = () => {
    form.setFieldsValue({
      topic: "",
      inspiration: "",
    });
    setMediaSelect({
      file: "",
      id: "",
      media_name: "",
    });
  };

  const onModal = () => {
    setVisible(!visible);
  };

  return active ? (
    <div>
      <Card style={{ marginTop: "10px" }}>
        <div className="text-h" style={{ paddingBottom: "10px" }}>
          อัพโหลดข้อมูลการตัดประกอบ
        </div>
        <MediaModal
          visible={visible}
          onModal={onModal}
          setMediaSelect={setMediaSelect}
        />
        <Row justify="center" style={{ marginTop: 20 }}>
          <Col span={17}>
            <div style={{ marginBottom: "10px" }}>
              <Form.Item
                className="layout-formitem-project"
                name="topic"
                label={"หัวข้อ"}
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error("โปรดกรอกข้อมูล!")),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <Form.Item
                className="layout-formitem-project"
                name="inspiration"
                label={"ไอเดีย"}
              >
                <Select showSearch optionFilterProp="children">
                  {inspireList.map((item: IInspiList, index: number) => (
                    <Option key={index} value={item?.id}>
                      {item?.title_th}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <MediaSpace onModal={onModal} mediaSelect={mediaSelect} />
        </Row>
        <Row justify="center" style={{ marginTop: 20 }}>
          <Button
            type="default"
            onClick={() => {
              HandleResetValue();
              onChange();
            }}
          >
            ยกเลิก
          </Button>
          <Button className="btn-save ml-1" onClick={HandleCheckValidate}>
            {!_isEmpty(initialValue) ? "บันทึก" : "เพิ่ม"}
          </Button>
        </Row>
      </Card>
    </div>
  ) : null;
};

export default ChildrenForm;
