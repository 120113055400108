import React, { useState, useEffect } from "react";
import { _isEmpty } from "../../../../tools/util";
import { Button, Card, Col, Form, Row } from "antd";
import UploadPhoto from "../component/_UploadPhoto";
import DataUserInput from "../component/_SetDataUser";
import CreatePassword from "../component/_SetPassword";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { IAdmitPost } from "../../../../interface/IUsers.interface";
import { IMediaSelect } from "../../../../interface/IMedia.interface";

import { openNotification } from "../../../../component/notification/Notification";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../../tools/recoil/recoil-atom";

//-----------------api-----------------//
import {
  postUserList,
  getUserListByID,
  pitUserList,
} from "../../../../services/api/user";

type Props = {
  url: string;
};

const Fields = ({ url }: Props) => {
  const setLoading = useSetRecoilState<boolean>(loadingState);
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const { params }: any = useRouteMatch();
  const id: number = params.id;

  const history = useHistory();
  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });
  const [form] = Form.useForm();
  const match: any = useRouteMatch();
  const pathID = match.params.id;

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    if (!_isEmpty(pathID)) {
      handleGetAdminByID();
    }
  };

  const handleGetAdminByID = async () => {
    setLoading(true);
    try {
      const res = await getUserListByID(id);
      const newdata = res.result[0];
      if (res.code === 200) {
        form.setFieldsValue({
          ...newdata,
          department: newdata.department.id,
          role: newdata.role.id,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onFinish = (values: any) => {
    if (!_isEmpty(pathID)) {
      onEdit(values);
    } else {
      onCreate(values);
    }
  };

  const onCreate = async (value: IAdmitPost) => {
    try {
      const newdata = {
        ...value,
        media: mediaSelect.id,
        username: value.email,
      };
      //---------------------------------------------//
      const res = await postUserList(newdata);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        history.push("/user");
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onEdit = async (value: IAdmitPost) => {
    try {
      const newdata = {
        ...value,
        media: mediaSelect.id,
        username: value.email,
      };
      const res = await pitUserList(newdata, pathID);
      if (res.code === 200) {
        history.push("/user");
        openNotification({ method: "PUT_SUCCESS", message: res.message });
      } else {
        // history.push("/user");
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = () => {
    setEditPassword(!editPassword);
  };

  return (
    <div>
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Card
          // extra={id ? <>แก้ไขข้อมูลผู้ใช้</> : <>สร้างข้อมูลผู้ใช้</>}

          extra={!_isEmpty(pathID) ? "แก้ไขข้อมูลผู้ใช้" : "สร้างข้อมูลผู้ใช้"}
          actions={[
            <Row justify="end">
              <Col>
                <Button onClick={() => history.push("/user")} type="default">
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button className="btn-save" htmlType="submit">
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row>
            <Col lg={5} offset={1}>
              <UploadPhoto
                mediaSelect={mediaSelect}
                setMediaSelect={setMediaSelect}
              />
            </Col>
            <Col lg={17} offset={1}>
              <DataUserInput form={form} />
              <br />
              {_isEmpty(pathID) ? (
                <CreatePassword edit={editPassword} />
              ) : (
                <>
                  {editPassword ? (
                    <>
                      <CreatePassword edit={editPassword} />
                    </>
                  ) : (
                    <>
                      <div>เปลี่ยนรหัสผ่าน</div>
                      <Button onClick={handleEditClick} className="mt-1">
                        ตั้งรหัสผ่านใหม่
                      </Button>
                      <br />
                      <br />
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
