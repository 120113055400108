import React from "react";
import { Table, Pagination } from "antd";
import { displayHide } from "../../tools/util";

type Props = {
  dataSource?: any[];
  columns?: any[];
  loading?: boolean;
  config?: any;
  onChangePage?: (page: number, pageSize?: string) => void;
  onClickRow?: (row: any) => void;
  rowKey?: string;
};
const MoTable = ({
  dataSource = [],
  columns = [],
  loading = false,
  config = { total: 15, currentPage: 1, pageSize: 10 },
  onChangePage,
  rowKey = "id",
  onClickRow,
}: Props) => {
  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        loading={loading}
        rowKey={rowKey}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: (event) => {
              onClickRow && onClickRow(record);
            },
          };
        }}
      />
      <div
        className={displayHide(
          config.total,
          config.total === 0 ? null : config.total
        )}
      >
        <div className="p-1 w-100 d-flex justify-flex-end">
          <div className="d-flex items-center pr-1">แสดงรายการ</div>
          <Pagination
            showSizeChanger
            onChange={(page) => onChangePage && onChangePage(page, "page")}
            total={config.total}
            current={config.currentPage}
            pageSize={config.pageSize}
            className="pagi"
            onShowSizeChange={(_current, pageSize) =>
              onChangePage && onChangePage(pageSize, "pageSize")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MoTable;
