// import LoginPage from "../views/login/LoginPage";
import React from "react";
import Inspiration from "../views/inspiration/inspiration";
import Product from "../views/product/Product";
import Project from "../views/project/_Project";
import Article from "../views/article/Article";
import Users from '../views/users/Users'
import FormGroupProject from "../views/project/grouptab/Form/_Form";
import FormInspi from "../views/inspiration/inspitab/Form/_Form";
import FormProject from "../views/project/projecttab/Form/_Form";
import FormArticle from "../views/article/articletab/Form/_Form";
import FormGroupArticle from "../views/article/grouptab/Form/_Form";
import FormUser from "../views/users/usertab/form/Form"
import FormRole from "../views/users/roletab/form/Form"
import FormBanner from "../views/bannermanagement/form/Form"
import FormProduct from "../views/product/product/form/Form"
import FormCategoty from "../views/product/category/form/Form"
import FormColorType from "../views/product/colortype/form/Form"
import FormColor from "../views/product/colors/form/Form"
import FormMirror from "../views/product/mirror/form/Form"
import FormLogDevice from "../views/product/logdevice/form/Form"
import FormDoorOpenPattern from "../views/product/dooropenpattern/form/Form"
import FormInstallation from "../views/product/installation/form/Form"
import FormGrouptab from "../views/inspiration/grouptab/Form/_Form"
import FormApprove from "../views/approve-management/form/Fields"



import Media from "../views/media/Media"
import FormMediaImage from "../views/media/image/form/Form"
import FormMediaFile from "../views/media/file/form/Form"
import Overall from "../views/overall/Overall";
import FormUserEdit from "../views/users/usertab/form/Form"
import FormRoleEdit from "../views/users/roletab/form/Form"
import SettingPage from "../views/settingpage/SettingPage"
import ApproveManage from "../views/approve-management/ApproveManagement"
import BannerManage from "../views/bannermanagement/BannerManagement"
import SettingProfile from "../views/setting-profile/SettingProfile";
import FormGroup from "../views/product/group/formgroup/FormGroup";
import Home from "../views/home/Home";
import Serve from "../views/serve/Serve";

// import FormAluminium from "../views/product/aluminium/form/Form"

export interface IRouter {
  path: string;
  name: string;
  authentMenuName: string;
  exact: boolean;
  component: React.FC;
}

export const routers: IRouter[] = [
  {
    path: "/",
    name: "home",
    exact: true,
    component: Home,
    authentMenuName: "Home"
  },
  {
    path: "/media",
    name: "media",
    exact: true,
    component: Media,
    authentMenuName: "Medias"
  },
  {
    path: "/media/image/create",
    name: "media",
    exact: true,
    component: FormMediaImage,
    authentMenuName: "Medias"
  },
  {
    path: "/media/file/create",
    name: "media",
    exact: true,
    component: FormMediaFile,
    authentMenuName: "Medias"
  },
  {
    path: "/overall",
    name: "overall",
    exact: true,
    component: Overall,
    authentMenuName: "Dashboard"
  },
  {
    path: "/inspiration",
    name: "inspiration",
    exact: true,
    component: Inspiration,
    authentMenuName: "Idea"
  },
  {
    path: "/inspiration/inspiration/create",
    name: "inspiration-create",
    exact: true,
    component: FormInspi,
    authentMenuName: "Idea"
  },
  {
    path: "/inspiration/inspiration/:id",
    name: "grouptab",
    exact: true,
    component: FormInspi,
    authentMenuName: "Idea"
  },
  {
    path: "/inspiration/grouptab/create",
    name: "grouptab",
    exact: true,
    component: FormGrouptab,
    authentMenuName: "Idea"
  },
  {
    path: "/inspiration/grouptab/:id",
    name: "grouptab",
    exact: true,
    component: FormGrouptab,
    authentMenuName: "Idea"
  },
  {
    path: "/product",
    name: "products",
    exact: true,
    component: Product,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/product/create",
    name: "products",
    exact: true,
    component: FormProduct,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/product/:id",
    name: "products",
    exact: true,
    component: FormProduct,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/category/create",
    name: "categories",
    exact: true,
    component: FormCategoty,
    authentMenuName: "Manage Products"
  },

  {
    path: "/product/category/:id",
    name: "categories",
    exact: true,
    component: FormCategoty,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/color-type/create",
    name: "colortype",
    exact: true,
    component: FormColorType,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/color-type/:id",
    name: "colortype",
    exact: true,
    component: FormColorType,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/color/create",
    name: "color",
    exact: true,
    component: FormColor,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/color/:id",
    name: "color",
    exact: true,
    component: FormColor,
    authentMenuName: "Manage Products"
  },
  {
    path: "/settingprofile",
    name: "setting-profile",
    exact: true,
    component: SettingProfile,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/door-open-pattern/create",
    name: "door-open-pattern",
    exact: true,
    component: FormDoorOpenPattern,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/door-open-pattern/:id",
    name: "door-open-pattern",
    exact: true,
    component: FormDoorOpenPattern,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/installation/create",
    name: "installation",
    exact: true,
    component: FormInstallation,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/installation/:id",
    name: "installation",
    exact: true,
    component: FormInstallation,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/mirror/create",
    name: "mirror",
    exact: true,
    component: FormMirror,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/mirror/:id",
    name: "mirror",
    exact: true,
    component: FormMirror,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/log-device/create",
    name: "log-device",
    exact: true,
    component: FormLogDevice,
    authentMenuName: "Manage Products"
  },
  {
    path: "/product/log-device/:id",
    name: "log-device",
    exact: true,
    component: FormLogDevice,
    authentMenuName: "Manage Products"
  },
  // {
  //   path: "/product/aluminium/create",
  //   name: "aluminium",
  //   exact: true,
  //   component: FormAluminium,
  //   authentMenuName: "Manage Products"
  // },
  // {
  //   path: "/product/aluminium/:id",
  //   name: "aluminium",
  //   exact: true,
  //   component: FormAluminium,
  //   authentMenuName: "Manage Products"
  // },
  {
    path: "/project",
    name: "project",
    exact: true,
    component: Project,
    authentMenuName: "Project"
  },
  {
    path: "/project/project/create",
    name: "project-create",
    exact: true,
    component: FormProject,
    authentMenuName: "Project"
  },
  {
    path: "/project/project/:id",
    name: "project-edit",
    exact: true,
    component: FormProject,
    authentMenuName: "Project"
  },
  {
    path: "/project/group/create",
    name: "group-create",
    exact: true,
    component: FormGroupProject,
    authentMenuName: "Project"
  },
  {
    path: "/project/group/:id",
    name: "group-edit",
    exact: true,
    component: FormGroupProject,
    authentMenuName: "Project"
  },
  {
    path: "/article",
    name: "article",
    exact: true,
    component: Article,
    authentMenuName: "Article"
  },
  {
    path: "/article/article/create",
    name: "article-create",
    exact: true,
    component: FormArticle,
    authentMenuName: "Article"
  },
  {
    path: "/article/article/:id",
    name: "article-create",
    exact: true,
    component: FormArticle,
    authentMenuName: "Article"
  },
  {
    path: "/article/group/create",
    name: "group-create",
    exact: true,
    component: FormGroupArticle,
    authentMenuName: "Article"
  },
  {
    path: "/article/group/:id",
    name: "group-edit",
    exact: true,
    component: FormGroupArticle,
    authentMenuName: "Article"
  },

  {
    path: "/user",
    name: "user",
    exact: true,
    component: Users,
    authentMenuName: "User"
  },
  {
    path: "/user/user/create",
    name: "user-create",
    exact: true,
    component: FormUser,
    authentMenuName: "User"
  },
  {
    path: "/user/role/create",
    name: "role-create",
    exact: true,
    component: FormRole,
    authentMenuName: "User"
  },
  {
    path: "/user/user/edit/:id",
    name: "user-edit",
    exact: true,
    component: FormUserEdit,
    authentMenuName: "User"
  },
  {
    path: "/user/role/edit/:id",
    name: "role-edit",
    exact: true,
    component: FormRoleEdit,
    authentMenuName: "User"
  },
  {
    path: "/approve-management",
    name: "approve",
    exact: true,
    component: ApproveManage,
    authentMenuName: "Manage Approvals"
  },
  {
    path: "/approve-management/:id",
    name: "approve-create",
    exact: true,
    component: FormApprove,
    authentMenuName: "Manage Approvals"
  },
  {
    path: "/serve",
    name: "serve",
    exact: true,
    component: Serve,
    authentMenuName: "Setting"
  },
  {
    path: "/setting",
    name: "setting",
    exact: true,
    component: SettingPage,
    authentMenuName: "Setting"
  },

  {
    path: "/banner-management",
    name: "banner-management",
    exact: true,
    component: BannerManage,
    authentMenuName: "Manage Banners"
  },
  {
    path: "/banner-management/create",
    name: "banner-create",
    exact: true,
    component: FormBanner,
    authentMenuName: "Manage Banners"
  },
  {
    path: "/banner-management/:id",
    name: "banner-edit",
    exact: true,
    component: FormBanner,
    authentMenuName: "Manage Banners"
  },

  {
    path: "/product/group/:id",
    name: "group-edit",
    exact: true,
    component: FormGroup,
    authentMenuName: "Manage Products"

  },
  {
    path: "/product/list/:id",
    name: "group-edit",
    exact: true,
    component: FormGroup,
    authentMenuName: "Manage Products"
  },

];
