/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { _isEmpty } from "@yim/util-tool-ts";
import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";
import { IMarkPost } from "../../interface/IBanner.interface";
import { getProductCategoryMenuActive } from "../../services/api/product_category";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  currentMarker,
  listCurrentItemMarker,
} from "../../tools/recoil/recoil-atom";
import CardBoxInlineMarker from "./CardBoxInlineMarker";

type Props = {
  mapImg: string;
  initialMarkers?: IMarkPost;
  lang?: string;
};

const MapInspiration = ({ mapImg, initialMarkers, lang = "th" }: Props) => {
  const [markers, setMarkers] = useState([] as any);
  const [listItem, setListItem] = useState([] as any);
  const setListItemMarker = useSetRecoilState(listCurrentItemMarker);

  useEffect(() => {
    HandleGetListProduct();
    if (!_isEmpty(initialMarkers)) {
      handleGetMarkerList(initialMarkers);
    }
  }, [initialMarkers]);

  const handleGetMarkerList = (markers: any = []) => {
    const RefacterData = markers?.map((item: any, _idx: number) => {
      return {
        left: item?.x,
        top: item?.y,
        key: item?.x + item?.y,
        id: item?.product,
        color: item?.color,
      };
    });
    setMarkers(RefacterData);
  };

  const HandleGetListProduct = async () => {
    try {
      const res = await getProductCategoryMenuActive();
      const itemList = res.result[0]?.item;
      setListItem(itemList);
      if (res.result[0]?.item.length > 0) {
        setListItemMarker(itemList);
      } else {
        setListItemMarker([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleGetInitialValueItemProduct = (value: number) => {
    const result = listItem.find((item: any) => {
      return item.id === value;
    });

    return result;
  };

  const CustomMarker = (props: MarkerComponentProps | any) => {
    const [curentMarker, setCurrentMarker] = useRecoilState(currentMarker);
    const currentKey = props.top + props.left;
    const data = HandleGetInitialValueItemProduct(props.id);

    const colorMarkVertical = {
      borderLeft: `5px solid ${props?.color}`,
    };
    const colorMarkHorizontal = {
      borderBottom: `5px solid ${props?.color}`,
    };
    const classDesktop =
      curentMarker.key === currentKey
        ? curentMarker.value
          ? "detail--marker-click"
          : "detail--marker"
        : "detail--marker";

    return (
      <div className={classDesktop}>
        <div
          className={`alert-empty-marker-preview-ins`}
          style={
            Number(props.top) < 50 && Number(props.left) < 50
              ? { top: "0px", left: 50 }
              : Number(props.top) > 50 && Number(props.left) > 50
              ? { bottom: "0px", right: 50 }
              : Number(props.top) < 50 && Number(props.left) > 50
              ? { top: "0px", right: 50 }
              : Number(props.top) > 50 && Number(props.left) < 50
              ? { bottom: "0px", left: 50 }
              : Number(props.top) > 50
              ? { bottom: "0px" }
              : Number(props.left) > 50
              ? { right: 50 }
              : {}
          }
        >
          <CardBoxInlineMarker
            src={data?.cover_image?.file_path}
            title={
              lang === "th" ? data?.title_th : data?.title_en
            }
            price={data?.size?.price}
            textHeight={data?.size?.height}
            textWidth={data?.size?.width}
            lang={lang}
          />
        </div>
        <div
          style={
            Number(props.top) < 50 && Number(props.left) < 50
              ? { top: 8, left: 7, ...colorMarkVertical }
              : Number(props.top) > 50 && Number(props.left) > 50
              ? { bottom: 8, right: 7, ...colorMarkVertical }
              : Number(props.top) < 50 && Number(props.left) > 50
              ? { top: 8, right: 7, ...colorMarkVertical }
              : Number(props.top) > 50 && Number(props.left) < 50
              ? { bottom: 8, left: 8, ...colorMarkVertical }
              : Number(props.top) > 50
              ? { bottom: 8, ...colorMarkVertical }
              : Number(props.left) > 50
              ? { right: 7, ...colorMarkVertical }
              : {}
          }
          className="marker-line"
        />
        <div
          style={
            Number(props.top) < 50 && Number(props.left) < 50
              ? { top: 63, left: 8, ...colorMarkHorizontal }
              : Number(props.top) > 50 && Number(props.left) > 50
              ? { bottom: 64, right: 8, ...colorMarkHorizontal }
              : Number(props.top) < 50 && Number(props.left) > 50
              ? { top: 63, right: 8, ...colorMarkHorizontal }
              : Number(props.top) > 50 && Number(props.left) < 50
              ? { bottom: 63, left: 8, ...colorMarkHorizontal }
              : Number(props.top) > 50
              ? { bottom: 63, ...colorMarkHorizontal }
              : Number(props.left) > 50
              ? { right: 8, ...colorMarkHorizontal }
              : {}
          }
          className="marker-line-horizontal"
        />
        <div className="custom-marker">
          <span
            className="marker-custom"
            style={{
              backgroundColor: props?.color || "black",
            }}
            onClick={() => {
              if (curentMarker.key === currentKey) {
                setCurrentMarker({
                  key: curentMarker.key,
                  value: curentMarker?.value ? false : true,
                });
              } else {
                setCurrentMarker({
                  key: currentKey,
                  value: true,
                });
              }
            }}
          >
            <div
              style={{
                position: "relative",
                transform:
                  curentMarker.key === currentKey
                    ? curentMarker.value
                      ? "rotate(45deg)"
                      : ""
                    : "",
              }}
            >
              <div
                className="icon-vertical"
                style={{
                  borderRight:
                    props?.color === "white"
                      ? "2px solid black"
                      : "2px solid white",
                }}
              />
              <div
                className="icon-Horizontal"
                style={{
                  borderBottom:
                    props?.color === "white"
                      ? "2px solid black"
                      : "2px solid white",
                }}
              />
            </div>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ImageMarker
        src={mapImg}
        markers={markers}
        onAddMarker={(marker: Marker) => console.log("marker preview", marker)}
        markerComponent={CustomMarker}
      />
    </div>
  );
};

export default MapInspiration;
