import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import UploadImage from "./UploadImage";

type Props = {
  maxCount?: number | boolean;
  onChange?: (info: UploadChangeParam<UploadFile<any>>) => void;
  value?: any;
};
const FileSpaceMoreFile = ({
  onChange,
  value = [],
  maxCount = false,
}: Props) => {
  return <UploadImage onChange={onChange} value={value} maxCount={maxCount} />;
};

export default FileSpaceMoreFile;
