import { Button, Col, Form, Input, Row } from "antd";
import { Dispatch, SetStateAction, useState } from "react";
import { IMediaCategory } from "../../interface/IMedia.interface";
import { postMediaImage } from "../../services/api/media";
import { IResStatus } from "../../tools/fecth/interface";
import { _appendFields } from "../../tools/util";
import { openNotification } from "../notification/Notification";
import Selector from "../selector/Selector";
type Props = {
  mediaCategoryList: IMediaCategory[];
  setTabSelected: Dispatch<SetStateAction<string>>;
  handleGetMedia: () => void;
};
const URL = ({ mediaCategoryList, setTabSelected, handleGetMedia }: Props) => {
  const [imageUrl, setImageUrl] = useState("");

  const [form] = Form.useForm();

  const hadleUploadImage = async (values: any) => {
    try {
      const data = _appendFields({
        ...values,
      });
      const res: IResStatus = await postMediaImage(data);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        form.resetFields();
        setImageUrl("");
        handleGetMedia();
        setTabSelected("1");
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Form layout="vertical" onFinish={hadleUploadImage}>
        <Row gutter={[14, 14]}>
          <Col lg={24}>
            <Form.Item
              label="ที่อยู่รูปภาพ"
              name="url"
              rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
            >
              <Input onChange={(e) => setImageUrl(e.target.value)} />
            </Form.Item>
          </Col>
          {imageUrl !== "" && (
            <Col lg={24}>
              <div>
                ตัวอย่างภาพ :<br />
                <img src={imageUrl} className="image-render" alt="media-list" />
              </div>
            </Col>
          )}
          <Col lg={12}>
            <Form.Item
              label="ชื่อมีเดีย"
              name="media_name"
              rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12}>
            <Form.Item
              label="ประเภทมีเดีย"
              name="media_category_id"
              rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
            >
              <Selector
                Source={mediaCategoryList}
                keyValue="id"
                keyName="name"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="pt-1">
          <Button type="primary" className="mr-1" htmlType="submit">
            บันทึก
          </Button>
          <Button
            onClick={() => {
              setImageUrl("");
            }}
            htmlType="reset"
          >
            ล้าง
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default URL;
