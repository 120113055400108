import { Col, Row } from "antd";
export interface headerProps {
    title: string;
    message?: string;
    onClick?:(dta?:any) => void;
    href?:string;
    className?:string
  }
const HeaderSlide = ({
  title = "",
  message,
  className = "my-3",
}: headerProps) => {
  return (
      <Row
        justify={"start"}
        align="top"
        className={className}
      >
        <Col>
          <div className="text-3xl font-bold" style={{ color: "#FF6B0B" }}>
            {title}
          </div>
          <div className="text-gray-400">{message ? message : ""}</div>
        </Col>
      </Row>
  );
};

export default HeaderSlide;
