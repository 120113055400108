import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Pagination,
  Popconfirm,
  Row,
  Spin,
} from "antd";
import { useRecoilState } from "recoil";
import {
  IMedia,
  IMediaCategory,
  IMediaSelect,
} from "../../../interface/IMedia.interface";
import { delMediaCategory, putMedia } from "../../../services/api/media";
import { IResStatus } from "../../../tools/fecth/interface";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import { openNotification } from "../../notification/Notification";
import Selector from "../../selector/Selector";

type Props = {
  lg?: number;
  onModal?: () => void;
  mediaList?: IMedia[];
  mediaCategoryList: IMediaCategory[];
  setMediaSelect?: Dispatch<SetStateAction<IMediaSelect>>;
  setGellery?: Dispatch<SetStateAction<any>>;
  gallery?: any[];
  onChange?: any;
  handleSelectMedia?: any;
  handleGetMedia: (name?: string | any, id?: number) => void;
  total: number;
};
const Container = ({
  lg = 4,
  onModal,
  mediaList,
  mediaCategoryList,
  setMediaSelect,
  setGellery,
  gallery,
  onChange,
  handleGetMedia,
  handleSelectMedia,
  total,
}: Props) => {
  const [imageValue, setImageValue] = useState({ name: "", id: 0 });
  const [loading] = useRecoilState(loadingState);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState({});
  const handleChangeValue = (name: string, id: number) => {
    setImageValue({ name: name, id: id });
  };
  const [form] = Form.useForm();

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, [currentPage, limitPage]);

  const handleGetData = () => {
    let page = { page: currentPage, limit: limitPage, ...search };
    handleGetMedia(page);
  };

  const handleSelect = (data: any) => {
    if (setMediaSelect)
      setMediaSelect({
        file: data.file_path,
        id: data.id,
        media_name: data.media_name,
      });
    onModal && onModal();
    setGellery &&
      handleAdd({
        file: data.file_path,
        id: data.id,
        media_name: data.media_name,
      });
    onChange && onChange(data);
    handleSelectMedia &&
      handleSelectMedia({
        file: data.file_path,
        id: data.id,
        media_name: data.media_name,
      });
  };

  const handleAdd = (data: IMediaSelect) => {
    if (gallery && setGellery) {
      setGellery([...gallery, data]);
    }
  };

  const handleUpdateMediaObject = async () => {
    try {
      const res: IResStatus = await putMedia(
        { media_name: imageValue.name },
        imageValue.id
      );
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetMedia();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMediaObject = async () => {
    try {
      const res: IResStatus = await delMediaCategory(imageValue.id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetMedia();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const searchMedia = async (searchMedia: any) => {
    handleGetMedia({ ...searchMedia, page: 1 });
    setSearch({ ...searchMedia });
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <Spin spinning={loading} size="large" tip="กำลังโหลด..." delay={500}>
        <Form onFinish={searchMedia} form={form}>
          <Row gutter={[14, 14]}>
            <Col lg={8}>
              <Form.Item name="media_name">
                <Input placeholder="ค้นหาชื่อรูปภาพ" />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item name="media_category_id">
                <Selector
                  Source={mediaCategoryList}
                  keyValue="id"
                  keyName="name"
                />
              </Form.Item>
            </Col>
            <Col lg={5}>
              <Button className="mr-1 btn-search" htmlType="submit" block>
                <SearchOutlined className="search-icons" /> ค้นหา
              </Button>
            </Col>
            <Col lg={4}>
              <Form.Item>
                <Button
                  className="clear"
                  htmlType="button"
                  block
                  onClick={onReset}
                >
                  ล้างข้อมูล
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row gutter={[14, 14]} className="mt-1">
          {mediaList &&
            mediaList.map((item, ind) => (
              <Col lg={lg} key={ind}>
                <div className="media-focus cursor">
                  <div className="icon-media-format">
                    <Popconfirm
                      onConfirm={handleUpdateMediaObject}
                      title={
                        <>
                          <Input
                            value={imageValue.name}
                            className="media-container"
                            onChange={(e) =>
                              handleChangeValue(e.target.value, item.id)
                            }
                          />
                        </>
                      }
                      okText="บันทึก"
                      cancelText="ยกเลิก"
                    >
                      <EditOutlined
                        className="media-icon-block"
                        onClick={() =>
                          setImageValue({ name: item.media_name, id: item.id })
                        }
                      />
                    </Popconfirm>

                    <Popconfirm
                      onConfirm={handleDeleteMediaObject}
                      title={`คุณต้องการลบ ${item.media_name} หรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้`}
                      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                      okText="ยืนยัน"
                      cancelText="ยกเลิก"
                    >
                      <DeleteOutlined
                        className="ml-05 media-icon-block"
                        onClick={() =>
                          setImageValue({ name: item.media_name, id: item.id })
                        }
                      />
                    </Popconfirm>
                  </div>
                  <img
                    src={item.file_path}
                    alt={item.media_name}
                    className="w-100 img-round img-cover blur h-12rem"
                    onClick={() => handleSelect(item)}
                  />
                </div>

                <div className="pt-05 cut-text-2-row">{item.media_name}</div>
              </Col>
            ))}
          <div className="p-1 w-100 d-flex justify-flex-end">
            <div className="d-flex items-center pr-1">แสดงรายการ</div>

            <Pagination
              showSizeChanger
              onChange={(page) => onChangePage && onChangePage(page, "page")}
              total={total}
              pageSize={limitPage}
              current={currentPage}
              className="pagi"
              onShowSizeChange={(_current, pageSize) =>
                onChangePage && onChangePage(pageSize, "pageSize")
              }
            />
          </div>
        </Row>
      </Spin>
    </div>
  );
};

export default Container;
