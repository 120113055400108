/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Menu, Modal, Row, Col, Card, Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { IProductCategoryMenu } from "../../interface/IProductCategory.interface";
import {
  getInspirationCategoryGetListByID,
} from "../../services/api/inspiration_category";
import {
  getInspirationListActive,
} from "../../services/api/inspiration";
import { _isEmpty } from "@yim/util-tool-ts";
import { openNotification } from "../notification/Notification";

type Props = {
  onClose: Function;
  onSave: Function;
  initialValue: number;
};

const ModalProduct = ({ onSave, onClose, initialValue }: Props) => {
  const [productSelected, setProductSelected] = useState({} as any);
  const [listMenuProduct, setListMenuProduct] = useState<
    IProductCategoryMenu[]
  >([]);
  const [listItem, setListItem] = useState([] as any);

  useEffect(() => {
    HandleGetMenuProductList();
    HandleGetListProduct();
  }, []);

  const HandleGetInitialValueItemProduct = (value: number, listItem: any) => {
    const result = listItem.find((item: any) => {
      return item.id === value
    });
    setProductSelected({
      key: result?.id,
      image: result?.media?.file_path,
      title: result?.title_th,
      description: result?.content_th,
      price: "",
    });
  }

  const HandleGetMenuProductList = async () => {
    try {
      const res = await getInspirationCategoryGetListByID();
      if (res.code === 200) {
        setListMenuProduct(res.result[0]?.item);
      } else {
        openNotification({ method: "PUT_ERROR", message: res?.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleGetListProduct = async (id?: number) => {
    const insertValue = {
      inspiration_category_id: !_isEmpty(id) ? id : "",
    };
    try {
      const res = await getInspirationListActive(insertValue);
      const itemList = res.result[0]?.item;
      setListItem(itemList);
      if (!_isEmpty(initialValue)) {
        HandleGetInitialValueItemProduct(initialValue, itemList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectProduct = async (key: number) => {

    const result = await listItem.find((item: any) => {
      return item.id === key
    });

    setProductSelected({
      key: result?.id,
      image: result?.media?.file_path,
      title: result?.title_th,
      description: result?.content_th,
      price: "",
    });
  };

  const ListItemMenu = () => {
    return (
      listMenuProduct &&
      listMenuProduct?.map((item: any, index: number) => (
        <Menu.Item
          onClick={() => HandleGetListProduct(item?.id)}
          key={`sub-${index}`}
          className="tw-b c-grey d-flex justify-between"
        >
          {`${item?.name_th}`}
        </Menu.Item>
      ))
    );
  };

  const ListItemCard = () => {
    return (
      listItem &&
      listItem.map((item: any, index: number) => {
        return (
          <Col lg={6} key={index} className="mb-1">
            {productSelected.key === item.id ? (
              <div className='icon-true-check'>
                <CheckOutlined />
              </div>
            ) : null}
            <Card
              onClick={() => handleSelectProduct(item?.id)}
              hoverable={true}
              cover={
                    <img
                      src={item?.media?.file_path}
                      alt={item?.title_th}
                      width={'100%'}
                      height={200}
                      style={{objectFit:'cover'}}
                    />
                }
            >
              <div style={{ height: 60 }}>
                <h5 className="c-black text-description-custom">
                  {item?.title_th}
                </h5>
                <h6 className="c-grey text-description-custom ">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.content_th,
                    }}
                  />
                </h6>
              </div>
            </Card>
          </Col>
        );
      })
    );
  };

  return (
    <div>
      <div className="m-1">
        <div className="d-flex justify-end mr-2">
          <Modal
            className="boder-radius"
            title={<h3 className="tw-b">รายการ</h3>}
            visible={true}
            onCancel={() => onClose()}
            width={1300}
            footer={null}
            style={{ top: 15, paddingBottom: 0 }}
          >
            <Row>
              <Col lg={5}>
                <Menu
                  onClick={(e) => console.log("menuMom", e)}
                  style={{ width: 256 }}
                  defaultSelectedKeys={["sub-all"]}
                  defaultOpenKeys={["sub-all"]}
                  mode="inline"
                >
                  <Menu.Item
                    key={"sub-all"}
                    className="tw-b c-grey d-flex justify-between"
                    onClick={() => HandleGetListProduct()}
                  >
                    All
                  </Menu.Item>
                  {ListItemMenu()}
                </Menu>
              </Col>
              <Col lg={19} className="p-3">
                <Row gutter={18}>{ListItemCard()}</Row>
              </Col>
            </Row>
            <Row justify="end">
              <Button className="btn-save" onClick={() => onSave(productSelected)}>
                บันทึก
              </Button>
            </Row>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ModalProduct;
