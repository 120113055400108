import React, { useEffect, useState } from "react";
import { Button, Col, Radio, Row } from "antd";
import ArticleTab from "./articletab/ArticleTab";
import ArticleHeader from "./articletab/Headers";
import ArticleGroupHeader from "./grouptab/Headers";

import { PlusOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { openNotification } from "../../component/notification/Notification";
import { loadingState } from "../../tools/recoil/recoil-atom";
import { useSetRecoilState } from "recoil";
import { Dataset } from "../../interface/IArticle.interface";

//----------------------api-------------------------//
import {
  getArticleList,
  deleteArticleList,
  putArticleActive,
} from "../../services/api/article";
import GroupTab from "./grouptab/GroupTab";
import {
  getArticleCategory,
  putArticleCategorytActive,
} from "../../services/api/article_category";

const Article: React.FC = () => {
  const [datatest, setDatatest] = useState<Dataset[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [tabSelected, setTabSelected] = useState<string>("1");
  const [groupList, setGroupList] = useState<any[]>([]);
  const [groupLimitPage, setGroupLimitPage] = useState<number>(10);
  const [groupCurrentPage, setGroupCurrentPage] = useState<number>(1);
  const [groupTotal, setGroupTotal] = useState<number>(1);
  const location: any = useLocation();
  const pathState = location.state;

  const setLoading = useSetRecoilState<boolean>(loadingState);
  const history = useHistory();

  useEffect(() => {
    handleGetArticle();
    handleGetArticleGroupList();
    CheckTab(tabSelected);
    // eslint-disable-next-line
  }, [currentPage, limitPage, groupCurrentPage, groupLimitPage]);

  const CheckTab = (tab?: string) => {
    if (pathState) {
      setTabSelected(pathState?.tabSelected.toString());
    } else {
      setTabSelected(tab ? tab : "1");
    }
  };
  const handleGetArticle = async (search?: any) => {
    try {
      setLoading(true);
      const data = {
        page: currentPage,
        limit: limitPage,
        ...search,
      };
      const res = await getArticleList(data);
      if (res.code === 200) {
        setDatatest(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleGetArticleGroupList = async (search?: any) => {
    const query = {
      page: groupCurrentPage,
      limit: groupLimitPage,
      orderby: "DESC",
      ...search,
    };
    try {
      setLoading(true);
      const res = await getArticleCategory(query);
      if (res.code === 200) {
        setGroupList(res.result[0].item);
        setGroupTotal(res.result[0].total);
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleChangeSwitchArticle = async (source: any) => {
    try {
      setLoading(true);
      const res = await putArticleActive(source.id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetArticle();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const handleChangeSwitchArticleGroup = async (source: any) => {
    try {
      setLoading(true);
      const res = await putArticleCategorytActive(source.id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetArticleGroupList();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const searchArticleList = (values: any) => {
    if (tabSelected === "1") {
      setCurrentPage(1);
      handleGetArticle(values);
    } else {
      setGroupCurrentPage(1);
      handleGetArticleGroupList(values);
    }
  };

  const handleDeleteArticle = async (id: any) => {
    try {
      setLoading(true);
      const res = await deleteArticleList(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetArticle();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onChangePage = (page: number, type?: string) => {
    if (tabSelected === "1") {
      if (type === "pageSize") {
        setLimitPage(page);
      } else {
        setCurrentPage(page);
      }
    } else {
      if (type === "pageSize") {
        setGroupLimitPage(page);
      } else {
        setGroupCurrentPage(page);
      }
    }
  };

  const onCheckAfterDelete = (tab: string) => {
    if (tab === "2") {
      handleGetArticleGroupList();
    } else {
      handleGetArticle();
    }
  };

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">บทความ</span>
        </Col>
        <Col lg={2}>
          <Button
            className="btn-create"
            type="primary"
            onClick={() =>
              history.push(
                tabSelected === "1"
                  ? "/article/article/create"
                  : "/article/group/create"
              )
            }
          >
            <PlusOutlined className="icons-size" /> สร้าง
          </Button>
        </Col>
      </Row>
      <div className="set-layout">
        {tabSelected === "1" && <ArticleHeader onFinish={searchArticleList} />}
        {tabSelected === "2" && (
          <ArticleGroupHeader onFinish={searchArticleList} initialValue={""} />
        )}

        <div className="pt-1">
          <Radio.Group
            // defaultValue="1"
            value={tabSelected}
            onChange={(e) => setTabSelected(e.target.value)}
          >
            <Radio.Button value="1" className="first-child">
              บทความ
            </Radio.Button>
            <Radio.Button value="2" className="last-child">
              หมวดหมู่
            </Radio.Button>
          </Radio.Group>
          {tabSelected === "1" && (
            <ArticleTab
              handleChangeSwitch={handleChangeSwitchArticle}
              dataSource={datatest}
              handleDeleteClick={handleDeleteArticle}
              onChangePage={onChangePage}
              total={total}
              currentPage={currentPage}
              limitPage={limitPage}
            />
          )}
          {tabSelected === "2" && (
            <GroupTab
              config={{
                total: groupTotal,
                pageSize: groupLimitPage,
                currentPage: groupCurrentPage,
              }}
              onChangePage={onChangePage}
              HandleOnCheck={onCheckAfterDelete}
              dataSource={groupList}
              handleChangeSwitch={handleChangeSwitchArticleGroup}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Article;
