import React from "react";
import { Col, Row } from "antd";
import Fields from "./Fields";

const Form = () => {
  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">มีเดีย</span>
        </Col>
      </Row>
      <div className="set-layout">
        <div className="pt-1">
          <Fields />
        </div>
      </div>
    </div>
  );
};

export default Form;
