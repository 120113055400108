export const data = [
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 1,
        group: [
            {
                id: 1,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 1,
        group: [
            {
                id: 1,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 1,
        group: [
            {
                id: 1,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 1,
        group: [
            {
                id: 1,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 1,
        group: [
            {
                id: 1,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    }, {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 1,
        group: [
            {
                id: 1,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 1,
        group: [
            {
                id: 1,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
    {
        name_en: "Door & Windows",
        name_th: "Door & Windows",
        id: 2,
        group: [
            {
                id: 2,
                list: [
                    {
                        id: 1,
                        name_en: "Slidnig System",
                        name_th: "Slidnig System",
                    },
                ],
            },
        ],
    },
]