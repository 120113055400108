import { Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { HandleScroll } from "../../tools/util";
type Props = {
  text: string;
  length?: number;
  more?: boolean;
  className?: string;
  autoHeight?: boolean;
  lang?: string;
};
const SmartText = ({
  text = "",
  more = true,
  length = 500,
  className = "des-detail-article",
  lang = "th",
}: Props) => {
  const [showLess, setShowLess] = useState(true);
  const myRef = useRef(null) as any;
  const [elemHeight, setElemHeight] = useState<number>(0);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setElemHeight(entry?.target?.clientHeight);
      });
    });
    if (myRef.current) {
      observer.observe(myRef.current);
    }
    /////clean state for close warning memory leak
    return () => {
      observer.disconnect();
    };
  }, [text]);

  return (
    <div
      className={more && text?.length > length ? "py-5" : "py-3"}
      id="top-less"
    >
      <div
        style={{
          height: showLess && elemHeight > length ? length : "100%",
          overflow: showLess && elemHeight > length ? "hidden" : "unset",
        }}
      >
        <div
          ref={myRef}
          dangerouslySetInnerHTML={{
            __html:
              more && text?.length > length
                ? showLess
                  ? `${text.slice(0, length)}...`
                  : text
                : text,
          }}
          className={className}
        />
      </div>
      {elemHeight > length && showLess && (
        <div className={"border-fade-html-tag"} />
      )}
      {more && (elemHeight > length || text?.length > length) && (
        <Row justify="center" align="middle" className={"mt-3"}>
          <div
            className="btn-more"
            onClick={() => {
              if (!showLess) {
                HandleScroll("top-less", true);
              }
              setShowLess(!showLess);
            }}
          >
            {showLess
              ? lang === "th"
                ? "ดูเพิ่มเติม"
                : "View More"
              : lang === "th"
              ? "ดูน้อยลง"
              : "View Less"}
          </div>
        </Row>
      )}
    </div>
  );
};

export default SmartText;
