import React, { useState } from "react";
import Modaler from "../../../component/modaler/Modaler";
import FormModal from "../FormModal";
import { Form } from "antd";
import MediaContainer from "./Container";
import { IMedia } from "../../../interface/IMedia.interface";
import { IResStatus } from "../../../tools/fecth/interface";
import {
  delMediaCategory,
  getMedia,
  getMediaCategory,
  putMedia,
} from "../../../services/api/media";
import { openNotification } from "../../../component/notification/Notification";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../tools/recoil/recoil-atom";

type Props = {
  filterSearchImage: any;
  setMediaCategoryList: any;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const Container = ({
  filterSearchImage,
  setMediaCategoryList,
  currentPage,
  setCurrentPage,
}: Props) => {
  const [mediaId, setMediaId] = useState<number>(1);
  const [initialValue, setIntialValue] = useState<any>();
  const [form] = Form.useForm();
  const [mediaList, setMediaList] = useState<IMedia[]>([]);
  const [total, setTotal] = useState<number>(10);
  const [firstWorks, setFirstWorks] = useState<boolean>(true);
  const [limitPage, setLimitPage] = useState<number>(18);
  const [visibleEdit, setVisibleEdit] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [mediaAction, setMediaAction] = useState({ type: "", actionName: "" });
  const setLoading = useSetRecoilState(loadingState);

  React.useEffect(() => {
    form.setFieldsValue({
      media_name: initialValue?.media_name,
      media_category_id: initialValue?.media_category_id
    });
    // eslint-disable-next-line
  }, [initialValue]);

  React.useEffect(() => {
    if (firstWorks) handleMediCategory();
    setFirstWorks(false);

    handleGetMediaImage();
    // eslint-disable-next-line
  }, [filterSearchImage, limitPage, currentPage]);

  const onModal = (modal: { type: string; actionName: string }) => {
    setMediaAction({ ...modal });
    if (modal.type === "delete") {
      setVisibleDelete(!visibleDelete);
    } else {
      setVisibleEdit(!visibleEdit);
    }
  };

  const onOK = () => {
    form.submit();
  };

  const onDelete = () => {
    handleDeleteMedia();
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const handleGetMediaImage = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await getMedia({
        ...filterSearchImage,
        page: currentPage,
        limit: limitPage,
        directory: "media",
      });
      if (res.code === 200) {
        setMediaList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleMediCategory = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await getMediaCategory();
      if (res.code === 200) {
        setMediaCategoryList(res.result);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleUpdateTitle = async (values: any) => {
    try {
      const res: IResStatus = await putMedia(values, mediaId);
      if (res.code === 200) {
        handleGetMediaImage();
        openNotification({ method: "PUT_SUCCESS", message: res.message });
      } else {
        openNotification({
          method: "PUT_ERROR",
        });
      }
    } catch (error) {
      console.log(error);
    }
    onCancel();
  };

  const handleDeleteMedia = async () => {
    try {
      const res: IResStatus = await delMediaCategory(mediaId);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetMediaImage();
      } else {
        openNotification({
          method: "DEL_ERROR",
        });
      }
    } catch (error) {
      console.log(error);
    }
    onCancel();
  };

  const onCancel = () => {
    setVisibleEdit(false);
    setVisibleDelete(false);
  };

  return (
    <div>
      <Modaler
        visible={visibleEdit}
        onCancel={onCancel}
        onOk={onOK}
        header="แก้ไขชื่อรูปภาพ"
        width="30%"
      >
        <FormModal
          name="ภาพ"
          form={form}
          onFinish={handleUpdateTitle}
          initialValue={initialValue}
        />
      </Modaler>

      <Modaler
        visible={visibleDelete}
        onCancel={onCancel}
        onOk={onDelete}
        width="25%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            คุณแน่ใจไหม?
          </div>
        }
      >
        คุณต้องการลบ {mediaAction.actionName} หรือไม่
        กระบวนการนี้ไม่สามารถยกเลิกได้
      </Modaler>

      <MediaContainer
        onModal={onModal}
        lg={4}
        mediaList={mediaList}
        setMediaId={setMediaId}
        setIntialValue={setIntialValue}
        total={total}
        onChangePage={onChangePage}
        limitPage={limitPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default Container;
