import React from "react";
import { Button, Modal } from "antd";

type Props = {
  onOk: () => void;
  okText?: string;
  cancelText?: string;
  // onModal?: ([key]?: any, [key2]?: any, [key3]?: any) => void;
  onCancel: () => void;
  visible?: boolean;
  children?: React.ReactNode;
  header?: any;
  width?: string;
  footer?: boolean;
  closable?: boolean;
  loadingButton?: boolean;
};
const Modaler = ({
  onOk,
  // onModal,
  onCancel,
  loadingButton,
  header = " ",
  visible,
  children,
  width = "50%",
  footer = true,
  closable = true,
  okText = "ตกลง",
  cancelText = "ยกเลิก",
}: Props) => {
  return (
    <div>
      {footer ? (
        <Modal
          title={header}
          onCancel={onCancel}
          visible={visible}
          width={width}
          centered
          closable={closable}
          footer={[
            <Button htmlType="submit" onClick={onCancel}>
              {cancelText}
            </Button>,
            <Button
              className="button-save-modol"
              htmlType="submit"
              onClick={onOk}
              loading={loadingButton}
            >
              {okText}
            </Button>,
          ]}
        >
          {children}
        </Modal>
      ) : (
        <Modal
          title={header}
          visible={visible}
          onCancel={onCancel}
          width={width}
          centered
          onOk={onOk}
          okText="ยืนยัน"
          cancelText="ยกเลิก"
          closable={closable}
          footer={
            <>
              <Button onClick={onOk} type="primary">
                OK
              </Button>
            </>
          }
        >
          {children}
        </Modal>
      )}
    </div>
  );
};

export default Modaler;
