import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from '../../tools/util'


export const getProducts = async (data?: any) => {
    const qs = createQueryString(data);
    const res = await Fecth({
        method: "GET",
        path: `${version}/products${qs}`,
    })
    return res
}


export const getProductsCheckSKU = async (data?: any) => {
    const qs = createQueryString(data);
    const res = await Fecth({
        method: "GET",
        path: `${version}/products/check-sku${qs}`,
    })
    return res
}




export const getProductsById = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/products/${id}`,
    })
    return res
}



export const updateActiveProducts = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/products/active/${id}`,
    })
    return res
}
export const postProducts = async (data: any) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/products`,
    })
    return res
}
export const putProducts = async (data: any, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/products/${id}`,
    })
    return res
}


export const delProducts = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/products/${id}`,
    })
    return res
}