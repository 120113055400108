import { PlusOutlined } from "@ant-design/icons";
import { _isEmpty } from "@yim/util-tool-ts";
import { Button, Card, Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { openNotification } from "../../../component/notification/Notification";
import {
  getProductsGroup,
  postProductsGroup,
  putProductsGroup,
} from "../../../services/api/product_group";
import { IResStatus } from "../../../tools/fecth/interface";
import FormDragDrop from "./_formdragdrop/index";
import { useRecoilState, useSetRecoilState } from "recoil";
import { data } from "./GroupData";
import {
  dragState,
  editState,
  loadingState,
} from "../../../tools/recoil/recoil-atom";
import { availableID } from "../../../tools/util";

const Group = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [pathID, setPathID] = useState<boolean>(false);
  const [first, setFirst] = useState<boolean>(true);

  // const [dragState, setDragState] = useState<boolean>(false);

  const [modalDetal, setModalDetail] = useState<any>({});
  const [titleTH, setTitleTH] = useState<string>("");
  const [titleEN, setTitleEN] = useState<string>("");
  const [groupID, setGroupID] = useState<number>(0);
  const [categoryList, setCategoryList] = useState<any[]>(data);
  const setLoading = useSetRecoilState(loadingState);
  const [toggleEdit, setToggleEdit] = useRecoilState(editState);
  const [toggleDrag, setToggleDrag] = useRecoilState(dragState);

  const categoryRef = useRef(categoryList);

  const setCategoryRef = (data: any[]) => {
    categoryRef.current = data;
    setCategoryList(data);
  };

  useEffect(() => {
    handleGetGroup();
    // eslint-disable-next-line
  }, []);

  const handleGetGroup = async () => {
    try {
      const res: IResStatus = await getProductsGroup();
      if (res.code === 200) {
        setPathID(res.result.length > 0 ? true : false);
        setCategoryRef(res.result);
        setFirst(false);
      }
    } catch (error) {}
  };
  const handleSetCategoryList = (newState: any) => {
    setCategoryRef(newState);
  };

  const handleCreateGroup = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await postProductsGroup(categoryRef.current);
      if (res.code === 200) {
        setCategoryRef(res.result[0].category);
        openNotification({ method: "POST_SUCCESS", message: res.message });
        setToggleEdit(true);
        setToggleDrag(true);
        window.location.reload();
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
      setLoading(false);
    } catch (error) {}
  };

  const handleUpdateGroup = async () => {
    setLoading(true);

    try {
      const res: IResStatus = await putProductsGroup(categoryRef.current);
      if (res.code === 200) {
        setCategoryRef(res.result[0].category);
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        setToggleEdit(true);
        setToggleDrag(true);
        window.location.reload();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
      setLoading(false);
    } catch (error) {}
  };
  const handleSetList = (state: any, idGroup: number, idCate: number) => {
    const sortNewList = categoryRef.current.map((category: any) => {
      if (category[availableID(category)] === idCate) {
        let result = category.group.map((group: any) => {
          if (group.idGroup === idGroup) {
            return { ...group, list: state };
          }
          return group;
        });
        return { ...category, group: result };
      }
      return category;
    });
    setCategoryRef(sortNewList);
  };

  const handleSetGroupList = (state: any, idCate: number) => {
    const sortNewGroup = categoryRef.current.map((item: any) => {
      if (item.id === idCate) {
        return { ...item, group: state };
      }
      return item;
    });

    setCategoryRef(sortNewGroup);
  };

  const onDelete = (action?: any) => {
    if (action.type === "category") onDeleteCategory(action.idCate);
    else if (action.type === "group")
      onDeleteGroup(action.idCate, action.idGroup);
    else if (action.type === "list")
      onDeleteList(action.idCate, action.idGroup, action.idList);
  };

  const onModal = (action?: any) => {
    if (_isEmpty(action)) {
      setVisible(!visible);
      return;
    }

    if (action.type === "category") {
      setModalDetail({
        topic: "หมวดหมู่",
        ...action,
      });
      setTitleTH(action.titleTH);
      setTitleEN(action.titleEN);
    } else if (action.type === "group") {
      setModalDetail({
        topic: "กลุ่ม",
        ...action,
      });
      setGroupID(action.idGroup);
    } else if (action.type === "list") {
      setModalDetail({
        topic: "รายการ",
        ...action,
      });

      setTitleTH(action.titleTH);
      setTitleEN(action.titleEN);
    }
    setVisible(!visible);
  };

  const onOK = () => {
    if (modalDetal.functional === "create" && modalDetal.type === "category")
      onCreateCategory();
    else if (modalDetal.functional === "create" && modalDetal.type === "group")
      onCreateGroup();
    else if (modalDetal.functional === "create" && modalDetal.type === "list")
      onCreateList();
    else if (modalDetal.functional === "edit" && modalDetal.type === "category")
      onEditCategory();
    else if (modalDetal.functional === "edit" && modalDetal.type === "group")
      onEditGroup();
    else if (modalDetal.functional === "edit" && modalDetal.type === "list")
      onEditList();

    onModal();
  };

  const onEditCategory = () => {
    const newList = categoryRef.current.map((category: any) => {
      if (category[availableID(category)] === modalDetal.idCate) {
        return { ...category, name_th: titleTH, name_en: titleTH };
      }
      return category;
    });
    setCategoryRef(newList);
  };

  const onEditGroup = () => {
    const newList = categoryRef.current.map((category: any) => {
      if (modalDetal.idCate === category[availableID(category)]) {
        const groupList = category.group.map((group: any) => {
          if (modalDetal.idGroup === group.id) {
            return { ...group, id: groupID };
          }
          return group;
        });
        return {
          ...category,
          group: groupList,
        };
      }
      return category;
    });

    setCategoryRef(newList);
  };

  const onEditList = () => {
    const newList = categoryRef.current.map((category: any) => {
      if (modalDetal.idCate === category[availableID(category)]) {
        const groupList = category.group.map((group: any) => {
          if (modalDetal.idGroup === group.id) {
            const list = group.list.map((lister: any) => {
              if (modalDetal.idList === lister.id) {
                return { ...lister, name_th: titleTH, name_en: titleEN };
              }
              return lister;
            });
            return { ...group, list: list };
          }
          return group;
        });
        return {
          ...category,
          group: groupList,
        };
      }
      return category;
    });

    setCategoryRef(newList);
  };

  const onCancel = () => {
    setTitleTH("");
    setTitleEN("");

    onModal();
  };

  const handleSetDetailCreateCategory = () => {
    onModal({
      type: "category",
      functional: "create",
      titleTH: titleTH,
      titleEN: titleEN,
    });
  };

  const onCreateCategory = () => {
    if (categoryRef.current.length >= 30) {
      openNotification({
        type: "error",
        message: "ไม่สามารถสร้างเพิ่มได้หมวดหมู่",
        description: "สร้างหมวดหมู่ได้ไม่เกิน 30 รายการ",
        custom: true,
      });
      return;
    }
    const newCategory = {
      idx: categoryRef.current.length + 1,
      name_en: titleEN,
      name_th: titleTH,
      group: [],
    };
    setCategoryRef([...categoryRef.current, newCategory]);
    setTitleEN("");
    setTitleTH("");
  };

  const onCreateGroup = () => {
    const newList = categoryRef.current.map((category: any) => {
      if (modalDetal.idCate === category[availableID(category)]) {
        const newGroup = {
          id: groupID,
          list: [],
          notCrete: true,
        };

        return {
          ...category,
          group: [...category.group, newGroup],
        };
      }
      return category;
    });

    setCategoryRef(newList);
  };

  const onCreateList = () => {
    const newList = categoryRef.current.map((category: any) => {
      if (modalDetal.idCate === category[availableID(category)]) {
        const groupList = category.group.map((group: any) => {
          if (modalDetal.idGroup === group.id) {
            const newList = {
              name_en: titleEN,
              name_th: titleTH,
            };
            return { ...group, list: [...group.list, newList] };
          }
          return group;
        });
        return {
          ...category,
          group: groupList,
        };
      }
      return category;
    });

    setCategoryRef(newList);
    setTitleEN("");
    setTitleTH("");
  };

  const onDeleteCategory = async (idCate: number) => {
    const newList = categoryRef.current.filter((category: any) => {
      return category[availableID(category)] !== idCate;
    });
    setCategoryRef(newList);
  };

  const onDeleteGroup = async (idCate: number, idGroup: number) => {
    const newList = categoryRef.current.map((category: any) => {
      if (idCate === category[availableID(category)]) {
        const groupList = category.group.filter((group: any) => {
          return idGroup !== group.id;
        });
        return {
          ...category,
          group: groupList,
        };
      }
      return category;
    });
    setCategoryRef(newList);
  };

  const onDeleteList = async (
    idCate: number,
    idGroup: number,
    idList: number
  ) => {
    const newList = categoryRef.current.map((category: any) => {
      if (idCate === category[availableID(category)]) {
        const groupList = category.group.map((group: any) => {
          if (idGroup === group.id) {
            const list = group.list.filter((lister: any) => {
              return idList !== lister.id;
            });
            return { ...group, list: list };
          }
          return group;
        });
        return {
          ...category,
          group: groupList,
        };
      }
      return category;
    });
    setCategoryRef(newList);
  };

  const topicName = (title: string) => {
    let functional = title === "create" ? "สร้าง" + title : "แก้ไข" + title;
    return functional;
  };
  return (
    <div>
      <div>
        <Card
          className="card-group"
          actions={[
            <Row justify="end">
              {(toggleDrag || toggleEdit) && (
                <>
                  <Col>
                    <Button
                      type="default"
                      // onClick={() => history.goBack()}
                      onClick={() => {
                        setToggleEdit(false);
                        setToggleDrag(false);
                        handleGetGroup();
                      }}
                    >
                      ยกเลิก
                    </Button>
                  </Col>
                  <Col className="pl-1">
                    <Button
                      className="btn-save"
                      onClick={() => {
                        !pathID ? handleCreateGroup() : handleUpdateGroup();
                      }}
                      // loading={loadingButton}
                    >
                      บันทึก
                    </Button>
                  </Col>
                </>
              )}
              {!toggleDrag && !toggleEdit && (
                <Col className="pl-1">
                  <Button
                    onClick={() => setToggleEdit(true)}
                    style={{ width: "130px" }}
                  >
                    แก้ไข
                  </Button>
                </Col>
              )}
              {!toggleDrag && !toggleEdit && (
                <Col className="pl-1">
                  <Button onClick={() => setToggleDrag(true)}>
                    ปรับตำแหน่งรายการ
                  </Button>
                </Col>
              )}
            </Row>,
          ]}
        >
          <Row justify="end">
            <Col>
              <Button
                className="btn-create"
                type="primary"
                onClick={handleSetDetailCreateCategory}
                disabled={!toggleEdit}
              >
                <PlusOutlined className="icons-size" /> สร้างหมวดหมู่
              </Button>
            </Col>
          </Row>
          <div className={first ? "d-none" : ""}>
            <FormDragDrop
              visible={visible}
              categoryList={categoryRef.current}
              handleSetCategoryList={handleSetCategoryList}
              handleSetGroupList={handleSetGroupList}
              handleSetList={handleSetList}
              topicName={topicName}
              onCancel={onCancel}
              onOK={onOK}
              modalDetal={modalDetal}
              titleTH={titleTH}
              setTitleTH={setTitleTH}
              titleEN={titleEN}
              setTitleEN={setTitleEN}
              onModal={onModal}
              onDelete={onDelete}
              groupID={groupID}
              setGroupID={setGroupID}
              handleGetProductsGroup={handleGetGroup}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Group;
