import { Fecth, FecthFile } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from '../../tools/util'
import { IColorsPost } from '../../interface/IColors.inteface'

export const getColors = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/color${queryStr}`,
    })
    return res
}


export const getColorsByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/color/${id}`,
    })
    return res
}



export const postColors = async (data: IColorsPost) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/color`,
    })
    return res
}
export const putColors = async (data: IColorsPost, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/color/${id}`,
    })
    return res
}


export const putColorActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/color/active/${id}`,
    })
    return res
}


export const delColor = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/color/${id}`,
    })
    return res
}



export const getColorsType = async (qs?: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/color-type${queryStr}`,
    })
    return res
}


export const getExcelColors = async () => {
    const res = await FecthFile({
        method: "GET",
        path: `${version}/color/export/excel`,
    })
    return res
}

