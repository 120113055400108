import React, { useState } from "react";
import { Row, Col, Tabs } from "antd";
import EstimatePriceForm from "./estimate_price/Form/Form";
import FindTechnicianForm from "./findtechnician/Form/Form";
import SpecDesignForm from "./spec_design/Form/Form";

const Serve = () => {
  const [selectTab, setSelectTab] = useState("1");

  const { TabPane } = Tabs;
  const callback = (key: any) => {
    setSelectTab(key);
  };

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">บริการ</span>
        </Col>
      </Row>
      <div className="set-layout">
        <div className="pt-1">
          <Tabs
            className="tab-mainmenu"
            defaultActiveKey={selectTab}
            onChange={callback}
          >
            <TabPane tab="ประเมินราคา" key="1">
              <EstimatePriceForm />
            </TabPane>
            <TabPane tab="หาช่างติดตั้ง" key="2">
              <FindTechnicianForm />
            </TabPane>
            <TabPane tab="ออกแบบวางสเปค" key="3">
              <SpecDesignForm />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Serve;
