import React, { useEffect, useState } from "react";
import Forms from "./form/Form";
import { Form, Row, Col, Button, Card } from "antd";
import { IMediaSelect } from "../../../interface/IMedia.interface";
import { openNotification } from "../../../component/notification/Notification";
import { useHistory } from "react-router-dom";

//=================API=================//
import {
  GET_CONTACTID_API,
  PUT_CONTACT_API,
} from "../../../services/api/setting";

const ContactTab = () => {
  const [mediaSelectSetting, setMediaSelectSetting] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    try {
      const res = await GET_CONTACTID_API();
      const data = res.result[0];

      if (res.code === 200) {
        setMediaSelectSetting({
          ...mediaSelectSetting,
          file: data.media?.file_path,
          id: data.media?.id,
        });
        form.setFieldsValue({
          // media_id: mediaSelect.id,
          ...data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = async (value: any) => {
    try {
      const data = {
        id: 1,
        image: mediaSelectSetting.id,
        name: value.name,
        address: value.address,
        email: value.email,
        map: value.map,
        phone: value.phone,
      };
      const res = await PUT_CONTACT_API(data);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/setting");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
        history.push("/setting");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Card
          extra={<>ติดต่อ</>}
          actions={[
            <Row justify="end">
              <Col className="pl-1">
                <Button className="btn-save" htmlType="submit">
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-media"
        >
          <Forms
            mediaSelectSetting={mediaSelectSetting}
            setMediaSelectSetting={setMediaSelectSetting}
          />
        </Card>
      </Form>
    </>
  );
};

export default ContactTab;
