import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from "../../tools/util"

//================interface==================//
import { GetData, IAdmitPost } from '../../interface/IUsers.interface'

//------------User---------------//
export const getUserList = async(data?:GetData) => {
    const value = createQueryString(data);
    const res = await Fecth({
        method:"GET",
        path:`${version}/admin${value}`
    });
    return res;
}

export const getUserListByID = async(id:number) => {
    const res = await Fecth({
        method:"GET",
        path:`${version}/admin/${id}`
    });
    return res;
}

export const postUserList = async(data:IAdmitPost) => {
    const res = await Fecth({
        data,
        method:"POST",
        path:`${version}/admin`
    });
    return res;
}

export const pitUserList = async(data:IAdmitPost,id:number) => {
    const res = await Fecth({
        data,
        method:"PUT",
        path: `${version}/admin/${id}`
    })
    return res
}

export const deleteAdminList = async(id:number) => {
    const res = await Fecth({
        method:"DELETE",
        path:`${version}/admin/${id}`
    });
    return res;
}

export const putAdminActive = async(id:number)=>{
    const res = await Fecth({
        method:"PUT",
        path:`${version}/admin/active/${id}`
    });
    return res;
}


/////-*-----------------department
export const GET_DEPARTMENT_API = async(data?:any)=>{
    const res = await Fecth({
        method:"GET",
        path:`${version}/department`
    })
    return res
}