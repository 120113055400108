
export const _isEmpty = (data: any) => {

  if (typeof data === 'object' && data !== null) {
    let objEmpty = true
    Object.keys(data).forEach(item => {
      const queryDefault =
        data[item] === null || data[item] === "undefined" || data[item] === undefined || data[item] === "";
      if (!queryDefault) {
        objEmpty = false
      }
    });
    return objEmpty
  }
  const queryDefault =
    data === null || data === "undefined" || data === undefined || data === "";
  if (queryDefault) return true;
  if (typeof data === "number") return false;
  if (typeof data === "string") return false;
  const obj = queryDefault ? [] : data;
  return Object.entries(obj).length === 0;
};


export const checkCurrentURL = (): string | null => {
  const current_url = localStorage.getItem("current_url")
  return current_url
}

export const _RESValidationCode = (res: any, message: string) => {
  if (res['status']['code'] === "F203") {
    return false
  }
  return true
}

export const changeLanguage = (key = "", langguage = "th") => {
  const newKey = "th.header"
  return newKey
}


export const _isHaveKey = (values: any) => {
  if (!values) {
    return "";
  }
  let result = {};
  for (const [key, value] of Object.entries(values)) {
    if (value || value === 0 || value === false) {
      result = { ...result, [key]: value };
    }
  }
  return result;
};

export const _appendFields = (data: any) => {
  const formData: any = new FormData();


  Object.keys(data).forEach((key) => {
    Array.isArray(data[key])
      ? data[key].forEach((value: any) => {
        Object.keys(value).forEach((keyF) => {
          let keyName = keyF !== "file" ? key + keyF + "[]" : key + keyF;
          formData.append(keyName, value[keyF]);
        });
      })
      : formData.append(key, data[key]);

  });
  for (let [key, value] of formData) {
    console.log(`${key}: ${value}`)
  }
  return formData;
};

export const createQueryString = (values: any) => {
  if (!values) {
    return "";
  }
  let query = "";
  let result = {};
  for (const [key, value] of Object.entries(values)) {
    if (value || value === 0 || value === false) {
      result = { ...result, [key]: value };
    }
  }
  Object.entries(result).forEach(([key, value], index) => {
    if (index === Object.keys(result).length - 1) {
      if (value || value === 0 || value === false) {
        query += `${key}=${value}`;
      }
    } else {
      if (value || value === 0 || value === false) {
        query += `${key}=${value}&`;
      }
    }
  });
  return "?" + query;
};


export const findItemByID = (selectValue: [], list: any) => {

  const newList = selectValue.map((item: any) => {
    const newData = list.find((value: any) => item === value.id);
    return newData;
  });
  return newList
}

export const findItemByKey = (key: string, list: any[]) => {

  const newList = list.map((item: any) => {

    return item[key]
  })
  return newList
}



export const displayHide = (keyActive: string | number, visible?: string | number) => {
  if (keyActive === visible) {
    return ""
  }
  return "d-none"
}



export const seleteKeyArrayOfObject = (array: any[], keysSeleted: any[]) => {
  const newArray: any = array.map(obj => {
    const newObj = {}
    keysSeleted.forEach((key: any) => {
      //@ts-ignore
      newObj[key] = obj[key]
    })
    return newObj
  });
  return newArray
}


export const deleteKeyArrayOfObject = (array: any[], keysSeleted: any[]) => {
  const newArray: any = array.map(obj => {
    const filtered = obj;
    keysSeleted.forEach((key: any) => {
      //@ts-ignore
      delete obj[key]
    })
    return filtered
  });

  return newArray
}

export const _RandomReadmore = (dataList: any) => {
  let cloneList = [...dataList];
  let dividLenght =
    cloneList.length > 20 ? cloneList.length / 2 : cloneList.length;
  const randomRow = Math.floor(Math.random() * dividLenght) + 1;
  let blockList: any = [];
  const mold = cloneList.length < 5 ? cloneList.length : 5;
  for (let i = 0; i <= randomRow - 1 && dividLenght !== 0; i++) {
    const restLenght = dividLenght > mold ? mold : dividLenght
    const blockNumber = Math.floor(Math.random() * restLenght) + 1
    blockList.push(blockNumber);
    dividLenght = dividLenght - blockNumber;
  }
  let newList: any = [];
  for (let i = 0; i <= blockList.length - 1; i++) {
    const data = cloneList.splice(0, blockList[i]);
    newList.push({ data });
  }
  return newList;
};

export const availableID = (obj: any) => {
  if (obj.idx) {
    return "idx"
  }
  else {
    return "id"
  }

}

export const numberToCommasNumber = (num: number) => {
  const internationalNumberFormat = new Intl.NumberFormat('en-US')
  return internationalNumberFormat.format(num)
}

export const HandleScroll = (key: string, smooth?: boolean) => {
  var elmnt = document.getElementById(key);
  elmnt?.scrollIntoView(
    smooth && {
      behavior: "smooth",
    }
  );
};
export const undefinedToNullKey = (objKey: any) => {
  let newObjKey = {}
  Object.keys(objKey).forEach((key: string) => {
    if (objKey[key]) {
      newObjKey = { ...newObjKey, [key]: objKey[key] }
    }
    else {
      newObjKey = { ...newObjKey, [key]: null }
    }
  })
  return newObjKey
}

export const dummyRequest = ({ onSuccess }: any) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const downloadFile = (
  blobFile: Blob | undefined,
  fileType: string,
  fileName: string
) => {
  if (blobFile) {
    const url = window.URL.createObjectURL(new Blob([blobFile]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}.${fileType}`); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
};