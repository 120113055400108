import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { Switch } from "antd";
import MoTable from "../../../component/table/Table";
import {
  delGlass,
  getGlass,
  putGlassActive,
} from "../../../services/api/glass";
import { IResStatus } from "../../../tools/fecth/interface";
import { IMedia } from "../../../interface/IMedia.interface";
import { IEditor } from "../../../interface/IEditor.interface";
import { useHistory } from "react-router-dom";
import { IMirror } from "../../../interface/IMirror.interface";
import { openNotification } from "../../../component/notification/Notification";
import Modaler from "../../../component/modaler/Modaler";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import { DateTimeStandard } from "../../../tools/date";
import { _isEmpty } from "../../../tools/util";
import noImage from "../../../assets/img/No_image.svg";
type Props = {
  filterValue: any;
};
const Mirror = ({ filterValue }: Props) => {
  const setLoading = useSetRecoilState(loadingState);
  const [visible, setVisible] = useState<boolean>(false);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [id, setId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const [mirrorList, setMirrorList] = useState<IMirror[]>([]);
  const history = useHistory();

  useEffect(() => {
    if (!_isEmpty(filterValue)) {
      setCurrentPage(1);
    }
    handleGetData();
    // eslint-disable-next-line
  }, [currentPage, limitPage, filterValue]);

  const handleGetData = () => {
    handleGetMirror();
  };

  const handleGetMirror = async () => {
    try {
      setLoading(true);

      const res: IResStatus = await getGlass({
        page: currentPage,
        limit: limitPage,
        ...filterValue,
      });
      if (res.code === 200) {
        setMirrorList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push("/product/mirror/" + event.id);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleteMirror(id);
  };

  const handleUpdateActive = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await putGlassActive(id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetData();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleteMirror = async (id: number) => {
    try {
      setLoading(true);
      const res: IResStatus = await delGlass(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetMirror();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    onModal();
    setLoading(false);
  };

  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "8%",
      render: (status: boolean, data: any) => {
        return (
          <>
            <Switch
              checked={status}
              onChange={() => handleUpdateActive(data.id)}
            />
          </>
        );
      },
    },
    {
      title: "ชื่อ SKU",
      dataIndex: "sku",
      key: "sku",
      width: "10%",
    },
    {
      title: "รูปภาพ",
      dataIndex: "media",
      key: "media",
      align: "center",
      width: "10%",

      render: (media: IMedia) => {
        return (
          <>
            <img
              src={media?.file_path ? media?.file_path : noImage}
              className="img-squad"
              alt={media?.media_name}
            />
          </>
        );
      },
    },
     {
      title: "สี",
      dataIndex: "color",
      key: "color",
      width: "10%",
    },
    {
      title: "ชื่อกระจก",
      dataIndex: "name_th",
      key: "name_th",
      width: "45%",
    },
   
    {
      title: "อัปเดต",
      dataIndex: "edit_by",
      key: "edit_by",
      width: "15%",
      render: (editBy: IEditor, data: IMirror) => {
        return (
          <>
            {editBy.first_name} {editBy.last_name}
            <br />
            {!_isEmpty(data.updated_at)
              ? DateTimeStandard(data.updated_at)
              : DateTimeStandard(data.created_at)}
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",

      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                setId(data.id);
                onModal();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div>
        <Modaler
          visible={visible}
          onCancel={onModal}
          onOk={onDelete}
          width="25%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              คุณแน่ใจไหม?
            </div>
          }
        >
          คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
        </Modaler>
        <div className="card-table-tab">
          <MoTable
            dataSource={mirrorList}
            columns={columns}
            onChangePage={onChangePage}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            onClickRow={onClickRow}
          />
        </div>
      </div>
    </div>
  );
};

export default Mirror;
