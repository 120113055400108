
import { version } from "../../config/api"
import { IColorTypePost } from "../../interface/IColorType.interface"
import { Fecth } from "../../tools/fecth/fecth"
import { createQueryString } from "../../tools/util"

export const getColorType = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/color-type${queryStr}`,
    })
    return res
}


export const getColorTypeByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/color-type/${id}`,
    })
    return res
}



export const postColorType = async (data: IColorTypePost) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/color-type`,
    })
    return res
}
export const putColorType = async (data: IColorTypePost, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/color-type/${id}`,
    })
    return res
}


export const putColorTypeActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/color-type/active/${id}`,
    })
    return res
}


export const delColorType = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/color-type/${id}`,
    })
    return res
}




