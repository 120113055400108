import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row } from "antd";
import { useState } from "react";
import { IMediaSelect } from "../../interface/IMedia.interface";
import { _isEmpty } from "../../tools/util";
import MediaModal from "../mediamodal/MediaModal";

const UploadImage = ({ onChange, value , onDelete}: any) => {
  const [visible, setVisible] = useState(false);
  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: !_isEmpty(value) ? value.file_path : "",
    id: !_isEmpty(value) ? value.id : "",
    media_name: !_isEmpty(value) ? value.media_name : "",
  });

  const onModal = async () => {
    setVisible(!visible);
  };

  return (
    <>
      <Button
        icon={<UploadOutlined />}
        disabled={false}
        block
        type="dashed"
        onClick={onModal}
      >
        อัพโหลด
      </Button>
      <MediaModal
        visible={visible}
        onModal={onModal}
        setMediaSelect={setMediaSelect}
        onChange={onChange}
        value={value}
      />
      {!_isEmpty(mediaSelect.file) && (
        <div className="list-image-upload mt-05">
          <Row className="h-100">
            <Col
              lg={8}
              className="d-flex justify-start items-center img-upload-padding"
            >
              <div
                onClick={() => window.open(mediaSelect.file)}
                className="cursor-pointer"
              >
                <img
                  src={mediaSelect.file}
                  className="img-upload-squad"
                  alt="upload"
                />
              </div>
            </Col>
            <Col lg={8} className="d-flex justify-center items-center">
              <span className="cut-text-1-row cursor-pointer">
                <Popover content={mediaSelect.media_name}>
                  {mediaSelect.media_name}
                </Popover>
              </span>
            </Col>
            <Col lg={8} className="d-flex justify-flex-end items-center h-100">
              <Button
                className="btn-delete-upload"
                onClick={() => {
                  onDelete && onDelete(null)
                  setMediaSelect({
                    file: "",
                    id: "",
                    media_name: "",
                  })
                }
              }
              >
                <DeleteOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default UploadImage;
