import React, { useEffect } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { _isEmpty } from "../../../../tools/util";
import { IResStatus } from "../../../../tools/fecth/interface";
import { IDoorOpenPatternPost } from "../../../../interface/IDoorOpenPattern.interface";
import { openNotification } from "../../../../component/notification/Notification";
import {
  getDoorOpenPatternByID,
  postDoorOpenPattern,
  putDoorOpenPattern,
} from "../../../../services/api/door_open_pattern";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  loadingButtonState,
  loadingState,
} from "../../../../tools/recoil/recoil-atom";

const Fields = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);
  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    if (!_isEmpty(pathID)) {
      handleGetDoorOpenPatternByID();
    }
  };

  const handleGetDoorOpenPatternByID = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getDoorOpenPatternByID(pathID);
      if (res.code === 200) {
        const data = res.result[0];
        form.setFieldsValue({
          ...data,
        });
      }
    } catch (error) {}
    setLoading(false);
  };

  const onFinish = (values: any) => {
    if (!_isEmpty(pathID)) {
      handleUpdateDoorOpenPattern(values);
    } else {
      handleCreateDoorOpenPattern(values);
    }
  };

  const handleCreateDoorOpenPattern = async (values: IDoorOpenPatternPost) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await postDoorOpenPattern(values);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const handleUpdateDoorOpenPattern = async (values: IDoorOpenPatternPost) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await putDoorOpenPattern(
        {
          ...values,
        },
        pathID
      );
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  return (
    <div>
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Card
          extra={
            !_isEmpty(pathID)
              ? "แก้ไขรูปแบบการเปิดปิด"
              : "สร้างรูปแบบการเปิดปิด"
          }
          actions={[
            <Row justify="end">
              <Col>
                <Button type="default" onClick={() => history.goBack()}>
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button
                  className="btn-save"
                  htmlType="submit"
                  loading={loadingButton}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row justify="center">
            <Col lg={12}>
              <Row gutter={[14, 14]}>
                <Col lg={24}>
                  <Form.Item
                    label="ชื่อรูปแบบการเปิดปิด (TH)"
                    name="name_th"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    label="ชื่อรูปแบบการเปิดปิด (EN)"
                    name="name_en"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
