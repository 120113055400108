import { Divider, Row } from "antd";

type Props = {
  img: string;
  title?: string;
  textHeight?: string;
  textWidth?: string;
  price?: number;
  height?: number|string;
  width?: number|string;
  onClick?: (data?: any) => void;
  shadow?: boolean;
  lang?: string;
};

const CardBoxProducts = ({
  onClick,
  height = '100%',
  width = '100%',
  img = "",
  title = "บานเลื่อน",
  textHeight = "0",
  textWidth = "0",
  price = 0,
  shadow = true,
  lang = "th",
}: Props) => {
  const HandleOnClick = (value?: any) => {
    if (onClick) {
      onClick(value);
    }
  };

  return (
    <div>
      <div
        className={`card-box-v3 h-100 cursor-pointer bg-white ${
          shadow ? "shadow-md" : ""
        }  border`}
        onClick={HandleOnClick}
      >
        <img
          src={img}
          alt={title}
          width={width}
          height={height}
          className="image-card-box-v3"
          style={{
            borderRadius: `15px 15px 0 0`,
          }}
        />
        <div className="px-3 py-2">
          <div className="mt-1 title-card-box">{title}</div>
          <div className="mt-1 text-box-inline">
            <div>
              - {lang === "th" ? "ความกว้างเริ่มต้น" : "Default Width"}{" "}
              {textWidth} {lang === "th" ? "มม." : "MM"}{" "}
            </div>
            <div>
              - {lang === "th" ? "ความสูงเริ่มต้น" : "Default Height"}{" "}
              {textHeight} {lang === "th" ? "มม." : "MM"}
            </div>
          </div>
        </div>
        <Divider style={{ margin: 0 }} className="bg-gray-100" />
        <div className="py-2 px-3 relative">
          <Row align="top" style={{ color: "#808284" }}>
            <span className="left-0 relative" style={{ fontSize: 12 }}>
              {lang === "th" ? "เริ่มต้น" : "START"}{" "}
              <span style={{ fontWeight: "bold", color: "black" }}>
                {Number(price || 0).toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })}
              </span>{" "}
              {lang === "th" ? "บาท" : "BATH"}
            </span>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default CardBoxProducts;
