import React from "react";
import { Row, Col, Button, Card, Form } from "antd";
import Selector from "../../../component/selector/Selector";

type Props = {
  onFinish: (value: any) => void;
  bannerMenuList: any;
};

const Header = ({ onFinish, bannerMenuList }: Props) => {
  return (
    <div>
      <Form onFinish={onFinish}>
        <Card className="card-round">
          <Row gutter={14}>
            {/* <Col lg={6}>
              <Form.Item name="projectName">
                <Input placeholder="ค้นหาชื่อโครงการ" />
              </Form.Item>
            </Col> */}
            <Col lg={9}>
              <Form.Item name="menu_id">
                <Selector
                  placeholder="เมนู: ทั้งหมด"
                  Source={bannerMenuList}
                  keyName="name"
                  keyValue="id"
                />
              </Form.Item>
            </Col>
            {/* <Col lg={4}>
              <Form.Item name="category">
                <Select placeholder="หมวดหมู่: ทั้งหมด">
                  <Option value="ทั้งหมด">ทั้งหมด</Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col lg={3}>
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
            </Col>
            <Col lg={3}>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Header;
