import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from '../../tools/util'
import { IInspiPost } from '../../interface/IInspiration.interface'


export const getInspirationList = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/inspiration${queryStr}`,
    })
    return res
}

export const getInspirationListById = async (id?: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/inspiration/${id}`,
    })
    return res
}
export const postInspirationList = async (data: any) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/inspiration`,
    })
    return res
}

export const putInspirationList = async (data: IInspiPost, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/inspiration/${id}`,
    })
    return res
}

export const delInspirationList = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/inspiration/${id}`,
    })
    return res
}
export const putInspirationListActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/inspiration/active/${id}`,
    })
    return res
}

export const getInspirationListActive = async (qs?: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/inspiration/active${queryStr}`,
    })
    return res
}
