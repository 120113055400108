import React from "react";
import { Button, Card, Col, Form, Row } from "antd";
import { useHistory } from "react-router-dom";
import FileSpace from "../../../../component/filespace/FileSpace";
import { findItemByKey } from "../../../../tools/util";
import { postMediaManyFile } from "../../../../services/api/media";
import { IResStatus } from "../../../../tools/fecth/interface";
import { openNotification } from "../../../../component/notification/Notification";

const ManyFile = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const mapKeyArray = (list: any[]) => {
    var formData = new FormData();
    list.forEach((item) => {
      formData.append("file", item);
    });
    return formData;
  };

  const onFinish = (values: any) => {
    handleCreateMediaFile(values.media.fileList);
  };

  const handleCreateMediaFile = async (dataList: any) => {
    try {
      const filterObjFile = findItemByKey("originFileObj", dataList);
      const fileList = mapKeyArray(filterObjFile);
      const res: IResStatus = await postMediaManyFile(fileList);
      if (res.code === 200) {
        openNotification({
          method: "POST_SUCCESS",
        });
        form.resetFields();
      } else {
        openNotification({
          method: "POST_ERROR",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Card
        extra={<>อัพโหลด</>}
        actions={[
          <Row justify="end">
            <Col>
              <Button type="default" onClick={history.goBack}>
                ยกเลิก
              </Button>
            </Col>
            <Col className="pl-1">
              <Button className="btn-save" onClick={() => form.submit()}>
                บันทึก
              </Button>
            </Col>
          </Row>,
        ]}
        className="card-for-tabs"
      >
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Row gutter={[14, 14]} justify="center">
            <Col lg={8}>
              <Form.Item
                label="ไฟล์"
                name="media"
                rules={[
                  {
                    required: true,
                    message: "โปรดกรอกข้อมูล!",
                  },
                ]}
              >
                <FileSpace fileType="file" multiple={true} maxCount={0} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ManyFile;
