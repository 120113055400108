import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from "../../tools/util"
import { IGroupArticlePost } from '../../interface/IArticleCategory.interface';

export const getArticleCategory = async (data: any) => {
    const qr = createQueryString(data);
    const res = await Fecth({
        method: "GET",
        path: `${version}/article-category${qr}`,
    });
    return res;
};
export const getArticleCategoryByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/article-category/${id}`,
    })
    return res
}



export const postArticleCategory = async (data: IGroupArticlePost) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/article-category`,
    })
    return res
}
export const putArticleCategory = async (data: IGroupArticlePost, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/article-category/${id}`,
    })
    return res
}


export const putArticleCategorytActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/article-category/active/${id}`,
    })
    return res
}


export const delArticleCategory = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/article-category/${id}`,
    })
    return res
}




