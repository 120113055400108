import React, { useEffect, useState } from "react";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MoTable from "../../../component/table/Table";
import { Switch } from "antd";
import { DateTimeStandard } from "../../../tools/date";
import Modaler from "../../../component/modaler/Modaler";

import {
  IInspiList,
  IProduct_category,
} from "../../../interface/IInspiration.interface";

import { IMedia } from "../../../interface/IMedia.interface";
import { IEditor } from "../../../interface/IEditor.interface";
import { IResStatus } from "../../../tools/fecth/interface";
import {
  delInspirationList,
  getInspirationList,
  putInspirationListActive,
} from "../../../services/api/inspiration";
import { openNotification } from "../../../component/notification/Notification";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import { getInspirationCategoryList } from "../../../services/api/inspiration_category";
import CImage from "../../../component/image/Image";

type Props = {
  search: any;
  setinspirationCategoryList: any;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};
const InspiTab = ({
  search,
  setinspirationCategoryList,
  currentPage,
  setCurrentPage,
}: Props) => {
  const [limitPage, setLimitPage] = useState<number>(10);
  const [InspirationList, setInspirationList] = useState<IInspiList[]>([]);
  const [total, setTotal] = useState<number>(1);
  const [id, setId] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const history = useHistory();
  const setLoading = useSetRecoilState(loadingState);
  const [firstWorks, setFirstWorks] = useState<boolean>(true);

  useEffect(() => {
    if (firstWorks) handleGetInspirationCategoryList();
    setFirstWorks(false);
    handleGetInspirationList();

    // eslint-disable-next-line
  }, [currentPage, limitPage, search]);

  const handleGetInspirationList = async () => {
    try {
      setLoading(true);

      const res: IResStatus = await getInspirationList({
        page: currentPage,
        limit: limitPage,
        ...search,
      });
      if (res.code === 200) {
        setInspirationList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleteInspirationList = async (id: number) => {
    try {
      setLoading(true);

      const res: IResStatus = await delInspirationList(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetInspirationList();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    onModal();
    setLoading(false);
  };

  const handleUpdateActive = async (id: number) => {
    try {
      setLoading(true);

      const res: IResStatus = await putInspirationListActive(id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetInspirationList();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onDelete = () => {
    handleDeleteInspirationList(id);
  };

  const onClickRow = (event: any) => {
    history.push("/inspiration/inspiration/" + event.id);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const handleGetInspirationCategoryList = async () => {
    try {
      const res: IResStatus = await getInspirationCategoryList({
        is_active: true,
      });
      if (res.code === 200) {
        setinspirationCategoryList(res.result[0].item);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "10%",
      render: (status: boolean, data: any) => {
        return (
          <>
            <Switch
              checked={status}
              onChange={() => handleUpdateActive(data.id)}
            />
          </>
        );
      },
    },
    {
      title: "รูปปก",
      dataIndex: "cover_image",
      key: "cover_image",
      width: "10%",
      align: "center",
      render: (cover_image: IMedia) => {
        return (
          <>
            <CImage
              src={cover_image?.file_path}
              className="img-squad"
              alt={cover_image?.media_name}
            />
          </>
        );
      },
    },
    {
      title: "ชื่อหัวข้อ",
      dataIndex: "topic",
      key: "topic",
      width: "40%",
      render: (_: string, data: any) => {
        return (
          <>
            <b> {data.title_th}</b>
          </>
        );
      },
    },
    {
      title: "หมวดหมู่ไอเดีย",
      dataIndex: "inspiration_category",
      key: "inspiration_category",
      width: "15%",
      render: (inspiration_category: IProduct_category) => {
        return <>{inspiration_category.name_th}</>;
      },
    },

    {
      title: "อัปเดต",
      dataIndex: "edit_by",
      key: "edit_by",
      width: "15%",
      render: (editBy: IEditor, data: IInspiList) => {
        return (
          <>
            {editBy.first_name} {editBy.last_name}
            <br />
            {DateTimeStandard(data.updated_at)}
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",

      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                setId(data.id);
                onModal();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <Modaler
          visible={visible}
          onCancel={onModal}
          onOk={onDelete}
          width="25%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              คุณแน่ใจไหม?
            </div>
          }
        >
          คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
        </Modaler>
        <div className="card-table-tab">
          <MoTable
            dataSource={InspirationList}
            columns={columns}
            rowKey={"id"}
            onChangePage={onChangePage}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            onClickRow={onClickRow}
          />
        </div>
      </div>
    </div>
  );
};
export default InspiTab;
