import {
  fecthConfig, fecthConFormdata
} from './interface'
import { apiURL, } from '../../config/api'
import { openNotification } from '../../component/notification/Notification';


export const Fecth = async (fecthConfig: fecthConfig) => {
  try {
    const token = localStorage.getItem("cmsWebPlateformAccessToken");
    const url = `${apiURL}${fecthConfig.path}`;
    const res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: fecthConfig.method,
      body: fecthConfig.method !== "GET" ? JSON.stringify(fecthConfig.data) : null,
    });
    const _data = await res.json();

    if (_data.code === 401) {
      openNotification({ method: "TOKEN_EXPIRE", message: _data.message })
      setTimeout(() => {
        localStorage.clear();
        let url: string = window.location.origin;
        window.location.replace(url + "/#/login")
      }, 2000)
    }
    else if (_data.code !== 200 && fecthConfig.method === "GET") {
      openNotification({ method: "ERROR", message: _data.message })
    }
    return _data;
  } catch (err: any) {
    console.log(err, "err");
    return { success: false, message: err.message };
  }
};

export const FecthFormdata = async (fecthConfig: fecthConFormdata) => {
  try {
    const token = localStorage.getItem("cmsWebPlateformAccessToken");
    const url = `${apiURL}${fecthConfig.path}`;
    const res = await fetch(url, {
      headers: { Authorization: `Bearer ${token}` },
      method: fecthConfig.method,
      body: fecthConfig.data
    });
    const _data = await res.json();
    return _data;
  } catch (err: any) {
    return { success: false, message: err.message };
  }
};

export const FecthFile = async (fecthConfig: fecthConfig) => {
  try {
    const url = `${apiURL}${fecthConfig.path}`;
    const token = localStorage.getItem("cmsWebPlateformAccessToken");
    const res = await fetch(url, {
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
      method: fecthConfig.method,
    });
    if (res.status === 200) {
      return { code: 200, data: await res.blob() };
    } else {
      return { code: 400, message: res.statusText };
    }
  } catch (err: any) {
    return { success: false, message: err.message };
  }
};