import { Card, Col, Row, Tooltip } from "antd";
import React from "react";
import { IProductList } from "../../../../interface/IApprove.interface";
type Props = {
  productList: IProductList[];
};
const Products = ({ productList }: Props) => {
  return (
    <Card extra={"รายละเอียด"} className="h-100">
      <Row className="text-product-list" style={{ borderBottom: "1px solid #EEEDED", marginBottom: 4 }}>
        <Col lg={11}>
          <h4>Code</h4>
        </Col>
        <Col lg={9}>
          <h4>ชนิด</h4>
        </Col>
        <Col lg={4} className="d-flex justify-center">
          <h4> จำนวน</h4>
        </Col>
      </Row>
      {productList.map((item, idx) => {
        return (
          <Tooltip key={idx} placement="top" title={
            <>
              <RenderTextInfo topic={"ลักษณะบาน"} desc={item.feature} />
              <RenderTextInfo topic={"วงกบ"} desc={item.jambs} />
              <RenderTextInfo topic={"กรอบบาน"} desc={item.frame} />
              <RenderTextInfo topic={"กระจก"} desc={item.glass} />
              <RenderTextInfo
                topic={"อุปกรณ์ล็อกและมือจับ"}
                desc={item.lock_kit}
              />
              <RenderTextInfo
                topic={"จำนวน"}
                desc={item.amount + " ชุด"}
              />
              <RenderTextInfo
                topic={"สี"}
                desc={
                  <img
                    src={item.color.url}
                    className="color-box box-round"
                    alt={item.color.nameEn}
                  />
                }
              />
            </>
          }
          >
            <Row key={idx} className="text-product-list" gutter={[4, 4]} style={{ borderBottom: "1px solid #EEEDED", padding: 4 }}>
              <Col lg={6}>{item.name} </Col>
              <Col lg={14}>
                {item.product_type}
              </Col>
              <Col lg={4} className="d-flex justify-center items-center ">
                <div>
                  <span className="amount-box">{item.amount} ชุด</span>
                </div>
              </Col>
            </Row>
          </Tooltip>
        );
      })}
    </Card>
  );
};
const RenderTextInfo = ({
  topic,
  desc,
}: {
  topic: string;
  desc: string | number | React.ReactElement;
}) => {
  return (
    <div>
      <div className="mb-0" style={{ marginTop: "3px", fontSize: "0.8rem" }}>
        <b>{topic} :</b>
      </div>
      <div style={{ fontSize: "0.7rem", marginTop: "3px" }}>
        {desc ? desc : <span style={{ color: "lightgray" }}>ไม่มีข้อมูล</span>}
      </div>
    </div>
  );
};
export default Products;
