import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { _isEmpty } from "@yim/util-tool-ts";
import { Button, Col, Popover, Row } from "antd";
import { useEffect, useState } from "react";
import { IMediaSelect } from "../../interface/IMedia.interface";
import PDFIcon from "../../assets/img/icons/pdf.png";
import ExelIcon from "../../assets/img/icons/exel.png";
import MedaiModal from "./Modal";
import CImage from "../image/Image";
type Props = {
  onChange?: any;
  onSelect?: any;
  fileExtension: "pdf" | "exel" | "dwg";
  value?: any;
  onDelete?: (data: any) => any;
};
const MediaModalFile = ({
  onChange,
  onSelect,
  fileExtension,
  value,
  onDelete
}: Props) => {
  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: !_isEmpty(value) ? value.file_path : "",
    id: !_isEmpty(value) ? value.id : "",
    media_name: !_isEmpty(value) ? value.media_name : "",
    type: !_isEmpty(value) ? value.type : "",
  });

  useEffect(() => {
    setMediaSelect({
      file: !_isEmpty(value) ? value.file_path : "",
      id: !_isEmpty(value) ? value.id : "",
      media_name: !_isEmpty(value) ? value.media_name : "",
      type: !_isEmpty(value) ? value.type : "",
    });
    // eslint-disable-next-line
  }, [value]);

  const [visible, setVisible] = useState(false);
  const checkFileKey = (key: string) => {
    if (key === "pdf") {
      return { acceptFile: "application/pdf", filterTypeFile: ".pdf" };
    } else if (key === "exel") {
      return { acceptFile: ".xlsx,.xls", filterTypeFile: ".xls" };
    } else if (key === "dwg") {
      return { acceptFile: ".dwg", filterTypeFile: ".dwg" };
    } else {
      return {
        acceptFile: ".xlsx,.xls,application/pdf,.dwg",
        filterTypeFile: "",
      };
    }
  };

  const onModal = () => {
    setVisible(!visible);
  };
  const displayValue = (data: any) => {
    setMediaSelect({
      id: data.id,
      media_name: data.media_name,
      file: data.file_path,
      type: data.type,
    });
  };

  return (
    <div>
      <Button
        icon={<UploadOutlined />}
        disabled={false}
        block
        type="dashed"
        onClick={onModal}
      >
        อัพโหลด
      </Button>
      {!_isEmpty(mediaSelect.file) && (
        <div className="list-image-upload mt-05">
          <Row className="h-100">
            <Col
              lg={8}
              className="d-flex justify-start items-center img-upload-padding"
            >
              <div
                onClick={() => window.open(mediaSelect.file)}
                className="cursor-pointer"
              >
                <CImage
                  src={mediaSelect.type === ".pdf" ? PDFIcon : ExelIcon}
                  className="img-upload-squad"
                  alt="upload"
                />
              </div>
            </Col>
            <Col lg={8} className="d-flex justify-center items-center">
              <span
                className="cut-text-1-row cursor-pointer"
                //
              >
                <Popover content={mediaSelect.media_name}>
                  {mediaSelect.media_name}
                </Popover>
              </span>
            </Col>
            <Col lg={8} className="d-flex justify-flex-end items-center h-100">
              <Button
                className="btn-delete-upload"
                onClick={() => {
                  onDelete && onDelete(null);
                  setMediaSelect({
                    file: "",
                    id: "",
                    media_name: "",
                    type: "",
                  });
               
                }}
              >
                <DeleteOutlined />
              </Button>
            </Col>
          </Row>
        </div>
      )}
      <MedaiModal
        value={value}
        visible={visible}
        onModal={onModal}
        onChange={onChange}
        onSelect={onSelect}
        displayValue={displayValue}
        fileExtension={checkFileKey(fileExtension)}
      />
    </div>
  );
};

export default MediaModalFile;
