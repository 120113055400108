import React from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
type Props = {
  onFinish: (values: any) => void;
};
const header = ({ onFinish }: Props) => {
  return (
    <div>
      <Form onFinish={onFinish}>
        <Card>
          <Row gutter={14}>
            <Col lg={9}>
              <Form.Item name="category_name">
                <Input placeholder="ค้นหาหมวดหมู่" />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
            </Col>
            <Col lg={3}>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default header;
