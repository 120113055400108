import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
type Props = {
  onGetValue: any;
  value?: any;
};
const DeleteDubplicate = ({ value, onGetValue }: Props) => {
  return (
    <>
      {value && (
        <Button onClick={() => onGetValue(value)}>
          <DeleteOutlined />
        </Button>
      )}
    </>
  );
};

export default DeleteDubplicate;
