import { Fecth } from "../../tools/fecth/fecth";
import { version } from "../../config/api";
import { createQueryString } from "../../tools/util";

export const postAddProject = async (data: any) => {
  const res = await Fecth({
    method: "POST",
    path: `${version}/project-content`,
    data: data,
  });
  return res;
};

export const getProjectContentByID = async (id: number) => {
  const res = await Fecth({
    method: "GET",
    path: `${version}/project-content/${id}`,
  });
  return res;
};

export const UpdateProjectContentByID = async (id: number,data:any) => {
  const res = await Fecth({
    method: "PUT",
    path: `${version}/project-content/${id}`,
    data: data
  });
  return res;
};

export const delProjectContent = async (id: number) => {
  const res = await Fecth({
    method: "DELETE",
    path: `${version}/project-content/${id}`,
  });
  return res;
};

export const getProjectContent = async (data: any) => {
  const qr = createQueryString(data);
  const res = await Fecth({
    method: "GET",
    path: `${version}/project-content${qr}`,
  });
  return res;
};

export const isActiveProjectContent = async (id: number) => {
  const res = await Fecth({
    method: "PUT",
    path: `${version}/project-content/active/${id}`,
  });
  return res;
};

export const getProjectContentCategory = async (data: any) => {
  const qr = createQueryString(data);
  const res = await Fecth({
    method: "GET",
    path: `${version}/project-content-category${qr}`,
  });
  return res;
};

export const getProjectContentCategoryById = async (id: number) => {
  const res = await Fecth({
    method: "GET",
    path: `${version}/project-content-category/${id}`,
  });
  return res;
};

export const editProjectContentCategory = async (id: number, data: any) => {
  const res = await Fecth({
    method: "PUT",
    path: `${version}/project-content-category/${id}`,
    data: data,
  });
  return res;
};

export const addProjectContentCategory = async (data: any) => {
  const res = await Fecth({
    method: "POST",
    path: `${version}/project-content-category`,
    data: data,
  });
  return res;
};

export const isDelProjectCategoryList = async (id: number) => {
  const res = await Fecth({
    method: "DELETE",
    path: `${version}/project-content-category/${id}`,
  });
  return res;
};

export const isActiveProjectCategoryList = async (id: number) => {
  const res = await Fecth({
    method: "PUT",
    path: `${version}/project-content-category/active/${id}`,
  });
  return res;
};
