import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UploadIcon from "../../../../assets/img/icons/upload.png";

import { openNotification } from "../../../../component/notification/Notification";
import {
  getMediaCategory,
  IMedia,
  postMediaImage,
} from "../../../../services/api/media";
import { IResStatus } from "../../../../tools/fecth/interface";
import { _appendFields, _isEmpty, _isHaveKey } from "../../../../tools/util";
import { ICategorymedia } from "./fieds";

const OneFile = () => {
  const [imageUrl, setImageUrl] = useState("");

  const [imageFile, setImageFile] = useState({});
  const [mediaCategory, setMediaCategory] = useState<ICategorymedia[]>([]);
  const history = useHistory();
  const [form] = Form.useForm();

  const handleCreateMedia = async (value: IMedia) => {
    try {
      const data = {
        ...value,
        media: imageFile,
      };
      const newData = _isHaveKey(data);
      const dataValue = _appendFields(newData);
      const res: IResStatus = await postMediaImage(dataValue);

      if (res.code === 200) {
        openNotification({
          method: "POST_SUCCESS",
        });
        history.push("/media");
      } else {
        openNotification({
          method: "POST_ERROR",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);
  const handleGetData = async () => {
    handleGetMediaCategory();
  };

  const onFinish = (values: any) => {
    handleCreateMedia(values);
  };

  const handleGetMediaCategory = async () => {
    try {
      const res: IResStatus = await getMediaCategory();
      if (res.code === 200) {
        setMediaCategory(res.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const beforeUpload = (file: any) => {
    const fileName = file.name.split(".");
    form.setFieldsValue({ media_name: fileName[0] });
    return;
  };

  const { Option } = Select;
  const { Dragger } = Upload;

  const props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    customRequest: dummyRequest,
    beforeUpload: beforeUpload,
    onChange(info: any) {
      let fileObj = info.file;
      const isSize = fileObj.size / 1024 / 1024 < 10;
      if (!isSize) {
        message.error("รูปภาพควรมีขนาดไฟล์ไม่เกิน 10MB!");
      } else {
        if (fileObj.type === "image/jpeg" || fileObj.type === "image/png") {
          getBase64(info.file.originFileObj, (imageUrl: any) => {
            setImageUrl(imageUrl);
            setImageFile(info.file.originFileObj);
          });
        } else {
          message.error(
            "อัปโหลดไฟล์ไม่สำเร็จ กรุณาอัปโหลดไฟล์รูปภาพ (png, jpg) เท่านั้น "
          );
          return false;
        }
      }
    },

    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const onSubmit = () => {
    form.submit();
  };

  return (
    <div>
      <Card
        extra={<>อัพโหลด</>}
        actions={[
          <Row justify="end">
            <Col>
              <Button type="default" onClick={history.goBack}>
                ยกเลิก
              </Button>
            </Col>
            <Col className="pl-1">
              <Button className="btn-save" onClick={() => onSubmit()}>
                บันทึก
              </Button>
            </Col>
          </Row>,
        ]}
        className="card-for-tabs"
      >
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Row gutter={[14, 14]}>
            <Col lg={8}>
              <Form.Item
                label="ชื่อภาพ"
                name="media_name"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                label="ประเภทมีเดีย"
                name="media_category_id"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Select placeholder="โปรดเลือก">
                  {mediaCategory.map((item) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                label="รูปภาพ"
                name="media"
                rules={[
                  {
                    required: !_isEmpty(imageUrl) ? false : true,
                    message: "โปรดกรอกข้อมูล!",
                  },
                ]}
              >
                <Dragger {...props}>
                  {!_isEmpty(imageUrl) ? (
                    <img src={imageUrl} alt="media" />
                  ) : (
                    <>
                      <p className="ant-upload-drag-icon">
                        <img src={UploadIcon} alt="media" />
                      </p>
                      <p className="header-xs">
                        กด หรือ ลาก ไฟล์ไปยังพื้นที่อัพโหลดนี้
                      </p>
                      <p>สำหรับอัพโหลดไฟล์เดียว</p>
                    </>
                  )}
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default OneFile;
