import React from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Selector from "../../../component/selector/Selector";

type Props = {
  onFinish: (values: any) => void;
  mediaCategory: any[];
};
const Headers = ({ onFinish, mediaCategory }: Props) => {
  return (
    <div>
      <Form onFinish={onFinish}>
        <Card className="card-round">
          <Row gutter={14}>
            <Col lg={8}>
              <Form.Item name="media_name">
                <Input placeholder="ค้นหาชื่อ" />
              </Form.Item>
            </Col>
            <Col lg={6}>
              <Form.Item name="media_category_id">
                <Selector Source={mediaCategory} keyValue="id" keyName="name" />
              </Form.Item>
            </Col>
            <Col lg={3} className="d-flex justify-between">
              <Button className="mr-1 btn-search" htmlType="submit" block>
                <SearchOutlined className="search-icons" /> ค้นหา
              </Button>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Headers;
