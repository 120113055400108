import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import MoTable from "../../../component/table/Table";
import { useHistory } from "react-router-dom";
import {
  delProductCategory,
  getProductCategory,
  putProductCategoryActive,
} from "../../../services/api/product_category";
import { IResStatus } from "../../../tools/fecth/interface";
import { IProductCategory } from "../../../interface/IProductCategory.interface";
import { IEditor } from "../../../interface/IEditor.interface";
import { openNotification } from "../../../component/notification/Notification";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import { useSetRecoilState } from "recoil";
import Modaler from "../../../component/modaler/Modaler";
import { DateTimeStandard } from "../../../tools/date";
import { _isEmpty } from "../../../tools/util";

type Props = {
  filterValue: any;
};

const Category = ({ filterValue }: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [productCategoryList, setProductCategoryList] = useState<
    IProductCategory[]
  >([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [id, setId] = useState<number>(0);

  const setLoading = useSetRecoilState(loadingState);

  const history = useHistory();

  useEffect(() => {
    if (!_isEmpty(filterValue)) {
      setCurrentPage(1);
    }
    handleGetData();
    // eslint-disable-next-line
  }, [currentPage, limitPage, filterValue]);

  const handleGetData = () => {
    handleGetProductCategory();
  };

  const handleGetProductCategory = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await getProductCategory({
        page: currentPage,
        limit: limitPage,
        ...filterValue,
      });
      if (res.code === 200) {
        setProductCategoryList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUpdateProductCategoryActive = async (id: number) => {
    setLoading(true);
    try {
      const res: IResStatus = await putProductCategoryActive(id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetData();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleleteProductCategory = async (id: number) => {
    setLoading(true);
    try {
      const res: IResStatus = await delProductCategory(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetProductCategory();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    onModal();
    setLoading(false);
  };
  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };
  const onClickRow = (event: any) => {
    history.push("/product/category/" + event.id);
  };
  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleleteProductCategory(id);
  };
  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "10%",
      render: (status: boolean, data: any) => {
        return (
          <>
            <Switch
              checked={status}
              onChange={() => handleUpdateProductCategoryActive(data.id)}
            />
          </>
        );
      },
    },
    {
      title: "ชื่อประเภทสินค้า",
      dataIndex: "name_th",
      key: "name_th",
      width: "60%",
      render: (name_th: string) => {
        return (
          <>
            <b>{name_th}</b>
          </>
        );
      },
    },

    {
      title: "อัปเดต",
      dataIndex: "edit_by",
      key: "edit_by",
      width: "20%",
      render: (editBy: IEditor, data: IProductCategory) => {
        return (
          <>
            {editBy.first_name} {editBy.last_name}
            <br />
            {!_isEmpty(data.updated_at)
              ? DateTimeStandard(data.updated_at)
              : DateTimeStandard(data.created_at)}
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      width: "20%",

      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                setId(data.id);
                onModal();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div>
        <Modaler
          visible={visible}
          onCancel={onModal}
          onOk={onDelete}
          width="25%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              คุณแน่ใจไหม?
            </div>
          }
        >
          คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
        </Modaler>
        <div className="card-table-tab">
          <MoTable
            dataSource={productCategoryList}
            columns={columns}
            onChangePage={onChangePage}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            onClickRow={onClickRow}
          />
        </div>
      </div>
    </div>
  );
};

export default Category;
