import { Layout } from "antd";
import React from "react";

const TheFooter = () => {
  const { Footer } = Layout;

  return (
    <>
      <Footer style={{ textAlign: "center" }}>
        {/* Ant Design ©2018 Created by Ant UED */}
      </Footer>
    </>
  );
};

export default TheFooter;
