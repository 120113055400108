import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { getProductCategoryOnlyType } from "../../../services/api/product_category";
import { IResStatus } from "../../../tools/fecth/interface";
import Selector from "../../../component/selector/Selector";
type Props = {
  onFinish: (values: any) => void;
};
const Headers = ({ onFinish }: Props) => {
  const [categoryOnlyTypeList, setCategoryOnlyTypeList] = useState([]);
  useEffect(() => {
    handleGetProductCategoryOnlyType();
  }, []);

  const handleGetProductCategoryOnlyType = async () => {
    try {
      const res: IResStatus = await getProductCategoryOnlyType();
      if (res.code === 200) {
        setCategoryOnlyTypeList(res.result);
      }
    } catch (error) {}
  };

  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Card className="card-round">
          <Row gutter={[14, 14]}>
            <Col lg={9}>
              <Form.Item name="search">
                <Input placeholder="SKU / ค้นหาชื่อสินค้า" />
              </Form.Item>
            </Col>
            <Col lg={5}>
              <Form.Item name="product_category_type">
                <Selector
                  Source={categoryOnlyTypeList}
                  keyName="name_th"
                  keyValue="id"
                  placeholder="เลือกกลุ่มย่อย"
                />
              </Form.Item>
            </Col>
            <Col lg={5} className="d-flex justify-between">
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Headers;
