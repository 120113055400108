import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row } from "antd";
import { useState } from "react";
import NewMediaModal from "../newmediamodal/NewMediaModal";

const UploadImage = ({ onChange, value, maxCount }: any) => {
  const [visible, setVisible] = useState(false);

  const onModal = async () => {
    setVisible(!visible);
  };

  const onDeleteImage = (index: number) => {
    const filterListImage = value.filter(
      (_item: any, ind: number) => ind !== index
    );
    onChange(filterListImage);
  };

  return (
    <div>
      <Button
        icon={<UploadOutlined />}
        disabled={false}
        block
        type="dashed"
        onClick={onModal}
      >
        อัพโหลด
      </Button>
      <NewMediaModal
        visible={visible}
        onModal={onModal}
        onChange={onChange}
        value={value}
        maxCount={maxCount}
      />

      {value.length > 0 &&
        value.map((item: any, ind: number) => {
          return (
            <div className="list-image-upload mt-05" key={ind}>
              <Row className="h-100">
                <Col
                  lg={8}
                  className="d-flex justify-start items-center img-upload-padding"
                >
                  <div
                    onClick={() => window.open(item.file_path)}
                    className="cursor-pointer"
                  >
                    <img
                      src={item.file_path}
                      className="img-upload-squad"
                      alt="upload"
                    />
                  </div>
                </Col>
                <Col lg={8} className="d-flex justify-center items-center">
                  <span className="cut-text-1-row cursor-pointer">
                    <Popover content={item.media_name}>
                      {item.media_name}
                    </Popover>
                  </span>
                </Col>
                <Col
                  lg={8}
                  className="d-flex justify-flex-end items-center h-100"
                >
                  <Button
                    className="btn-delete-upload"
                    onClick={() => onDeleteImage(ind)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          );
        })}
    </div>
  );
};

export default UploadImage;
