import OneFile from "./OneFile";
import { Tabs } from "antd";
import { useState } from "react";
import ManyFile from "./ManyFile";

const Fields = () => {
  const [tabActive, setTabActive] = useState("1");
  const { TabPane } = Tabs;
  const callback = (key: any) => {
    setTabActive(key);
  };
  return (
    <div>
      <Tabs
        className="tab-mainmenu"
        defaultActiveKey={tabActive}
        onChange={callback}
      >
        <TabPane tab="ไฟล์" key="1">
          <OneFile />
        </TabPane>
        <TabPane tab="หลายไฟล์" key="2">
          <ManyFile />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Fields;
