import React from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";

import { useHistory } from "react-router";
import { _appendFields, _isHaveKey } from "../../../../tools/util";
import { IResStatus } from "../../../../tools/fecth/interface";
import { postMediaFile } from "../../../../services/api/media";
import { openNotification } from "../../../../component/notification/Notification";
import FileSpace from "../../../../component/filespace/FileSpace";

const OneFile = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values: any) => {
    handleCreateMedia(values);
  };

  const handleCreateMedia = async (value: any) => {
    try {
      const data = {
        ...value,
        file: value.media.file.originFileObj,
      };
      const newData = _isHaveKey(data);
      const dataValue = _appendFields(newData);
      const res: IResStatus = await postMediaFile(dataValue);

      if (res.code === 200) {
        openNotification({
          method: "POST_SUCCESS",
        });
        history.push("/media");
      } else {
        openNotification({
          method: "POST_ERROR",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFileName = (file: any) => {
    const fileName = file.name.split(".");

    form.setFieldsValue({ media_name: fileName[0] });
    return;
  };
  return (
    <div>
      <Card
        extra={<>อัพโหลด</>}
        actions={[
          <Row justify="end">
            <Col>
              <Button type="default" onClick={history.goBack}>
                ยกเลิก
              </Button>
            </Col>
            <Col className="pl-1">
              <Button className="btn-save" onClick={() => form.submit()}>
                บันทึก
              </Button>
            </Col>
          </Row>,
        ]}
        className="card-for-tabs"
      >
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Row gutter={[14, 14]} justify="center">
            <Col lg={8}>
              <Form.Item
                label="ชื่อไฟล์"
                name="media_name"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={8}>
              <Form.Item
                label="ไฟล์"
                name="media"
                rules={[
                  {
                    required: true,
                    message: "โปรดกรอกข้อมูล!",
                  },
                ]}
              >
                <FileSpace fileType="file" getFileName={getFileName} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default OneFile;
