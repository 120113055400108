import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Input, Row, Tabs } from "antd";

import Selector from "../../../../component/selector/Selector";
import CCheckBoxGroup from "../../../../component/checkboxgroup/CCheckBoxGroup";
// import FileSpace from "../../../../component/filespace/FileSpace";
import { useHistory, useRouteMatch } from "react-router-dom";
import SKUFields from "./sku/Sku";
import ColorsFields, { ISelectColor } from "./colors/Colors";
import Product from "./product/Product";
import { getInstallation } from "../../../../services/api/installation";
import { IResStatus } from "../../../../tools/fecth/interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../../tools/recoil/recoil-atom";
import { IInstallation } from "../../../../interface/IInstallation.interface";
import { getDoorOpenPattern } from "../../../../services/api/door_open_pattern";
import { IDoorOpenPattern } from "../../../../interface/IDoorOpenPattern.interface";
import {
  getProductCategory,
  getProductCategoryOnlyType,
} from "../../../../services/api/product_category";
import { IProductCategory } from "../../../../interface/IProductCategory.interface";
import {
  deleteKeyArrayOfObject,
  findItemByID,
  findItemByKey,
  seleteKeyArrayOfObject,
  undefinedToNullKey,
  _isEmpty,
} from "../../../../tools/util";
import { gelLogDeviceList } from "../../../../services/api/log_device";
import { ILogDevice } from "../../../../interface/ILogDevice.interface";

import {
  getProductsById,
  getProductsCheckSKU,
  postProducts,
  putProducts,
} from "../../../../services/api/product";
import { openNotification } from "../../../../component/notification/Notification";
import { IGallery } from "../../../../interface/IGallery.interface";
import { getColors } from "../../../../services/api/colors";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import {
  IColorInitial,
  IDefaultColor,
} from "../../../../interface/IColors.inteface";
import { IMirror } from "../../../../interface/IMirror.interface";
import { getGlass } from "../../../../services/api/glass";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Modaler from "../../../../component/modaler/Modaler";

const Fields = () => {
  const setLoading = useSetRecoilState<boolean>(loadingState);

  const [formMain] = Form.useForm();
  const [formSKU] = Form.useForm();
  const [formSize] = Form.useForm();
  const [formSizeWidth] = Form.useForm();
  const [formSizeHeight] = Form.useForm();
  const [formColor] = Form.useForm();

  const [formSkuDetail] = Form.useForm();

  const [installationList, setInstallationList] = useState<IInstallation[]>([]);
  const [doorOpenPatternList, setDoorOpenPatternList] = useState<
    IDoorOpenPattern[]
  >([]);
  const [productCategoryList, setProductCategoryList] = useState<any[]>([]);
  const [productCategoryTypeList, setProductCategoryTypeList] = useState<any[]>(
    []
  );
  const [logDeviceList, setLogDeviceList] = useState<ILogDevice[]>([]);
  const [mirrorList, setMirrorList] = useState<IMirror[]>([]);

  const [productGallery, setProductGallery] = useState<IGallery[]>([]);
  const [colorsList, setColorsList] = useState<IColorInitial[] | any[]>([]);
  const [skuList, setSkuList] = useState<any[]>([]);

  const [dataFormSKU, setDataFormSKU] = useState<any>({});
  const [dataFormSKUDetail, setDataFormSKUDetail] = useState<any>({});
  const [dataFormColor, setDataFormColor] = useState<ISelectColor | [] | any>(
    []
  );
  const [defaultColor, setDefaultColor] = useState<string | null>();
  const [editSKU, setEditSKU] = useState<boolean>(false);
  const [colorsFormatList, setColorFormatList] = useState<any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);

  const [mediaSelectCoverImage, setMediaSelectCoverImage] =
    useState<IMediaSelect>({
      file: "",
      id: "",
      media_name: "",
    });
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const { TabPane } = Tabs;

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    if (!_isEmpty(pathID)) {
      handleGetProductsById();
      setEditSKU(true);
    }
    handleGetInstallation();
    handleGetDoorOpenPattern();
    handleGetProductCategory();
    handleGetLogDevice();
    handleGetProductCategoryGetType();
    handleGetColors();
    handleGetMirror();
  };

  const handleGetProductsById = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getProductsById(pathID);
      if (res.code === 200) {
        handleSetData(res.result[0]);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSetData = (data: any) => {
    formMain.setFieldsValue({
      title_detail_en: data.title_detail_en,
      title_detail_th: data.title_detail_th,
      title_en: data.title_en,
      title_th: data.title_th,
      product_category: data.product_category,
      desigh_detail_th: data.desigh_detail_th,
      desigh_detail_en: data.desigh_detail_en,
      product_detail_en: data.product_detail_en,
      product_detail_th: data.product_detail_th,
      cover_description_en: data.cover_description_en,
      cover_description_th: data.cover_description_th,
    });
    setMediaSelectCoverImage({
      file: data.cover_image?.file_path,
      id: data.cover_image?.id,
      media_name: data.cover_image?.media_name,
    });

    setProductGallery(
      seleteKeyArrayOfObject(data.gallerys, ["id", "media_name", "file_path"])
    );

    formSizeWidth.setFieldsValue({
      sizeWidthList: data.size?.W,
    });

    formSizeHeight.setFieldsValue({
      sizeHeightList: data.size?.H,
    });

    formSKU.setFieldsValue({ ...data.choice });

    const variantProductIsFiltered = deleteKeyArrayOfObject(
      data.variant_product,
      ["created_at", "updated_at", "is_delete", "deleted_at"]
    );

    setDefaultColor(`${data.default_colors}`);
    setSkuList(variantProductIsFiltered);
    formSkuDetail.setFieldsValue({
      sku: [...variantProductIsFiltered],
      sku_default: data.sku_default,
    });
    handleSetColorsList(data.colors);
  };

  const handleGetInstallation = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getInstallation({ is_active: true });
      if (res.code === 200) {
        setInstallationList(res.result[0].item);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleGetDoorOpenPattern = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getDoorOpenPattern({ is_active: true });
      if (res.code === 200) {
        setDoorOpenPatternList(res.result[0].item);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleGetProductCategory = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await getProductCategory({ is_active: true });
      if (res.code === 200) {
        setProductCategoryList(res.result[0].item);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetLogDevice = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await gelLogDeviceList({ is_active: true });
      if (res.code === 200) {
        setLogDeviceList(res.result[0].item);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetMirror = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getGlass({ is_active: true, limit: 0 });
      if (res.code === 200) {
        setMirrorList(res.result[0].item);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetProductCategoryGetType = async () => {
    setLoading(true);
    try {
      const res: IResStatus = await getProductCategoryOnlyType({
        is_active: true,
      });
      if (res.code === 200) {
        setProductCategoryTypeList(res.result);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onFinishMain = (values: any) => {
    let SKU: any = {};
    let SKUDetail: any = {};

    SKUDetail = formSkuDetail.getFieldsValue();
    if (!_isEmpty(pathID)) {
      SKU = formSKU.getFieldsValue();
    }
    const galleryIDList = findItemByKey("id", productGallery);
    // const lockKits = !_isEmpty(pathID)
    //   ? SKU?.lock_kit_file?.id
    //   : dataFormSKU?.lock_kit_file?.id;
    // if (!lockKits) {
    //   return openNotification({
    //     type: "error",
    //     message: "ไม่สำเร็จ",
    //     description: "โปรดอัพโหลดอุปกรณ์ล็อค",
    //     custom: true,
    //   });
    // }
    const dataNewSKUDetail = dataFormSKUDetail.map((item: any) => {
      item.lock_kit_file = item.lock_kit_file
        ? item.lock_kit_file.id
        : item.lock_kit_file;
      if (item.glass) {
        return item;
      }
      item.glass = [];
      return item;
    });

    let allData = {
      ...values,
      gallerys: galleryIDList,
      sku: !_isEmpty(pathID) ? SKU.sku_name : dataFormSKU.sku_name,
      // variant_product: !_isEmpty(pathID) ? SKUDetail.sku : dataFormSKUDetail,
      variant_product: dataNewSKUDetail,
      choice: !_isEmpty(pathID) ? { ...SKU } : { ...dataFormSKU },
      // lock_kit_file: lockKits,
      default_colors: Number(defaultColor),
      colors: dataFormColor,
      sku_default: SKUDetail.sku_default,
      cover_image: mediaSelectCoverImage.id,
    };

    if (_isEmpty(defaultColor)) {
      openNotification({
        type: "error",
        message: "ไม่สำเร็จ",
        description: "โปรดเลือกสีหลัก",
        custom: true,
      });
    } else if (!_isEmpty(pathID)) {
      updateProducts(allData);
    } else if (_isEmpty(pathID)) {
      createProducts(allData);
    }
  };

  const onFinishSKU = async (values: any) => {
    setDataFormSKU({ ...values });
    const skuResult = await handleCheckSKU(values.sku_name);
    if (!skuResult) {
      return;
    }
    const productTypeData = findItemByID(
      values.product_type,
      productCategoryList
    );
    const installationData = findItemByID(
      values.installation,
      installationList
    );
    const openDoorData = findItemByID(values.dooropen, doorOpenPatternList);

    let newSku = cross(
      values.sku_name,
      productTypeData,
      installationData,
      openDoorData
    );
    const skuDetail = formSkuDetail.getFieldsValue();
    formSkuDetail.resetFields();
    if (skuList.length > 0) {
      newSku = checkSkuMatch(newSku, skuDetail.sku, values.sku_name);
      setSkuList(newSku);
    }

    formSkuDetail.setFieldsValue({ sku: [...newSku] });
    setEditSKU(true);
  };

  const handleCheckSKU = async (skuName: string) => {
    try {
      setLoading(true);
      const qrsHaveID = { sku: skuName, id: pathID };
      const qrsNotHaveID = { sku: skuName, id: pathID };
      const res: IResStatus = await getProductsCheckSKU(
        !_isEmpty(pathID) ? qrsHaveID : qrsNotHaveID
      );

      if (res.code === 200) {
        if (res.result[0].already_have) {
          openNotification({
            type: "warning",
            message: "มีชื่อ SKU นี้ในระบบแล้ว ",
            description: "โปรดเลือกชื่อ SKU อื่น",
            custom: true,
          });
          setLoading(false);
          return false;
        } else {
          setLoading(false);
          return true;
        }
      }
      setLoading(false);
    } catch (error) {}
  };

  const checkSkuMatch = (newSku: any[], oldSku: any[], sku: string) => {
    const ListMatch = oldSku.filter((item: any) => {
      return newSku.some((val: any) => {
        return (
          item.options.installation === val.options.installation &&
          item.options.open_type === val.options.open_type &&
          item.options.product_type === val.options.product_type
        );
      });
    });

    if (ListMatch.length === 0) {
      return newSku;
    } else if (
      ListMatch.length === oldSku.length &&
      ListMatch.length === newSku.length
    ) {
      return oldSku;
    }

    let mergeList: any[] = [];

    newSku.forEach((item) => {
      const newListMerge = ListMatch.find(
        (val: any) =>
          item.options.installation === val.options.installation &&
          item.options.open_type === val.options.open_type &&
          item.options.product_type === val.options.product_type
      );

      if (newListMerge) mergeList = [...mergeList, newListMerge];
      else {
        mergeList = [...mergeList, item];
      }
    });

    return mergeList;
  };

  const onFinishSKUDetail = (values: any) => {
    const data = removeKey(values.sku);
    const newSkuDetail = data.map((val) => {
      const resultNullKey = undefinedToNullKey(val);
      return resultNullKey;
    });
    setDataFormSKUDetail([...newSkuDetail]);
  };

  const onColorsChange = (colorList: any) => {
    setDataFormColor(colorList);
  };

  const removeKey = (list: any[]) => {
    const newList = list.map((item: any) => {
      delete item["sku"];
      return item;
    });
    return newList;
  };

  const cross = (
    sku: string,
    productTypeData: IProductCategory[],
    installationList: IInstallation[],
    doorOpenPatternList: IDoorOpenPattern[]
  ) => {
    const newList: any = [];
    productTypeData.forEach((item) => {
      installationList.forEach((val) => {
        doorOpenPatternList.forEach((data) => {
          newList.push({
            sku,
            name_option: item.name_th + " " + val.name_th + " " + data.name_th,
            options: {
              open_type: data.id,
              installation: val.id,
              product_type: item.id,
            },

            description: "",
          });
        });
      });
    });
    return newList;
  };

  const handleGetColors = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getColors({ is_active: true });
      if (res.code === 200) {
        const data = res.result[0].item.map((item: any) => {
          return {
            name: item.name_th,
            id: `${item.id}`,
            image: item.media.file_path,
            media: { file: "", id: "", media_name: "" },
          };
        });
        setColorFormatList(data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSetColorsList = (list: IDefaultColor[]) => {
    const data = list.map((item) => {
      return {
        name: item.color.name_th,
        id: `${item.color.id}`,
        image: item.color.media.file_path,
        media: {
          file: !_isEmpty(item.media) ? item.media.file_path : "",
          id: !_isEmpty(item.media) ? item.media.id : "",
          media_name: !_isEmpty(item.media) ? item.media.media_name : "",
        },
      };
    });

    const findedID = findItemByKey("id", data);
    formColor.setFieldsValue({ colors: findedID });
    const reData = data?.map((i) => {
      return { color: Number(i.id), media: Number(i.media.id) };
    });
    setColorsList(data);
    setDataFormColor(reData);
  };

  const createProducts = async (data: any) => {
    try {
      const res: IResStatus = await postProducts(data);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({
          type: "error",
          message: "สร้างไม่สำเร็จ",
          description: res.message,
          custom: true,
        });
      }
    } catch (error) {}
  };

  const updateProducts = async (data: any) => {
    try {
      const res: IResStatus = await putProducts(data, pathID);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({
          type: "error",
          message: "อัปเดตไม่สำเร็จ",
          description: res.message,
          custom: true,
        });
      }
    } catch (error) {}
  };

  const handleDuplicateSKU = (options: any) => {
    const currentList = formSkuDetail.getFieldsValue();
    formSkuDetail.resetFields();

    const findedSKU = currentList.sku.find((item: any) => {
      return (
        item.options.installation === options.installation &&
        item.options.open_type === options.open_type &&
        item.options.product_type === options.product_type
      );
    });
    delete findedSKU.id;
    const mergeList = [...currentList.sku, { ...findedSKU, duplicate: true }];
    setSkuList(mergeList);

    formSkuDetail.setFieldsValue({ sku: mergeList });
  };

  const handleDeleteDuplicateSKU = (options: any) => {
    const currentList = formSkuDetail.getFieldsValue();
    formSkuDetail.resetFields();
    const filterDuplicate = currentList.sku.filter((item: any) => {
      return !item.duplicate;
    });
    setSkuList(filterDuplicate);

    formSkuDetail.setFieldsValue({ sku: filterDuplicate });
  };

  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={() => {
          setVisible(!visible);
        }}
        onOk={() => {
          setVisible(!visible);
        }}
        width="25%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            คุณต้องเลือก
          </div>
        }
      >
        กรุณาเพิ่มรูปสินค้าของแต่ละสี
      </Modaler>
      <Product
        formMain={formMain}
        onFinish={onFinishMain}
        productCategoryTypeList={productCategoryTypeList}
        productGallery={productGallery}
        setProductGallery={setProductGallery}
        mediaSelectCoverImage={mediaSelectCoverImage}
        setMediaSelectCoverImage={setMediaSelectCoverImage}
      />
      <Card
        className="card-out-padding mt-1"
        actions={[
          <Row justify="end">
            <Col>
              <Button type="default" onClick={history.goBack}>
                ยกเลิก
              </Button>
            </Col>
            <Col className="pl-1">
              <Button
                className="btn-save"
                onClick={() => {
                  const colorNoneFile = colorsList?.filter((item) => {
                    if (!item?.media?.file) {
                      return item;
                    } else return null;
                  });

                  if (!!colorNoneFile?.length) {
                    setVisible(!visible);
                  } else {
                    formSkuDetail.submit();
                    formSize.submit();
                    formMain.submit();
                    formSizeWidth.submit();
                    formSizeHeight.submit();
                  }
                }}
              >
                บันทึก
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Tabs defaultActiveKey="1" className="tab-basic">
          <TabPane tab="สีอลูมิเนียม" key="1">
            <Card className="card-bg-grey mt-1" extra="เลือกเพื่อเพิ่ม SKU">
              <Form onFinish={onFinishSKU} layout="vertical" form={formSKU}>
                <Row gutter={[14, 14]}>
                  <Col lg={24}>
                    <Form.Item
                      name="product_type"
                      label="ประเภทสินค้า"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                    >
                      <CCheckBoxGroup
                        options={productCategoryList}
                        keyName="name_th"
                        keyValue="id"
                        disabled={editSKU}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={24}>
                    <Form.Item
                      name="installation"
                      label="รูปแบบการติดตั้ง"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                    >
                      <Selector
                        Source={installationList}
                        keyName="name_th"
                        keyValue="id"
                        options={{ mode: "multiple" }}
                        disabled={editSKU}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item
                      name="dooropen"
                      label="รูปแบบการเปิดปิด"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                    >
                      <Selector
                        Source={doorOpenPatternList}
                        keyName="name_th"
                        keyValue="id"
                        options={{ mode: "multiple" }}
                        disabled={editSKU}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item
                      name="sku_name"
                      label="ชื่อ SKU"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                    >
                      <Input disabled={editSKU} />
                    </Form.Item>
                  </Col>
                  {/* <Col lg={6} sm={24}>
                    <Form.Item
                      name="lock_kit_file"
                      label="อุปกรณ์ล็อค/มือจับ"
                      rules={[{ required: true, message: "โปรดอัพโหลดไฟล์!" }]}
                    >
                      <MediaModalFile
                        onDelete={() => {
                          formSKU.setFieldsValue({ lock_kit_file: undefined });
                        }}
                        fileExtension="exel"
                      />
                    </Form.Item>
                  </Col> */}
                  <Col lg={24}>
                    <Button type="primary" htmlType="submit" disabled={editSKU}>
                      สร้าง SKU
                    </Button>
                    <Button
                      className="ml-1"
                      disabled={!editSKU}
                      onClick={() => setEditSKU(false)}
                    >
                      แก้ไขปรับตำแหน่งรายการ
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
            <Card className="bg-grey mt-1 " extra="กรอก SKU">
              <SKUFields
                logDeviceList={logDeviceList}
                mirrorList={mirrorList}
                // options={skuList}
                onFinish={onFinishSKUDetail}
                formSkuDetail={formSkuDetail}
                dataFormSKUDetail={dataFormSKUDetail}
                handleDuplicateSKU={handleDuplicateSKU}
                handleDeleteDuplicateSKU={handleDeleteDuplicateSKU}
              />
            </Card>
            <h3 className="mb-1 mt-1 ">
              {" "}
              <label style={{ color: "#ff9c6e" }}>* </label>เลือกสี
            </h3>
            <Card className="card-out-padding">
              <ColorsFields
                formColor={formColor}
                onChange={onColorsChange}
                colorsList={colorsList}
                setColorsList={setColorsList}
                colorsFormatList={colorsFormatList}
              />
            </Card>
            <Row gutter={[14, 14]} className="mt-1">
              <Col lg={8}>
                <h4 className="mb-1 mt-1 ">
                  <label style={{ color: "#ff9c6e" }}>* </label>สีเริ่มต้น
                </h4>
                <Selector
                  Source={colorsList}
                  keyName="name"
                  keyValue="id"
                  value={defaultColor}
                  onChange={(e: any) => {
                    setDefaultColor(e);
                  }}
                  disabled={!_isEmpty(colorsList) ? false : true}
                />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default Fields;
