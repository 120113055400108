import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, FormInstance, Row } from "antd";
import { useState } from "react";
import MediaSpace from "../../../../../component/mediaspace/MediaSpace";
import Modaler from "../../../../../component/modaler/Modaler";
import Selector from "../../../../../component/selector/Selector";
import { IMediaSelect } from "../../../../../interface/IMedia.interface";
import { _isEmpty } from "../../../../../tools/util";
import MediaModal from "../../../../../component/mediamodal/MediaModal";
import CImage from "../../../../../component/image/Image";
import {
  IColorInitial,
  IColors,
} from "../../../../../interface/IColors.inteface";
export interface ISelectColor {
  color: number;
  media: number;
}
type Props = {
  onChange: (colorList: ISelectColor[]) => void;
  colorsList: IColorInitial[] | any[];
  setColorsList: React.Dispatch<React.SetStateAction<IColorInitial[] | any[]>>;
  colorsFormatList: IColors[];
  formColor: FormInstance<any>;
};
const ColorsFields = ({
  onChange,
  colorsList,
  setColorsList,
  colorsFormatList,
  formColor,
}: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleColor, setVisibleColor] = useState<boolean>(false);
  const [colorSelect, setColorSelect] = useState<any>({
    name: "",
    id: 0,
    media: {
      file: "",
      id: "",
      media_name: "",
    },
  });

  const DataInitial = {
    name: "",
    id: 0,
    media: {
      file: "",
      id: "",
      media_name: "",
    },
  };

  const onModal = () => {
    setVisible(!visible);
  };
  const onModalColor = () => {
    setVisibleColor(!visibleColor);
  };

  const onOK = () => {
    formColor.submit();
    onModal();
    setColorSelect({ ...DataInitial });
  };

  const handleSelectList = (colorValue: any) => {
    setColorSelect({ ...DataInitial });
    if (colorValue.id !== colorSelect.id) {
      setColorSelect({ ...colorValue });
    }
  };

  const handleFindColor = (values: { colors: number[] }) => {
    const listMatch = Array.from(
      new Map(
        [...colorsFormatList, ...colorsList]?.map((item) => [item?.id, item])
      ).values()
    )?.filter((item) => {
      return values.colors.some((val) => {
        return item.id === val;
      });
    });
    const findedID = findItemBy2Key(listMatch);
    const reList = listMatch.map((i) => {
      return { ...i, id: Number(i.id) };
    });
    setColorsList(reList);
    onChange(findedID);
  };

  const handleSelectImage = (media: IMediaSelect) => {
    const reColorSelect = { ...colorSelect, id: Number(colorSelect?.id) };

    setColorSelect({ ...reColorSelect, media: media });
    const newColorSelect = { ...reColorSelect, media: media };
    const newColorsList = colorsList.map((item: any) => {
      if (newColorSelect.id === item.id) {
        return { ...newColorSelect, id: Number(newColorSelect?.id) };
      }
      return { ...item, id: Number(item?.id) };
    });
    setColorsList([...newColorsList]);
    const findedID = findItemBy2Key(newColorsList);

    onChange(findedID);
  };

  const findItemBy2Key = (list: any[]) => {
    const newList = list.map((item: any) => {
      return { color: Number(item.id), media: item.media.id };
    });
    return newList;
  };

  return (
    <div>
      <MediaModal
        onModal={onModalColor}
        visible={visibleColor}
        handleSelectMedia={handleSelectImage}
        onChange={handleSelectImage}
      />
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={onOK}
        header="เพิ่มสี"
        footer={false}
        closable={false}
      >
        <Form onFinish={handleFindColor} form={formColor}>
          <Row>
            <Col lg={22}>
              <Form.Item label="colors" name="colors">
                <Selector
                  Source={colorsFormatList}
                  keyName="name"
                  keyValue="id"
                  options={{ mode: "tags", tokenSeparators: [","] }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modaler>
      <Row>
        <Col lg={8}>
          <Card
            extra={
              <>
                <Row justify="center">
                  <Col>
                    <Button className="d-flex items-center" onClick={onModal}>
                      <PlusOutlined />
                      เลือกสี
                    </Button>
                  </Col>
                </Row>
              </>
            }
            className="colors-card h-100"
          >
            <Row>
              {colorsList.length > 0
                ? colorsList.map((item: any, idx: number) => (
                    <Col
                      key={idx}
                      span={24}
                      className={`d-flex  items-center list mt-05 ${
                        colorSelect.id === item.id ? "active-list" : ""
                      }`}
                      onClick={() => handleSelectList(item)}
                    >
                      <CImage
                        src={item.image}
                        alt="colors"
                        className="img-upload-squad"
                      />
                      <div className="pl-1">{item.name}</div>
                    </Col>
                  ))
                : "โปรดเลือกสี"}
            </Row>
          </Card>
        </Col>
        <Col lg={16}>
          <Row justify="center">
            <Col lg={20} className="p-1">
              {colorsList.length > 0 && !_isEmpty(colorSelect.name) ? (
                <MediaSpace
                  key={colorSelect.id ? "load" : "success"}
                  onModal={onModalColor}
                  mediaSelect={colorSelect.media}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ColorsFields;
