import React, { Dispatch, SetStateAction, useState } from "react";
import { Modal, Tabs } from "antd";
import Drager from "./Drager";
import Overall from "./overall/Overall";
import URL from "./URL";

import {
  IMedia,
  IMediaCategory,
  IMediaSearch,
  IMediaSelect,
} from "../../interface/IMedia.interface";
import { IResStatus } from "../../tools/fecth/interface";
import { getMedia, getMediaCategory } from "../../services/api/media";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../tools/recoil/recoil-atom";
import { _isEmpty } from "@yim/util-tool-ts";

type Props = {
  visible?: boolean;
  browse?: boolean;
  onModal?: () => void;
  onUpload?: () => void;
  onOk?: () => void;
  setMediaSelect?: Dispatch<SetStateAction<IMediaSelect>>;
  setGellery?: Dispatch<SetStateAction<any>>;
  gallery?: any[];
  handleSelectMedia?: any;
  onChange?: any;
  value?: any;
};
const MediaModal = ({
  visible,
  onModal,
  browse = true,
  setMediaSelect,
  setGellery,
  gallery,
  handleSelectMedia,
  onChange,
  value,
}: Props) => {
  const { TabPane } = Tabs;
  const [tabSelected, setTabSelected] = useState("1");
  const [isFirst, setIsFirst] = useState(true);

  const setLoading = useSetRecoilState<boolean>(loadingState);

  const [mediaCategoryList, setMediaCategoryList] = useState<IMediaCategory[]>(
    []
  );
  const [mediaList, setMediaList] = useState<IMedia[]>([]);
  const [total, setTotal] = useState<number>(1);
  React.useEffect(() => {
    handleMediCategory();
    // handleGetMedia();
    if (isFirst && !_isEmpty(value)) {
      onChange(value);
    }
    setIsFirst(false);
    // eslint-disable-next-line
  }, []);

  const handleMediCategory = async () => {
    try {
      const res: IResStatus = await getMediaCategory();
      if (res.code === 200) {
        setMediaCategoryList(res.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetMedia = async (search?: IMediaSearch) => {
    setLoading(true);
    try {
      const data = {
        ...search,
        media_name: !search?.media_name ? "" : search.media_name,
        media_category_id: !search?.media_category_id
          ? ""
          : search.media_category_id,
        directory: "media",
      };
      const res: IResStatus = await getMedia(data);
      if (res.code === 200) {
        setMediaList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <div>
      <Modal
        footer={null}
        onCancel={onModal}
        title={
          tabSelected === "1"
            ? "BROWSE"
            : tabSelected === "2"
            ? "FROM COMPUTER"
            : "FROM URL"
        }
        visible={visible}
        width="50%"
        centered
      >
        <Tabs
          className="tab-modal"
          onChange={(e) => setTabSelected(e)}
          activeKey={tabSelected}
        >
          {browse && (
            <TabPane tab="BROWSE" key="1">
              <Overall
                handleSelectMedia={handleSelectMedia}
                onChange={onChange}
                setMediaSelect={setMediaSelect}
                onModal={onModal}
                mediaList={mediaList}
                mediaCategoryList={mediaCategoryList}
                setGellery={setGellery}
                gallery={gallery}
                handleGetMedia={handleGetMedia}
                total={total}
              />
            </TabPane>
          )}
          <TabPane tab="FROM COMPUTER" key="2">
            <Drager
              setMediaSelect={setMediaSelect}
              onModal={onModal}
              mediaCategoryList={mediaCategoryList}
              setTabSelected={setTabSelected}
              handleGetMedia={handleGetMedia}
              onChange={onChange}
            />
          </TabPane>
          <TabPane tab="FROM URL" key="3">
            <URL
              mediaCategoryList={mediaCategoryList}
              setTabSelected={setTabSelected}
              handleGetMedia={handleGetMedia}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default MediaModal;
