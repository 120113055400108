import { Row, Col, Button } from "antd";
import Fields from "./Fields";
import { useHistory, useRouteMatch } from "react-router-dom";
import BlackHome from "../../../../assets/img/icons/black-home.svg";

const Form = () => {
  const { url } = useRouteMatch();
  const history = useHistory();

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="blackHome">
            <Button
              onClick={() => history.goBack()}
              type="primary"
              shape="circle"
            >
              <img className="buttonBlackHome" src={BlackHome} alt="" />
            </Button>
          </span>
          <span className="text-head">ผู้ใช้งาน</span>
        </Col>
      </Row>
      <div className="set-layout">
        <div className="pt-1">
          <Fields url={url} />
        </div>
      </div>
    </div>
  );
};

export default Form;
