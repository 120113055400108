import { Button, Col, Form, Input, Row } from "antd";
import { _appendFields, _isHaveKey } from "../../tools/util";
import { IResStatus } from "../../tools/fecth/interface";
import { postMediaFile } from "../../services/api/media";
import { openNotification } from "../notification/Notification";
import FileSpace from "../filespace/FileSpace";
import { useRecoilState } from "recoil";
import { loadingButtonState } from "../../tools/recoil/recoil-atom";

const FormFields = ({
  fileExtension,
  setTabSelected,
  handleGetMediaFile,
}: any) => {
  const [form] = Form.useForm();
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);

  const onFinish = (values: any) => {
    handleCreateMedia(values);
  };

  const handleCreateMedia = async (value: any) => {
    setloadingButton(true);
    try {
      const data = {
        ...value,
        file: value.media.file.originFileObj,
      };
      const newData = _isHaveKey(data);
      const dataValue = _appendFields(newData);
      const res: IResStatus = await postMediaFile(dataValue);

      if (res.code === 200) {
        openNotification({
          method: "POST_SUCCESS",
        });
        handleGetMediaFile();
        setTabSelected("1");
        form.resetFields();
      } else {
        openNotification({
          method: "POST_ERROR",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const getFileName = (file: any) => {
    const fileName = file.name.split(".");

    form.setFieldsValue({ media_name: fileName[0] });
    return;
  };

  return (
    <div>
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={[14, 14]}>
          <Col lg={12}>
            <Form.Item
              label="ชื่อไฟล์"
              name="media_name"
              rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item
              label="ไฟล์"
              name="media"
              rules={[
                {
                  required: true,
                  message: "โปรดกรอกข้อมูล!",
                },
              ]}
            >
              <FileSpace
                getFileName={getFileName}
                fileType="file"
                acceptFile={fileExtension.acceptFile}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-1" gutter={[14, 14]}>
          <Col lg={3}>
            <Button
              type="primary"
              className="mr-1 w-100"
              htmlType="submit"
              loading={loadingButton}
            >
              บันทึก
            </Button>
          </Col>

          <Col lg={3}>
            <Button htmlType="reset" className="w-100">
              ล้าง
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormFields;
