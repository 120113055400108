import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tabs } from "antd";
import FileHeaders from "./file/Headers";
import ImageaHeaders from "./image/Headers";
import ImageContainer from "./image/Image";
import FileContainer from "./file/File";

import { useHistory } from "react-router-dom";

import {
  IMediaFilterSearch,
  IMediaCategory,
} from "../../interface/IMedia.interface";
import { displayHide } from "../../tools/util";
import { tabActiveMediaState } from "../../tools/recoil/recoil-atom";
import { useRecoilState } from "recoil";

const Media = () => {
  const [mediaCategoryList, setMediaCategoryList] = useState<IMediaCategory[]>(
    []
  );
  const [filterSearchImage, setFilterSearchImage] =
    useState<IMediaFilterSearch>({
      media_name: "",
      media_category_id: "",
    });
  const [fiterSearchFile, setSearchFile] = useState<IMediaFilterSearch>({
    media_name: "",
  });
  const [currentPageImage, setCurrentPageImage] = useState<number>(1);
  const [currentPageFile, setCurrentPageFile] = useState<number>(1);

  const [tabActiveMedia, setTabActiveMedia] =
    useRecoilState(tabActiveMediaState);

  const { TabPane } = Tabs;
  const history = useHistory();

  const callback = (key: any) => {
    setTabActiveMedia(key);
  };

  const onFinishImage = (values: IMediaFilterSearch) => {
    setFilterSearchImage({ ...values });
    setCurrentPageImage(1);
  };
  const onFinishFile = (values: any) => {
    setSearchFile({ ...values });
    setCurrentPageFile(1);
  };

  const handleCreate = () => {
    if (tabActiveMedia === "1") history.push("/media/image/create");
    else history.push("/media/file/create");
  };
  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">มีเดีย</span>
        </Col>
        <Col lg={2}>
          <Button className="btn-create" type="primary" onClick={handleCreate}>
            <PlusOutlined className="icons-size" /> สร้าง
          </Button>
        </Col>
      </Row>

      <div className="set-layout">
        <div className={displayHide(tabActiveMedia, "1")}>
          <ImageaHeaders
            onFinish={onFinishImage}
            mediaCategory={mediaCategoryList}
          />
        </div>

        <div className={displayHide(tabActiveMedia, "2")}>
          <FileHeaders onFinish={onFinishFile} />
        </div>

        <div className="pt-1">
          <Tabs
            className="tab-mainmenu"
            defaultActiveKey={tabActiveMedia}
            onChange={callback}
          >
            <TabPane tab="ภาพ" key="1">
              <ImageContainer
                setMediaCategoryList={setMediaCategoryList}
                filterSearchImage={filterSearchImage}
                currentPage={currentPageImage}
                setCurrentPage={setCurrentPageImage}
              />
            </TabPane>
            <TabPane tab="ไฟล์" key="2">
              <FileContainer
                fiterSearchFile={fiterSearchFile}
                currentPage={currentPageFile}
                setCurrentPage={setCurrentPageFile}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Media;
