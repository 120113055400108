import { useState } from "react";
import { Button, Col, Row } from "antd";
import UploadIcon from "../../assets/img/icons/upload.png";
import { IMediaSelect } from "../../interface/IMedia.interface";
import { _isEmpty } from "../../tools/util";
import MapInspiration from "../../component/map/MapInspiration";
import { openNotification } from "../notification/Notification";
// import MediaModal from "../mediamodal/MediaModal";

type Props = {
  visible: boolean;
  onModal?: () => void;
  getMarkers: any;
  initialMarkers?: any;
  mediaSelect?: IMediaSelect;
  type: string;
  textMessage?: string;
  onMarker?: boolean;
};

const Space = ({
  onModal,
  mediaSelect = { file: "", id: "", media_name: "" },
  getMarkers,
  initialMarkers,
  type,
  textMessage,
  onMarker = true,
}: Props) => {
  const [addMarker, setAddMarker] = useState(false);
  return (
    <div>
      <div
        style={{
          cursor: _isEmpty(mediaSelect.file)
            ? "pointer"
            : addMarker
            ? "pointer"
            : "unset",
        }}
        className="media-space-container-marker d-flex items-center justify-center"
        onClick={_isEmpty(mediaSelect.file) ? onModal && onModal : () => ""}
      >
        {_isEmpty(mediaSelect.file) ? (
          <>
            <p className="ant-upload-drag-icon">
              <img src={UploadIcon} alt="media" />
            </p>
            <p className="header-xs">กด หรือ ลาก ไฟล์ไปยังพื้นที่อัพโหลดนี้</p>
            <p>
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </>
        ) : (
          <MapInspiration
            mapImg={mediaSelect.file}
            onGetMarker={getMarkers}
            initialMarkers={initialMarkers}
            addMarkerActive={addMarker}
            typelist={type}
            textMessage={textMessage}
          />
        )}
      </div>

      {!_isEmpty(mediaSelect.file) ? (
        <>
          <div className="bg-blur"></div>
          <div className="btn-space-blur">
            <Row justify={"space-between"} align="middle">
              {onMarker && (
                <Col span={11}>
                  <Button
                    className="btn-add-marker"
                    color="white"
                    onClick={() => {
                      openNotification({
                        type: "success",
                        message: `ตอนนี้คุณสามารถเพิ่มจุดบนรูปภาพได้เเล้ว`,
                        custom: true,
                      });
                      setAddMarker(true);
                    }}
                  >
                    เพิ่มจุด
                  </Button>
                </Col>
              )}
              <Col span={11}>
                <Button
                  className="btn-change-image"
                  type="primary"
                  danger
                  onClick={onModal}
                >
                  เปลี่ยนรูปภาพ
                </Button>
              </Col>
            </Row>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Space;
