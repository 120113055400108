import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { openNotification } from "../../../../component/notification/Notification";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IResStatus } from "../../../../tools/fecth/interface";
import { _isEmpty } from "../../../../tools/util";
import { IGrouptabPost } from "../../../../interface/IInspirationCategory.interface";
import {
  getInspirationCategoryListByID,
  postInspirationCategoryList,
  putInspirationCategoryList,
} from "../../../../services/api/inspiration_category";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  loadingButtonState,
  loadingState,
} from "../../../../tools/recoil/recoil-atom";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import MediaModal from "../../../../component/mediamodal/MediaModal";

const Fields = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [visibleImageModal, setVisibleImageModal] = useState(false);
  const [mediaImageSelect, setMediaImageSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });
  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);
  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    if (!_isEmpty(pathID)) {
      handleGetInspirationCategoryByID();
    }
  };

  const handleGetInspirationCategoryByID = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getInspirationCategoryListByID(pathID);
      if (res.code === 200) {
        const data = res.result[0];
        setMediaImageSelect({
          file: res.result[0].media.file_path,
          id: res.result[0].media.id,
          media_name: res.result[0].media.media_name,
        });
        form.setFieldsValue({
          ...data,
        });
      }
    } catch (error) {}
    setLoading(false);
  };

  const onFinish = (values: any) => {
    if (!_isEmpty(pathID)) {
      handleUpdateInspirationCategory(values);
    } else {
      handleCreateInspirationCategory(values);
    }
  };

  const handleCreateInspirationCategory = async (values: IGrouptabPost) => {
    try {
      setloadingButton(true);
      const data = {
        ...values,
        media: mediaImageSelect.id,
      };
      const res: IResStatus = await postInspirationCategoryList(data);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        history.push("/inspiration");
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const handleUpdateInspirationCategory = async (values: IGrouptabPost) => {
    try {
      setloadingButton(true);
      const data = {
        ...values,
        media: mediaImageSelect.id,
      };

      const res: IResStatus = await putInspirationCategoryList(data, pathID);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/inspiration");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const onModalImage = () => {
    setVisibleImageModal(!visibleImageModal);
  };

  return (
    <div>
      <MediaModal
        visible={visibleImageModal}
        onModal={onModalImage}
        setMediaSelect={setMediaImageSelect}
      />
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Card
          extra={!_isEmpty(pathID) ? "แก้ไขหมวดหมู่" : "สร้างหมวดหมู่"}
          actions={[
            <Row justify="end">
              <Col>
                <Button type="default" onClick={() => history.goBack()}>
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button
                  className="btn-save"
                  htmlType="submit"
                  loading={loadingButton}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row justify="center">
            <Col lg={12}>
              <Row gutter={[14, 14]}>
                <Col lg={24}>
                  <Form.Item
                    label="ชื่อหมวดหมู่ไอเดีย (TH)"
                    name="name_th"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    label="ชื่อหมวดหมู่ไอเดีย (EN)"
                    name="name_en"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    label="รูป"
                    name="name_en"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <MediaSpace
                      onModal={onModalImage}
                      mediaSelect={mediaImageSelect}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
