import { Col, Divider, Row } from "antd";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import { ListProduct } from "../../../../tools/recoil/interface";
import { IPreviewGallery } from "./Fields";
import TagText from "../../../../component/map/TagText";
import CardBoxProducts from "../../../../component/cardboxProducts/CardBoxProducts";
import HeaderSlide from "../../../../component/cardboxProducts/HeaderSider";
import EyePre from "../../../../assets/img/eye.png";

type Props = {
  mediaSelect: IMediaSelect;
  galleryList: IPreviewGallery[] | any;
  productList: ListProduct[];
  previewData: any;
  previewTab: string;
};

const Preview = ({
  mediaSelect,
  galleryList,
  productList,
  previewData,
  previewTab,
}: Props) => {
  return (
    <div className="preview-content ">
      <div className="py-5 bg-white">
        <Row justify="center">
          <Col xl={17} lg={20} md={20} xs={20}>
            <div className="border-radius-10 my-3">
              <img
                src={mediaSelect?.file || ""}
                style={{ borderRadius: 10 }}
                className="text-center border-radius-10"
                alt="mediaSelect"
              />
            </div>
            <TagText
              tag={
                previewTab === "1"
                  ? previewData?.title_th
                  : previewData?.title_en
              }
              date={previewData?.project_category?.updated_at}
              lang={previewTab === "1" ? "th" : "en"}
            />
            <div
              className="py-3"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <div className="title-detail-article">
                {previewTab === "1"
                  ? previewData?.title_th
                  : previewData?.title_en}
              </div>
              <div className="text-detail-article">
                {previewTab === "1"
                  ? previewData?.area_th
                  : previewData?.area_en}
              </div>
            </div>
            <div
              className="py-5 des-detail-article"
              dangerouslySetInnerHTML={{
                __html:
                  previewTab === "1"
                    ? previewData?.description_th
                    : previewData?.description_en || "",
              }}
            />
            <Divider
              orientation="left"
              plain
              className="p-0"
              style={{ borderTopColor: "#ff6b0b" }}
            >
              <div className="text-image-box">{"รูปภาพ"}</div>
            </Divider>
            <Row gutter={[8, 8]} className="my-4">
              {galleryList?.map((item: any, idx: number) => (
                <Col span={8} key={idx}>
                  <div className="border-radius-10 cursor-pointer image-container">
                    <img
                      src={item?.image}
                      width={"100%"}
                      height={80}
                      style={{ borderRadius: 10 }}
                      className="text-center border-radius-10 image-preview"
                      alt={`${item?.name}`}
                    />
                    <div className="text-container">
                      <img
                        src={EyePre}
                        width={20}
                        height={"auto"}
                        style={{ borderRadius: 50 }}
                        className="text-center border-radius-50"
                        alt={`eye`}
                      />
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
      <div className="py-5 bg-gray-fa">
        <Row justify="center">
          <Col xl={17} lg={20} md={20} xs={20} className="pb-3">
            <HeaderSlide
              title={previewTab === "1" ? "สินค้าเเนะนำ" : "RECOMMEND_PRODUCT"}
              message={
                previewTab === "1"
                  ? previewData?.title_th
                  : previewData?.title_en
              }
              href="/product"
            />
            <Row gutter={10}>
              {console.log("productList",productList)}
            {productList?.length > 0 &&
              productList
              ?.filter((fil: any, _idn: number) => fil.key !== "init")
                ?.filter((_fil: any, idn: number) => idn < 3)
                .map((item: any, idx: number) => (
                  <Col span={8} key={idx}>
                    <CardBoxProducts
                      img={item?.image || ""}
                      title={
                        previewTab === "1" ?
                        item?.title_th : item?.title_en || ""
                      }
                      height="200"
                      price={item?.price || 0}
                      textHeight={item?.height || ""}
                      textWidth={item?.width || ""}
                      lang={previewTab === "1" ? "th" : "en"}
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Preview;
