import React, { useState } from "react";
import { Row, Col, Radio } from "antd";

import ContactTab from "./contacttab/ContactTab";
import ConnectTab from "./connecttab/ConnectTab";

const SettingPage = () => {
  const [selectTab, setSelectTab] = useState("1");

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">ตั้งค่า</span>
        </Col>
      </Row>
      <div className="set-layout">
        <div className="pt-1">
          <Radio.Group
            defaultValue="1"
            onChange={(e) => setSelectTab(e.target.value)}
          >
            <Radio.Button value="1" className="first-child">
              ติดต่อ
            </Radio.Button>
            <Radio.Button value="2" className="last-child">
              การเชื่อมต่อ
            </Radio.Button>
          </Radio.Group>
          {selectTab === "1" && <ContactTab />}
          {selectTab === "2" && <ConnectTab />}
        </div>
      </div>
    </div>
  );
};

export default SettingPage;
