import React, { useEffect, useState } from "react";
import { Button, Col, Radio, Row } from "antd";
import ProjectTab from "./projecttab/ProjectTab";
import GroupTab from "./grouptab/GroupTab";
import ProjectHeader from "./projecttab/Headers";
import GroupHeader from "./grouptab/Headers";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import {
  getProjectContentCategory,
  getProjectContent,
  isActiveProjectCategoryList,
  isActiveProjectContent,
} from "../../services/api/project";
import { openNotification } from "../../component/notification/Notification";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../tools/recoil/recoil-atom";
// import { _isEmpty } from "../../tools/util";

const Project: React.FC = () => {
  const [tabSelected, setTabSelected] = useState<string>("1");
  const [projectList, setProjectList] = useState<any>([]);
  const [groupList, setGroupList] = useState<any>([]);
  const location: any = useLocation();
  const pathState = location.state;
  const history = useHistory();
  const setLoading = useSetRecoilState(loadingState);

  const [limitPage1, setLimitPage1] = useState<number>(10);
  const [currentPage1, setCurrentPage1] = useState<number>(1);
  const [total1, setTotal1] = useState<number>(1);
  const [limitPage2, setLimitPage2] = useState<number>(10);
  const [currentPage2, setCurrentPage2] = useState<number>(1);
  const [total2, setTotal2] = useState<number>(1);

  const [searchItem, setSearchItem] = useState({
    projectItem: "",
    projectTypeID: null,
    groupItem: "",
  });

  useEffect(() => {
    // if (!_isEmpty(currentPage1)) {
    //   setCurrentPage1(1);
    // }
    // if (!_isEmpty(currentPage2)) {
    //   setCurrentPage2(1);
    // }
    handleGetProjectList();
    handleGetProjectGroupList();

    CheckTab(tabSelected);
    // eslint-disable-next-line
  }, [searchItem, limitPage1, currentPage1, limitPage2, currentPage2]);

  const CheckTab = (tab?: string) => {
    if (pathState) {
      setTabSelected(pathState?.tabSelected.toString());
    } else {
      setTabSelected(tab ? tab : "1");
    }
  };

  const handleGetProjectGroupList = async () => {
    const query = {
      page: currentPage2,
      limit: limitPage2,
      orderby: "DESC",
      search: searchItem.groupItem,
    };
    try {
      setLoading(true);
      const res = await getProjectContentCategory(query);
      if (res.code === 200) {
        setGroupList(res.result[0].item);
        setTotal2(res.result[0].total);
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleGetProjectList = async () => {
    const query = {
      page: currentPage1,
      limit: limitPage1,
      orderby: "DESC",
      search: searchItem.projectItem,
      category_id: searchItem.projectTypeID,
    };
    try {
      setLoading(true);
      const res = await getProjectContent(query);
      if (res.code === 200) {
        setProjectList(res.result[0].item);
        setTotal1(res.result[0].total);
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleChangeSwitch = async (source: any) => {
    try {
      const res =
        tabSelected === "2"
          ? await isActiveProjectCategoryList(source?.id)
          : await isActiveProjectContent(source?.id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        if (tabSelected === "2") {
          handleGetProjectGroupList();
        } else {
          handleGetProjectList();
        }
      } else {
        openNotification({
          type: "error",
          message: `เกิดข้อผิดพลาดเนื่องจาก ${res?.message}`,
          custom: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const projectFilter = (values: any) => {
    setSearchItem({
      ...searchItem,
      projectItem: values?.search,
      projectTypeID: values?.category_id,
    });
    setLimitPage1(10);
    setCurrentPage1(1);
    setTabSelected("1");
  };

  const groupFilter = (values: any) => {
    setSearchItem({
      ...searchItem,
      groupItem: values?.search,
    });
    setLimitPage2(10);
    setCurrentPage2(1);
    setTabSelected("2");
  };

  const onChangePage = (page: number, type?: string, tab?: string) => {
    if (tab === "1") {
      if (type === "pageSize") {
        setLimitPage1(page);
      } else {
        setCurrentPage1(page);
      }
    } else {
      if (type === "pageSize") {
        setLimitPage2(page);
      } else {
        setCurrentPage2(page);
      }
    }
  };

  const onCheckAfterDelete = (tab: string) => {
    if (tab === "2") {
      handleGetProjectGroupList();
    } else {
      handleGetProjectList();
    }
  };

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">โครงการ</span>
        </Col>
        <Col lg={2}>
          <Button
            className="btn-create"
            type="primary"
            onClick={() =>
              history.push(
                tabSelected === "1"
                  ? "/project/project/create"
                  : "/project/group/create"
              )
            }
          >
            <PlusOutlined className="icons-size" /> สร้าง
          </Button>
        </Col>
      </Row>
      <div className="set-layout">
        {tabSelected === "1" && (
          <ProjectHeader onFinish={projectFilter} initialValue={searchItem} />
        )}
        {tabSelected === "2" && (
          <GroupHeader
            onFinish={groupFilter}
            initialValue={searchItem.groupItem}
          />
        )}

        <div className="pt-1">
          <Radio.Group
            // defaultValue="1"
            value={tabSelected}
            onChange={(e) => setTabSelected(e.target.value)}
          >
            <Radio.Button value="1" className="first-child">
              โครงการ
            </Radio.Button>
            <Radio.Button value="2" className="last-child">
              หมวดหมู่
            </Radio.Button>
          </Radio.Group>

          {tabSelected === "1" && (
            <ProjectTab
              config={{
                total: total1,
                pageSize: limitPage1,
                currentPage: currentPage1,
              }}
              onChangePage={onChangePage}
              HandleOnCheck={onCheckAfterDelete}
              handleChangeSwitch={handleChangeSwitch}
              dataSource={projectList}
            />
          )}
          {tabSelected === "2" && (
            <GroupTab
              config={{
                total: total2,
                pageSize: limitPage2,
                currentPage: currentPage2,
              }}
              onChangePage={onChangePage}
              HandleOnCheck={onCheckAfterDelete}
              dataSource={groupList}
              handleChangeSwitch={handleChangeSwitch}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;
