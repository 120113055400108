import React from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";

import { IGetRoleList } from "../../../interface/IRole.interface";
import Selector from "../../../component/selector/Selector";
//---------------------Type----------------------------//
type Props = {
  onFinish: (values: any) => void;
  roleList: IGetRoleList[];
};

///////////////////////////////////////////////////////////
const Header = ({ onFinish, roleList }: Props) => {
  return (
    <div>
      <Form onFinish={onFinish}>
        <Card className="card-round">
          <Row gutter={14}>
            <Col lg={8}>
              <Form.Item name="name">
                <Input placeholder="ค้นหาชื่อผู้ใช้" />
              </Form.Item>
            </Col>
            <Col lg={4}>
              <Form.Item name="roleId">
                <Selector Source={roleList} keyValue="id" keyName="name" />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
            </Col>
            <Col lg={3}>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Header;
