import { Fecth, FecthFile, FecthFormdata } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from '../../tools/util'


export const gelLogDeviceList = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/lock-kit${queryStr}`,
    })
    return res
}

export const getLogDeviceByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/lock-kit/${id}`,
    })
    return res
}

export const postLogDeviceList = async (data: any) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/lock-kit`,
    })
    return res
}

export const putLogDeviceList = async (data: any, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/lock-kit/${id}`,
    })
    return res
}

export const putLogDeviceActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/lock-kit/active/${id}`,
    })
    return res
}

export const delLogDeviceList = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/lock-kit/${id}`,
    })
    return res
}

export const postExcelLogDevice = async (data: any) => {
    const res = await FecthFormdata({
        data,
        method: "POST",
        path: `${version}/lock-kit/import/excel`,
    })
    return res
}

export const getExcelLogDevice = async () => {
    const res = await FecthFile({
        method: "GET",
        path: `${version}/lock-kit/export/excel`,
    })
    return res
}





