import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import ListComponent from "../list/MainListComponent";

import { getProductCategoryOnlyGroup } from "../../../../../../services/api/product_category";
import { useHistory } from "react-router-dom";
import { Button, Row, Col } from "antd";
import { PlusOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  editState,
  dragState,
  loadingState,
} from "../../../../../../tools/recoil/recoil-atom";
import { availableID } from "../../../../../../tools/util";
import { _isEmpty } from "../../../../../../tools/util";

const { Panel } = Collapse;

function ItemComponent(props) {
  const [groupList, setGroupList] = useState([]);
  const history = useHistory();
  const dnd = props.dnd;
  const cardClasses = props.itemInDrag ? "card in-drag" : "card";
  const [toggleEdit] = useRecoilState(editState);
  const [toggleDrag] = useRecoilState(dragState);
  const setLoading = useSetRecoilState(loadingState);

  useEffect(() => {
    handleGetGroup();
    // eslint-disable-next-line
  }, []);

  const handleGetGroup = async () => {
    setLoading(true);
    try {
      const res = await getProductCategoryOnlyGroup();
      if (res.code === 200) {
        setGroupList(res.result);
      }
    } catch (error) {}
    setLoading(false);
  };
  const onChange = (title) => {
    props.onModal({
      type: "group",
      functional: "edit",
      title: title,
      idCate: props.idCate,
      idGroup: props.item.id,
    });
  };

  const handleSetDetailCreateList = () => {
    props.onModal({
      type: "list",
      functional: "create",
      idCate: props.idCate,
      idGroup: props.item.id,
    });
  };

  const handleSetDetailDelGroup = () => {
    props.onDelete({
      type: "group",
      functional: "delete",
      idCate: props.idCate,
      idGroup: props.item.id,
    });
  };
  const findItem = (id) => {
    const findedGroup = groupList.find((item) => {
      return item.id === id;
    });

    return findedGroup.name_th;
  };

  const handleCreateForm = (id) => {
    history.push("/product/group/" + id);
  };
  return (
    <div
      style={dnd.item.styles}
      className={`${dnd.item.classes} card-outer main-card-group-item`}
      ref={dnd.item.ref}
    >
      <div className={cardClasses}>
        <div className="w-100  d-flex collapse-card-group ">
          {toggleDrag && (
            <div className="drag-card-group" {...dnd.handler.listeners}>
              ::
            </div>
          )}
          <div className="w-100">
            <Collapse>
              <Panel
                key="1"
                header={
                  <>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange();
                      }}
                      disabled={!toggleEdit}
                    >
                      {groupList.length > 0 &&
                        findItem(props["item"][availableID(props.item)])}
                    </Button>
                    {!toggleEdit && !toggleDrag ? (
                      <>
                        {!props.item.notCrete && (
                          <Button
                            className="ml-1"
                            onClick={() => handleCreateForm(props.item.id)}
                          >
                            {!_isEmpty(props.item.edit_content_at) ? (
                              <>
                                <EyeOutlined
                                  style={{ marginRight: "0.5rem" }}
                                />
                                ดูข้อมูล
                              </>
                            ) : (
                              ""
                            )}
                          </Button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {toggleEdit && (
                      <Button
                        className="ml-1"
                        onClick={() => handleSetDetailDelGroup()}
                      >
                        <DeleteOutlined />
                      </Button>
                    )}
                  </>
                }
                // onClick={() => console.log("group title", props.item.title)}
              >
                <Row justify="end">
                  <Col>
                    <Button
                      className="ml-1"
                      type="primary"
                      onClick={() => handleSetDetailCreateList()}
                      disabled={!toggleEdit}
                    >
                      <PlusOutlined className="icons-size" /> สร้างกลุ่มย่อย
                    </Button>
                  </Col>
                </Row>
                <ListComponent
                  list={props.item.list}
                  setList={props.setList}
                  onModal={props.onModal}
                  onDelete={props.onDelete}
                  idGroup={props.item.id}
                  idCate={props.idCate}
                />
                {/* {props.item.component} */}
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemComponent;
