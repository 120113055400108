import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import UploadImage from "./UploadImage";

type Props = {
  maxCount?: number;
  onChange?: (info: UploadChangeParam<UploadFile<any>>) => void;
  fileType?: string;
  value?: any;
  acceptFile?: string;
  getFileName?: (val: any) => void;
  multiple?: boolean;
  onDelete?: (data: any) => any;
};
const FileSpace = ({
  maxCount = 1,
  onChange,
  fileType = "image",
  value,
  acceptFile = ".xlsx,.xls,application/pdf",
  getFileName,
  multiple = false,
  onDelete
}: Props) => {
  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      {fileType === "image" ? (
        <UploadImage onDelete={onDelete} onChange={onChange} value={value} />
      ) : (
        <Upload
          accept={acceptFile}
          listType="picture"
          className="upload-list-inline"
          maxCount={maxCount}
          customRequest={dummyRequest}
          onChange={onChange}
          //@ts-ignore
          valuePropName="fileList"
          value={value}
          beforeUpload={getFileName}
          multiple={multiple}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={false}
            block
            type="dashed"
          >
            อัพโหลด
          </Button>
        </Upload>
      )}
    </>
  );
};

export default FileSpace;
