import { Row, Col, Button } from "antd";
import Fields from "./_Fields";
import { useHistory } from "react-router-dom";
import BlackHome from "../../../../assets/img/icons/black-home.svg";

const Form = () => {
  const history = useHistory();

  return (
    <div>
      <Row className="second-header" justify="start" align="middle">
        <span className="blackHome">
          <Button
            // onClick={() => history.goBack()}
            onClick={() => {
              history.push({
                pathname: "/project",
                state: { tabSelected: 2 },
              });
            }}
            type="primary"
            shape="circle"
          >
            <img className="buttonBlackHome" src={BlackHome} alt="" />
          </Button>
        </span>
        <Col>
          <span className="text-head">หมวดหมู่โครงการ</span>
        </Col>
      </Row>
      <div className="set-layout">
        <div className="pt-1">
          <Fields />
        </div>
      </div>
    </div>
  );
};
export default Form;
