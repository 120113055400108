import moment from "moment";

export const DateTimeStandard = (date: string) => {
  const newDate = moment(Number(date)).format("DD/MM/YYYY  HH:mm");
  return newDate;
};
export const DateStandard = (date: string) => {
  const newDate = moment(Number(date)).format("DD/MM/YYYY");
  return newDate;
};
