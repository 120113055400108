import React from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";
import { IProductCategory } from "../../../interface/IProductCategory.interface";
import Selector from "../../../component/selector/Selector";
type Props = {
  onFinish: (values: any) => void;

  inspirationCategoryList: IProductCategory[];
};
const Headers = ({ onFinish, inspirationCategoryList }: Props) => {
  return (
    <div>
      <Form onFinish={onFinish}>
        <Card className="card-round">
          <Row gutter={[14, 14]}>
            <Col lg={9}>
              <Form.Item name="inspiration_title">
                <Input placeholder="ค้นหาไอเดีย" />
              </Form.Item>
            </Col>
            <Col lg={5}>
              <Form.Item name="inspiration_category_id">
                <Selector
                  Source={inspirationCategoryList}
                  keyName="name_th"
                  keyValue="id"
                />
              </Form.Item>
            </Col>
            {/* <Col lg={5}>
              <Form.Item name="typeProduct">
                <Select showSearch placeholder="หมวดหมู่ไอเดีย">
                  <Option value="3">Communicated</Option>
                  <Option value="4">Identified</Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col lg={3}>
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
            </Col>
            <Col lg={3}>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Headers;
