import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "antd";
import UploadPhoto from "../users/usertab/component/_UploadPhoto";
import DataUserInput from "../users/usertab/component/_SetDataUser";
import CreatePassword from "../users/usertab/component/_SetPassword";
import { useHistory } from "react-router-dom";
import { IMediaSelect } from "../../interface/IMedia.interface";
import { setData, dataForm, EditData } from "../../interface/IUsers.interface";
import { openNotification } from "../../component/notification/Notification";

//-------------------API-------------------------//
import {
  GET_SETTINGPROFILE_API,
  PUT_SETTINGPROFILE_API,
} from "../../services/api/setting_profile";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../tools/recoil/recoil-atom";

const SettingProfile = () => {
  const [data, setData] = useState<setData>({
    first_name: "",
    last_name: "",
    nickname: "",
    phone: "",
    email: "",
    department: 1,
    role: 1,
  });
  const setLoading = useSetRecoilState<boolean>(loadingState);
  const history = useHistory();
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [mediaSelect2, setMediaSelect2] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });
  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await GET_SETTINGPROFILE_API();
      const newdata = res.result[0];
      if (res.code === 200) {
        setData({
          ...data,
          first_name: newdata.first_name,
          last_name: newdata.last_name,
          nickname: newdata.nickname,
          phone: newdata.phone,
          email: newdata.email,
          department: newdata.department?.id,
          role: newdata.role?.id,
        });
        setMediaSelect2({
          ...mediaSelect2,
          file: res.result[0].image?.file_path,
          id: res.result[0].image?.id,
        });
        form.setFieldsValue({
          ...newdata,
          department: newdata.department?.id,
          role: newdata.role?.id,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const onEditProfile = async (value: dataForm) => {
    try {
      const newdata: EditData = {
        //--------Request body------------
        media: mediaSelect2.id,
        first_name: value.first_name,
        last_name: value.last_name,
        nickname: value.nickname,
        phone: value.phone,
        email: value.email,
        password: value.password,
        department: value.department,
        role: value.role,
        current_password: value.currentPassword,
      };
      const res = await PUT_SETTINGPROFILE_API(newdata);
      if (res.code === 200) {
        history.push("/");
        openNotification({ method: "PUT_SUCCESS", message: res.message });
      } else {
        history.push("/settingprofile");
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditClick = () => {
    setEditPassword(!editPassword);
  };

  return (
    <div className="set-layout">
      <div className="pt-1">
        <Form onFinish={onEditProfile} layout="vertical" form={form}>
          <Card
            extra={<>ตั้งค่าโปรไฟล์</>}
            actions={[
              <Row justify="end">
                <Col>
                  <Button onClick={() => history.push("/")} type="default">
                    ยกเลิก
                  </Button>
                </Col>
                <Col className="pl-1">
                  <Button className="btn-save" htmlType="submit">
                    บันทึก
                  </Button>
                </Col>
              </Row>,
            ]}
            className="card-form"
          >
            <Row>
              <Col lg={5} offset={1}>
                <UploadPhoto
                  mediaSelect={mediaSelect2}
                  setMediaSelect={setMediaSelect2}
                />
              </Col>
              <Col lg={17} offset={1}>
                <DataUserInput form={form} />
                <br />
                <>
                  {editPassword ? (
                    <>
                      <CreatePassword edit={editPassword} />
                    </>
                  ) : (
                    <>
                      <div>เปลี่ยนรหัสผ่าน</div>
                      <Button onClick={handleEditClick} className="w-40">
                        ตั้งรหัสผ่านใหม่
                      </Button>
                      <br />
                      <br />
                    </>
                  )}
                </>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
    </div>
  );
};

export default SettingProfile;
