import React from "react";
import { IApprove } from "../../../../interface/IApprove.interface";
import { DateStandard } from "../../../../tools/date";
import InfoOnRow from "./InfoOnRow";
type Props = {
  approve: IApprove | any;
};
const ProjectDetail = ({ approve }: Props) => {
  return (
    <div className=" border-box h-100">
      <InfoOnRow topic="ชื่อโครงการ" data={approve.project_name} />
      <InfoOnRow topic="หมวดหมู่โครงการ" data={approve.project_category} />
      <InfoOnRow
        topic="ช่วงเวลาโครงการ"
        data={
          DateStandard(approve.start_date) +
          " - " +
          DateStandard(approve.end_date)
        }
      />
      <InfoOnRow topic="ชื่อบริษัท" data={approve.company_name} />
      <InfoOnRow topic="ที่อยู่" data={approve.address} />
      <InfoOnRow topic="ตำบล" data={approve.sub_district} />
      <InfoOnRow topic="อำเภอ" data={approve.district} />
      <InfoOnRow topic="จังหวัด" data={approve.province} />
      <InfoOnRow topic="รหัสไปรษณีย์" data={approve.post_code} />
    </div>
  );
};

export default ProjectDetail;
