import { Layout } from "antd";
import TheHeader from "./TheHeader";
import TheFooter from "./TheFooter";

import TheSideBar from "./TheSideBar";
import { useState } from "react";
import TheContent from "./TheContent";

type Props = {
  children?: React.ReactNode;
};
const DefaultLayout: React.FC<Props> = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  // const setMediaSelect = useSetRecoilState(mediaSelectState);
  // const setimageUploader = useSetRecoilState(imageUploaderState);

  const onCollapse = (collapsed: any) => {
    setCollapsed(collapsed);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <TheHeader collapsed={collapsed} />
      <Layout style={{ minHeight: "100vh" }}>
        <TheSideBar
          onCollapse={onCollapse}
          collapsed={collapsed}
          toggleSidebar={toggleSidebar}
        />
        <Layout
          className={collapsed ? "site-layout flex-layout" : "site-layout"}
        >
          <TheContent />
          <TheFooter />
        </Layout>
      </Layout>
    </>
  );
};

export default DefaultLayout;
