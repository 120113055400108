import React from "react";
import { IApprove } from "../../../../interface/IApprove.interface";
import { numberToCommasNumber } from "../../../../tools/util";
type Props = {
  approve: IApprove | any;
};
const InstallPrice = ({ approve }: Props) => {
  return (
    <div className="h-100 border-box ">
      <div className="d-flex justify-center flex-column">
        <div className="d-flex justify-center">
          <span>มูลค่าการติดตั้งโดยประมาณ</span>
        </div>
        <div className="d-flex justify-center">
          <span className="price">
            {numberToCommasNumber(approve ? approve.install_price : 0)} บาท
          </span>
        </div>
      </div>
    </div>
  );
};

export default InstallPrice;
