import { useEffect } from "react";
import Forms from "./form/Form";
import { Form, Row, Col, Button, Card } from "antd";
import { openNotification } from "../../../component/notification/Notification";
import { useHistory } from "react-router-dom";

//=================API=================//
import {
  GET_CONTACTID_API,
  PUT_CONTACT_API,
} from "../../../services/api/setting";

const ConnectTab = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    try {
      const res = await GET_CONTACTID_API();
      const data = res.result[0];
      console.log(data);
      if (res.code === 200) {
        form.setFieldsValue({
          // media_id: mediaSelect.id,
          ...data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = async (value: any) => {
    try {
      const data = {
        id: 1,
        line_id: value.line_id,
        facebook: value.facebook,
        instagram: value.instagram,
      };
      const res = await PUT_CONTACT_API(data);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/setting");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
        history.push("/setting");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Card
          extra={<>การเชื่อมต่อ</>}
          actions={[
            <Row justify="end">
              <Col className="pl-1">
                <Button className="btn-save" htmlType="submit">
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-media"
        >
          <Forms form={form} />
        </Card>
      </Form>
    </>
  );
};

export default ConnectTab;
