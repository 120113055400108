/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Modal, Row, Popover } from "antd";
import { _isEmpty } from "@yim/util-tool-ts";
import { ChromePicker } from "react-color";
import { openNotification } from "../notification/Notification";
import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";
import { IMarkPost } from "../../interface/IBanner.interface";
import ModalProductList from "./ModalProductList";
import ModalInspirationList from "./ModalInspirationList";

type Props = {
  mapImg: string;
  onGetMarker?: any;
  initialMarkers?: IMarkPost;
  addMarkerActive?: boolean;
  textMessage?: string;
  listChild?: [];
  typelist: string;
};

const MapInspiration = ({
  mapImg,
  onGetMarker,
  initialMarkers,
  addMarkerActive,
  textMessage = 'เเรงบันดาลใจ',
  typelist
}: Props) => {
  const [markers, setMarkers] = useState([] as any);
  const [currentColor, setCurrentColor] = useState("white");
  const [show, setShow] = useState(false);
  const [initialValue, setInitialValue] = useState({} as any);
  const [visibleColor, setVisibleColor] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!_isEmpty(initialMarkers)) {
      handleGetMarkerList(initialMarkers);
    }
  }, []);

  const handleGetMarkerList = (markers: any) => {
    const RefacterData = markers?.map((item: any, idx: number) => {
      return {
        left: item.x,
        top: item.y,
        key: item.x + item.y,
        text: typelist === "inspiration" ? item.inspiration : item.product,
      };
    });
    setCurrentColor(markers[0]?.color);
    setMarkers(RefacterData);
  };

  const CustomMarker = (props: MarkerComponentProps) => {
    const keyItem = Number(props.top) + Number(props.left);
    const result = markers.find((item: any) => item.key === keyItem);

    return (
      <Popover
        className="popover-custom-marker"
        content={DetailMarker({ ...props, key: keyItem, text: result.text })}
        title={false}
      >
        <div className="custom-marker">
          <span
            className="marker-custom"
            style={{ backgroundColor: result.text ? currentColor : "red" }}
          />
        </div>
      </Popover>
    );
  };

  const DetailMarker = (value: any) => {
    return (
      <div className="alert-empty-marker-detail" style={{ textAlign: 'start' }}>
        <div className='alert-empty-marker-item alert-marker-cursur' onClick={() => {
          setInitialValue(value)
          showModal();
          setVisibleColor(false);
        }}>{`เลือก${textMessage}`}</div>
        {value.text ? <div className='alert-empty-marker-item alert-marker-cursur'
          onClick={() => setShow(true)}>เปลี่ยนสี</div> : null}
        <div className='alert-marker-cursur'
          onClick={() => HandleDeleteItem(value.key)}>ลบ</div>
      </div>
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onChangeComplete = (e: any) => {
    setCurrentColor(e.hex);
    handleReturnData(markers);
  };

  const DisplayColorPicker = () => {
    return (
      <div className="card-picker-color">
        <ChromePicker color={currentColor} onChange={onChangeComplete} />
      </div>
    );
  };

  const HandleDeleteItem = (keyItem: any) => {
    const result = markers.filter(
      (item: any, idx: number) => item.key !== keyItem
    );

    setMarkers(result);
    handleReturnData(result);
    handleCancel();
  };

  const onSave = (value: any) => {
    if (!_isEmpty(value)) {
      const result = markers.map((item: any, idx: number) => {
        if (item.key === initialValue.key) {
          return {
            ...item, text: value.key,
            color: _isEmpty(value.key) ? currentColor : null,
          };
        }
        return item;
      });

      setMarkers(result);
      handleReturnData(result);
      handleCancel();
    } else {
      openNotification({
        custom: true,
        type: "warning",
        message: `โปรดเลือกข้อมูล${textMessage}ที่ต้องการเเสดง!`,
      });
    }
  };

  const handleReturnData = (value: any) => {
    const RefacterData = value?.map((item: any, idx: number) => {
      return {
        x: item.left,
        y: item.top,
        color: !_isEmpty(item.text) ? currentColor : null,
        [`${typelist}`]: !_isEmpty(item.text) ? item.text : null,
      };
    });
    onGetMarker(RefacterData);
  };

  return (
    <div>
      <Modal
        title={false}
        visible={show}
        footer={false}
        onCancel={() => setShow(false)}
        style={{ top: '30%' }}
        width={270}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
          }}
        >
          <Row justify="start" style={{ marginBottom: 15 }} align="middle">
            <div
              className="card-color-pick"
              onClick={() => setVisibleColor(!visibleColor)}
            >
              <div
                style={{ background: `${currentColor}` }}
                className="color-picker-show"
              />
            </div>
            <span
              style={{
                margin: "0 10px",
                color: "black",
              }}
            >
              {currentColor}
            </span>
          </Row>
          {DisplayColorPicker()}
        </div>
      </Modal>
      {typelist === "inspiration" ?
        isModalVisible && (
          <ModalInspirationList
            initialValue={initialValue.text}
            onClose={handleCancel}
            onSave={onSave}
          />
        ) : isModalVisible && (
          <ModalProductList
            initialValue={initialValue.text}
            onClose={handleCancel}
            onSave={onSave}
          />
        )}
      <ImageMarker
        src={mapImg}
        markers={markers}
        onAddMarker={(marker: Marker) => {
          if (addMarkerActive) {
            const currentMarkers = [
              ...markers,
              {
                top: marker.top,
                left: marker.left,
                key: Number(marker.top) + Number(marker.left),
              },
            ];
            setMarkers(currentMarkers);
            handleReturnData(currentMarkers);
          }
        }}
        markerComponent={CustomMarker}
      />
    </div>
  );
};

export default MapInspiration;
