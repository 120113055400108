import { atom, } from 'recoil'
// import {ListProduct} from './interface'

export const prodectSelect = atom({
    key: 'product',
    default: [{
        key: "init",
        image: "string",
        title_th: "string",
        title_en: "string",
        title: "string",
        description_th: "string",
        description_en: "string",
        description: "string",
        price: "string",
        width: "string",
        height: "string",
    }],
})

export const loadingState = atom({
    key: 'loading',
    default: false
})
export const loadingButtonState = atom({
    key: 'loadingButton',
    default: false
})



export const imageUploaderState = atom({
    key: 'imageUploader',
    default: {
        file: "",
        url: "",
        id: null
    }
})

export const mediaSelectState = atom({
    key: 'mediaSelect',
    default: {
        file: "",
        id: 0
    }
})
export const categoryState = atom({
    key: 'cID',
    default: ""
})

export const tabSelectedState = atom({
    key: 'tabSelected',
    default: "1"
})

export const tabSelectedUserState = atom({
    key: 'tabSelectedUser',
    default: "1"
})

export const tabSelectedInspirationState = atom({
    key: 'tabSelectedInspiration',
    default: "1"
})


export const tabActiveMediaState = atom({
    key: 'tabActiveMedia',
    default: "1"
})

export const editState = atom({
    key: 'toggleEdit',
    default: false
})


export const dragState = atom({
    key: 'toggleDrag',
    default: false
})


export const currentMarker = atom({
    key: "currentMark",
    default: {
      key:0,
      value:false
    },
  });

export const listCurrentItemMarker = atom({
    key: "listCurrentItemMarker",
    default: []as any,
  });