import { useState } from "react";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MoTable from "../../../component/table/Table";
import { Switch } from "antd";
import { useHistory } from "react-router-dom";
import { Dataset, Columns } from "../../../interface/IArticle.interface";

import Modaler from "../../../component/modaler/Modaler";
import { IMedia } from "../../../interface/IMedia.interface";
import { DateTimeStandard } from "../../../tools/date";
import { _isEmpty } from "@yim/util-tool-ts";
import { IEditor } from "../../../interface/IEditor.interface";
import noImage from "../../../assets/img/No_image.svg"

type Props = {
  handleChangeSwitch: (source: any) => void;
  handleDeleteClick: (source: any) => void;
  onChangePage: (page: number, type?: string) => void;
  dataSource?: Dataset[];
  total: number;
  currentPage: number;
  limitPage: any;
};
const ArticleTab = ({
  handleChangeSwitch,
  dataSource,
  handleDeleteClick,
  total,
  currentPage,
  onChangePage,
  limitPage,
}: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const history = useHistory();

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleteClick(id);
    onModal();
  };

  const columns: Columns[] = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "8%",
      render: (status: boolean, soure: Dataset) => {
        return (
          <>
            <Switch
              checked={status}
              //checked={status}
              onChange={() => handleChangeSwitch(soure)}
            />
          </>
        );
      },
    },
    {
      title: "รูปปก",
      dataIndex: "cover_image",
      key: "cover_image",
      align: "center",
      width: "10%",
      render: (cover_image: IMedia) => {
        return (
          <img
            src={cover_image?.file_path ? cover_image?.file_path : noImage}
            className="img-squad"
            alt={cover_image?.media_name}
          />
        );
      },
    },
    {
      title: "ชื่อบทความ",
      dataIndex: "topic",
      key: "topic",
      width: "55%",

      render: (_: string, data: Dataset) => {
        return (
          <>
            <b>{data.title_th}</b>
          </>
        );
      },
    },
    {
      title: "อัปเดต",
      dataIndex: "edit_by",
      key: "edit_by",
      width: "15%",

      render: (editBy: IEditor, data: any) => {
        return (
          <>
            {editBy.first_name} {editBy.last_name}
            <br />
            {!_isEmpty(data.updated_at)
              ? DateTimeStandard(data.updated_at)
              : DateTimeStandard(data.created_at)}
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                e.stopPropagation();
                setId(data.id);
                onModal();
              }}
            />
          </>
        );
      },
    },
  ];

  const onClickRow = (row: any) => {
    history.push(`/article/article/${row.id}`);
  };
  return (
    <div>
      <div>
        <Modaler
          visible={visible}
          onCancel={onModal}
          onOk={onDelete}
          width="25%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              คุณแน่ใจไหม?
            </div>
          }
        >
          คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
        </Modaler>
        <div className="card-table-tab">
          <MoTable
            dataSource={dataSource}
            columns={columns}
            onChangePage={onChangePage}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            onClickRow={onClickRow}
          />
        </div>
      </div>
    </div>
  );
};
export default ArticleTab;
