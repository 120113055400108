import { Tabs } from "antd";
import { useState } from "react";
import ManyImage from "./ManyImage";
import OneImage from "./OneImage";

const Fields = () => {
  const [tabActive, setTabActive] = useState("1");
  const { TabPane } = Tabs;

  const callback = (key: any) => {
    setTabActive(key);
  };
  return (
    <div>
      <Tabs
        className="tab-mainmenu"
        defaultActiveKey={tabActive}
        onChange={callback}
      >
        <TabPane tab="ภาพ" key="1">
          <OneImage />
        </TabPane>
        <TabPane tab="หลายภาพ" key="2">
          <ManyImage />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Fields;
