import { Fecth } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
// import { createQueryString } from '../../tools/util'

export const getProductsGroup = async () => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/product-category`,
    })
    return res
}


export const postProductsGroup = async (data: any) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/product-category`,
    })
    return res
}
export const putProductsGroup = async (data: any) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/product-category/`,
    })
    return res
}
export const delProductsGroup = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/product-category/${id}`,
    })
    return res
}
export const updateActiveGroup = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/product-category/active/${id}`,
    })
    return res
}

