
import { version } from "../../config/api"
import { IGrouptabPost } from "../../interface/IInspirationCategory.interface"
import { Fecth } from "../../tools/fecth/fecth"
import { createQueryString } from "../../tools/util"

export const getInspirationCategoryList = async (qs?: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/inspiration-category/get${queryStr}`,
    })
    return res
}


export const getInspirationCategoryListByID = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/inspiration-category/${id}`,
    })
    return res
}


export const getInspirationCategoryGetListByID = async (qs?: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/inspiration-category/get${queryStr}`,
    })
    return res
}


export const postInspirationCategoryList = async (data: IGrouptabPost) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/inspiration-category`,
    })
    return res
}
export const putInspirationCategoryList = async (data: IGrouptabPost, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/inspiration-category/${id}`,
    })
    return res
}


export const putInspirationCategoryListActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/inspiration-category/active/${id}`,
    })
    return res
}


export const delInspirationCategoryList = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/inspiration-category/${id}`,
    })
    return res
}




