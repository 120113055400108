
import { Select } from "antd";
import { SelectValue } from "antd/lib/select";
import React from "react";
const { Option } = Select;
type Props = {
  Source: any[];
  onChange?: (value: SelectValue, option: any) => void;
  keyName: string;
  keyNameSecondary?: string;
  keyValue: string;
  placeholder?: string;
  value?: any;
  options?: any;
  disabled?: boolean;
};
const Selector = ({
  Source = [],
  onChange,
  keyNameSecondary = "",
  value,
  keyName,
  keyValue,
  placeholder = "เลือกรายการ",
  options,
  disabled = false,
}: Props) => {
  return (
    <>
      <Select
        showSearch
        placeholder={placeholder}
        onChange={onChange}
        allowClear
        value={value}
        disabled={disabled}
        {...options}
        optionFilterProp="children"
      >
        {Source.map((item, idx) => (
          <Option value={item[keyValue]} key={idx}>
            {keyNameSecondary === ""
              ? `${item[keyName]}`
              : `${item[keyName]} / ${item[keyNameSecondary]}`}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default Selector;
