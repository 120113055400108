import React, { Dispatch, SetStateAction } from "react";
import {
  IMedia,
  IMediaCategory,
  IMediaSelect,
} from "../../../interface/IMedia.interface";
import MediaContainer from "./Container";
type Props = {
  onModal?: () => void;
  mediaList?: IMedia[];
  mediaCategoryList: IMediaCategory[];
  setMediaSelect?: Dispatch<SetStateAction<IMediaSelect>>;
  setGellery?: Dispatch<SetStateAction<any>>;
  gallery?: any[];
  onChange?: any;
  handleSelectMedia?: any;
  handleGetMedia: () => void;
  total: number;
};
const Overall = ({
  onModal,
  mediaList,
  mediaCategoryList,
  setMediaSelect,
  setGellery,
  gallery,
  onChange,
  handleGetMedia,
  handleSelectMedia,
  total,
}: Props) => {
  return (
    <div>
      <MediaContainer
        lg={6}
        setMediaSelect={setMediaSelect}
        setGellery={setGellery}
        onModal={onModal}
        mediaList={mediaList}
        mediaCategoryList={mediaCategoryList}
        gallery={gallery}
        onChange={onChange}
        handleSelectMedia={handleSelectMedia}
        handleGetMedia={handleGetMedia}
        total={total}
      />
    </div>
  );
};

export default Overall;
