import { Row, Col, Button} from "antd";
import Fields from "./Fields";
import { useHistory } from "react-router-dom";
import BlackHome from "../../../../assets/img/icons/black-home.svg";

const Form = () => {
  const history = useHistory();

  return (
    <div>
      <Row className="second-header" justify="start" align="middle">
      <span className="blackHome">
          <Button
            onClick={() => {
              history.push({
                pathname: "/project",
                state: { tabSelected: 1 }
              });
            }}
            type="primary"
            shape="circle"
          >
            <img className="buttonBlackHome" src={BlackHome} alt="" />
          </Button>
        </span>
        <Col>
          <span className="text-head">โครงการ</span>
        </Col>
      </Row>

      <div className="set-layout">
        <Fields />
      </div>
    </div>
  );
};

export default Form;
