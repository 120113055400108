import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Card, Transfer } from "antd";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";

import { GET_MENU_API } from "../../../../services/api/menu";
import { openNotification } from "../../../../component/notification/Notification";
import {
  getRoleListByID,
  postRoleList,
  putRoleList,
} from "../../../../services/api/role";
import { _isEmpty } from "../../../../tools/util";

const Fields = () => {
  const [menu, setMenu] = useState<any>([]);
  const [selectedKeys, setSelectedKeys] = useState<any>([]);
  const [targetKeys, setTargetKeys] = useState<any>();
  const { params } = useRouteMatch();
  const { id }: any = params;
  const history = useHistory();
  const [form] = Form.useForm();
  const match: any = useRouteMatch();
  const pathID = match.params.id;

  useEffect(() => {
    handelGetDat();
    // eslint-disable-next-line
  }, []);

  const handelGetDat = () => {
    getMenu();
    getData();
  };

  const getData = async () => {
    try {
      if (id) {
        const res = await getRoleListByID(id);
        const data = res.result[0];
        const datamenu = res.result[0].menu;
        const newdata = datamenu.map((item: any) => {
          return `${item.id}`;
        });
        if (res.code === 200) {
          setTargetKeys(newdata);
          form.setFieldsValue({
            ...data,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMenu = async () => {
    try {
      const res = await GET_MENU_API();
      const newdata = res.result[0].item;
      const dataset = newdata.map((item: any) => {
        return {
          key: `${item.id}`,
          name: item.name_th,
        };
      });
      if (res.code === 200) {
        setMenu(dataset);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSelectChange = (sourceSelectKeys: any, targetSelectKeys: any) => {
    setSelectedKeys([...sourceSelectKeys, ...targetSelectKeys]);
  };

  const onChange = (nextTargetKeys: any, direction: any, moveKeys: any) => {
    setTargetKeys(nextTargetKeys);
  };

  const handleCreateRoleList = async (value: any) => {
    try {
      const newdata = targetKeys.map((i: any) => Number(i));
      const data = {
        name: value.name,
        menu: newdata,
      };
      const res = await postRoleList(data);
      if (res.code === 200) {
        history.push("/user");
        openNotification({ method: "POST_SUCCESS", message: res.message });
      } else {
        history.push("/user");
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateRoleList = async (value: any) => {
    try {
      const newdata = targetKeys.map((i: any) => Number(i));
      const data = {
        id: Number(id),
        //--------Request body------------
        name: value.name,
        menu: newdata,
      };
      const res = await putRoleList(data);
      if (res.code === 200) {
        history.push("/user");
        openNotification({ method: "PUT_SUCCESS", message: res.message });
      } else {
        history.push("/user");
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = (values: any) => {
    if (!_isEmpty(pathID)) {
      handleUpdateRoleList(values);
    } else {
      handleCreateRoleList(values);
    }
  };

  return (
    <div>
      <Form onFinish={onFinish} form={form}>
        <Card
          extra={!_isEmpty(pathID) ? "แก้ไขบทบาท" : "สร้างบทบาท"}
          actions={[
            <Row justify="end">
              <Col>
                <Button onClick={() => history.push("/user")} type="default">
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button className="btn-save" htmlType="submit">
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Row>
            <Col offset={1} lg={12}>
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "โปรดใส่ชื่อบทบาทของคุณ!!!" },
                ]}
              >
                <Input className="input-role" placeholder={"input role name"} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col offset={1} lg={23}>
              <Form.Item
                name="transferChosen"
                valuePropName="check"
                rules={[
                  { required: true, message: "โปรดเลือกบทบาทอย่างน้อย 1!" },
                ]}
              >
                <Transfer
                  selectAllLabels={[
                    ({ selectedCount }) => (
                      <span>
                        <div>
                          <b>Choices</b>
                        </div>
                        <div>{selectedCount} Selected</div>
                      </span>
                    ),
                    ({ selectedCount }) => (
                      <span>
                        <div>
                          <b>Chosen</b>
                        </div>
                        <div>{selectedCount} Selected</div>
                      </span>
                    ),
                  ]}
                  dataSource={menu}
                  selectedKeys={selectedKeys}
                  targetKeys={targetKeys}
                  onSelectChange={onSelectChange}
                  onChange={onChange}
                  render={(item) => item.name}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
