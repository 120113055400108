import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Editor from "../../../../component/editor/Editor";
import Gallery from "../../../../component/gallery/Gallery";
import MediaModal from "../../../../component/mediamodal/MediaModal";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import { openNotification } from "../../../../component/notification/Notification";
import { IGallery } from "../../../../interface/IGallery.interface";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import {
  getProductCategoryContentByID,
  putProductCategoryContent,
} from "../../../../services/api/product_category_content";
import { IResStatus } from "../../../../tools/fecth/interface";
import { loadingState } from "../../../../tools/recoil/recoil-atom";
import { findItemByKey, _isEmpty } from "../../../../tools/util";

const Fields = () => {
  const [productGroupName, setProductGroupName] = useState<string>("");

  const [descriptionEN, setDescriptionEN] = useState("");
  const [descriptionTH, setDescriptionTH] = useState("");
  const [coverEN, setCoverEN] = useState("");
  const [coverTH, setCoverTH] = useState("");

  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });
  const [visibleImage, setVisibleImage] = useState<boolean>(false);
  const [visibleGallery, setVisibleGallery] = useState<boolean>(false);
  const [visibleCoverImage, setVisibleCoverImage] = useState<boolean>(false);
  const [listForm, setListForm] = useState<boolean>(false);
  const [mediaSelectCoverImage, setMediaSelectCoverImage] =
    useState<IMediaSelect>({
      file: "",
      id: "",
      media_name: "",
    });

  const [productGallery, setProductGallery] = useState<IGallery[]>([]);
  const setLoading = useSetRecoilState(loadingState);

  const [form] = Form.useForm();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const history = useHistory();

  useEffect(() => {
    setListForm(history.location.pathname.includes("list"));
    if (!_isEmpty(pathID)) {
      handleGetProduct();
    }
    // eslint-disable-next-line
  }, []);

  const handleGetProduct = async () => {
    setLoading(true);
    try {
      const res = await getProductCategoryContentByID(pathID);
      if (res.code === 200) {
        const data = res.result[0];
        setProductGroupName(res.result[0].product_category.name_th);
        form.setFieldsValue({
          ...data,
          name_th: data.product_category.name_th,
        });

        if (!_isEmpty(data.media)) {
          setMediaSelect({
            file: data.media.file_path,
            id: data.media.id,
            media_name: data.media.media_name,
          });
        }
        if (!_isEmpty(data.media)) {
          setMediaSelectCoverImage({
            file: data.cover_image?.file_path,
            id: data.cover_image?.id,
            media_name: data.cover_image?.media_name,
          });
        }
        if (!_isEmpty(data.gallerys)) {
          setProductGallery(data.gallerys);
        }

        setDescriptionEN(data.description_en);
        setDescriptionTH(data.description_th);
        setCoverEN(data.cover_description_en);
        setCoverTH(data.cover_description_th);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleUpdateProductFormGroup = async (data: any) => {
    try {
      let galleryIDList = [];
      if (!listForm) {
        galleryIDList = findItemByKey("id", productGallery);
      }
      let mapKeyDataList = {};
      let mapKeyDataGroup = {};

      if (listForm) {
        mapKeyDataList = {
          ...data,
          media: mediaSelect.id,
          cover_image: mediaSelectCoverImage.id,
        };
      } else {
        mapKeyDataGroup = {
          ...data,
          gallerys: galleryIDList,
          cover_image: mediaSelectCoverImage.id,
        };
      }
      const res: IResStatus = await putProductCategoryContent(
        listForm ? mapKeyDataList : mapKeyDataGroup,
        pathID
      );
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {}
  };

  const onModalImage = () => {
    setVisibleImage(!visibleImage);
  };
  const onModalGallery = () => {
    setVisibleGallery(!visibleGallery);
  };
  const onModalCoverImage = () => {
    setVisibleCoverImage(!visibleCoverImage);
  };

  return (
    <div>
      <MediaModal
        visible={visibleImage}
        onModal={onModalImage}
        setMediaSelect={setMediaSelect}
      />
      <MediaModal
        visible={visibleGallery}
        onModal={onModalGallery}
        gallery={productGallery}
        setGellery={setProductGallery}
      />
      <MediaModal
        visible={visibleCoverImage}
        onModal={onModalCoverImage}
        setMediaSelect={setMediaSelectCoverImage}
      />
      <Form
        layout="vertical"
        form={form}
        onFinish={handleUpdateProductFormGroup}
      >
        <Card
          extra={productGroupName}
          actions={[
            <Row justify="end">
              <Col>
                <Button type="default" onClick={() => history.goBack()}>
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button className="btn-save" htmlType="submit">
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row gutter={[14, 14]}>
            <Col lg={12}>
              <Form.Item
                label="หมวดหมู่"
                name="name_th"
                rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col lg={12}></Col>
            <Col lg={24} className="mt-1">
              <Form.Item
                label="รูปปก"
                name="cover_image"
                rules={[
                  {
                    required: _isEmpty(mediaSelectCoverImage) ? true : false,
                    message: "โปรดกรอกข้อมูล!",
                  },
                ]}
              >
                <MediaSpace
                  onModal={onModalCoverImage}
                  mediaSelect={mediaSelectCoverImage}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                name="cover_description_th"
                rules={[
                  {
                    required: !_isEmpty(coverTH) ? false : true,
                    message: "โปรดใส่ข้อมูลของท่านด้วย",
                  },
                ]}
              >
                <Editor
                  header="ข้อความปก (TH)"
                  content={coverTH}
                  key={coverTH ? "succeed" : "failed"}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                name="cover_description_en"
                rules={[
                  {
                    required: !_isEmpty(coverEN) ? false : true,
                    message: "โปรดใส่ข้อมูลของท่านด้วย",
                  },
                ]}
              >
                <Editor
                  header="ข้อความปก (EN)"
                  content={coverEN}
                  key={coverEN ? "succeed" : "failed"}
                />
              </Form.Item>
            </Col>
            {listForm && (
              <Col lg={12}>
                <Form.Item
                  label="รูปภาพ"
                  name="image"
                  rules={[
                    {
                      required: _isEmpty(mediaSelect) ? true : false,
                      message: "โปรดกรอกข้อมูล!",
                    },
                  ]}
                >
                  <MediaSpace
                    onModal={onModalImage}
                    mediaSelect={mediaSelect}
                  />
                </Form.Item>
              </Col>
            )}

            <Col lg={24}>
              <Form.Item
                name="description_th"
                rules={[
                  {
                    required: !_isEmpty(descriptionTH) ? false : true,
                    message: "โปรดใส่ข้อมูลของท่านด้วย",
                  },
                ]}
              >
                <Editor
                  header="รายละเอียด (TH)"
                  content={descriptionTH}
                  key={descriptionTH ? "succeed" : "failed"}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                name="description_en"
                rules={[
                  {
                    required: !_isEmpty(descriptionEN) ? false : true,
                    message: "โปรดใส่ข้อมูลของท่านด้วย",
                  },
                ]}
              >
                <Editor
                  header="รายละเอียด (EN)"
                  content={descriptionEN}
                  key={descriptionEN ? "succeed" : "failed"}
                />
              </Form.Item>
            </Col>
            {!listForm && (
              <Col lg={24}>
                <Form.Item
                  label=" "
                  name="gallerys"
                  rules={[
                    {
                      required: !_isEmpty(productGallery) ? false : true,
                      message: "โปรดใส่ข้อมูลของท่านด้วย",
                    },
                  ]}
                >
                  <Gallery
                    onModal={onModalGallery}
                    gallery={productGallery}
                    setGellery={setProductGallery}
                    label="แกลเลอรีสินค้า"
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
