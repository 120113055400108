import React from "react";
import { IMediaSelect } from "../../interface/IMedia.interface";

import Space from "./Space";
import SpacePhoto from "./SpaceUploadPhoto";
type Props = {
  onModal?: () => void;
  visible?: boolean;
  mediaSelect?: IMediaSelect;
  uploadPage?: boolean
};

const MediaSpace = ({ onModal, visible = false, mediaSelect, uploadPage }: Props) => {
  return (
    uploadPage === true ? (
      <div>
        <SpacePhoto onModal={onModal} visible={visible} mediaSelect={mediaSelect} />
      </div >
    ) : (
      <div>
        <Space onModal={onModal} visible={visible} mediaSelect={mediaSelect} />
      </div >
    )
  );
};

export default MediaSpace;
