import React from "react";
import { Button, Col, Row } from "antd";
import { useHistory } from "react-router-dom";
import BlackHome from "../../../../assets/img/icons/black-home.svg";
import Fields from "./Fields";

const Form = () => {
  const history = useHistory();
  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <Col>
            <span className="blackHome">
              <Button
                onClick={() => history.goBack()}
                type="primary"
                shape="circle"
              >
                <img className="buttonBlackHome" src={BlackHome} alt="" />
              </Button>
            </span>
            <span className="text-head ">ไอเดีย</span>
          </Col>
        </Col>
      </Row>
      <div className="set-layout">
        <div className="pt-1">
          <Fields />
        </div>
      </div>
    </div>
  );
};

export default Form;
