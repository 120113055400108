import { Fecth, FecthFile, FecthFormdata } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from '../../tools/util'
import { IMediaFilterSearch } from '../../interface/IMedia.interface'

export interface IMedia {
    media_name: string;
    media_category_id: number;
    url?: string
    media?: Blob
}


export const getMedia = async (qs: IMediaFilterSearch) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/media-object${queryStr}`,
    })
    return res
}

export const getMediaDowloadFileByID = async (id: number) => {
    const res = await FecthFile({
        method: "GET",
        path: `${version}/media-object/download/${id}`,
    })
    return res
}


export const postMediaImage = async (data: any) => {

    const res = await FecthFormdata({
        data,
        method: "POST",
        path: `${version}/media-object`,
    })
    return res
}

export const postMediaManyImage = async (data: any) => {

    const res = await FecthFormdata({
        data,
        method: "POST",
        path: `${version}/media-object/many`,
    })
    return res
}
export const postMediaManyFile = async (data: any) => {
    const res = await FecthFormdata({
        data,
        method: "POST",
        path: `${version}/media-object/file-many`,
    })
    return res
}

export const postMediaFile = async (data: any) => {

    const res = await FecthFormdata({
        data,
        method: "POST",
        path: `${version}/media-object/file`,
    })
    return res
}


export const getMediaCategory = async () => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/media-category`,
    })
    return res
}

export const putMedia = async (data: any, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/media-object/${id}`,
    })
    return res
}

export const delMediaCategory = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/media-object/${id}`,
    })
    return res
}