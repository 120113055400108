import React, { useState } from "react";
import { Button, Col, Row, Tabs } from "antd";
import InspiTab from "./inspitab/InspiTab";
import GroupTab from "./grouptab/GroupTab";
import InspiHeader from "./inspitab/Headers";
import GroupHeader from "./grouptab/Headers";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { IProductCategory } from "../../interface/IProductCategory.interface";
import { useRecoilState } from "recoil";
import { tabSelectedInspirationState } from "../../tools/recoil/recoil-atom";
import { displayHide } from "../../tools/util";

const Inspiration: React.FC = () => {
  const [tabSelected, setTabSelected] = useRecoilState(
    tabSelectedInspirationState
  );
  const [inspirationCategoryList, setinspirationCategoryList] = useState<
    IProductCategory[]
  >([]);

  const history = useHistory();
  const [searchInspiGroup, setSearchInspiGroup] = useState<any>();
  const [searchInspi, setSearchInspi] = useState<any>();
  const [currentPageInspi, setCurrentPageInspi] = useState<number>(1);
  const [currentPageGroup, setCurrentPageGroup] = useState<number>(1);

  const { TabPane } = Tabs;

  const onFinishInspi = (values: any) => {
    setSearchInspi(values);
    setCurrentPageInspi(1);
  };

  const onFinishGroup = (values: any) => {
    setSearchInspiGroup(values);
    setCurrentPageGroup(1);
  };

  const handleChangePage = () => {
    tabSelected === "1"
      ? history.push("/inspiration/inspiration/create")
      : tabSelected === "2"
      ? history.push("/inspiration/groupTab/create")
      : history.push("/inspiration");
  };

  const callback = (key: any) => {
    setTabSelected(key);
  };

  return (
    <div>
      <Row className="second-header" justify="space-between" align="middle">
        <Col>
          <span className="text-head">ไอเดีย</span>
        </Col>
        <Col lg={2}>
          <Button
            className="btn-create"
            type="primary"
            onClick={handleChangePage}
          >
            <PlusOutlined className="icons-size" /> สร้าง
          </Button>
        </Col>
      </Row>
      <div className="set-layout">
        <div className={displayHide(tabSelected, "1")}>
          <InspiHeader
            inspirationCategoryList={inspirationCategoryList}
            onFinish={onFinishInspi}
          />
        </div>

        <div className={displayHide(tabSelected, "2")}>
          <GroupHeader onFinish={onFinishGroup} />
        </div>

        <div className="pt-1">
          <Tabs
            className="tab-mainmenu"
            defaultActiveKey={tabSelected}
            onChange={callback}
          >
            <TabPane tab="ไอเดีย" key="1">
              <InspiTab
                setinspirationCategoryList={setinspirationCategoryList}
                search={searchInspi}
                currentPage={currentPageInspi}
                setCurrentPage={setCurrentPageInspi}
              />
            </TabPane>
            <TabPane tab="หมวดหมู่" key="2">
              <GroupTab
                search={searchInspiGroup}
                currentPage={currentPageGroup}
                setCurrentPage={setCurrentPageGroup}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Inspiration;
