import { Row, Col, Input, Form } from "antd";

type Props = {
  edit: boolean;
};

const setPassword = ({ edit }: Props) => {
  return (
    <div>
      <Row>
        <div>
          <b>ตั้งค่ารหัสผ่าน</b>
        </div>
      </Row>
      <Row gutter={20}>
        <Col lg={12}>
          <Form.Item
            label={!edit ? "รหัสผ่าน" : "รหัสผ่านใหม่"}
            name="password"
            className="mt-1"
            rules={[{ required: true, message: "input your password" }]}
          >
            <Input.Password
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[\]^_`)(?=.*[A-Z]).{8,}"
              title="โปรดตั้งรหัสอย่างน้อย 8 ตัว โดยประกอบด้วย  ตัวอักษร (a-z, A-Z) ตัวเลข (0-9) เครื่องหมายหรืออักขระพิเศษ  (!@#$%^*()_+|~-=\`{}[]:”;'<>?,./)"
            />
          </Form.Item>
        </Col>
        <Col lg={12}>
          <Form.Item
            label={!edit ? "ยืนยันรหัสผ่าน" : "ยืนยันรหัสผ่านใหม่"}
            name="confirmPassword"
            className="mt-1"
            dependencies={["password"]}
            rules={[
              { message: "input your password", required: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("รหัสผ่านไม่ตรง"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      {edit && (
        <Row gutter={20}>
          <Col lg={12}>
            <Form.Item
              label="รหัสผ่านเก่า"
              className="mt-1"
              name="current_password"
              rules={[{ message: "input your password", required: true }]}
            >
              <Input.Password className="" />
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default setPassword;
