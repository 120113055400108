import { Fecth, FecthFile, FecthFormdata } from '../../tools/fecth/fecth'
import { version } from '../../config/api'
import { createQueryString } from '../../tools/util'


export const getGlass = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/glass${queryStr}`,
    })
    return res
}

export const getGlassById = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/glass/${id}`,
    })
    return res
}
export const postGlass = async (data: any) => {
    const res = await Fecth({
        data,
        method: "POST",
        path: `${version}/glass`,
    })
    return res
}

export const putGlass = async (data: any, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/glass/${id}`,
    })
    return res
}

export const delGlass = async (id: number) => {
    const res = await Fecth({
        method: "DELETE",
        path: `${version}/glass/${id}`,
    })
    return res
}
export const putGlassActive = async (id: number) => {
    const res = await Fecth({
        method: "PUT",
        path: `${version}/glass/active/${id}`,
    })
    return res
}

export const postExcelGlass = async (data: any) => {
    const res = await FecthFormdata({
        data,
        method: "POST",
        path: `${version}/glass/import/excel`,
    })
    return res
}

export const getExcelGlass = async () => {
    const res = await FecthFile({
        method: "GET",
        path: `${version}/glass/export/excel`,
    })
    return res
}

