import React from "react";
import { Button, Card, Col, Form, Input, Row } from "antd";

type Props = {
  onFinish: (values: any) => void;
};

const Header = ({ onFinish }: Props) => {
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Card className="card-round">
          <Row gutter={[14, 14]}>
            <Col lg={9}>
              <Form.Item name="search">
                <Input placeholder="ค้นหาชื่อรูปแบบการเปิดปิด" />
              </Form.Item>
            </Col>
            <Col lg={5} className="d-flex justify-between">
              <Button className="mr-1 btn-search" htmlType="submit" block>
                ค้นหา
              </Button>
              <Button className="clear" htmlType="reset" block>
                ล้างข้อมูล
              </Button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Header;
