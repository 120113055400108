import React from "react";
import { Col, Layout, Menu, Row } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../assets/img/New-Logo-Alumet-Hub-01.svg";
import navMenu from "../nav";

type Props = {
  onCollapse: (collapsed: any) => void;
  collapsed: boolean;
  toggleSidebar: () => void;
};

const TheSideBar = ({ onCollapse, collapsed, toggleSidebar }: Props) => {
  const location = useLocation();
  const history = useHistory();

  const { Sider } = Layout;

  const onChangePath = (pathname: string) => {
    history.push(pathname);
  };
  const filterNavPermission = (_nav: any) => {
    const userInfo = localStorage.getItem("cmsWebPlateformUserInfo");
    const { permission } = JSON.parse(userInfo ? userInfo : ``);
    const routeList = _nav.map((item: any) => {
      let findItem = "";
      permission.forEach((val: any) => {
        if (item.authentMenuName === val.name_en) {
          findItem = item;
        }
      });
      return findItem;
    });
    const result = routeList.filter((item: any) => item !== "");
    return result;
  };
  return (
    <>
      <Sider collapsed={collapsed} onCollapse={onCollapse}>
        <Row className="left-header">
          <Col className="d-flex justify-center items-center w-80 logo-visible">
            <img src={Logo} className="w-80" alt="logo" />
          </Col>
          <Col className="d-flex justify-flex-end items-center p-1  w-20 icons-collapsed">
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: toggleSidebar,
              }
            )}
          </Col>
        </Row>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
        >
          {filterNavPermission(navMenu).map((nav: any) => (
            <Menu.Item
              className="sidebar-menu-main"
              key={nav.route}
              icon={
                <img
                  src={nav.icons}
                  width={40}
                  className="icons"
                  alt={nav.name}
                />
              }
              onClick={() => onChangePath(nav.route)}
            >
              <span className="sidebar-item-menu">{nav.name}</span>
              {/* {nav.name} */}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
    </>
  );
};

export default TheSideBar;
