import { Button, Card, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import MediaModal from "../../../../component/mediamodal/MediaModal";
import MediaSpace from "../../../../component/mediaspace/MediaSpace";
import { openNotification } from "../../../../component/notification/Notification";
import Selector from "../../../../component/selector/Selector";
import { IColorsPost, IColors } from "../../../../interface/IColors.inteface";
import { IMediaSelect } from "../../../../interface/IMedia.interface";
import {
  getColorsByID,
  getColorsType,
  postColors,
  putColors,
} from "../../../../services/api/colors";
import { IResStatus } from "../../../../tools/fecth/interface";
import {
  loadingButtonState,
  loadingState,
} from "../../../../tools/recoil/recoil-atom";
import { _isEmpty } from "../../../../tools/util";

const Fields = () => {
  const [colorTypeList, setColorTypeList] = useState<IColors[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [mediaSelect, setMediaSelect] = useState<IMediaSelect>({
    file: "",
    id: "",
    media_name: "",
  });

  const [loadingButton, setloadingButton] = useRecoilState(loadingButtonState);
  const setLoading = useSetRecoilState(loadingState);

  const [form] = Form.useForm();
  const history = useHistory();
  const match: any = useRouteMatch();
  const pathID = match.params.id;

  useEffect(() => {
    handleGetData();
    // eslint-disable-next-line
  }, []);

  const handleGetData = () => {
    handleGetColorsType();
    if (!_isEmpty(pathID)) {
      handleGetColorsByID();
    }
  };

  const handleGetColorsByID = async () => {
    try {
      setLoading(true);
      const res: IResStatus = await getColorsByID(pathID);
      if (res.code === 200) {
        const data = res.result[0];
        setMediaSelect({
          file: data.media.file_path,
          id: data.media.id,
          media_name: data.media.media_name,
        });

        form.setFieldsValue({
          ...data,
          color_type: data.color_type.id,
          media_id: data.media.id,
        });
      }
    } catch (error) {}
    setLoading(false);
  };
  const handleGetColorsType = async () => {
    const res: IResStatus = await getColorsType({ is_active: true });
    if (res.code === 200) {
      setColorTypeList(res.result[0].item);
    }
  };

  const onFinish = (values: any) => {
    let valueForm = { ...values, price_per_kg: Number(values.price_per_kg) };

    if (!_isEmpty(pathID)) {
      handleUpdateColors(valueForm);
    } else {
      handleCreateColors(valueForm);
    }
  };

  const handleCreateColors = async (values: IColorsPost) => {
    try {
      setloadingButton(true);
      const data = {
        ...values,
        media: Number(mediaSelect.id),
      };
      const res: IResStatus = await postColors(data);
      if (res.code === 200) {
        openNotification({ method: "POST_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({ method: "POST_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };

  const handleUpdateColors = async (values: IColorsPost) => {
    try {
      setloadingButton(true);
      const res: IResStatus = await putColors(
        {
          ...values,
          media: Number(mediaSelect.id),
        },
        pathID
      );
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        history.push("/product");
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setloadingButton(false);
  };
  const onModal = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <MediaModal
        visible={visible}
        onModal={onModal}
        setMediaSelect={setMediaSelect}
      />
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Card
          extra={!_isEmpty(pathID) ? "แก้ไขสี" : "สร้างสี"}
          actions={[
            <Row justify="end">
              <Col>
                <Button type="default" onClick={() => history.goBack()}>
                  ยกเลิก
                </Button>
              </Col>
              <Col className="pl-1">
                <Button
                  className="btn-save"
                  htmlType="submit"
                  loading={loadingButton}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>,
          ]}
          className="card-form"
        >
          <Row justify="center">
            <Col lg={12}>
              <Row gutter={[14, 14]}>
                <Col lg={12}>
                  <Form.Item
                    label="ชื่อสี / ลวดลาย (TH)"
                    name="name_th"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="ชื่อสี / ลวดลาย (EN)"
                    name="name_en"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    label="ประเภทสี"
                    name="color_type"
                    rules={[{ required: true, message: "โปรดกรอกข้อมูล!" }]}
                  >
                    <Selector
                      Source={colorTypeList}
                      keyName="name_th"
                      keyValue="id"
                    />
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item
                    key={mediaSelect.media_name ? "mediaHave" : "mediaNull"}
                    label="รูปสี / ลวดลาย"
                    name="media_id"
                    rules={[
                      {
                        required: _isEmpty(mediaSelect.media_name)
                          ? true
                          : false,
                        message: "โปรดเลือกรูปภาพ!",
                      },
                    ]}
                  >
                    <MediaSpace onModal={onModal} mediaSelect={mediaSelect} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
