import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import MoTable from "../../../component/table/Table";
import Modaler from "../../../component/modaler/Modaler";
import { DateTimeStandard } from "../../../tools/date";

import {
  EditBy,
  IGrouptab,
} from "../../../interface/IInspirationCategory.interface";
import { useSetRecoilState } from "recoil";
import { loadingState } from "../../../tools/recoil/recoil-atom";
import {
  delInspirationCategoryList,
  getInspirationCategoryList,
  putInspirationCategoryListActive,
} from "../../../services/api/inspiration_category";
import { IResStatus } from "../../../tools/fecth/interface";
import { openNotification } from "../../../component/notification/Notification";
import { useHistory } from "react-router-dom";
import { _isEmpty } from "@yim/util-tool-ts";

type Props = {
  search: any;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const GroupTab = ({ search, currentPage, setCurrentPage }: Props) => {
  const setLoading = useSetRecoilState(loadingState);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [groupTabList, setGroupTabList] = useState<IGrouptab[]>([]);
  const [total, setTotal] = useState<number>(1);
  const [id, setId] = useState<number>(0);
  const history = useHistory();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!_isEmpty(search)) {
      setCurrentPage(1);
    }
    handleGetInspirationCategoryList();

    // eslint-disable-next-line
  }, [currentPage, limitPage, search]);

  const handleGetInspirationCategoryList = async () => {
    try {
      setLoading(true);

      const res: IResStatus = await getInspirationCategoryList({
        page: currentPage,
        limit: limitPage,
        ...search,
      });
      if (res.code === 200) {
        setGroupTabList(res.result[0].item);
        setTotal(res.result[0].total);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleDeleteInspirationList = async (id: number) => {
    try {
      setLoading(true);

      const res: IResStatus = await delInspirationCategoryList(id);
      if (res.code === 200) {
        openNotification({ method: "DEL_SUCCESS", message: res.message });
        handleGetInspirationCategoryList();
      } else {
        openNotification({ method: "DEL_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    onModal();
    setLoading(false);
  };

  const handleUpdateActive = async (id: number) => {
    try {
      setLoading(true);

      const res: IResStatus = await putInspirationCategoryListActive(id);
      if (res.code === 200) {
        openNotification({ method: "PUT_SUCCESS", message: res.message });
        handleGetInspirationCategoryList();
      } else {
        openNotification({ method: "PUT_ERROR", message: res.message });
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const onDelete = () => {
    handleDeleteInspirationList(id);
  };

  const onClickRow = (event: any) => {
    history.push("/inspiration/grouptab/" + event.id);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const columns: any = [
    {
      title: "สถานะ",
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: "10%",
      render: (status: boolean, data: any) => {
        return (
          <>
            <Switch
              checked={status}
              onClick={() => handleUpdateActive(data.id)}
            />
          </>
        );
      },
    },

    {
      title: "หมวดหมู่",
      dataIndex: "name_th",
      key: "name_th",
      width: "65%",
      render: (name_th: string) => {
        return (
          <>
            <b>{name_th}</b>
          </>
        );
      },
    },

    {
      title: "อัปเดต",
      dataIndex: "edit_by",
      key: "edit_by",
      width: "15%",
      render: (editBy: EditBy, data: IGrouptab) => {
        return (
          <>
            {editBy.first_name} {editBy.last_name}
            <br />
            {!_isEmpty(data.updated_at)
              ? DateTimeStandard(data.updated_at)
              : DateTimeStandard(data.created_at)}
          </>
        );
      },
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      render: (_: string, data: any) => {
        return (
          <>
            <DeleteOutlined
              className="pl-1"
              onClick={(e) => {
                setId(data.id);
                onModal();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <div>
      <div className="card-table-tab">
        <Modaler
          visible={visible}
          onCancel={onModal}
          onOk={onDelete}
          width="25%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              คุณแน่ใจไหม?
            </div>
          }
        >
          คุณต้องการลบหรือไม่ กระบวนการนี้ไม่สามารถยกเลิกได้
        </Modaler>
        <MoTable
          dataSource={groupTabList}
          columns={columns}
          onChangePage={onChangePage}
          config={{
            total: total,
            pageSize: limitPage,
            currentPage: currentPage,
          }}
          onClickRow={onClickRow}
        />
      </div>
    </div>
  );
};

export default GroupTab;
