import { Col, Row } from "antd";
import TagText from "../../../../component/map/TagText";
import SmartText from "../../../../component/smartText/SmartText";

type Props = {
  previewData: any;
  mediaSelect: any;
  lang: string;
};

const Preview = ({ previewData, mediaSelect, lang = "th" }: Props) => {
  return (
    <div className="preview-content ">
      <div className="py-5 bg-white">
        <Row justify="center">
          <Col xl={17} lg={20} md={20} xs={20}>
            <div className="border-radius-10 my-3">
              <img
                src={mediaSelect?.file || ""}
                style={{ borderRadius: 10 }}
                className="text-center border-radius-10"
                alt="mediaSelect"
              />
            </div>
            <TagText
              tag={
                lang === "th"
                  ? previewData?.article_category?.name_th
                  : previewData?.article_category?.name_en
              }
              date={previewData?.article_category?.updated_at}
              lang={lang}
            />
            <div
              className="py-3"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <div className="title-detail-article">
                {lang === "th" ? previewData?.title_th : previewData?.title_en}
              </div>
              <div className="text-detail-article">
                {lang === "th" ? previewData?.area_th : previewData?.area_en}
              </div>
            </div>
            <SmartText
              text={
                lang === "th"
                  ? previewData?.description_th
                  : previewData?.description_en || ""
              }
              lang={lang}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Preview;
