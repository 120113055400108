
import { version } from "../../config/api"
import { Fecth } from "../../tools/fecth/fecth"
import { createQueryString } from "../../tools/util"

export const getApproves = async (qs: any) => {
    const queryStr = createQueryString(qs)
    const res = await Fecth({
        method: "GET",
        path: `${version}/approve${queryStr}`,
    })
    return res
}

export const getApproveById = async (id: number) => {
    const res = await Fecth({
        method: "GET",
        path: `${version}/approve/${id}`,
    })
    return res
}
export const putApprove = async (data: any, id: number) => {
    const res = await Fecth({
        data,
        method: "PUT",
        path: `${version}/approve/${id}`,
    })
    return res
}




