import React from "react";
import DnDList from "../../../../../../component/@yim/react-dnd-list/List";
import "./styles.css";
import ItemCategoryComponent from "./ItemCategoryComponent";
interface Props {
  onModal: any;
  onDelete: any;
  list: any;
  setCategory: any;
  horizontal?: boolean;
  // itemComponent: React.ReactNode;
  setSwapThreshold?: number;
  setOverflowThreshold?: number;
  setGroup: any;
  setList: any;
  handleGetProductsGroup: () => void;
  // id: number;
}

function MainCategoryComponent(props: Props) {
  // console.log("main cate", props);

  const Comp = (p: any) => {
    return (
      <ItemCategoryComponent
        {...p}
        setGroup={props.setGroup}
        setList={props.setList}
        onModal={props.onModal}
        onDelete={props.onDelete}
        handleGetProductsGroup={props.handleGetProductsGroup}
        // id={props.id}
      />
    );
  };

  return (
    <div className="main-card-category">
      <DnDList
        items={props.list}
        setList={props.setCategory}
        horizontal={props.horizontal || false}
        itemComponent={Comp}
        setSwapThreshold={props.setSwapThreshold || null}
        setOverflowThreshold={props.setOverflowThreshold || null}
      />
    </div>
  );
}

export default MainCategoryComponent;
